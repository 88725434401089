import { AppConfigContext } from '@warnermmedia/gsp-core/brands/estadio/data-access';
import { useContext } from 'react';

export const ENV = {
  PRODUCTION: 'PRODUCTION',
  DEVELOPMENT: 'DEVELOPMENT',
  DEVELOPMENT_STAGING: 'DEVELOPMENT_STAGING',
  DEVELOPMENT_QA: 'DEVELOPMENT_QA',
  DEVELOPMENT_DEV: 'DEVELOPMENT_DEV',
  DEVELOPMENT_INT: 'DEVELOPMENT_INT',
  DEVELOPMENT_PROD: 'DEVELOPMENT_PROD',
} as const;

type EnvValues<T> = T[keyof T];
type EnvObj = EnvValues<typeof ENV>;

export const useGetEnvironment = () => {
  const appConfig = useContext(AppConfigContext);
  const envArray = appConfig?.appConfig.webUrl.split('.');
  let environment: EnvObj = ENV.DEVELOPMENT;
  let isDevelopment = true;
  let isProduction = false;
  const devEnv = ['dev', 'development'].includes(process.env.NODE_ENV ?? '');
  const nonprod = envArray?.includes('nonprod');
  const isEnvArray = envArray && envArray.length > 0;
  const isNonProdDev = isEnvArray && nonprod && devEnv;

  if (isNonProdDev && envArray?.includes('int')) {
    isDevelopment = true;
    isProduction = false;
    environment = ENV.DEVELOPMENT_INT;
  } else if (isNonProdDev && envArray?.includes('qa')) {
    isDevelopment = true;
    isProduction = false;
    environment = ENV.DEVELOPMENT_QA;
  } else if (isNonProdDev && envArray?.includes('stage')) {
    isDevelopment = true;
    isProduction = false;
    environment = ENV.DEVELOPMENT_STAGING;
  } else if (isNonProdDev && envArray?.includes('dev')) {
    isDevelopment = true;
    isProduction = false;
    environment = ENV.DEVELOPMENT_DEV;
  } else if (isEnvArray && !nonprod && devEnv) {
    isProduction = false;
    isDevelopment = true;
    environment = ENV.DEVELOPMENT_PROD;
  } else if (isEnvArray && !nonprod && !devEnv) {
    isProduction = true;
    isDevelopment = false;
    environment = ENV.PRODUCTION;
  }

  return {
    isDevelopment,
    isProduction,
    environment,
  };
};
