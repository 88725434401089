/**
 * SDK DATA-ACCESS CONSTS
 */

// Identity types to be used in Dalton core calls
export const IdentityTypes = {
  INTERNAL: '_INTERNAL',
  EMAIL: 'EMAIL',
};

// Error messages to be sent as alerts back to the user
export const ErrorMessages = {
  SIGNUP: 'There was an issue signing up',
  SIGNIN: 'There was an issue signing in',
  ACCOUNT: 'There was an issue getting profile',
  PREFERENCES: 'There was an issue getting preferences',
  BILLING_SYNC: 'There was an issue syncing your billing profile',
  BILLING_PROFILE: 'There was an issue getting your billing profile',
  LOGOUT: 'There was an issue logging out',
  USER_IDENTITY: 'There was an issue creating a user identity',
  UPDATE_USER: 'There was an issue updating your profile',
  ENTITLEMENTS: 'There was an issue gathering entitlements',
  PRODUCTS: 'There was an issue retrieving products',
  SUBSCRIPTIONS: 'There was an issue retrieving subscriptions',
  FORGOT_PASSWORD: 'There was an issue sending the email to reset your password',
  UPDATE_PASSWORD: 'There was an issue resetting your password',
  ATTACH_PAYMENT: 'There was an issue attaching the payment method to your account',
  PURCHASE_PREVIEW: 'There was an issue preparing your purchase',
  PURCHASE: 'There was an issue with your purchase',
  COUPON_DETAIL: 'There was an issue retrieving coupon details',
  REFRESH_TOKEN: 'There was an issue with refreshing your access token',
  REGENERATE_CONFIRMATION_CODE: 'There was an issue sending the email to regenerate your confirmation code',
  RESEND_VERIFICATION_EMAIL: 'There was an issue sending the email to regenerate your verification code',
  TRANSACTIONS_ERROR: 'There was an issue syncing fetching charges for your profile.',
  CONFIRM_VERIFICATION_EMAIL: 'There was an issue confirming your verification code',
  CANCEL_SUBSCRIPTION: 'There was an issue canceling your subscription',
};

export const VideoErrorMessages = {
  GEOBLOCK_EVENT_ERROR: 'Content blocked due to location',
  CONCURRENCY_LIMIT_EVENT_ERROR: 'Max allowed streams exceeded',
  TRY_AGAIN_LATER: 'There was a problem playing content. Please try again later.',
  AUTHORISATION_ERROR: 'authorization error',
};

// Headers
export const JSON_HEADER = "'Content-Type': 'application/json'";

// Error messages to be sent as alerts back to the user
export const PathNames = {
  // Dalton API Core Endpoints
  LOGIN: 'core/api/2/user/login',
  IDENTITY: 'core/api/1/identity',
  PROFILE: 'core/api/2/user',
  ACCOUNT: 'core/api/1/user',
  USER_CONTACT_NUMBER: 'core/api/1/user/contact_number',
  PREFERENCES: 'preferences/api/1/preferences',
  LOGOUT: 'core/api/1/user/logout',
  UPDATE_USER: 'core/api/1/user/profile',
  REFRESH_TOKEN: 'core/api/1/user/refresh_authtoken',
  FORGOT_PASSWORD: 'core/api/1/identity/password_reset_token',
  UPDATE_PASSWORD: 'core/api/1/identity/password',
  REGENERATE_CONFIRMATION_CODE: 'core/api/1/user/email/regenerate_confirmation_code',
  CONFIRM_VERIFICATION_CODE: 'core/api/1/user/email/confirm',

  // Dalton Billing/entitlements API
  BILLING_PROFILE: 'billing/api/1/profile',
  BILLING_PROFILE_SYNC: 'billing/api/1/billing_sync',
  REDEEM_COUPON: 'billing/api/1/subscription/',
  COUPON_DETAIL: 'billing/api/1/coupon',
  PURSCHASE_SUBSCRIPTION: 'billing/api/1/subscription/purchase',
  SESSIONID_HASH: 'billing/api/1/pmt/token',
  CANCEL_SUBSCRIPTION: 'billing/api/1/subscription',

  // subscription with paypal
  PURSCHASE_SUBSCRIPTION_REQUEST: 'billing/api/1/subscription/paypal/purchase',
  PURSCHASE_SUBSCRIPTION_FINALIZE: 'billing/api/1/subscription/paypal/finalize/',

  // Dalton API Regwall Endpoints
  ENTITLEMENTS: 'regwall/api/1/regwall/entitlements',
  PREAUTHORIZE: 'regwall/api/1/regwall/preauthorize',
  TRANSACTION_CHARGES: 'regwall/api/1/transactions/charges',

  // Dalton API Payment/Products Endpoints
  PURCHASE_PREVIEW: 'billing/api/1/subscription/purchase/preview',

  SUBSCRIPTIONS: 'regwall/api/1/subscriptions',

  PURCHASE: 'gizmo/api/1/wingman/purchase',
  PRODUCTS: 'gizmo/api/1/wingman/products',
  ATTACH_USER_PAYMENT: 'gizmo/api/1/wingman/paymentsource',
  // SUBSCRIPTIONS: 'gizmo/api/1/wingman/account/subscriptions',

  // Strapii API Endpoints
  STRAPII: 'strapii/api/1/authz/token',
  STRAPII_VIEW: 'strapii/api/1/authz/view',
};

export const apiFallBack = {
  DALTON_USERNAME: 'https://audience.bleacherreport.com/core/api/1/user',
  GATEKEEPER_URL_FALLBACK: 'https://gatekeeper.4bleacherreport.com/oauth2/device/code',
  GATEKEEPER_DEVICE_TOKEN: 'https://gatekeeper.4bleacherreport.com/oauth2/token',
  DALTON_AUTH_TOKEN: 'https://audience.qa.bleacherreport.com/core/api/1/gatekeeper/auth',
  // eslint-disable-next-line no-template-curly-in-string
  AIRCHAIN_SINGLE_EVENT_ENDPOINT: 'https://layserbeam.8bleacherreport.com/live_events/${id}?store=${store}',
  AIRCHAIN_EVENTS_ENDPOINT: 'https://layserbeam.8bleacherreport.com/connected_devices/landing',
};

export const EMAIL_VERIFICATION_STATUS = {
  NEW: 'NEW',
  CONFIRMED: 'CONFIRMED',
};

export const USER_EMAIL_STATUS = 'USER_EMAIL_STATUS';
export const USER_PREAUTZS = 'USER_PREAUTZS';
export const DALTON_AUTH_TOKEN = 'DALTON_AUTH_TOKEN';
export const TVE_TOOLBOX_AUTH_TOKEN = 'TVE_TOOLBOX_AUTH_TOKEN';
export const TVE_AUTH_PROVIDER = 'TVE_AUTH_PROVIDER';
export const CUSTOM_DOMAIN = 'CUSTOM_DOMAIN';
export const CALLED_USER_EMAIL_VALIDATION = 'CALLED_USER_EMAIL_VALIDATION';

export const TV_LOGIN_POLL_INTERVAL = 5000;

export const TVE_URN_PARAM = 'urn:tve:tntsports';
