import React from 'react';
import { Text, View } from 'react-native';
import { CardProps, getLocalDate, PressableWithOpacity } from '@warnermmedia/gsp-core/sdk/ui';
import { Cta } from '../../cta/cta';
import { getCardSettings } from '../atoms/cardsAtoms';
import { getStyles } from './tickerCard.styles';
import { carouselBase, languageStrings, team } from '@warnermmedia/gsp-core/brands/estadio/feature';

export const TickerCard = ({ item, action, breakpoints, colors, device }: CardProps<carouselBase>) => {
  const cardSettings = getCardSettings(colors);
  const styles = getStyles(breakpoints, device.isTv, colors);
  const refreshLabelChange = device.isTv ? cardSettings.refreshLabelTv : cardSettings.refreshLabel;
  const playLabelChange = device.isTv ? cardSettings.playLabelTv : cardSettings.playLabel;

  function getReadableStart(epoch: number): string {
    const date = getLocalDate(epoch);
    return date ? date.format('DD MMM HH:mm') : '';
  }

  function getReadableResult(homeTeam?: team, awayTeam?: team): string {
    if (homeTeam && awayTeam) {
      let score = '';
      if (typeof homeTeam.score !== 'undefined' && typeof awayTeam.score !== 'undefined') {
        score = `${homeTeam.score}-${awayTeam.score}`;
      }
      return `${score}`;
    }
    return '';
  }

  if (!item.match) {
    return <View />;
  }

  return (
    <PressableWithOpacity onPress={() => (action ? action(item) : null)} style={styles.tickerWrapper}>
      <View style={styles.tickerContainer}>
        <View style={styles.tickerMain}>
          <View style={styles.tickerTextScore}>
            <Text accessible={false} style={styles.tickerTeam}>
              {item.match.homeTeam ? item.match.homeTeam.tricode : ''}
            </Text>
          </View>
          {!item.match.gameStatus ||
            (item.match.gameStatus < 3 && (
              <View style={styles.tickerTextScore}>
                <Text accessible={false} style={styles.tickerText}>
                  VS
                </Text>
              </View>
            ))}
          {item.match.gameStatus && item.match.gameStatus >= 3 && (
            <View style={styles.tickerTextScore}>
              <Text accessible={false} style={styles.tickerText}>
                {getReadableResult(item.match.homeTeam, item.match.awayTeam)}
              </Text>
              {item.match.homeTeam &&
                item.match.homeTeam.penalty &&
                item.match.awayTeam &&
                item.match.awayTeam.penalty && (
                  <Text accessible={false} style={styles.tickerTextPenalty}>
                    ({item.match.homeTeam.penalty}-{item.match.awayTeam.penalty})
                  </Text>
                )}
            </View>
          )}
          <View style={styles.tickerTextScore}>
            <Text accessible={false} style={styles.tickerTeam}>
              {item.match.awayTeam ? item.match.awayTeam.tricode : ''}
            </Text>
          </View>
        </View>

        <View style={styles.tickerStatus}>
          {!item.match.gameStatus ||
            (item.match.gameStatus < 3 && (
              <Text accessible={false} style={styles.status}>
                {item.match.gamestateDisplay && item.match.gamestateDisplay !== ''
                  ? item.match.gamestateDisplay
                  : getReadableStart(item.match.startDateTime)}
              </Text>
            ))}
          {item.match.gameStatus && item.match.gameStatus === 3 && (
            <View>
              <Cta
                icon={playLabelChange}
                position="left"
                text={languageStrings.default.liveLabel}
                textContainStyle={styles.labelContentStyle}
                textStyle={styles.ctaText}
                style={styles.playLabelStyle}
              ></Cta>
            </View>
          )}
          {item.match.gameStatus && item.match.gameStatus === 4 && (
            <View>
              <Cta
                icon={refreshLabelChange}
                position="left"
                text={languageStrings.default.revealLabel}
                textContainStyle={styles.labelContentStyle}
                textStyle={styles.ctaText}
                style={styles.refreshLabelStyle}
              ></Cta>
            </View>
          )}
        </View>
      </View>
    </PressableWithOpacity>
  );
};

export default TickerCard;
