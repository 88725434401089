import React, { KeyboardEvent, useCallback, useRef } from 'react';
import { Animated, ListRenderItemInfo, View } from 'react-native';
import { PipCard } from '../../cards/pip/pipCard';
import HeroCard from '../../cards/hero/heroCard';
import { DirectionalArrow } from '../directionalArrow/directionalArrow';
import {
  Breakpoints,
  Carousel,
  CarouselPropsExtra,
  DeviceTypes,
  PressableWithOpacity,
} from '@warnermmedia/gsp-core/sdk/ui';
import { carouselBase, useHideSwimlaneArrow, useReducedHeroWidth } from '@warnermmedia/gsp-core/brands/estadio/feature';
import { getStyles } from './hero.styles';

export interface HeroProps extends CarouselPropsExtra<carouselBase> {
  /**
   * Array of carousel items
   */
  data: carouselBase[];
  heroWidth?: number;
  cardBg?: string;
  breakpoints: Breakpoints;
  device: DeviceTypes;
  colors: ReactNativePaper.ThemeColors;
}

const Hero = ({
  index: slideIndex,
  laneWidth,
  indexUpdate,
  data,
  action,
  heroWidth,
  cardBg,
  breakpoints,
  colors,
  device,
  ...props
}: HeroProps) => {
  const styles = getStyles(breakpoints, device.isTv);
  const reducedItemWidth = useReducedHeroWidth(laneWidth, 146, 56, 46, 36);
  const { hideRightArrow, hideLeftArrow } = useHideSwimlaneArrow(1, slideIndex, data.length);
  const scrollX = useRef(new Animated.Value(0)).current;
  const isTinyMobileScreen = breakpoints.currentBreakpoints.isTiny || breakpoints.currentBreakpoints.isTnySm;
  const mobileHeroRailWidth = isTinyMobileScreen
    ? reducedItemWidth * (data.length || 1) + 40
    : reducedItemWidth * (data.length || 1) + 55;

  const onTabChange = useCallback(
    (e: KeyboardEvent) => {
      if (!indexUpdate) return;
      if (e.key === 'ArrowLeft') {
        slideIndex > 0 && indexUpdate(slideIndex - 1);
      } else if (e.key === 'ArrowRight') {
        slideIndex < data.length - 1 && indexUpdate(slideIndex + 1);
      } else {
        return;
      }
    },
    [slideIndex, data, indexUpdate]
  );

  const onCardPress = useCallback(
    (item: carouselBase) => {
      action && action(item);
    },
    [action]
  );

  const heroSlide = useCallback(
    (slideProps: ListRenderItemInfo<carouselBase>) => {
      const onPress = () => onCardPress(slideProps.item);
      return (
        <PressableWithOpacity style={styles.heroFocusStyle} onKeyDown={onTabChange} onPress={onPress}>
          <HeroCard
            laneWidth={laneWidth}
            action={action}
            {...slideProps}
            cardBg={cardBg}
            device={device}
            breakpoints={breakpoints}
            colors={colors}
          />
        </PressableWithOpacity>
      );
    },
    [action, cardBg, onTabChange, laneWidth, styles, onCardPress, device, breakpoints, colors]
  );

  const onScrollMobile = useCallback(() => {
    return Animated.event([{ nativeEvent: { contentOffset: { x: scrollX } } }], {
      useNativeDriver: false,
    });
  }, [scrollX]);

  const getCarousel = useCallback(
    () => (
      <Carousel
        index={slideIndex}
        indexUpdate={indexUpdate}
        data={data}
        renderItem={heroSlide}
        laneWidth={laneWidth}
        itemWidth={reducedItemWidth}
        showsHorizontalScrollIndicator={false}
        action={action}
        scrollEventThrottle={0}
        contentContainerStyle={[styles.heroCardMargin, device.isMobileDevice && { width: mobileHeroRailWidth }]}
        {...(device.isMobileDevice
          ? {
              onScroll: onScrollMobile(),
            }
          : {})}
        device={device}
        {...props}
      />
    ),
    [
      action,
      data,
      heroSlide,
      indexUpdate,
      device,
      laneWidth,
      onScrollMobile,
      props,
      reducedItemWidth,
      slideIndex,
      styles.heroCardMargin,
      mobileHeroRailWidth,
    ]
  );

  const getDirectionalArrow = useCallback(
    (isLeftPoint = false) => (
      <DirectionalArrow
        dataLength={data.length}
        index={slideIndex}
        indexUpdate={indexUpdate}
        isLeftPoint={isLeftPoint}
        device={device}
        colors={colors}
        breakpoints={breakpoints}
      />
    ),
    [data.length, slideIndex, indexUpdate, breakpoints, colors, device]
  );

  return (
    <View style={{ width: heroWidth }}>
      <View style={styles.arrowsWrapper}>
        {!hideLeftArrow && getDirectionalArrow(true)}
        <View>{getCarousel()}</View>
        {!hideRightArrow && getDirectionalArrow()}
      </View>
      <View style={styles.pip}>
        <PipCard
          reducedItemWidth={reducedItemWidth}
          currentIndex={slideIndex}
          scrollX={scrollX}
          dataLength={data.length}
          device={device}
          mobileWebPipCardWidth={(breakpoints.windowWidth * 0.5) / (data.length ?? 1)}
          breakpoints={breakpoints}
          colors={colors}
        />
      </View>
    </View>
  );
};

export default Hero;
