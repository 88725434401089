import merge from 'lodash/merge';
import { ImageStyle, Platform, StyleProp, StyleSheet, TextStyle, ViewStyle } from 'react-native';
import { Breakpoints } from '@warnermmedia/gsp-core/sdk/ui';
import { isCssSupported } from '@warnermmedia/gsp-core/brands/estadio/feature';

interface Styles {
  slide: ViewStyle;
  slideBg: ImageStyle;
  matchImages: ViewStyle;
  episodeImageContain: ViewStyle;
  episodeImage: ImageStyle;
  slideLeft: ViewStyle;
  slideRight: ViewStyle;
  slideRightActive: ViewStyle;
  slideRightInner: ViewStyle;
  ctaContain: ViewStyle;
  ctaContainBox: ViewStyle;
  ctaText: TextStyle;
  textBox: ViewStyle;
  title: TextStyle;
  subTitle: TextStyle;
  date: TextStyle;
  playButton: ViewStyle;
  teamLogoStyle: ImageStyle;
}

export const getStyles = (breakpoints: Breakpoints, isTv: boolean, colors: ReactNativePaper.ThemeColors) => {
  const isSpaceEvenlySupported = Platform.OS === 'web' ? isCssSupported('justify-content', 'space-evenly') : true;

  const base: StyleProp<Styles> = {
    slide: {
      flexDirection: 'column',
      alignItems: 'center',
      height: 284,
      width: 325,
      padding: 0,
      marginLeft: 5,
      marginRight: 5,
      marginTop: 4,
      marginBottom: 4,
    },
    slideBg: {
      borderRadius: 5,
    },
    slideLeft: {
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
    },
    matchImages: {
      width: '100%',
      flexDirection: 'row',
      justifyContent: isSpaceEvenlySupported ? 'space-evenly' : 'space-around',
      alignItems: 'center',
      marginTop: 35,
      marginBottom: 25,
    },
    episodeImageContain: {
      width: '100%',
    },
    episodeImage: {
      height: 160,
      margin: 10,
    },
    slideRight: {
      flexDirection: 'column',
      width: '50%',
      borderTopRightRadius: 5,
      borderBottomRightRadius: 5,
      flex: 1,
    },
    slideRightActive: {},
    slideRightInner: {
      marginVertical: 10,
      marginHorizontal: 50,
      marginTop: 'auto',
    },
    ctaContain: {
      marginBottom: 5,
      marginRight: 'auto',
      marginLeft: 'auto',
      alignItems: 'center',
    },
    ctaContainBox: {
      marginRight: 10,
      marginLeft: 15,
    },
    ctaText: {
      fontSize: 10,
      textTransform: 'uppercase',
      lineHeight: 16,
    },
    textBox: {
      marginBottom: 2,
    },
    title: {
      fontSize: 18,
      fontFamily: 'Oswald-Regular',
      color: colors.keyboardFocus.light,
      textAlign: 'center',
      textTransform: 'uppercase',
      lineHeight: 24,
    },
    subTitle: {
      fontSize: 12,
      fontFamily: 'Oswald-Regular',
      color: colors.keyboardFocus.light,
      textAlign: 'center',
      textTransform: 'uppercase',
    },
    date: {
      fontSize: 12,
      fontFamily: 'Oswald-Regular',
      color: colors.keyboardFocus.light,
      textAlign: 'center',
      textTransform: 'uppercase',
    },
    playButton: {
      height: '100%',
      width: '100%',
      position: 'absolute',
      alignItems: 'center',
      justifyContent: 'center',
      zIndex: 100,
    },
    teamLogoStyle: {
      margin: isSpaceEvenlySupported ? 5 : 0,
    },
  };

  const mediaQueries = isTv
    ? ({
        slide: {
          flexDirection: 'row',
          height: 382,
          width: 1260,
          marginLeft: 7,
          marginRight: 7,
          marginTop: 7,
          marginBottom: 7,
        },
        slideLeft: {
          width: '50%',
        },
        matchImages: {
          marginTop: 85,
          marginBottom: 85,
        },
        episodeImageContain: {},
        episodeImage: {
          height: 363,
          width: '100%',
        },
        textBox: {
          maxHeight: 140,
          marginBottom: 0,
        },
        title: {
          fontSize: 35,
          lineHeight: 46,
        },
        subTitle: {
          fontSize: 18,
        },
        date: {
          fontSize: 18,
        },
        ctaContainBox: {
          marginRight: 20,
          marginLeft: 30,
        },
        ctaText: {
          fontSize: 18,
          lineHeight: 24,
        },
      } as StyleProp<Styles>)
    : breakpoints.currentBreakpoints.isTiny || breakpoints.currentBreakpoints.isTnySm
    ? ({
        slideRight: {
          width: '100%',
        },
        matchImages: {
          marginTop: 15,
        },
        episodeImage: {
          height: 160,
        },
        slide: {
          height: 284,
        },
      } as StyleProp<Styles>)
    : breakpoints.currentBreakpoints.isSmMed
    ? ({
        slideRight: {
          width: '100%',
        },
        episodeImage: {
          height: 215,
        },
        slide: {
          height: 330,
        },
      } as StyleProp<Styles>)
    : breakpoints.currentBreakpoints.isMedLg || breakpoints.windowWidth === breakpoints.breakpointSizes.lg
    ? ({
        slide: {
          flexDirection: 'row',
          height: 214,
          width: 688,
        },
        episodeImage: {
          height: 194,
          width: '100%',
        },
        slideLeft: {
          width: '50%',
        },
        slideRight: {
          width: '50%',
        },
        matchImages: {
          marginTop: 15,
          marginBottom: 25,
        },
      } as StyleProp<Styles>)
    : breakpoints.currentBreakpoints.isLgXl ||
      breakpoints.currentBreakpoints.isXlXxl ||
      breakpoints.currentBreakpoints.isXxl
    ? ({
        slide: {
          flexDirection: 'row',
          height: 382,
          width: 1260,
        },
        slideLeft: {
          width: '50%',
        },
        matchImages: {
          marginTop: 85,
          marginBottom: 85,
        },
        episodeImageContain: {},
        episodeImage: {
          height: 363,
          width: '100%',
        },
        textBox: {
          maxHeight: 140,
          marginBottom: 0,
        },
        title: {
          fontSize: 35,
          lineHeight: 46,
        },
        subTitle: {
          fontSize: 18,
        },
        date: {
          fontSize: 18,
        },
        ctaContainBox: {
          marginRight: 20,
          marginLeft: 30,
        },
        ctaText: {
          fontSize: 18,
          lineHeight: 24,
        },
      } as StyleProp<Styles>)
    : {};
  const mergedStyles = merge(base, mediaQueries);

  return StyleSheet.create(mergedStyles);
};
