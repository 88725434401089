import React, { useCallback, useContext, useState } from 'react';
import {
  Dalton,
  languageStrings,
  ScreenEventType,
  useMparticleCustomEventObject,
  useMparticleScreenEvent,
} from '@warnermmedia/gsp-core/brands/estadio/feature';
import { getStyles } from './unsubscribeConfirm.styles';
import { useReactiveVar } from '@apollo/client';
import { LayoutType, LayoutWrapper, TitleType } from '../../../../layoutWrapper';
import { breakpointsStateStore, HistoryContext } from '@warnermmedia/gsp-core/brands/estadio/data-access';
import { EstadioButton } from '../../../../components';
import { MetaTags, TextContent } from '@warnermmedia/gsp-core/sdk/ui';
import { MParticleCustomEventTypes, mParticleEventProcessor } from '@warnermmedia/gsp-core/sdk/data-access';
import { CancellationReason } from '@warnermmedia/gsp-core/brands/estadio/ui';
import { useTheme } from 'react-native-paper';

interface UnsubscribeConfirm {
  subscriptionId: string;
  cancelingReason: CancellationReason;
  subscriptionEndDate: string;
}

export const UnsubscribeConfirm = ({ subscriptionId, cancelingReason, subscriptionEndDate }: UnsubscribeConfirm) => {
  const [canceled, setCanceled] = useState(false);
  const [loading, setLoading] = useState(false);
  const breakpoints = useReactiveVar(breakpointsStateStore);
  const historyContext = useContext(HistoryContext);
  const history = historyContext?.ready ? historyContext?.useHistory() : null;
  const { colors } = useTheme();
  const styles = getStyles(breakpoints, colors);
  const dalton = Dalton();
  const contentTitle = canceled
    ? languageStrings.default.cancelSubscriptionContentTitle
    : languageStrings.default.unsubscribeConfirmContentTitle;
  useMparticleScreenEvent(ScreenEventType.Unsubscribe, contentTitle);
  const mParticleEventData = useMparticleCustomEventObject({ contentTitle, section: ScreenEventType.Unsubscribe });

  const cancelSubscription = useCallback(async () => {
    if (subscriptionId && cancelingReason) {
      setLoading(true);
      const result = await dalton.cancelSubscription(subscriptionId, cancelingReason.key.toString());
      if (result.success) {
        mParticleEventProcessor.pushMParticleEvent(MParticleCustomEventTypes.CancellationCompleteEvent, {
          ...mParticleEventData,
          ...{ cancel_reason: cancelingReason.label },
        });
        setCanceled(true);
      }
      setLoading(false);
    }
    return;
  }, [subscriptionId, cancelingReason, mParticleEventData, dalton]);

  const backToProfile = useCallback(() => {
    history?.push('/profile');
  }, [history]);

  if (canceled) {
    return (
      <LayoutWrapper
        pageTitle={languageStrings.default.subscriptionCancelledTitle}
        layoutType={LayoutType.Full}
        titleType={TitleType.Left}
        subTitle={languageStrings.default.subscriptionCancelledLabel}
      >
        <MetaTags title={languageStrings.default.cancelSubscriptionContentTitle} />
        <TextContent style={styles.descriptionText}>
          {languageStrings.default.subscriptionExpireDescription(subscriptionEndDate)}
        </TextContent>
        <EstadioButton
          onPress={() => {
            history?.push('/home');
          }}
          label={languageStrings.default.subscriptionSuccessHomeBtnText}
          labelStyle={styles.cancelBtnText}
          btnStyle={styles.cancelBtn}
        />
      </LayoutWrapper>
    );
  }

  return (
    <LayoutWrapper
      pageTitle={languageStrings.default.subscriptionInfoCancelButton}
      layoutType={LayoutType.Full}
      titleType={TitleType.Left}
      subTitle={languageStrings.default.cancelSubscriptionWarningDescription}
    >
      <MetaTags title={languageStrings.default.unsubscribeConfirmContentTitle} />
      <EstadioButton
        onPress={backToProfile}
        label={languageStrings.default.backToAccountLabel}
        labelStyle={styles.activateBtnText}
        btnStyle={styles.activateBtn}
      />
      <EstadioButton
        onPress={cancelSubscription}
        disabled={loading}
        label={loading ? languageStrings.default.loadingText : languageStrings.default.cancelSubscriptionLabel}
        labelStyle={styles.cancelBtnText}
        btnStyle={styles.cancelBtn}
      />
    </LayoutWrapper>
  );
};

export default UnsubscribeConfirm;
