/* eslint-disable @nrwl/nx/enforce-module-boundaries */
import {
  BillingSubscriptionResponse,
  CancelSubscriptionResponse,
  ChargeType,
  ConfirmVerificationCodeResponse,
  CouponDetailResponse,
  CreateProfileParams,
  DALTON_AUTH_TOKEN,
  EMAIL_VERIFICATION_STATUS,
  GetSessionIdResponse,
  IdentityTypes,
  loadData,
  LogoutResponse,
  MapCouponResponse,
  PayPalSubscriptionParams,
  PreAuthzs,
  PurchasePreviewParams,
  PurchaseSubscriptionParams,
  PurchaseSubscriptionResponse,
  RedeemCouponParams,
  RedeemCouponResponse,
  RefreshTokenResponse,
  RequestPaypalSubscriptionResponse,
  ResendVerificationEmailResponse,
  saveData,
  SendSDKResponse,
  SubscriptionsResponse,
  UpdateUserContactNumberParams,
  UpdateUserContactNumberResponse,
  UpdateUserParams,
  UpdateUserPreferencesItemParams,
  UpdateUserPreferencesParams,
  UpdateUserPreferencesResponse,
  UpdateUserResponse,
  useDalton,
  User,
  USER_EMAIL_STATUS,
  UserEmailResponsesEntity,
  UserProfileResponse,
} from '@warnermmedia/gsp-core/sdk/data-access';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import {
  AppConfigContext,
  DaltonConfig,
  isUserEmailVerified,
  isUserLoggedIn,
  loginAPIStateStore,
  userHasSubscription,
} from '@warnermmedia/gsp-core/brands/estadio/data-access';
import { useContext, useState } from 'react';
import {
  getAuthData,
  getUserPrimaryEmail,
  handleApiCall,
  handleApiCallError,
  languageStrings,
  logoutEstadioApplication,
} from '@warnermmedia/gsp-core/brands/estadio/feature';
import { AxiosError } from 'axios';

export type Product = { sku: string; description: string };

interface FetchUserInfoProps {
  shouldGetBillingProfile?: boolean;
  token?: string;
  emailStatus?: string;
}

export const Dalton = () => {
  const appConfig = useContext(AppConfigContext);
  const daltonConfig: DaltonConfig | undefined = appConfig?.appConfig?.dalton;
  const apps = daltonConfig && daltonConfig.apps.length > 0 ? daltonConfig.apps : ['preferences', 'billing', 'regwall'];
  const dalton = useDalton(appConfig?.appConfig.apis.daltonAPI ?? '', daltonConfig?.tenant ?? 'estadiochile', apps);
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(loginAPIStateStore().isLogged);

  // Helper Functions
  const logoutApplication = (): void => {
    setIsAuthenticated(false);
    updateToken('');
    logoutEstadioApplication();
  };

  const getLocalUserData = (): { token: string; expiry: string; emailStatus: string } | null => {
    const userToken = getAuthData(DALTON_AUTH_TOKEN);
    const emailStatus = loadData(USER_EMAIL_STATUS);
    if (userToken && emailStatus) {
      return {
        token: userToken.token,
        expiry: userToken.expiry,
        emailStatus,
      };
    }
    return null;
  };

  const updateUserEmailStatus = (status: string): void => {
    loginAPIStateStore({ ...loginAPIStateStore(), checkUserEmail: status !== EMAIL_VERIFICATION_STATUS.CONFIRMED });
    isUserEmailVerified(status === EMAIL_VERIFICATION_STATUS.CONFIRMED);
    saveData(USER_EMAIL_STATUS, status);
  };

  const saveUserEmailStatus = (user: User | null) => {
    const primaryEmail: UserEmailResponsesEntity | undefined = getUserPrimaryEmail({ user } as UserProfileResponse);

    if (primaryEmail) {
      updateUserEmailStatus(primaryEmail.status);
    }
  };

  const validateSubscription = (entitlements: PreAuthzs[]) => {
    return !!entitlements.find((item) => item.entitlement === 'futbol');
  };

  const handleSetIsAuthenticated = (val: boolean) => {
    setIsAuthenticated(val);
  };

  const fetchUserInfo = async ({ shouldGetBillingProfile = true, token, emailStatus }: FetchUserInfoProps) => {
    const localUserData = getLocalUserData();
    const authToken = token || localUserData?.token;

    if (authToken) {
      setIsAuthenticated(true);
      isUserLoggedIn(true);
      updateToken(authToken || '');
      if (!loginAPIStateStore().user && !loginAPIStateStore().fetchingUser) {
        getProfile();
      }

      if (!loginAPIStateStore().checkingBillingProfile && loginAPIStateStore().user?.tid && shouldGetBillingProfile) {
        await getBillingProfile();
      }

      if (!localUserData?.emailStatus && loginAPIStateStore().user) {
        saveUserEmailStatus(loginAPIStateStore().user);
      }

      if (!localUserData?.emailStatus && emailStatus && !loginAPIStateStore().user) {
        updateUserEmailStatus(emailStatus);
      }
      if (
        !loginAPIStateStore().preferences &&
        !loginAPIStateStore().gettingPreferences &&
        !loginAPIStateStore().preferencesFetched
      ) {
        getPreferences();
      }
    } else {
      setIsAuthenticated(false);
      isUserLoggedIn(false);
      userHasSubscription(null);
    }
  };

  // Dalton Calls

  const login = async (
    userEmail: string,
    password: string,
    onSuccess: (result: UserProfileResponse) => void,
    onError: (error: AxiosError) => void
  ) => {
    try {
      const loginResponse = await dalton.login({
        principal: userEmail,
        credential: password,
        apps,
        identityType: IdentityTypes.EMAIL,
      });

      if (loginResponse.success && loginResponse.data) {
        setIsAuthenticated(true);
        saveUserEmailStatus(loginResponse.data.user);
        loginAPIStateStore({ ...loginAPIStateStore(), ...loginResponse.data });
        await getPreferences();
        await getBillingProfile();
        onSuccess(loginResponse.data);
      } else {
        throw loginResponse.error;
      }
    } catch (e) {
      isUserLoggedIn(false);
      onError(e as AxiosError);
    }
  };

  const logout = async (onSuccess: (result?: LogoutResponse) => void) => {
    try {
      await dalton.logout();

      // We want to run all logout processes no matter what we get back from Dalton
      logoutApplication();
      onSuccess();
    } catch (e) {
      logoutApplication();
      onSuccess();
    }
  };

  const createProfile = async (
    createProfileParams: CreateProfileParams
  ): Promise<SendSDKResponse<UserProfileResponse>> => {
    try {
      const createProfileResponse = await dalton.createProfile(createProfileParams);

      if (createProfileResponse.success) {
        saveUserEmailStatus(createProfileResponse?.data?.user ?? null);
        setIsAuthenticated(true);
        loginAPIStateStore({ ...loginAPIStateStore(), ...createProfileResponse.data, isNewUser: true });
      }
      return createProfileResponse;
    } catch (e) {
      return handleApiCallError(e as AxiosError);
    }
  };

  const signup = async (
    firstname: string,
    lastname: string,
    useremail: string,
    password: string,
    onSuccess: (result: UserProfileResponse) => void,
    onError: (error: AxiosError) => void
  ) => {
    try {
      const signupResponse = await dalton.signup({
        firstName: firstname,
        lastName: lastname,
        principal: useremail,
        credential: password,
        apps,
        identityType: IdentityTypes.EMAIL,
      });

      if (signupResponse.success) {
        const profileParams = {
          firstName: firstname,
          lastName: lastname,
          emailAddress: useremail,
          password: password,
          apps,
        };
        const profileResponse = await createProfile(profileParams);
        if (profileResponse.success && profileResponse.data) {
          isUserLoggedIn(true);
          userHasSubscription(false);
          onSuccess(profileResponse.data);
        } else {
          throw profileResponse.error;
        }
      } else {
        throw signupResponse.error;
      }
    } catch (e) {
      isUserLoggedIn(false);
      onError(e as AxiosError);
    }
  };

  const getProfile = async () => {
    try {
      loginAPIStateStore({ ...loginAPIStateStore(), fetchingUser: true });
      const profileResponse = await dalton.getProfile();
      if (profileResponse.success && profileResponse?.data) {
        loginAPIStateStore({
          ...loginAPIStateStore(),
          ...{ user: profileResponse?.data ?? null, fetchingUser: false },
        });
        return profileResponse;
      }
      throw profileResponse.error;
    } catch (e) {
      logoutApplication();
      return handleApiCallError(e as AxiosError, languageStrings.default?.tokenExpiredError);
    }
  };

  const getPreferences = async () => {
    try {
      loginAPIStateStore({ ...loginAPIStateStore(), gettingPreferences: true });
      const profileResponse = await dalton.getPreferences();
      if (profileResponse) {
        loginAPIStateStore({ ...loginAPIStateStore(), gettingPreferences: false, preferencesFetched: true });
      }

      if (profileResponse.success) {
        loginAPIStateStore({ ...loginAPIStateStore(), ...profileResponse.data });
        return profileResponse;
      }
      throw profileResponse.error;
    } catch (e) {
      return handleApiCallError(e as AxiosError);
    }
  };

  const getBillingProfile = async () => {
    try {
      loginAPIStateStore({ ...loginAPIStateStore(), checkingBillingProfile: true });
      const billingProfileResponse = await dalton.getBillingProfile();
      if (billingProfileResponse) {
        loginAPIStateStore({
          ...loginAPIStateStore(),
          checkBillingProfile: false,
          checkingBillingProfile: false,
        });
      }

      if (billingProfileResponse.success) {
        loginAPIStateStore({
          ...loginAPIStateStore(),
          preAuthorizedEntitlements: billingProfileResponse?.data?.preAuthorizedEntitlements ?? [],
        });
        userHasSubscription(validateSubscription(billingProfileResponse?.data?.preAuthorizedEntitlements ?? []));
        return billingProfileResponse;
      }
      throw billingProfileResponse.error;
    } catch (e) {
      logoutApplication();
      return handleApiCallError(e as AxiosError);
    }
  };

  const forgotPassword = async (
    userEmail: string,
    onSuccess: (result: unknown) => void,
    onError: (error: unknown) => void
  ) => {
    try {
      const forgotPasswordResponse = await dalton.forgotPassword({
        principal: userEmail,
        identityType: IdentityTypes.EMAIL,
      });
      if (forgotPasswordResponse.success) {
        onSuccess(forgotPasswordResponse.data);
      } else {
        throw forgotPasswordResponse.error;
      }
    } catch (e) {
      return onError(e);
    }
  };

  const updatePassword = async (
    userEmail: string,
    newPassword: string,
    resetToken: string,
    onSuccess: (result: unknown) => void,
    onError: (error: unknown) => void
  ) => {
    try {
      const authTokenResponse = await dalton.getAuthToken({ resetToken });
      const updatePasswordResponse = await dalton.updatePassword({
        principal: userEmail,
        credential: newPassword,
        identityType: IdentityTypes.EMAIL,
        authToken: authTokenResponse?.data ?? '',
      });
      if (updatePasswordResponse.success) {
        onSuccess(updatePasswordResponse);
      } else {
        throw updatePasswordResponse.error;
      }
    } catch (e) {
      return onError(e);
    }
  };

  const savePassword = (userEmail: string, newPassword: string, authToken: string) => {
    return dalton.updatePassword({
      principal: userEmail,
      credential: newPassword,
      identityType: IdentityTypes.EMAIL,
      authToken,
    });
  };

  const resendVerificationEmail = async (
    userEmail: string,
    onSuccess: (result?: ResendVerificationEmailResponse) => void,
    onError: (error: AxiosError) => void
  ) => {
    try {
      const resendVerificationEmailResponse = await dalton.resendVerificationEmail({
        emailAddress: userEmail,
      });

      if (resendVerificationEmailResponse.success) {
        onSuccess(resendVerificationEmailResponse?.data);
      } else {
        throw resendVerificationEmailResponse.error;
      }
    } catch (e) {
      onError(e as AxiosError);
    }
  };

  const getUserTransactions = async (onSuccess: (result: ChargeType[]) => void, onError: (error: unknown) => void) => {
    try {
      const transactionsResponse = await dalton.getTransactions();
      if (transactionsResponse.success && transactionsResponse?.data) {
        onSuccess(transactionsResponse.data.charges);
      } else {
        throw transactionsResponse.error;
      }
    } catch (e) {
      onError(e);
    }
  };

  const confirmVerificationCode = async (
    userConfirmationCode: string,
    onSuccess: (result: ConfirmVerificationCodeResponse | undefined) => void,
    onError: (error: AxiosError) => void
  ) => {
    try {
      const confirmResponse = await dalton.confirmVerificationCode({
        confirmationCode: userConfirmationCode,
      });
      if (confirmResponse.success) {
        updateUserEmailStatus(EMAIL_VERIFICATION_STATUS.CONFIRMED);
        onSuccess(confirmResponse.data);
      } else {
        throw confirmResponse.error;
      }
    } catch (e) {
      onError(e as AxiosError);
    }
  };

  const refreshToken = async (enforce = false): Promise<SendSDKResponse<RefreshTokenResponse>> => {
    try {
      //only refresh if token is expired.
      const tokenInfo = getLocalUserData();
      const currentTimeStamp = Date.now();
      if ((tokenInfo?.expiry && currentTimeStamp > parseInt(tokenInfo?.expiry)) || enforce) {
        const tokenResponse = await dalton.refreshToken();
        if (tokenResponse.success && tokenResponse?.data) {
          loginAPIStateStore({
            ...loginAPIStateStore(),
            authToken: tokenResponse.data.authToken ?? '',
          });
          return tokenResponse;
        }
        throw tokenResponse.error;
      }
      return { data: { authToken: tokenInfo?.token }, success: true };
    } catch (e) {
      logoutApplication();
      return handleApiCallError(e as AxiosError);
    }
  };

  const mapCoupon = async (couponCode: string, signal?: AbortSignal) =>
    handleApiCall<MapCouponResponse>(dalton.mapCoupon(couponCode, signal));

  const getSessionId = async () => handleApiCall<GetSessionIdResponse>(dalton.getSessionId());

  const purchasePreview = async (previewParams: PurchasePreviewParams, signal?: AbortSignal) =>
    handleApiCall<BillingSubscriptionResponse>(dalton.purchasePreview(previewParams, signal));

  const purchaseSubscription = async (purchaseParams: PurchaseSubscriptionParams) =>
    handleApiCall<PurchaseSubscriptionResponse>(dalton.purchaseSubscription(purchaseParams));

  const updateUser = async (userObj: UpdateUserParams) => handleApiCall<UpdateUserResponse>(dalton.updateUser(userObj));

  const updateUserContactNumber = async (userObj: UpdateUserContactNumberParams) =>
    handleApiCall<UpdateUserContactNumberResponse>(dalton.updateUserContactNumber(userObj));

  const removeUserContactNumber = async (contactNumberId: string) =>
    handleApiCall<UpdateUserContactNumberResponse>(dalton.removeUserContactNumber(contactNumberId));

  const updateUserPreferences = async (preferences: UpdateUserPreferencesParams) =>
    handleApiCall<UpdateUserPreferencesResponse>(dalton.updateUserPreferences(preferences));

  const updateUserPreferencesItem = async (preferences: UpdateUserPreferencesItemParams) =>
    handleApiCall<UpdateUserPreferencesResponse>(dalton.updateUserPreferencesItem(preferences));

  const cancelSubscription = async (subscriptionId: string, cancelingReason: string) =>
    handleApiCall<CancelSubscriptionResponse>(dalton.cancelUserSubscription(subscriptionId, cancelingReason));

  const getSubscriptions = async () => handleApiCall<SubscriptionsResponse>(dalton.getSubscriptions());

  const redeemCoupon = async (params: RedeemCouponParams) =>
    handleApiCall<RedeemCouponResponse>(dalton.redeemCoupon(params));

  const couponDetail = async (coupon: string, signal?: AbortSignal) =>
    handleApiCall<CouponDetailResponse>(dalton.couponDetail(coupon, signal));

  const subscriptionPurchaseRequest = async (purchaseParams: PayPalSubscriptionParams) =>
    handleApiCall<RequestPaypalSubscriptionResponse>(dalton.subscriptionPurchaseRequest(purchaseParams));

  const finalizeSubscriptionPurchase = async (vid: string) =>
    handleApiCall<PurchaseSubscriptionResponse>(dalton.finalizeSubscriptionPurchase(vid));

  const updateToken = (token: string) => {
    dalton.updateToken(token);
  };

  return {
    login,
    logout,
    signup,
    updateUser,
    updateUserPreferences,
    updateUserPreferencesItem,
    updateUserContactNumber,
    removeUserContactNumber,
    getProfile,
    getPreferences,
    getBillingProfile,
    mapCoupon,
    getSessionId,
    purchasePreview,
    purchaseSubscription,
    forgotPassword,
    updatePassword,
    resendVerificationEmail,
    confirmVerificationCode,
    updateToken,
    handleSetIsAuthenticated,
    getUserTransactions,
    getSubscriptions,
    redeemCoupon,
    couponDetail,
    cancelSubscription,
    refreshToken,
    subscriptionPurchaseRequest,
    finalizeSubscriptionPurchase,
    fetchUserInfo,
    updateUserEmailStatus,
    saveUserEmailStatus,
    isAuthenticated,
    savePassword,
    validateSubscription,
  };
};
