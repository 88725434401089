import React, { ReactNode } from 'react';
import { instance } from '../singleton';

/**
 * Scrollable marks the associated DOM node as being a scroll container for
 * arcade-machine. This will cause arcade-machine to ensure that, within these
 * scroll containers, any focused element is visible.
 */
export class NotScrollable extends React.PureComponent<{
  children: React.ReactNode;

  id?: string;
}> {
  /**
   * The node this element is attached to.
   */

  private scrollerRef = React.createRef<HTMLDivElement>();
  private node!: HTMLElement;

  componentDidMount(): void {
    const element = this.scrollerRef.current;
    if (!(element instanceof HTMLElement)) {
      throw new Error(`Attempted to mount an <ArcScrollable /> not attached to an element, got ${element}`);
    }

    this.node = element;
    instance.getServices().scrollRegistry.add({
      element,
      horizontal: false,
      vertical: false,
    });
  }

  componentWillUnmount(): void {
    const services = instance.maybeGetServices();
    if (services) {
      services.scrollRegistry.remove(this.node);
    }
  }

  render(): ReactNode {
    return (
      <div ref={this.scrollerRef} id={this.props.id}>
        {this.props.children}
      </div>
    );
  }
}

/**
 * HOC to create a Scrollable.
 */
export const ArcNotcrollable = <P extends Record<string, unknown> = Record<string, unknown>>(
  Composed: React.ComponentType<P>,

  id = ''
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  // eslint-disable-next-line react/display-name
) => (props: P) => (
  <NotScrollable id={id}>
    <Composed {...props} />
  </NotScrollable>
);
