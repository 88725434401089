import React, { useCallback, useContext } from 'react';
import { View } from 'react-native';
import { FooterData, HistoryContext } from '@warnermmedia/gsp-core/brands/estadio/data-access';
import { getStyles } from './footer.styles';
import PrivacyPreferenceCenter from './privacyPreferenceCenter/privacyPreferenceCenter';
import { Breakpoints, TextContent } from '@warnermmedia/gsp-core/sdk/ui';

/* eslint-disable-next-line */
export interface MiddleProps {
  links: FooterData[];
  breakpoints: Breakpoints;
  colors: ReactNativePaper.ThemeColors;
}

export const FooterMiddle = (props: MiddleProps) => {
  const historyContext = useContext(HistoryContext);
  const history = historyContext?.ready ? historyContext?.useHistory() : null;
  const styles = getStyles(props.breakpoints, props.colors);

  const onPress = useCallback(
    (item) => {
      if (item.callback) {
        item.callback(item.link);
      }
      if (item.link) {
        history?.push(item.link);
      }
    },
    [history]
  );

  return (
    <View style={styles.linkContainer}>
      {props.links.map((item, index) => {
        return item.id === 4 ? (
          <PrivacyPreferenceCenter key={item.id} link={item} />
        ) : (
          <TextContent
            key={item.id}
            style={[styles.itemTitleStyle, index === 0 ? styles.itemStyleNoBorder : {}]}
            onPress={() => onPress(item)}
          >
            {item.title}
          </TextContent>
        );
      })}
    </View>
  );
};
