import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { emitCustomEvent, useCustomEventListener } from 'react-custom-events';
import {
  languageStrings,
  useIsMountedRef,
  useVideo,
  videoPlayerError,
} from '@warnermmedia/gsp-core/brands/estadio/feature';
import {
  PlayerMediaConfig,
  TOP,
  useContentEntryBuilder,
  usePlayerConfigBuilder,
  useVideoPlayer,
} from '@warnermedia/gsp-core/sdk/video';
import {
  ConvivaAnalyticsConfig,
  DeepPartial,
  MediaState,
  Player,
  PlayerConfigUIData,
  UIControlName,
  UIControlSetConfig,
} from '@top/player-block-web';
import { useReactiveVar } from '@apollo/client';
import {
  AppConfigContext,
  errorMsgStore,
  EstadioContext,
  isVideoPlayerFullScreen,
  loginAPIStateStore,
  PlayerState,
  playerStateStore,
  videoStates,
} from '@warnermmedia/gsp-core/brands/estadio/data-access';
import './VideoPlayer.scss';
import { Button as ARC_Button } from '@warnermedia/gsp-core/sdk/arcade-machine';
import { CSSProperties } from '@material-ui/core/styles/withStyles';
import { reportVideoErrorToConviva, useGetDevice, useGetEnvironment, APP_VERSION } from '@warnermmedia/gsp-core/sdk/ui';
import { KeyboardBackspace } from '@material-ui/icons';

import { loadData, VideoErrorMessages } from '@warnermmedia/gsp-core/sdk/data-access';
import { VideoErrorModal } from '@warnermmedia/gsp-core/brands/estadio/ui';

interface Props {
  mediaId: string;
  authToken?: string;
  isLive: boolean;
  title?: string;
  convivaTags?: { [x: string]: string | undefined } | undefined;
  showBackBtn: boolean;
  containerStyle?: CSSProperties;
  videoPlayerOverride?: CSSProperties;
  useRatioSizing?: boolean;
  withMediaConfig: PlayerMediaConfig;
  playerStartTime: string;
  isFreeVideo?: boolean;
  posterImage?: string | null | undefined;
  autoPlay?: boolean;
}

declare global {
  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace JSX {
    interface IntrinsicElements {
      'google-cast-launcher': React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement>;
    }
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const cast: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const chrome: any;
}
export const VideoPlayer: React.FC<Props> = ({
  mediaId,
  authToken = '',
  isLive,
  title,
  convivaTags,
  showBackBtn,
  containerStyle,
  videoPlayerOverride,
  withMediaConfig,
  playerStartTime,
  posterImage,
  useRatioSizing = true,
  isFreeVideo = false,
  autoPlay = true,
}): JSX.Element => {
  const evt = new Event('mouseenter');
  const appConfig = useContext(AppConfigContext);
  const { device, isWeb, isNotWeb } = useGetDevice();
  const {
    playerConfigSetup,
    playByMediaJson,
    chromeClicked,
    isPlaying,
    videoError,
    setVideoError,
    mediaPaused,
    player,
    refreshToken,
    setRefreshToken,
    isFullScreen,
  } = useVideoPlayer(playerStateStore, playerStartTime);
  const { setupEntryOptions } = useContentEntryBuilder();
  const isMountedRef = useIsMountedRef();
  const { setupConfig, updateConfig, uiPlayerControlsDefault, uiLivePlayerControlsDefault } = usePlayerConfigBuilder(
    withMediaConfig
  );
  const [castToken, setCastToken] = useState<string | undefined>(undefined);
  const videoContainerRef = useRef<HTMLDivElement>(null);
  const [topPlayer, setTopPlayer] = useState<Player>();
  const [controlsVisible, setControlsVisibility] = useState<boolean>(true);
  const [playerReady, setPlayerReady] = useState<boolean>(false);
  const [videoToken, setVideoToken] = useState<string | undefined>(undefined);
  const playerState = useReactiveVar<PlayerState>(playerStateStore);
  const { token, getNewVideoToken } = useVideo({ mediaId, token: authToken, isFreeVideo });
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const isAvailable = (window as { [key: string]: any })['castIsAvailable'] as boolean;
  const { magicRemoteVisible } = useContext(EstadioContext);
  const { isDevelopment } = useGetEnvironment();
  let debounceTimeout: ReturnType<typeof setTimeout>;

  const ratioDivStyles: CSSProperties = {
    paddingTop: '56.24%',
    backgroundColor: 'black',
  };
  const playerStyles: CSSProperties = { zIndex: 0, position: 'absolute', width: '100%', height: '100%', top: 0 };
  const isCastingAvailable = isAvailable && isWeb && chrome?.cast;
  let generatedToken: string;
  const convivaConfig: DeepPartial<ConvivaAnalyticsConfig> = {
    applicationName: `${appConfig?.appConfig?.dalton.tenant ?? ''} - ${device}`,
    customerKey: appConfig?.appConfig.video.convivaId,
    applicationVersion: loadData(APP_VERSION),
    assetName: title,
    ...(isDevelopment && {
      touchstoneUrl: appConfig?.appConfig.video.touchStoneApi,
    }),
  };

  const companyId = isLive ? appConfig?.appConfig.video.live.compId : appConfig?.appConfig.video.vod.compId;
  const env = isLive ? appConfig?.appConfig.video.live.env : appConfig?.appConfig.video.vod.env;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  let castSession: any;
  const castDevices = isAvailable && cast.framework.CastContext.getInstance().getCastState();
  const isDeviceAvailable = castDevices !== 'NO_DEVICES_AVAILABLE';

  const tokenRequest = async (forCast?: boolean) => {
    try {
      const vidToken = await getNewVideoToken();
      if (vidToken.length && isMountedRef.current) {
        if (forCast) {
          setCastToken(vidToken);
          generatedToken = vidToken;
        } else {
          setVideoToken(vidToken);
        }
      }
      setRefreshToken(false);
    } catch (error) {
      setRefreshToken(false);
    }
  };

  const handleEnter = () => {
    if (isPlaying) {
      player?.pause();
    } else if (mediaPaused) {
      player?.resume();
    } else {
      playVideo();
    }
  };

  const onButtonHandler = (event: KeyboardEvent) => {
    emitCustomEvent('BUTTON_PRESS', event.keyCode);
    if (device === 'tizen' && event.code === 'Enter') {
      handleEnter();
    }
  };

  const appVisibilityHandler = () => {
    if (document.visibilityState === 'hidden' && device === 'tizen') {
      topPlayer?.pause();
    }
  };

  const renderCastIcon = useCallback(() => {
    if (player) {
      TOP.topPlayer.sendMessageToUI('remoteTargetsChanged', {
        chromecast: {
          available: isDeviceAvailable, // <-- show / hide the casting icon
          active: chromeClicked, // <-- toggle blue line under the icon
        },
      });
    }
  }, [chromeClicked, isDeviceAvailable, player]);

  useEffect(() => {
    if (isMountedRef.current) {
      isVideoPlayerFullScreen(isFullScreen);
    }
  }, [isMountedRef, isFullScreen]);

  useEffect(() => {
    if (isMountedRef.current) renderCastIcon();
  }, [isMountedRef, renderCastIcon]);

  useEffect(() => {
    window.document.addEventListener('keydown', onButtonHandler, false);
    return () => {
      window.document.removeEventListener('keydown', onButtonHandler);
    };
  }, [isPlaying, mediaPaused]);

  useEffect(() => {
    document.addEventListener('visibilitychange', appVisibilityHandler);
    return () => {
      document.removeEventListener('visibilitychange', appVisibilityHandler);
    };
  }, [isPlaying, mediaPaused]);

  useEffect(() => {
    const getToken = async () => {
      if (isMountedRef.current && refreshToken) {
        tokenRequest();
        setRefreshToken(false);
      }
    };
    !isFreeVideo && getToken();
  }, [refreshToken, isMountedRef, isFreeVideo]);

  useEffect(() => {
    isVideoPlayerFullScreen(isFullScreen);
  }, [isFullScreen]);

  useEffect(() => {
    reportVideoErrorToConviva(videoError, player);
  }, [videoError, player]);

  const playVideo = () => {
    const playConfig = updateConfig(
      {
        appId: appConfig?.appConfig.video.appId,
        companyId,
        env,
      },
      undefined,
      {
        assetName: title,
        viewerId: !loginAPIStateStore().isTveAuth
          ? loginAPIStateStore().user?.tid
          : loginAPIStateStore().tveUserId || '',
        tags: convivaTags,
      },
      undefined
    );
    const entryData = setupEntryOptions(undefined, playConfig, videoToken);
    playByMediaJson({ mediaId }, entryData);
    setTopPlayer(TOP.topPlayer);
  };

  const entryDataConfig = setupEntryOptions(
    {},
    updateConfig(
      {
        appId: appConfig?.appConfig.video.appId,
        companyID: companyId,
        env,
      },
      undefined,
      {},
      undefined
    ),
    videoToken
  );

  const entryOption = {
    mediaId,
    mediaType: 'medium',
    accessToken: castToken || videoToken,
    accessTokenType: 'jws',
    config: entryDataConfig.config,
  };

  const setCastOptions = () => {
    const castContext = cast.framework.CastContext.getInstance();
    const environment = process.env.NODE_ENV;

    castContext.setOptions({
      receiverApplicationId: environment === 'prod' || environment === 'production' ? '827A44F6' : '52A88EAC',
      autoJoinPolicy: chrome.cast.AutoJoinPolicy.ORIGIN_SCOPED,
    });
  };

  const loadContent = async (token?: string) => {
    const castSession = cast.framework.CastContext.getInstance().getCurrentSession();
    if (castSession) {
      // use cast loadMedia API
      const mediaInfo = new chrome.cast.media.MediaInfo(mediaId, 'medium');
      const request = new chrome.cast.media.LoadRequest(mediaInfo);
      request.customData = entryOption;
      request.customData.accessToken = token || videoToken;
      try {
        const sessionObj = await castSession.getSessionObj();
        await new Promise((res) => {
          sessionObj.loadMedia(request, res);
          player?.stop();
        });
      } catch (e) {
        errorMsgStore({
          type: 'AXIOS_ERROR',
          message: languageStrings?.default?.cantPlayContentError,
        });
      }
    }
  };

  const generateTokenBeforeCast = async () => {
    try {
      const vidToken = await getNewVideoToken();
      if (vidToken?.length) {
        loadContent(vidToken);
      } else {
        errorMsgStore({
          type: 'AXIOS_ERROR',
          message: languageStrings?.default?.cantPlayContentError,
        });
      }
    } catch (error) {
      errorMsgStore({
        type: 'AXIOS_ERROR',
        message: languageStrings?.default?.cantPlayContentError,
      });
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const sessionStateChangedHandler = function (event: any) {
    switch (event.sessionState) {
      case cast.framework.SessionState.SESSION_STARTED:
      case cast.framework.SessionState.SESSION_RESUMED:
        castSession = cast.framework.CastContext.getInstance().getCurrentSession();
        generateTokenBeforeCast();
        break;
      case cast.framework.SessionState.SESSION_START_FAILED:
        errorMsgStore({
          type: 'AXIOS_ERROR',
          message: languageStrings?.default?.cantPlayContentError,
        });
        break;
      case cast.framework.SessionState.SESSION_ENDED:
        break;
    }
  };

  const initializeCastApi = async () => {
    const castContext = cast?.framework?.CastContext?.getInstance();
    setCastOptions();
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    if ('U' in castContext?.m) {
      const U = castContext?.m.U;

      if (U?.sessionstatechanged?.length) {
        return;
      }
    }
    castContext.addEventListener(cast.framework.CastContextEventType.SESSION_STATE_CHANGED, sessionStateChangedHandler);
  };

  const playPauseBtnSelect = (keyCode: number) => {
    const ppBtns = [
      ARC_Button.FireMediaPlayPause,
      ARC_Button.Pause,
      ARC_Button.Play,
      ARC_Button.SAMSUNG_PLAYPAUSE,
      ARC_Button.PlayPause,
    ];

    return ppBtns.includes(keyCode);
  };

  useCustomEventListener('BUTTON_PRESS', (msg) => {
    const playPauseSelect = playPauseBtnSelect(msg as number);
    if (topPlayer && playPauseSelect) {
      const mediaState = topPlayer.mediaState;
      if (mediaState === MediaState.Paused) {
        topPlayer.resume();
      } else {
        topPlayer.pause();
      }
    }
  });

  const disconnectCastSession = useCallback(() => {
    if (isWeb && isAvailable) {
      const castContext = cast?.framework?.CastContext?.getInstance();
      const castSession = castContext?.getCurrentSession();
      if (castSession) {
        cast.framework.CastContext.getInstance().endCurrentSession();
        castContext?.endCurrentSession(true);
      }
    }
  }, [isAvailable, isWeb]);

  const connect = useCallback(() => {
    try {
      if (isCastingAvailable) {
        const castSession = cast.framework.CastContext.getInstance().getCurrentSession();
        if (castSession) {
          cast.framework.CastContext.getInstance().requestSession();
          return;
        }
        if (!castSession && chromeClicked) {
          cast.framework.CastContext.getInstance().requestSession();
          return;
        }
      }
    } catch (error) {
      console.warn(error);
    }
  }, [chromeClicked, isCastingAvailable]);

  useEffect(() => {
    if (isMountedRef.current) {
      if (playerState.type === videoStates.PLAYER_READY) {
        setPlayerReady(true);
      }
      if (playerState.type === videoStates.CONTENT_ERROR) {
        const messageDisplay = videoPlayerError((playerState.message as string) || '');
        errorMsgStore({
          type: 'AXIOS_ERROR',
          message: messageDisplay,
        });
      }
      if (playerState.type === videoStates.CONTROLS_HIDDEN) {
        setControlsVisibility(false);
      }
      if (playerState.type === videoStates.CONTROLS_VISIBLE) {
        setControlsVisibility(true);
      }
      if (!mediaId) {
        errorMsgStore({
          type: 'AXIOS_ERROR',
          message: languageStrings?.default?.cantPlayContentError,
        });
      }
    }
  }, [isMountedRef, playerState, mediaId, isPlaying]);

  useEffect(() => {
    if (playerReady && (videoToken || isFreeVideo) && isMountedRef.current) {
      playVideo();
      if (isCastingAvailable) {
        initializeCastApi();
      }
    }
    return () => {
      if (castSession) {
        const castContext = cast.framework.CastContext.getInstance();
        castContext.removeEventListener(
          cast.framework.CastContextEventType.SESSION_STATE_CHANGED,
          sessionStateChangedHandler
        );
      }
    };
  }, [playerReady, videoToken, isMountedRef, isCastingAvailable, isFreeVideo]);

  useEffect(() => {
    if (isMountedRef.current) {
      connect();
    }
  }, [connect, isMountedRef]);

  useEffect(() => {
    if (isMountedRef.current && isPlaying) {
      disconnectCastSession();
    }
  }, [isMountedRef, isPlaying]);

  useEffect(() => {
    if (isMountedRef && token?.indexOf('error') === -1 && token?.indexOf('pending') === -1) {
      setVideoToken(token);
    }
  }, [isMountedRef, token]);

  useEffect(() => {
    if (videoContainerRef.current && isMountedRef.current) {
      const playerDefaultsConfig = isLive ? uiLivePlayerControlsDefault : uiPlayerControlsDefault;
      const posterImageConfig = posterImage ? { posterImage } : {};
      const newSet: UIControlSetConfig = {
        default: { ...playerDefaultsConfig },
        LIVE: {
          ...playerDefaultsConfig,
          order: {
            left: [UIControlName.Play, UIControlName.Volume],
            right: [...(isAvailable ? ['Cast'] : []), UIControlName.Fullscreen, UIControlName.Live],
            center: [UIControlName.Progress_Bar],
            topLeft: [],
            topRight: [],
          },
        },
        LIVE_NO_DVR: { ...playerDefaultsConfig },
        VOD: {
          ...playerDefaultsConfig,
          order: {
            left: [UIControlName.Play, UIControlName.Volume, UIControlName.Time_Duration],
            right: [...(isAvailable ? ['Cast'] : []), UIControlName.Fullscreen],
            center: [UIControlName.Progress_Bar],
            topLeft: [],
            topRight: [],
          },
        },
      };
      const configUI: DeepPartial<PlayerConfigUIData> = {
        sets: newSet,
        ...posterImageConfig,
      };
      playerConfigSetup(
        setupConfig(
          videoContainerRef.current,
          autoPlay,
          appConfig?.appConfig.video.appId ?? '',
          configUI,
          convivaConfig,
          undefined
        )
      );
    }
  }, [isMountedRef, videoContainerRef, posterImage]);

  /***Disconnect cast session if any video error occurs */
  useEffect(() => {
    if (
      videoError &&
      [VideoErrorMessages.GEOBLOCK_EVENT_ERROR, VideoErrorMessages.CONCURRENCY_LIMIT_EVENT_ERROR].includes(videoError)
    ) {
      disconnectCastSession();
    }
  }, [disconnectCastSession, videoError]);

  const handleOnclick = () => {
    window.history.back();
  };

  return (
    <>
      <div className="videoPlayer videoPlayerContain" style={containerStyle}>
        {/** This is a placeholder div component for the videoplayer initial loading
         *  phase which gives the player a ratio of 16:9, this div will removed
         *  from view once the player loads and starts the media playback
         */}
        {useRatioSizing && <div style={ratioDivStyles}></div>}
        <div
          id="player-container"
          className="videoPlayer__video"
          ref={videoContainerRef}
          style={videoPlayerOverride ? videoPlayerOverride : playerStyles}
        >
          {isWeb && showBackBtn && (
            <div className="back-button" tabIndex={0} onClick={handleOnclick}>
              <KeyboardBackspace htmlColor="#FFFFFF" focusable={true} />
            </div>
          )}
        </div>
      </div>
      <VideoErrorModal errMessage={videoError} setVideoError={setVideoError} isTv={false} />
    </>
  );
};

// Avoid unnecessary re-renders
export default React.memo(VideoPlayer);
