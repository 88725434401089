export default {
  app_name: 'EstadioTNTSports',
  app_version: 'Version',
  SplashScreenText: 'POTENTIAL STRAPLINE',

  // FOOTER STRINGS
  FOOTER_LABEL_1: 'TERMS AND CONDITIONS',
  FOOTER_LABEL_2: 'COOKIES POLICY',
  FOOTER_LABEL_3: 'PREFERENCE CENTER',
  FOOTER_LABEL_4: 'FAQ',
  FOOTER_LABEL_5: 'MINIMUM REQS.',
  FOOTER_LABEL_6: 'TICKET CONSULTATION',
  FOOTER_LABEL_7: 'TNTSports.com.ar',
  FOOTER_LABEL_8: 'GO TO STADIUM CHILE',
  FOOTER_LABEL_9: 'HELP',
  FOOTER_LABEL_10: 'PRIVACY POLICY',
  FOOTER_COPYRIGHT_1: 'TNT SPORTS STADIUM',
  FOOTER_COPYRIGHT_2: ', ALL RIGHTS RESERVED.',
  //////////////////////////////

  //Card State Labels
  todayLabel: 'Today',
  revealLabel: 'Reveal',
  liveLabel: 'Live',
  dayLabel: 'Day',

  //Error MessageDisplay
  contentLockError: 'Sorry, you are not authorized for this content.',
  noAccessError: 'You have no access to this content.',
  cantPlayContentError: "Can't play content, try again later.",
  tokenExpiredError: 'User token expired. Please login again.',
  locationError: 'Content blocked due to location',
  noAccessToContent: 'Sorry, you do not have access to this content. Contact us for more information.',
  maxStream: 'Max allowed streams exceeded',
  mediaNotAvailable: 'Media not available at this moment',

  //ResendEmailVerication
  emailVericationPageTitle: 'YOU MUST VALIDATE YOUR ACCOUNT',
  emailVericationPageTitleSuccess: 'Your email was successfully validated.',
  emailVericationPageTitleError: 'You must Validate your Account.',
  emailVericationParagraph_1: 'Validate your account by clicking on the e-mail we sent to',
  emailVerificationParagraph_2: "If you haven't received it, please click the button below to resend it.",
  emailVericationCtaButton: 'RESEND EMAIL',
  emailVericationMessage: 'Did you already validate your account?.',
  emailVericationLinkLabel: 'Please get in touch with us.',
  emailVericationLogoutMessage: 'Did you enter the wrong email?.',
  emailVericationLogoutLabel: 'Click here to start over.',
  emailVericationSuccessMessage: 'You have not received the validation email?.',
  emailVericationSuccessLabel: 'Get in contact with us.',
  emailVericationCheckButtonText: 'Check validation',
  emailVericationApiSuccess:
    'We sent an email to the account you used to register.\n\nPlease click on the email to validate your account.',
  emailVericationApiError: 'The email has not been sent, please try again.',
  emailVericationSuccessRouteMessage: 'You can now enter Estadio TNT Sports.',
  emailCheckvalidationMessageSuccess:
    'We have checked and your email is not verified yet.\nPlease click on the email to validate your account.',
  emailCheckvalidationMessageError: 'An error occurred checking the validation of your account.',

  //update app modal
  updateAppModalTitle: 'UPDATE AVAILABLE',
  updateAppModalDescription: 'A New Version of the app is available.',
  updateAppModalBtnText: 'UPDATE',
  // My Account
  editProfileSuccessMessage: 'Your personal information was successfully updated.',

  // Subscription
  buyNowLabel: 'BUY NOW',
  subscriptionFailureMessage: 'Invalid coupon code.',
  subscriptionInvalidPromocodeMessage: 'Invalid promo code.',
  subscriptionSuccessMessage: 'The coupon has been applied successfully.',
  subscriptionPreviewFailedMessage: 'Product preview failed!',
  subscriptionUsedPromocodeMessage: 'Promotion code already used for this account.',
  subscriptionPaymentFailedMessage: 'Subscription payment failed!',
  subscriptioncancelledMessage: 'Current subscription already cancelled, please re-subscribe to use promo code.',

  doNotRefreshLabel: "Please don't refresh this page",
  doNotRefreshDescription: 'Wait a few moments while your payment is processed.',

  loadingText: 'Loading...',
  subscriptionSuccessHomeBtnText: 'Go to start',
  subscriptionSuccessHomeQuestionBtnText: 'What is TNT Sports Stadium?',
  subscriptionSuccessRecommendationBtnText: 'Basic recommendations',
  subscriptionSuccessText1: 'Welcome, you already have your seat secured',
  subscriptionSuccessText2: 'The coupon has been applied successfully.',
  subscriptionSuccessText3: 'The transaction has been processed successfully.',
  subscriptionSuccessText4:
    'You can start enjoying all the Chilean soccer matches live and VOD, along with the best programming from TNT Sports Stadium. We also invite you to review some tips.',
  backToAccountLabel: 'Back to my account',
  cancelSubscriptionLabel: 'cancel my subscription',
  subscriptionCancelledLabel: 'You have canceled your subscription.',
  cancelSubscriptionWarningDescription: 'You are about to cancel your subscription. Are you sure?',

  // Metatags
  myAccountTitle: 'My Account - Estadio TNT Sports',
  appInfoTitle: 'App Information - Estadio TNT Sports',
};
