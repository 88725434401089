import React, { FC, useCallback, useContext, useMemo, useRef, useState } from 'react';
import { ListRenderItemInfo, View } from 'react-native';
import { Carousel, TextContent, useGetDevice, validateNonEmptyString, Variant } from '@warnermmedia/gsp-core/sdk/ui';
import {
  Dalton,
  languageStrings,
  MparticleEventDefaultPropertyValue,
  useHideSwimlaneArrow,
  useScrollToTop,
  useStatusMessage,
} from '@warnermmedia/gsp-core/brands/estadio/feature';
import { getStyles } from './subscriptionsSwimlane.styles';

import {
  AppConfigContext,
  breakpointsStateStore,
  Content,
  COUPON,
  getCountryNamesAndCode,
  Page,
} from '@warnermmedia/gsp-core/brands/estadio/data-access';
import {
  clearData,
  CouponType,
  loadData,
  MParticleAppConfigObject,
  MParticleCustomEventTypes,
  mParticleEventProcessor,
  PurchaseSubscriptionParams,
  saveData,
  SubscriptionStatus,
  VindiciaSuccessResponse,
} from '@warnermmedia/gsp-core/sdk/data-access';
import { CouponModal } from '../../modal/couponModal';
import CouponRedemptionModal from '../../modal/couponRedemptionModal';
import { ConsentMessage, DirectionalArrow, Loaders } from '../../../index.native';
import SubscriptionCard from '../subscriptionCard/subscriptionCard';
import { useReactiveVar } from '@apollo/client';
import { VindiciaFormWrapper } from '../vindicia';
import { ScrollViewRefContext } from '../../layout';
import { EstadioButton } from '../../components';
import get from 'lodash/get';
import { useTheme } from 'react-native-paper';

interface SubscriptionSwimlaneProps {
  mParticleEventData: MParticleAppConfigObject;
  pageUriData: Page | undefined;
  couponApplied: boolean;
  loaders: Loaders;
  setPurchaseState: (state: string) => void;
  setCouponApplied: (applied: boolean) => void;
  setCouponSuccessfullyApplied: (applied: boolean) => void;
  handleSetLoader: (field: string, value: boolean) => void;
  setContentItemIndex: (index: number) => void;
}

export interface CouponState {
  [key: string]: string;
}

export const SubscriptionSwimlane: FC<SubscriptionSwimlaneProps> = ({
  mParticleEventData,
  pageUriData,
  setPurchaseState,
  couponApplied,
  setCouponApplied,
  setCouponSuccessfullyApplied,
  handleSetLoader,
  loaders,
  setContentItemIndex,
}) => {
  const language = languageStrings.default;
  const [country] = useState('CL');
  const appConfig = useContext(AppConfigContext);
  const apis = appConfig?.appConfig.apis;
  const vindiciaConfig = appConfig?.appConfig.vindicia;
  const breakpoints = useReactiveVar(breakpointsStateStore);
  const contentWidth = breakpoints.windowWidth;
  const device = useGetDevice();
  const { colors } = useTheme();
  const styles = getStyles(breakpoints, colors);
  const dalton = Dalton();
  const [couponDeduction, setCouponDeduction] = useState<CouponState>({});
  const [currentCouponDeduction, setCurrentCouponDeduction] = useState('');
  const [acceptTerms, setAcceptTerms] = useState(false);
  const [isPayPal, setIsPayPal] = useState(false);
  const [formError, setFormError] = useState({
    couponCode: false,
    unappliedCoupon: false,
    acceptTerms: false,
    cardNo: false,
    expiryDate: false,
    cvn: false,
  });

  const [couponError, setCouponError] = useState('');
  const [couponModalVisible, setCouponModalVisible] = useState(false);
  const [confirmCouponModalVisible, setConfirmCouponModalVisible] = useState(false);
  const [confirmCouponContent, setConfirmCouponContent] = useState({
    title: language.couponRedemptionTitle,
    description: language.couponRedemptionDescription,
  });
  const [selectedProductOfferId, setProductOfferId] = useState('');
  const [couponCode, setCouponCode] = useState('');
  const components = pageUriData?.components && pageUriData?.components[0];
  const [currentProductIndex, setCurrentProductIndex] = useState(0);
  const contentArr = components?.content && components?.content;
  const { setStatusMessage } = useStatusMessage();
  const { scrollToTop } = useScrollToTop(ScrollViewRefContext);
  const { hideRightArrow, hideLeftArrow } = useHideSwimlaneArrow(1, currentProductIndex, contentArr?.length ?? null);
  const mapCouponCallSource = useRef(new AbortController());
  const purchasePreviewCallSource = useRef(new AbortController());
  const couponDetailCallSource = useRef(new AbortController());

  const cardWidth = breakpoints.currentBreakpoints.isTnySm
    ? contentWidth - 60
    : breakpoints.currentBreakpoints.isSmMed || breakpoints.currentBreakpoints.isMedLg
    ? contentWidth - 70
    : contentWidth === breakpoints.breakpointSizes.lg
    ? contentWidth - 100
    : breakpoints.currentBreakpoints.isLgXl
    ? contentWidth - 180
    : breakpoints.currentBreakpoints.isXlXxl || breakpoints.currentBreakpoints.isXxl
    ? contentWidth - 200
    : contentWidth - 60;
  const cardHeight =
    breakpoints.currentBreakpoints.isSmMed ||
    breakpoints.currentBreakpoints.isMedLg ||
    breakpoints.currentBreakpoints.isLgXl
      ? 226
      : breakpoints.currentBreakpoints.isXlXxl
      ? 272
      : breakpoints.currentBreakpoints.isXxl
      ? 290
      : 266;

  const vindiciaOptions = useMemo(
    () => ({
      vindiciaAuthId: vindiciaConfig?.authid as string,
      vindiciaServer: apis?.vindiciaServer as string,
      vindiciaRestServer: apis?.vindiciaRestServer as string,
      iframeHeightPadding: 0,
      hmac: vindiciaConfig?.hmac as string,
    }),
    [vindiciaConfig, apis]
  );

  const purchasePreview = useCallback(
    (productOfferId: string, code?: string) => {
      if (couponCode || code) {
        return dalton.purchasePreview(
          {
            productOfferId,
            promoCode: code || couponCode,
          },
          purchasePreviewCallSource.current.signal
        );
      }
      return dalton.purchasePreview({ productOfferId }, purchasePreviewCallSource.current.signal);
    },
    [couponCode, dalton, purchasePreviewCallSource]
  );

  const handlePurchasePreview = useCallback(
    async (productOfferId: string, code?: string) => {
      const previewResult = await purchasePreview(productOfferId, code);

      if (previewResult.success && !purchasePreviewCallSource.current.signal.aborted) {
        const { amount } = get(previewResult?.data, 'charge') as { currency: string; amount: number };
        setCurrentCouponDeduction(amount.toString());
        setCouponSuccessfullyApplied(true);

        if (amount === 0) {
          const couponDetails = await dalton.couponDetail(code || couponCode, couponDetailCallSource.current.signal);
          if (!couponDetailCallSource.current.signal.aborted) {
            const offerEndDateMillis = get(couponDetails?.data, 'offerEndDateMillis') as number;

            setConfirmCouponContent({
              title: language.freeCouponRedemptionTitle,
              description: language.freeCouponRedemptionDescription(offerEndDateMillis),
            });
          }
        } else {
          setConfirmCouponContent({
            title: language.couponRedemptionTitle,
            description: language.couponRedemptionDescription,
          });
        }
        setCouponModalVisible(false);
        setConfirmCouponModalVisible(true);
        handleSetLoader('coupon', false);
        return;
      } else {
        handleSetLoader('coupon', false);
        setAcceptTerms(false);
        setConfirmCouponModalVisible(false);
        scrollToTop();
        setCouponError(language.redeemCouponErrorMessage);
        return;
      }
    },
    [
      couponCode,
      dalton,
      handleSetLoader,
      language,
      purchasePreview,
      scrollToTop,
      setCouponSuccessfullyApplied,
      couponDetailCallSource,
      purchasePreviewCallSource,
    ]
  );

  const handleSetFormError = useCallback((field: string, value: boolean) => {
    setFormError((prevState) => ({
      ...prevState,
      [field]: value,
    }));
  }, []);

  const validateAcceptTerms = useCallback(() => {
    if (!acceptTerms) {
      handleSetFormError('acceptTerms', true);
    }
    return acceptTerms;
  }, [acceptTerms, handleSetFormError]);

  const validateCouponUsage = useCallback(() => {
    if (couponCode && !couponApplied) {
      handleSetFormError('unappliedCoupon', true);
      return false;
    }
    return true;
  }, [couponCode, couponApplied, handleSetFormError]);

  const makePurchase = useCallback(
    async (params: PurchaseSubscriptionParams) => {
      handleSetLoader('session', true);
      mParticleEventProcessor.pushMParticleEvent(MParticleCustomEventTypes.SubscriptionStartEvent, mParticleEventData);
      const purchaseResponse = await dalton.purchaseSubscription(params);
      if (purchaseResponse.success) {
        clearData(COUPON);
        mParticleEventProcessor.pushMParticleEvent(MParticleCustomEventTypes.SubscriptionSuccessEvent, {
          ...mParticleEventData,
          subscription_status: SubscriptionStatus.Active,
          ...(params.productOfferId ? { subscription_type: params.productOfferId } : {}),
        });
        setPurchaseState('success');
      } else {
        handleSetLoader('session', false);
        setPurchaseState('failure');
        mParticleEventProcessor.pushMParticleEvent(MParticleCustomEventTypes.SubscriptionFailEvent, {
          ...mParticleEventData,
          ...{ error_code: MparticleEventDefaultPropertyValue },
        });
        scrollToTop();
      }
    },
    [dalton, handleSetLoader, mParticleEventData, scrollToTop, setPurchaseState]
  );

  const purchaseSubscription = useCallback(
    async (productOfferId: string, paymentMethodId: string) => {
      const code = await loadData(COUPON);
      makePurchase({
        ...{ productOfferId, paymentMethodId },
        ...(code ? { promoCode: code } : {}),
      });
    },
    [makePurchase]
  );

  const handleSubWithPayPalRequest = useCallback(
    async (productOfferId: string) => {
      const code = loadData(COUPON);
      setIsPayPal(true);
      const payload = code ? { promoCode: code, productOfferId } : { productOfferId };
      handleSetLoader('session', true);
      mParticleEventProcessor.pushMParticleEvent(MParticleCustomEventTypes.SubscriptionStartEvent, mParticleEventData);
      try {
        const payPalResponse = await dalton.subscriptionPurchaseRequest(payload);
        if (payPalResponse.error) {
          throw new Error('');
        }
        clearData(COUPON);
        window.open(get(payPalResponse?.data, 'paypalRedirectUrl', ''), '_self', 'noopener,noreferrer');
      } catch (e) {
        handleSetLoader('session', false);
        setPurchaseState('failure');
        scrollToTop();
      }
    },
    [mParticleEventData, handleSetLoader, setPurchaseState, dalton, scrollToTop]
  );

  const onFormSubmitComplete = useCallback(
    (data: VindiciaSuccessResponse, productOfferId: string) => {
      purchaseSubscription(productOfferId, data.detail.vid);
    },
    [purchaseSubscription]
  );

  const onFormSubmit = () => {
    return true;
  };

  const handleSubmitFailed = useCallback(() => {
    setStatusMessage({ message: language.subscriptionPaymentFailedMessage, type: Variant.Error });
    scrollToTop();
  }, [language.subscriptionPaymentFailedMessage, setStatusMessage, scrollToTop]);

  const renderPayment = useCallback(
    (productOfferId: string) => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const vindicia = window && (window as any).vindicia ? (window as any).vindicia : null;
      const vindiciaFields = [
        {
          name: 'name',
          label: language.accountName,
          formatinput: true,
        },
        {
          name: 'vin_billing_address_country',
          label: language.accountCountry,
          formatinput: true,
          render: (
            <select defaultValue={country} id="vin_billing_address_country" name="vin_billing_address_country">
              {getCountryNamesAndCode().map((country, index) => (
                <option key={`${country.name}-${index}`} value={country.code}>
                  {country.name}
                </option>
              ))}
            </select>
          ),
        },
        {
          type: 'cardNumber',
          label: language.cardNumber,
          autocomplete: 'cc-number',
          formatinput: true,
        },
        {
          type: 'expirationMonthInput',
          format: 'MM',
          placeholder: 'MM',
          label: language.expiryDate,
          formatinput: true,
        },
        {
          type: 'expirationYearInput',
          format: 'YYYY',
          placeholder: 'YYYY',
          label: 'year',
          formatinput: true,
        },
        {
          type: 'cvn',
          label: language.cvn,
          autocomplete: 'cc-csc',
        },
      ];

      return (
        <VindiciaFormWrapper
          vindicia={vindicia}
          options={vindiciaOptions}
          onSubmitCompleteEvent={(data) => onFormSubmitComplete(data, productOfferId)}
          onSubmitEvent={onFormSubmit}
          vinValidate="0"
          fields={vindiciaFields}
          validateAcceptTerms={validateAcceptTerms}
          validateCouponUsage={validateCouponUsage}
          onSubmitCompleteFailedEvent={handleSubmitFailed}
          termsAccepted={acceptTerms}
          sessionIsLoading={loaders.session && !isPayPal}
          renderConsent={() => <ConsentMessage textStyle={styles.consentMessage} formName={language.buyNowLabel} />}
        />
      );
    },
    [
      acceptTerms,
      country,
      handleSubmitFailed,
      isPayPal,
      language.accountCountry,
      language.accountName,
      language.buyNowLabel,
      language.cardNumber,
      language.cvn,
      language.expiryDate,
      loaders.session,
      onFormSubmitComplete,
      validateAcceptTerms,
      validateCouponUsage,
      vindiciaOptions,
      styles.consentMessage,
    ]
  );

  const applyCoupon = useCallback(
    (productOfferId: string) => {
      handleSetLoader('coupon', true);

      dalton.mapCoupon(couponCode, mapCouponCallSource.current.signal).then(async (resp) => {
        // adding this here because coupon mapping is not ready this will be moved into the success block when the mapping service is ready
        if (!mapCouponCallSource.current.signal.aborted) {
          const coupon = get(resp?.data, 'mapped_code', couponCode);
          setCouponCode(coupon);
          handlePurchasePreview(productOfferId, coupon);
        }
      });
    },
    [couponCode, dalton, handlePurchasePreview, handleSetLoader, mapCouponCallSource]
  );

  const validateCoupon = useCallback(() => {
    if (!validateNonEmptyString(couponCode)) {
      handleSetFormError('couponCode', true);
      return;
    }
    formError.unappliedCoupon && handleSetFormError('unappliedCoupon', false);
    formError.couponCode && handleSetFormError('couponCode', false);
    applyCoupon(selectedProductOfferId);
  }, [couponCode, selectedProductOfferId, formError, applyCoupon, handleSetFormError]);

  const handleConfirmCoupon = useCallback(() => {
    if (!acceptTerms) {
      handleSetFormError('acceptTerms', true);
      return;
    } else {
      setCouponDeduction((coup) => ({ ...coup, [selectedProductOfferId]: currentCouponDeduction }));
      handleSetFormError('acceptTerms', false);
      const isFullCoupon = parseInt(currentCouponDeduction) === 0;
      // The coupon event should be fired once is completely applied
      mParticleEventProcessor.pushMParticleEvent(MParticleCustomEventTypes.RedeemCouponEvent, {
        ...mParticleEventData,
        ...{
          type_coupon: isFullCoupon ? CouponType.Free : CouponType.Discount,
        },
      });
      if (isFullCoupon) {
        // no need to take cc details or paypal if coupon is 100%
        makePurchase({
          productOfferId: selectedProductOfferId,
          paymentMethodId: '',
          promoCode: couponCode,
        });
      } else {
        couponCode && saveData(COUPON, couponCode);
        setStatusMessage({ message: language.subscriptionSuccessMessage, type: Variant.Success });
      }
      handleSetLoader('coupon', false);
      setAcceptTerms(false);
      setCouponCode('');
      setCouponApplied(true);
      setConfirmCouponModalVisible(false);
      scrollToTop();
    }
  }, [
    acceptTerms,
    couponCode,
    currentCouponDeduction,
    handleSetLoader,
    language.subscriptionSuccessMessage,
    mParticleEventData,
    makePurchase,
    scrollToTop,
    selectedProductOfferId,
    setCouponApplied,
    setStatusMessage,
    handleSetFormError,
  ]);

  const toggleModal = useCallback(() => {
    mapCouponCallSource.current.abort();
    purchasePreviewCallSource.current.abort();
    couponDetailCallSource.current.abort();
    setCouponModalVisible(false);
    setConfirmCouponModalVisible(false);
    setCouponCode('');
    setCouponError('');
    setCurrentCouponDeduction('');
    setCouponDeduction((coup) => ({ ...coup, [selectedProductOfferId]: '' }));
    formError.couponCode && handleSetFormError('couponCode', false);
    handleSetLoader('coupon', false);
  }, [
    selectedProductOfferId,
    formError.couponCode,
    mapCouponCallSource,
    purchasePreviewCallSource,
    couponDetailCallSource,
    handleSetFormError,
  ]);

  const onChangeVisible = useCallback(() => {
    setCouponCode('');
    setConfirmCouponModalVisible(false);
    setCurrentCouponDeduction('');
    setCouponDeduction((coup) => ({ ...coup, [selectedProductOfferId]: '' }));
    if (!acceptTerms) {
      setCouponApplied(false);
      formError.acceptTerms && handleSetFormError('acceptTerms', false);
    }
    setAcceptTerms(false);
  }, [
    acceptTerms,
    setCouponCode,
    setCurrentCouponDeduction,
    setCouponApplied,
    selectedProductOfferId,
    formError.acceptTerms,
    handleSetFormError,
  ]);

  const renderPayWithPayPal = useCallback(
    (productOfferId: string) => {
      return (
        <View style={styles.payPalWrapper}>
          <View style={styles.payPalInfoWrapper}>
            <TextContent style={styles.paypalInfo}>{language.paypalText}</TextContent>
          </View>
          <View style={styles.payPalBtnWrapper}>
            <ConsentMessage textStyle={styles.consentMessage} formName={language.buyNowLabel} />
            <EstadioButton
              btnStyle={styles.ctnBtn}
              disabled={loaders.session}
              labelStyle={styles.ctnBtnLabel}
              mode={'contained'}
              loading={loaders.session}
              label={language.payPalBtnLabel}
              onPress={() => handleSubWithPayPalRequest(productOfferId)}
            />
          </View>
        </View>
      );
    },
    [
      handleSubWithPayPalRequest,
      language.buyNowLabel,
      language.payPalBtnLabel,
      language.paypalText,
      loaders.session,
      styles.consentMessage,
      styles.ctnBtn,
      styles.ctnBtnLabel,
      styles.payPalBtnWrapper,
      styles.payPalInfoWrapper,
      styles.payPalWrapper,
      styles.paypalInfo,
    ]
  );

  const updateIndex = useCallback(
    (index: number) => {
      setCurrentProductIndex(index);
      setCouponCode('');
      setAcceptTerms(false);
      setContentItemIndex(index);
    },
    [setContentItemIndex]
  );

  const wrapperStyle =
    currentProductIndex === 0
      ? { paddingLeft: 0 }
      : contentArr && currentProductIndex === contentArr.length - 1
      ? { paddingRight: 0 }
      : {};

  const contents = contentArr as Content[];

  const handleHasCoupon = useCallback(
    (productOfferId: string) => {
      setCouponModalVisible(true);
      setConfirmCouponModalVisible(false);
      setCouponError('');
      setProductOfferId(productOfferId);
      formError.couponCode && handleSetFormError('couponCode', false);
      formError.acceptTerms && handleSetFormError('acceptTerms', false);
    },
    [formError.couponCode, formError.acceptTerms, handleSetFormError]
  );

  const cardSlide = useCallback(
    ({ item, index }: ListRenderItemInfo<Content>) => {
      const product = item?.product && item?.product[0];
      const productOfferId = product?.productId as string;
      const description = components?.description;
      const cardStyle =
        contentArr && currentProductIndex === contentArr?.length - 1 ? { marginLeft: 16 } : { marginRight: 16 };

      const arrowStyle = {
        ...{ top: cardHeight / 2, zIndex: 101 },
        ...(currentProductIndex > 0 ? { left: 10 } : {}),
        ...(contentArr && currentProductIndex !== contentArr.length - 1 ? { right: 10 } : {}),
      };
      const directionalArrowProps = {
        device,
        breakpoints,
        colors,
        index: currentProductIndex,
        dataLength: contents.length,
        indexUpdate: updateIndex,
        isLeftPoint: !hideLeftArrow,
      };

      return product ? (
        <View style={[cardStyle, { width: cardWidth }]}>
          {!hideLeftArrow ? (
            <View style={arrowStyle}>
              <DirectionalArrow {...directionalArrowProps} overrideStyle={styles.backArrow} />
            </View>
          ) : null}
          {!hideRightArrow ? (
            <View style={arrowStyle}>
              <DirectionalArrow {...directionalArrowProps} overrideStyle={styles.forwardArrow} />
            </View>
          ) : null}
          <SubscriptionCard
            product={product}
            description={description}
            couponDeduction={couponDeduction[productOfferId]}
            renderPayment={() => renderPayment(productOfferId)}
            setHasCoupon={() => handleHasCoupon(productOfferId)}
            renderPayWithPayPal={() => renderPayWithPayPal(productOfferId)}
            key={index}
          />

          <CouponModal
            visible={couponModalVisible}
            title={language.redeemCouponTitle}
            description={language.redeemCouponSubTitle}
            buttonLabel={loaders.coupon ? language.loadingText : language.valid}
            bottomText={language.contactUsMessage}
            bottomLink={language.contactUsMessageButton}
            bottomTextSpace={8}
            inputLable={language.code}
            toggleModal={toggleModal}
            couponError={couponError}
            setCouponError={setCouponError}
            applyCoupon={validateCoupon}
            couponCode={couponCode}
            setCouponCode={setCouponCode}
            formError={formError}
            handleSetFormError={handleSetFormError}
            setCouponApplied={setCouponApplied}
            isLoading={loaders.coupon}
          />

          <CouponRedemptionModal
            isLoading={loaders.coupon}
            visible={confirmCouponModalVisible}
            title={confirmCouponContent.title}
            description={confirmCouponContent.description}
            termsText={language.couponTermsText}
            tooltipText={language.couponRedemptionToolTip}
            buttonLabel={loaders.coupon ? language.loadingText : language.confirm}
            handleSubmit={handleConfirmCoupon}
            onChangeVisible={onChangeVisible}
            setAcceptTerms={setAcceptTerms}
            termsAccepted={acceptTerms}
            termsError={formError.acceptTerms}
            handleSetTermsError={handleSetFormError}
          />
        </View>
      ) : (
        <View />
      );
    },
    [
      acceptTerms,
      cardHeight,
      cardWidth,
      components?.description,
      confirmCouponContent.description,
      confirmCouponContent.title,
      contentArr,
      couponCode,
      couponDeduction,
      couponError,
      currentProductIndex,
      formError,
      handleConfirmCoupon,
      language.code,
      language.confirm,
      language.contactUsMessage,
      language.contactUsMessageButton,
      language.couponRedemptionToolTip,
      language.couponTermsText,
      language.loadingText,
      language.redeemCouponSubTitle,
      language.redeemCouponTitle,
      language.valid,
      loaders.coupon,
      confirmCouponModalVisible,
      couponModalVisible,
      onChangeVisible,
      renderPayWithPayPal,
      renderPayment,
      setCouponApplied,
      toggleModal,
      validateCoupon,
      contents,
      updateIndex,
      hideLeftArrow,
      hideRightArrow,
      breakpoints,
      colors,
      device,
      handleHasCoupon,
      handleSetFormError,
      styles.backArrow,
      styles.forwardArrow,
    ]
  );

  return (
    <View style={[styles.wrapper, wrapperStyle]}>
      {contentArr && (
        <Carousel
          index={currentProductIndex}
          indexUpdate={updateIndex}
          data={contents}
          renderItem={cardSlide}
          laneWidth={contentWidth}
          itemWidth={cardWidth + 40}
          itemWidthMarginAllowance={0}
          contentContainerStyle={styles.carouselPadding}
          device={device}
        />
      )}
    </View>
  );
};

export default SubscriptionSwimlane;
