import React, { FC, useCallback } from 'react';
import { Image, Pressable, Text, View } from 'react-native';
import LinearGradient from 'react-native-linear-gradient';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { ImageWithStyle, Link, MAX_URL, Maybe, Product } from '@warnermmedia/gsp-core/brands/estadio/data-access';
import { RegisterAccountForm } from '../../forms/register/account';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { MParticleAppConfigParams } from '@warnermmedia/gsp-core/sdk/data-access';
import { getStyles } from './landingHero.style';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { Breakpoints, useGetDevice } from '@warnermmedia/gsp-core/sdk/ui';
import { ParallaxBanner, ParallaxProvider } from 'react-scroll-parallax';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { callbackNavigateExternal, useScaleSizing } from '@warnermmedia/gsp-core/brands/estadio/feature';
import get from 'lodash/get';

interface LandingHeroProps {
  landingImages?: Maybe<Maybe<ImageWithStyle>[]> | undefined;
  mParticleEventOptions?: MParticleAppConfigParams | undefined;
  breakpoints: Breakpoints;
  colors: ReactNativePaper.ThemeColors;
  width: number;
  shouldFocusForm: boolean;
  backgroundImage?: string;
  product?: Product | null;
  setShouldFocusForm: (value: boolean) => void;
  isRegisterEnabled: boolean;
  isAppEnabled?: boolean;
  ctaLink: Link | null | undefined;
}

enum ImageStyle {
  SmallImage = '16:4',
  BigImage = '16:9',
}

export const LandingHero: FC<LandingHeroProps> = ({
  landingImages,
  mParticleEventOptions,
  breakpoints,
  colors,
  shouldFocusForm,
  setShouldFocusForm,
  backgroundImage,
  product,
  isRegisterEnabled,
  ctaLink,
  isAppEnabled = true,
}) => {
  const scaling = useScaleSizing();
  const { isWeb, isAndroidDevice, isTv } = useGetDevice();
  const styles = getStyles(breakpoints, colors, isRegisterEnabled, isTv, scaling);
  const isMobileScreen = breakpoints.breakpointHelpers.isMobileScreen;
  const staticWebImage = get(landingImages, '[0].image.url', '');
  const staticMobileImage = get(landingImages, '[1].image.url', '');

  const callbackNavigateExternalMax = useCallback(() => {
    if (isWeb || isAndroidDevice) {
      callbackNavigateExternal(ctaLink?.uri ?? MAX_URL);
    }
  }, [ctaLink, isWeb, isAndroidDevice]);

  const ImageWrapper = isRegisterEnabled ? View : Pressable;
  const imageWrapperProps = isRegisterEnabled
    ? {}
    : {
        onPress: callbackNavigateExternalMax,
      };

  const getLandingHero = () => {
    return (
      <LinearGradient
        colors={[
          colors.tenantBackground.dark.surface02FilmGrad.start,
          colors.tenantBackground.dark.surface02FilmGrad.end,
        ]}
      >
        <View style={styles.pageContents}>
          <View style={styles.left}>
            {!landingImages && !!product && (
              <View>
                <View style={styles.headerViewStyle}>
                  <Text style={styles.header1TextStyle}>{product?.headline}</Text>
                  <Text style={styles.header2TextStyle}>{product?.subHeadline}</Text>
                </View>
                {product?.cta && (
                  <View style={[styles.subscribeViewStyle, styles.subscribeShadowStyle]}>
                    <View>
                      <Text style={styles.subscribeLargeTextStyle}>{product?.cta?.replace(/\s/, '\n')}</Text>
                    </View>
                    <Text style={styles.subscribeSmallTextStyle}>{product?.price}</Text>
                  </View>
                )}
              </View>
            )}
            <ImageWrapper style={styles.landingImageList} {...imageWrapperProps}>
              {isAppEnabled &&
                landingImages?.map(
                  (image, index) =>
                    image?.image && (
                      <Image
                        source={{ uri: image.image.url }}
                        style={
                          image.style === ImageStyle.SmallImage ? styles.topMarketingImage : styles.bottomMarketingImage
                        }
                        key={index}
                      />
                    )
                )}
              {!isAppEnabled && (
                <Image
                  source={{ uri: isMobileScreen ? staticMobileImage : staticWebImage }}
                  style={isMobileScreen ? styles.maxMobileImage : styles.maxImage}
                />
              )}
            </ImageWrapper>
            {!!product && <Text style={styles.disclaimerText}>{product.disclaimer}</Text>}
          </View>
          {isRegisterEnabled && (
            <View style={styles.right}>
              <RegisterAccountForm
                acceptText={'de Estadio.'}
                acceptLink={
                  'https://estadiocl.zendesk.com/hc/es/articles/360055102712-T%C3%89RMINOS-Y-CONDICIONES-DE-USO-DE-ESTADIO-TNT-SPORTS'
                }
                shouldFocusForm={shouldFocusForm}
                setShouldFocusForm={setShouldFocusForm}
                mParticleEventRegisterOptions={mParticleEventOptions}
              />
            </View>
          )}
        </View>
      </LinearGradient>
    );
  };

  return isWeb ? (
    <ParallaxProvider>
      <ParallaxBanner layers={[{ image: backgroundImage, translateY: ['-380px', '300px'], expanded: false }]}>
        {getLandingHero()}
      </ParallaxBanner>
    </ParallaxProvider>
  ) : (
    getLandingHero()
  );
};

export default LandingHero;
