import { useCallback } from 'react';

interface SetCardIndexAction {
  setIndex: (index: number) => void;
}

export const useSetCardIndex = (cardsIndex: number, setCardsIndex: (index: number) => void): SetCardIndexAction => {
  // Handles index changes from carousel
  // Gets called when the index changes in a carousel
  const setIndex = useCallback(
    (index = 0) => {
      if (typeof index !== 'undefined') {
        const newIndex = Number(index) >= 0 ? index : cardsIndex + 1;
        setCardsIndex(newIndex);
      }
    },
    [cardsIndex]
  );

  return {
    setIndex,
  };
};

export default useSetCardIndex;
