import React, { ReactNode, useCallback, useEffect, useState } from 'react';
import { Platform, Text, View } from 'react-native';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import {
  CustomButton,
  Dropdown,
  DropDownItem,
  MessageDisplay,
  useGetDevice,
  Variant,
} from '@warnermmedia/gsp-core/sdk/ui';
import { useReactiveVar } from '@apollo/client';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { breakpointsStateStore, ModalType } from '@warnermmedia/gsp-core/brands/estadio/data-access';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { languageStrings } from '@warnermmedia/gsp-core/brands/estadio/feature';
import { getStyles } from './favTeamModal.styles';
import { useTheme } from 'react-native-paper';
import ModalWrapper, { ModalContentContainer } from '../modalWrapper';

interface FavTeamModalProps {
  onRequestClose(): void;
  visible: boolean;
  onSave(selected: string): void;
  list: TeamListItem[];
  errorMessage?: string;
  headerText?: string;
  messageText?: string;
  skipLabel?: string;
  saveLabel?: string;
  dropDownTitle?: string;
  dropDownPlaceholder?: string;
}

export interface TeamListItem {
  label: string;
  value: string | number;
  custom?: ReactNode;
}

export const FavTeamModal = ({
  onRequestClose,
  onSave,
  list,
  visible,
  errorMessage,
  headerText = languageStrings.default.favoriteTeamModalHeaderText,
  messageText = languageStrings.default.favoriteTeamModalMessageText,
  skipLabel = languageStrings.default.favoriteTeamModalSkipLabel,
  saveLabel = languageStrings.default.saveText,
  dropDownTitle = languageStrings.default.favoriteTeamModalDropdownTitle,
  dropDownPlaceholder = languageStrings.default.favoriteTeamModalDropdownPlaceholder,
}: FavTeamModalProps) => {
  const breakpoints = useReactiveVar(breakpointsStateStore);
  const currentWindowWidth = breakpoints.windowWidth;
  const isMobileSize = Platform.OS !== 'web' || currentWindowWidth <= breakpoints.breakpointSizes.lg;
  const { isMobileDevice } = useGetDevice();
  const [selected, setSelected] = useState<string | number>(dropDownPlaceholder);
  const [items, setItems] = useState<DropDownItem[]>([]);
  const disabled = !selected || selected === dropDownPlaceholder;
  const { colors } = useTheme();
  const styles = getStyles(isMobileSize, breakpoints, colors);

  const dropDownContainerHeight = currentWindowWidth <= 375 ? 150 : isMobileDevice ? 200 : 395;

  useEffect(() => {
    setItems([
      ...[
        {
          label: dropDownPlaceholder,
          value: dropDownPlaceholder,
          disabled: true,
        },
      ],
      ...list.map((item) => ({
        label: item.label,
        value: item.value,
        disabled: false,
      })),
    ]);
  }, [list]);

  const onChange = useCallback(() => {
    setSelected('');
    onRequestClose();
  }, [setSelected, onRequestClose]);

  const save = useCallback(() => {
    if (!selected || selected === dropDownPlaceholder) {
      return;
    }
    return onSave(selected as string);
  }, [selected, dropDownPlaceholder, onSave]);

  const onSelect = useCallback(
    (selected) => {
      setSelected(selected);
    },
    [setSelected]
  );

  return (
    <ModalWrapper
      visible={visible}
      modalType={visible ? ModalType.FavTeamModal : null}
      onClose={onChange}
      showCloseButton={true}
      useModalWrapperContainer={true}
      modalContentContainerType={ModalContentContainer.Center}
      modalLayoutContainerStyle={styles.fullScreenContainer}
    >
      <View style={styles.mainTextContainer}>
        <Text style={styles.headerText}>{headerText}</Text>
        <View style={styles.descriptionContainer}>
          <Text style={styles.description}> {messageText}</Text>
        </View>
        {!!errorMessage && (
          <MessageDisplay displayMessage={errorMessage} variant={Variant.Error} wrapperStyle={styles.errorDisplay} />
        )}
        <View style={styles.ctaContainer}>
          <Text style={styles.dropdownTitle}>{dropDownTitle}</Text>
          <Dropdown
            data={items}
            label={selected}
            onSelect={onSelect}
            shouldAutoFocus={true}
            activeIconName="check"
            dropDownIconColor={colors.keyboardFocus.dark}
            hoverStyle={styles.hoverStyle}
            inputStyle={styles.dropdownInput}
            inputTextStyle={styles.dropDownTextStyle}
            inputClickStyle={styles.inputClickStyle}
            dropDownItemTextStyle={styles.dropDownTextStyle}
            dropDownContainerHeight={dropDownContainerHeight}
            dropDownItemDisabledTextStyle={{ color: 'grey' }}
            dropDownItemHoverStyle={styles.dropDownItemHoverStyle}
          />
          <CustomButton
            mode="outline"
            btnStyle={[styles.buttonSpace, styles.skipButtonStyle]}
            onPress={onChange}
            labelStyle={styles.buttonLabel}
            label={skipLabel}
          />
          <CustomButton
            mode="contained"
            disabled={!!disabled}
            labelStyle={styles.buttonLabel}
            btnStyle={[
              {
                backgroundColor: disabled ? colors.fill.action.accent01Pressed : colors.fill.action.accent01,
                borderWidth: 0,
              },
            ]}
            onPress={save}
            label={saveLabel}
          />
        </View>
      </View>
    </ModalWrapper>
  );
};

export default FavTeamModal;
