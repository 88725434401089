import React, { useEffect } from 'react';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import {
  breakpointsStateStore,
  MODAL_INITIAL_STATE,
  modalStateStore,
  ModalType,
} from '@warnermmedia/gsp-core/brands/estadio/data-access';
import { Modal, ModalProps, Text, TouchableOpacity, View, ViewStyle } from 'react-native';
import { useGetDevice } from '@warnermmedia/gsp-core/sdk/ui';
import { useReactiveVar } from '@apollo/client';
import { useTheme } from 'react-native-paper';
import { getStyles } from './modalWrapper.styles';

export enum ModalContentContainer {
  Center = 'center',
  Full = 'full',
  None = 'none',
}

interface ModalWrapperProps extends ModalProps {
  visible: boolean;
  modalType: ModalType | null;
  children: JSX.Element;
  onClose: () => void;
  showCloseButton?: boolean;
  useModalWrapperContainer?: boolean;
  useModalInnerContainers?: boolean;
  modalContentContainerType?: ModalContentContainer;
  modalWrapperContainerStyle?: ViewStyle;
  modalMainContainerStyle?: ViewStyle;
  modalLayoutContainerStyle?: ViewStyle;
  modalCloseButtonStyle?: ViewStyle;
  modalCloseButtonLabelStyle?: ViewStyle;
}

export const ModalWrapper = ({
  visible,
  modalType,
  children,
  onClose,
  presentationStyle,
  useModalWrapperContainer = false,
  useModalInnerContainers = true,
  modalContentContainerType = ModalContentContainer.None,
  modalLayoutContainerStyle,
  modalWrapperContainerStyle,
  modalMainContainerStyle,
  modalCloseButtonStyle,
  modalCloseButtonLabelStyle,
  transparent = true,
  animationType = 'none',
  showCloseButton = false,
}: ModalWrapperProps) => {
  const breakpoints = useReactiveVar(breakpointsStateStore);
  const { colors } = useTheme();
  const { isMobileDevice, isIosDevice } = useGetDevice();
  const currentWindowWidth = breakpoints.windowWidth;
  const isMobileSize = isMobileDevice || currentWindowWidth <= breakpoints.breakpointSizes.lg;
  const styles = getStyles(isMobileSize, breakpoints, colors, isMobileDevice, isIosDevice);
  const ModalWrapperContainer = useModalWrapperContainer ? View : React.Fragment;
  const closeBtnStyle = modalCloseButtonStyle ? modalCloseButtonStyle : styles.closeBtn;
  const closeBtnTextStyle = modalCloseButtonLabelStyle ? modalCloseButtonLabelStyle : styles.closeBtnText;
  const ModalInnerContainer = useModalInnerContainers ? View : React.Fragment;
  const wrapperContainerStyle = useModalWrapperContainer
    ? { style: [styles.fullScreenContainer, modalWrapperContainerStyle] }
    : {};

  const layoutContainerStyle = useModalInnerContainers
    ? {
        style: [
          modalContentContainerType === ModalContentContainer.Center
            ? isMobileSize
              ? styles.centeredViewMobile
              : styles.centeredView
            : {},
          modalLayoutContainerStyle,
          !useModalWrapperContainer && !modalLayoutContainerStyle ? styles.fullScreenContainer : {},
        ],
      }
    : {};
  const mainContainerStyle = useModalInnerContainers
    ? { style: modalMainContainerStyle ? modalMainContainerStyle : styles.container }
    : {};

  useEffect(() => {
    if (modalType) {
      modalStateStore({
        ...modalStateStore(),
        [modalType]: visible,
      });
    }
    return () => {
      modalStateStore({
        ...modalStateStore(),
        ...MODAL_INITIAL_STATE,
      });
    };
  }, [visible, modalType]);

  return visible ? (
    <ModalWrapperContainer {...wrapperContainerStyle}>
      <Modal
        visible={visible}
        transparent={transparent}
        onRequestClose={onClose}
        animationType={animationType}
        presentationStyle={presentationStyle}
      >
        <ModalInnerContainer {...layoutContainerStyle}>
          <ModalInnerContainer {...mainContainerStyle}>
            {showCloseButton && (
              <TouchableOpacity style={closeBtnStyle} onPress={onClose}>
                <Text style={closeBtnTextStyle}>x</Text>
              </TouchableOpacity>
            )}
            {children}
          </ModalInnerContainer>
        </ModalInnerContainer>
      </Modal>
    </ModalWrapperContainer>
  ) : null;
};

export default ModalWrapper;
