import React from 'react';
import { carouselFormat } from '@warnermmedia/gsp-core/brands/estadio/feature';
import { Product } from '@warnermmedia/gsp-core/brands/estadio/data-access';
import Subscription from './subscription/subscription';
import { Maybe } from 'graphql/jsutils/Maybe';

interface Props {
  handleLogin: () => void;
  handleSubscribe: () => void;
  product: Product | null;
  loginLabel: Maybe<string>;
}

export const SubscriptionProducts = ({ handleLogin, handleSubscribe, product, loginLabel }: Props) => {
  return product ? (
    <Subscription
      product={product}
      handleLogin={handleLogin}
      handleSubscribe={handleSubscribe}
      loginLabel={loginLabel}
    />
  ) : null;
};

export default SubscriptionProducts;
