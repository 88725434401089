import React from 'react';
import { Text, View } from 'react-native';
import { VerticalNavbar } from '../vertical-navbar/vertical-navbar';

type NavbarProps = React.ComponentPropsWithRef<typeof View> & {
  active: string;
  /**
   * The type of navbar to use.
   */
  type: 'vertical' | 'horizontal';
  /**
   * Content of the `Navbar`.
   */
  children: React.ReactNode;
  /**
   * Whether to open the drawer.
   */
  opened?: boolean;
  /**
   * Background color for navbar
   */
  backgroundColor?: string;
};

export const Navbar = (props: NavbarProps) => {
  if (!props.opened) {
    // Nav is closed, return null
    return null;
  }

  return (
    <View>
      {props.type === 'vertical' ? (
        <VerticalNavbar backgroundColor={props.backgroundColor}>{props.children}</VerticalNavbar>
      ) : (
        <Text>Horizontal Nav</Text>
      )}
    </View>
  );
};

export default Navbar;
