import { useCallback, useEffect, useRef, useState } from 'react';
import useIsMountedRef from './useIsMountedRef/useIsMountedRef';
import { modalStateStore, MODAL_INITIAL_STATE } from '@warnermmedia/gsp-core/brands/estadio/data-access';

export const useWebos = (device: string) => {
  const [magicRemoteVisible, setMagicRemoteVisible] = useState<boolean>(false);
  const keycode = useRef();
  const isMountedRef = useIsMountedRef();

  const onCursorStateChange = (event: CustomEventInit) => {
    // Handle cursorStateChange event for webOs magic remote cursor detection
    setMagicRemoteVisible(!!event.detail.visibility);
  };

  const onScrollHandler = useCallback((event: WheelEvent) => {
    // Handle wheel event for webOs magic remote scroll
    Math.sign(event.deltaY) > 0 ? scrollDown() : scrollUp();
  }, []);

  const scrollUp = () => {
    window.scrollBy(0, -100);
  };

  const scrollDown = () => {
    window.scrollBy(0, 100);
  };

  /* A callback function that is called when the back button is pressed on the remote. */
  const handleTvBackButton = useCallback((e) => {
    if (window.event) {
      keycode.current = e?.keyCode;
    } else if (e.which) {
      keycode.current = e.which;
    }
    if (keycode.current === 461) {
      modalStateStore(MODAL_INITIAL_STATE);
    }
  }, []);

  useEffect(() => {
    if (isMountedRef.current) {
      window.addEventListener('keydown', handleTvBackButton);
    }
    return () => {
      window.removeEventListener('keydown', handleTvBackButton);
    };
  }, [handleTvBackButton, isMountedRef]);

  useEffect(() => {
    document.addEventListener('cursorStateChange', onCursorStateChange);
    if (device === 'webos') document.addEventListener('wheel', onScrollHandler);
    return () => {
      document.removeEventListener('cursorStateChange', onCursorStateChange);
      if (device === 'webos') document.removeEventListener('wheel', onScrollHandler);
    };
  }, [device, onScrollHandler]);
  return magicRemoteVisible;
};
