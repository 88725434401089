export enum SamsungMisc {
  SAMSUNG_CANCEL = 65385,
  SAMSUNG_DONE = 65376,
  SAMSUNG_EXIT = 10009,
}

export enum KeyCodes {
  Backspace = 8,
  Tab = 9,
  Enter = 13,
  Shift = 16,
  Ctrl = 17,
  Alt = 18,
  Pause = 19,
  CapsLock = 20,
  Escape = 27,
  Space = 32,
  PageUp = 33,
  PageDown = 34,
  End = 35,
  Home = 36,
  LeftArrow = 37,
  UpArrow = 38,
  RightArrow = 39,
  DownArrow = 40,
  Insert = 45,
  Delete = 46,
  Digit0 = 48,
  Digit1 = 49,
  Digit2 = 50,
  Digit3 = 51,
  Digit4 = 52,
  Digit5 = 53,
  Digit6 = 54,
  Digit7 = 55,
  Digit8 = 56,
  Digit9 = 57,
  a = 65,
  b = 66,
  c = 67,
  d = 68,
  e = 69,
  f = 70,
  g = 71,
  h = 72,
  i = 73,
  j = 74,
  k = 75,
  l = 76,
  m = 77,
  n = 78,
  o = 79,
  p = 80,
  q = 81,
  r = 82,
  s = 83,
  t = 84,
  // u = 85,
  v = 86,
  w = 87,
  x = 88,
  // y = 89,
  // z = 90,
  LeftWindowLey = 91,
  RightWindowKey = 92,
  SelectKey = 93,
  Numpad0 = 96,
  Numpad1 = 97,
  Numpad2 = 98,
  Numpad3 = 99,
  Numpad4 = 100,
  Numpad5 = 101,
  Numpad6 = 102,
  Numpad7 = 103,
  Numpad8 = 104,
  Numpad9 = 105,
  Multiply = 106,
  Add = 107,
  Subtract = 109,
  DecimalPoint = 110,
  Divide = 111,
  F1 = 112,
  F2 = 113,
  F3 = 114,
  F4 = 115,
  F5 = 116,
  F6 = 117,
  F7 = 118,
  F8 = 119,
  F9 = 120,
  F10 = 121,
  F11 = 122,
  F12 = 123,
  NavigationLeft = 140,
  NavigationRight = 141,
  NavigationAccept = 142,
  NavigationUp = 138,
  NavigationDown = 139,
  NumLock = 144,
  ScrollLock = 145,
  SemiColon = 186,
  EqualSign = 187,
  Comma = 188,
  Dash = 189,
  Period = 190,
  ForwardSlash = 191,
  GraveAccent = 192,
  OpenBracket = 219,
  BackSlash = 220,
  CloseBraket = 221,
  SingleQuote = 222,
  GamepadA = 195,
  GamepadB = 196,
  GamepadX = 197,
  GamepadY = 198,
  GamepadRightShoulder = 199,
  GamepadLeftShoulder = 200,
  GamepadLeftTrigger = 201,
  GamepadRightTrigger = 202,
  GamepadDPadUp = 203,
  GamepadDPadDown = 204,
  GamepadDPadLeft = 205,
  GamepadDPadRight = 206,
  GamepadMenu = 207,
  GamepadView = 208,
  GamepadLeftThumbstick = 209,
  GamepadRightThumbstick = 210,
  GamepadLeftThumbstickUp = 211,
  GamepadLeftThumbstickDown = 212,
  GamepadLeftThumbstickRight = 213,
  GamepadLeftThumbstickLeft = 214,
  GamepadRightThumbstickUp = 215,
  GamepadRightThumbstickDown = 216,
  GamepadRightThumbstickRight = 217,
  GamepadRightThumbstickLeft = 218,

  // FireTV
  FireMediaPlayPause = 179,
  FireMediaRewind = 227,
  FireMediaFastForward = 228,

  PlayPause = 85,
  Rewind = 89,
  FastForward = 90,
  LgPlay = 415,
  LgPause = 19,
  LgRWD = 412,
  LgFWD = 417,

  SamsungEnter = 13,
  SamsungRWD = 412,
  SamsungFWD = 417,
  SamsungPlay = 415,
  SamsungPause = 19,
  SamsungStop = 413,
  SamsungBack = 10009,
  SamsungLeft = 37,
  SamsungUp = 38,
  SamsungRight = 39,
  SamsungDown = 40,
  SamsungReturn = 10009,
  SamsungExit = 10182,
  SamsungPLAYPAUSE = 10252,
}
export enum CommonKeys {
  Left = 0,
  Right = 1,
  Up = 2,
  Down = 3,
  Submit = 4,
  Back = 5,
  X = 6,
  Y = 7,
  TabLeft = 8,
  TabRight = 9,
  TabUp = 10,
  TabDown = 11,
  View = 12,
  Menu = 13,
}
