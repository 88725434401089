import React, { useEffect, useState } from 'react';
import { View } from 'react-native';
import { CmsCarousel } from '../components/cmsCarousel';
import { Maybe, Page } from '@warnermmedia/gsp-core/brands/estadio/data-access';
import {
  carouselFormat,
  estadioComponent,
  getContentTitle,
  useCMSPageData,
  useCMSPageMetaTags,
  useIsMountedRef,
  useMparticleScreenEvent,
  useProfileData,
} from '@warnermmedia/gsp-core/brands/estadio/feature';
import { MetaTags, useGetDevice } from '@warnermmedia/gsp-core/sdk/ui';
import { CmsGrid } from '../components/cmsGrid';
import { Banner } from '../components/banner';

export const CMSPageBuilder = ({
  data,
  shouldBannerOverflow = false,
}: {
  data: Page | undefined;
  shouldBannerOverflow?: boolean;
}) => {
  const [pageUriData, setPageUriData] = useState<Page>();
  const [display, setDisplay] = useState<carouselFormat[] | null>(null);
  const { formatData } = useCMSPageData();
  const { metaTags, type, title, cmsId } = useCMSPageMetaTags(pageUriData);
  useMparticleScreenEvent(type, title, cmsId);
  const { userData } = useProfileData();
  const isMountedRef = useIsMountedRef();
  const { isWeb } = useGetDevice();

  useEffect(() => {
    if (pageUriData && pageUriData.components && isMountedRef.current) {
      // We add the allItems field to cached data so the generated Component
      // type does not work here.  estadioComponent has the allItems field.
      const displayData = formatData(pageUriData.components as Maybe<estadioComponent>[], userData?.favoriteTeam);
      setDisplay(displayData);
    }
  }, [pageUriData, isMountedRef]);

  useEffect(() => {
    if (!!data && isMountedRef.current) {
      setPageUriData(data);
    }
  }, [data, isMountedRef]);

  return (
    <View>
      {isWeb && <MetaTags data={metaTags} title={getContentTitle(title)} />}
      {(display || []).map((item, index) => {
        if (['carousel'].includes(item.type ?? '')) {
          return <CmsCarousel key={`${item.weight}${index}`} {...item} />;
        }
        if (['grid'].includes(item.type ?? '')) {
          return <CmsGrid data={item} key={item.headline} type={data?.type as Maybe<string>} />;
        }
        if (['banner'].includes(item.type ?? '')) {
          return <Banner data={item} key={item.headline} shouldOverflow={shouldBannerOverflow} />;
        }
        return null;
      })}
    </View>
  );
};

export default CMSPageBuilder;
