import React from 'react';
import { breakpointsStateStore, ModalType } from '@warnermmedia/gsp-core/brands/estadio/data-access';
import { languageStrings } from '@warnermmedia/gsp-core/brands/estadio/feature';
import ModalWrapper, { ModalContentContainer } from '../modalWrapper';
import { Text, View } from 'react-native';
import { getStyles } from './styles';
import { useReactiveVar } from '@apollo/client';
import { useTheme } from 'react-native-paper';
import { EstadioButton } from '../../components';

interface Props {
  visible: boolean;

  onClose(): void;
}

export const DiscoverMaxModal = ({ visible, onClose }: Props) => {
  const breakpoints = useReactiveVar(breakpointsStateStore);
  const { colors } = useTheme();
  const styles = getStyles(breakpoints, colors);

  return (
    <ModalWrapper
      visible={visible}
      modalType={visible ? ModalType.DiscoverMaxModal : null}
      onClose={() => {
        onClose();
      }}
      showCloseButton={true}
      animationType={'fade'}
      modalContentContainerType={ModalContentContainer.Full}
      modalMainContainerStyle={styles.container}
      modalLayoutContainerStyle={styles.fullScreenContainer}
    >
      <View style={styles.textContainer}>
        <Text style={styles.title}>{languageStrings.default.discoverMaxModalTitle}</Text>
        <View style={styles.containerInner}>
          <Text style={styles.description}>{languageStrings.default.discoverMaxModalText}</Text>
        </View>
        <EstadioButton
          mode="outline"
          label={languageStrings.default.discoverMaxModalButtonLabel}
          onPress={() => {
            onClose();
          }}
          btnStyle={styles.buttonStyle}
          labelStyle={styles.buttonLabelStyle}
        />
      </View>
    </ModalWrapper>
  );
};
