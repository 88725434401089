import React, { useEffect, useState } from 'react';
import { View } from 'react-native';
import { CustomButton, MetaTags } from '@warnermmedia/gsp-core/sdk/ui';
import { LayoutType, LayoutWrapper, TitleType } from '../layoutWrapper';
import {
  callbackNavigateExternal,
  getContentTitle,
  languageStrings,
  PagesUrl,
  QueryNames,
  ScreenEventType,
  useCMSPageMetaTags,
  useCMSQueryDataResults,
  useIsMountedRef,
  useMparticleScreenEvent,
} from '@warnermmedia/gsp-core/brands/estadio/feature';
import { TvReady } from '@warnermmedia/gsp-core/brands/estadio/assets';
import { getStyles } from './codeValidationSuccess.styles';
import { Page, breakpointsStateStore, Link } from '@warnermmedia/gsp-core/brands/estadio/data-access';
import { get } from 'lodash';
import { useReactiveVar } from '@apollo/client';
import { useTheme } from 'react-native-paper';

export const CodeValidationSuccess = () => {
  const language = languageStrings.default;
  const breakpoints = useReactiveVar(breakpointsStateStore);
  const { colors } = useTheme();
  const styles = getStyles(breakpoints, colors);
  const [newsLink, setNewsLink] = useState<Link | null>(null);
  const { pageData: pageUriData } = useCMSQueryDataResults<Page>({
    queryName: QueryNames.GET_COMMON_PAGE_DATA,
    uri: PagesUrl.CodeValidationSuccess,
  });
  const { metaTags, title, type, cmsId } = useCMSPageMetaTags(pageUriData);
  useMparticleScreenEvent(type ?? ScreenEventType.CodeValidationSuccess, title ?? language.codeValidationTitle, cmsId);
  const isMountedRef = useIsMountedRef();

  useEffect(() => {
    if (pageUriData && isMountedRef.current) {
      const pageComponent = pageUriData?.components?.find((item) => item?.id === 'pin_and_pair_news_url');
      setNewsLink(get(pageComponent, 'link', null));
    }
  }, [isMountedRef, pageUriData]);

  return (
    <LayoutWrapper
      pageTitle={language.codeValidationSuccessTitle}
      layoutType={LayoutType.Center}
      titleType={TitleType.Left}
      subTitle={language.codeValidationSuccessSubtitle}
      overrideTitleStyle={styles.title}
      overrideSubTitleStyle={styles.subtitle}
    >
      <MetaTags title={getContentTitle(title ?? language.codeValidationSuccessTitle)} data={metaTags} />
      <View style={{ alignSelf: 'center' }}>
        <TvReady />
      </View>
      {newsLink && (
        <CustomButton
          mode="text"
          btnStyle={styles.newsLink}
          labelStyle={styles.underlinedText}
          onPress={() => callbackNavigateExternal(newsLink?.uri ?? '')}
          label={newsLink.text ?? language.codeValidationSuccessNewsBtn}
        />
      )}
    </LayoutWrapper>
  );
};

export default CodeValidationSuccess;
