import React, { useContext, useState } from 'react';
import { UniversalSwimlane } from '../../swimlanes/universal/universalSwimlane';
import { carouselFormat, LayoutUpdateContext, useSetCardIndex } from '@warnermmedia/gsp-core/brands/estadio/feature';
import { View } from 'react-native';
import { useGetDevice } from '@warnermmedia/gsp-core/sdk/ui';
import { useTheme } from 'react-native-paper';
import { useReactiveVar } from '@apollo/client';
import { breakpointsStateStore } from '@warnermmedia/gsp-core/brands/estadio/data-access';

export const CmsPageSwimlane: React.FC<carouselFormat> = ({ allItems, headline, type, clickAction, format, image }) => {
  const [cardsIndex, setCardsIndex] = useState(0);
  const contentWidth = useContext(LayoutUpdateContext);
  const { setIndex } = useSetCardIndex(cardsIndex, setCardsIndex);
  const device = useGetDevice();
  const { colors } = useTheme();
  const breakpoints = useReactiveVar(breakpointsStateStore);

  return (
    <View>
      {Array.isArray(allItems) && allItems?.length > 0 ? (
        <UniversalSwimlane
          headline={headline}
          index={cardsIndex}
          indexUpdate={setIndex}
          data={allItems}
          type={type}
          format={format}
          action={clickAction}
          laneWidth={contentWidth}
          cardBg={image?.url ?? ''}
          breakpoints={breakpoints}
          colors={colors}
          device={device}
        ></UniversalSwimlane>
      ) : null}
    </View>
  );
};

export default CmsPageSwimlane;
