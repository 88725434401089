import React, { useCallback, useContext } from 'react';
import { View } from 'react-native';
import { useReactiveVar } from '@apollo/client';
import { carouselBase, carouselFormat } from '@warnermmedia/gsp-core/brands/estadio/feature';
import { TextContent, useGetDevice } from '@warnermmedia/gsp-core/sdk/ui';
import { breakpointsStateStore, HistoryContext, Maybe } from '@warnermmedia/gsp-core/brands/estadio/data-access';
import { getStyles } from './cmsGrid.styles';
import { TeamCard } from '../../../cards/team';
import { useTheme } from 'react-native-paper';
import GenericCard from '../../../cards/generic/genericCard';

interface GridComponentProps {
  data: carouselFormat;
  type: Maybe<string>;
}

export const CmsGrid = ({ data, type }: GridComponentProps) => {
  const historyContext = useContext(HistoryContext);
  const history = historyContext?.ready ? historyContext?.useHistory() : null;
  const breakpoints = useReactiveVar(breakpointsStateStore);
  const device = useGetDevice();
  const { colors } = useTheme();
  const styles = getStyles(breakpoints, device.isTv);
  const isTeams = data.format === 'teams';
  const isGeneric = data.format === 'generic';
  const isShow = type === 'shows';
  const isTournaments = type === 'tournaments';
  const showHeadline = isTeams || isShow || isTournaments;
  const content = (data?.allItems ?? []).filter((v) => !!v);

  const GridItem = isTeams ? TeamCard : isGeneric ? GenericCard : null;

  if (!GridItem) {
    return null;
  }

  const navigateToItem = useCallback(
    (item: carouselBase | null) => {
      if (!item || !item.callToAction?.url) {
        return;
      }
      history?.push(item.callToAction.url);
    },
    [history]
  );

  const getItemCard = useCallback(
    (item: carouselBase, index) => (
      <GridItem
        item={item}
        key={index}
        type={type ?? ''}
        index={index}
        onPress={navigateToItem}
        action={navigateToItem}
        device={device}
        colors={colors}
        breakpoints={breakpoints}
        laneWidth={breakpoints.windowWidth}
      />
    ),
    [navigateToItem, GridItem, breakpoints, colors, device, type]
  );

  return (
    <View style={{ width: '100%' }}>
      {showHeadline ? <TextContent style={styles.subTitle}>{data?.headline || ''}</TextContent> : null}
      <View style={styles.wrapper}>{content.map((item, index) => getItemCard(item, index))}</View>
    </View>
  );
};

export default CmsGrid;
