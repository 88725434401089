import { StyleProp, ViewStyle, StyleSheet, TextStyle } from 'react-native';
import { Breakpoints } from '@warnermmedia/gsp-core/sdk/ui';
import merge from 'lodash/merge';

interface Styles {
  layoutWrapperStyle: ViewStyle;
  title: TextStyle;
  subtitle: TextStyle;
}

export const getStyles = (breakpoints: Breakpoints) => {
  const base: StyleProp<Styles> = {
    layoutWrapperStyle: {
      paddingHorizontal: 0,
    },
    title: {},
    subtitle: {},
  };

  const mediaQueries =
    breakpoints.currentBreakpoints.isSmMed ||
    breakpoints.currentBreakpoints.isMedLg ||
    breakpoints.windowWidth === breakpoints.breakpointSizes.lg
      ? ({
          title: {
            paddingLeft: 0,
          },
          subtitle: {
            paddingLeft: 0,
          },
        } as StyleProp<Styles>)
      : breakpoints.currentBreakpoints.isLgXl ||
        breakpoints.currentBreakpoints.isXlXxl ||
        breakpoints.currentBreakpoints.isXxl
      ? ({} as StyleProp<Styles>)
      : {};
  const mergedStyles = merge(base, mediaQueries);

  return StyleSheet.create(mergedStyles);
};
