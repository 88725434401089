import languageStrings from '../I18n';
import Constants from '../utils/Constants';
import { footerStateStore } from '../Apollo/ApolloProvider';
import { Linking, Platform } from 'react-native';

export type FooterState = {
  visibleFooterModalIs: string;
  footerData: FooterData[];
};

export type FooterData = {
  title?: string;
  callback(link?: string): void | null;
  imageIcon?: { [key: string]: JSX.Element } | null;
  link?: string;
  id: number;
};

export function callbackNavigateExternal(link: string): boolean {
  // For Security reasons lets enforce https
  const WWW_URL_PATTERN = new RegExp('^https?://', 'i');

  function isWebPlatform(): boolean {
    return Platform && Platform?.OS && Platform?.OS === 'web';
  }

  if (!WWW_URL_PATTERN.test(link)) {
    return false;
  } else {
    let returnVal = true;

    if (isWebPlatform()) {
      window.open(link, '_blank');
      returnVal = true;
    } else {
      Linking.canOpenURL(link).then((supported): void => {
        if (!supported) {
          returnVal = false;
        } else {
          Linking.openURL(link);
          returnVal = true;
        }
      });
    }

    return returnVal;
  }
}

export function callbackNavigateModal(link: string): boolean {
  footerStateStore({ ...footerStateStore(), visibleFooterModalIs: link });
  return true;
}

export const DEFAULT_FOOTER = ([
  {
    title: languageStrings.FOOTER_LABEL_8,
    callback: callbackNavigateExternal,
    link: Constants.FOOTER_LINK_8,
    id: 8,
  },
  {
    title: languageStrings.FOOTER_LABEL_1,
    callback: callbackNavigateExternal,
    link: Constants.FOOTER_LINK_1,
    id: 1,
  },
  {
    title: languageStrings.FOOTER_LABEL_10,
    callback: callbackNavigateExternal,
    link: Constants.FOOTER_LINK_15,
    id: 2,
  },
  // {
  //   "title": languageStrings.FOOTER_LABEL_2,
  //   "callback": callbackNavigateExternal,
  //   "link": Constants.FOOTER_LINK_2,
  //   "id": 3,
  // },
  {
    title: languageStrings.FOOTER_LABEL_3,
    callback: callbackNavigateModal,
    link: Constants.FOOTER_LINK_3,
    id: 4,
  },
  {
    title: languageStrings.FOOTER_LABEL_4,
    callback: callbackNavigateExternal,
    link: Constants.FOOTER_LINK_4,
    id: 5,
  },
  {
    title: languageStrings.FOOTER_LABEL_5,
    callback: callbackNavigateExternal,
    link: Constants.FOOTER_LINK_5,
    id: 6,
  },
  {
    title: languageStrings.FOOTER_LABEL_7,
    callback: callbackNavigateExternal,
    link: Constants.FOOTER_LINK_7,
    id: 7,
  },
  {
    callback: callbackNavigateExternal,
    imageIcon: Constants.FOOTER_IMAGE_1,
    link: Constants.FOOTER_LINK_9,
    id: 12,
  },
  {
    callback: callbackNavigateExternal,
    imageIcon: Constants.FOOTER_IMAGE_2,
    link: Constants.FOOTER_LINK_10,
    id: 13,
  },
  {
    callback: callbackNavigateExternal,
    imageIcon: Constants.FOOTER_IMAGE_3,
    link: Constants.FOOTER_LINK_11,
    id: 14,
  },
  {
    title: languageStrings.FOOTER_LABEL_9,
    callback: callbackNavigateModal,
    link: Constants.FOOTER_LINK_12,
    id: 15,
  },
  {
    callback: callbackNavigateExternal,
    imageIcon: Constants.FOOTER_IMAGE_4,
    link: Constants.FOOTER_LINK_13,
    id: 10,
  },
  {
    callback: callbackNavigateExternal,
    imageIcon: Constants.FOOTER_IMAGE_5,
    link: Constants.FOOTER_LINK_14,
    id: 11,
  },
] as unknown) as FooterData[];

export const FOOTER_STATE_INITIAL: FooterState = {
  visibleFooterModalIs: '',
  footerData: DEFAULT_FOOTER,
};
