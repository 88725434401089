import React, { FC, useCallback, useContext, useEffect, useState } from 'react';
import { NativeSyntheticEvent, TextInputKeyPressEventData, TextStyle, View, ViewStyle } from 'react-native';
import {
  TextContent,
  useGetDevice,
  validateNonEmptyString,
  validatePasswordRule,
  Variant,
} from '@warnermmedia/gsp-core/sdk/ui';
import { useReactiveVar } from '@apollo/client';
import { AppConfigContext, breakpointsStateStore } from '@warnermmedia/gsp-core/brands/estadio/data-access';
import {
  EstadioButton,
  EstadioCheckbox,
  EstadioLinkButton,
  EstadioTextInput,
  LayoutType,
  LayoutWrapper,
  TitleType,
} from '@warnermmedia/gsp-core/brands/estadio/ui';
import { getStyles } from './styles';
import { ArcEvent, Button, KeyCodes, Scope } from '@warnermedia/gsp-core/sdk/arcade-machine';
import { loadData } from '@warnermmedia/gsp-core/sdk/data-access';
import { languageStrings, useOnEnterKeyPress, useStatusMessage } from '@warnermmedia/gsp-core/brands/estadio/feature';
import { useTheme } from 'react-native-paper';

export const LOGIN_USER_ID = 'LOGIN_USER_ID';

export interface Data {
  username: string;
  password: string;
  rememberMe?: boolean;
}

enum FormTextField {
  Username = 'username',
  Password = 'password',
  RememberMe = 'rememberMe',
}

export interface LoginFormProps {
  title?: string;
  isLoading: boolean;
  subTitle?: string;
  usernameLabel?: string;
  emptyUsernameError?: string;
  invalidUsernameError?: string;
  emptyPasswordError?: string;
  invalidPasswordError?: string;
  usernamePlaceholder?: string;
  passwordLabel?: string;
  rememberMeText?: string;
  passwordPlaceholder?: string;
  forgotPassBtnText?: string;
  loginBtnText?: string;
  titleStyle?: TextStyle;
  subTitleStyle?: TextStyle;
  wrapperStyle?: ViewStyle;
  inputStyle?: ViewStyle;
  inputWrapperStyle?: ViewStyle;
  errorTextStyle?: ViewStyle;
  submitBtnStyle?: ViewStyle;
  loginBtnWrapperStyle?: ViewStyle;
  loginLabelStyle?: TextStyle;
  forgotPassBtnStyle?: ViewStyle;
  inputLabelStyle?: TextStyle;
  formHeaderStyle?: ViewStyle;
  formWrapperStyle?: TextStyle;
  rememberMeTextStyle?: TextStyle;
  forgotPassBtnWrapperStyle?: ViewStyle;
  forgotPassBtnLabelStyle?: TextStyle;
  handleForgotPasswordPress: () => void;
  handleLogin: (data: Data) => void;
  handleRecaptchaChange?: (value: string | null) => void;
  error?: string;
  checkboxColor?: 'string';
  inputIsFocusedColor?: 'string';
  inputHasErrorColor?: 'string';
  validateUsername: (username: string) => boolean;
  showForgotPassword?: boolean;
  onRegisterPress: (data: Data) => void;
  isValidatingCode: boolean;
  registerText?: string;
  showRegister?: boolean;
  handleLoginWithProvider?: () => void;
  loginWithProviderLabel?: string;
}

export const EstadioLoginForm: FC<LoginFormProps> = ({
  isLoading,
  usernameLabel = languageStrings.default.emailText,
  emptyUsernameError = languageStrings.default.emptyUsernameError,
  invalidUsernameError = languageStrings.default.invalidUsernameError,
  emptyPasswordError = languageStrings.default.missingPassword,
  invalidPasswordError = languageStrings.default.missingPassword2,
  usernamePlaceholder = '',
  passwordLabel,
  rememberMeText = languageStrings.default.rememberMeText,
  passwordPlaceholder = '',
  forgotPassBtnText,
  loginBtnText = languageStrings.default.enterText,
  inputStyle,
  inputWrapperStyle,
  rememberMeTextStyle,
  handleForgotPasswordPress,
  handleLogin,
  inputLabelStyle,
  error,
  inputHasErrorColor,
  inputIsFocusedColor,
  validateUsername,
  onRegisterPress,
  registerText,
  showRegister = true,
  handleLoginWithProvider,
  loginWithProviderLabel,
  isValidatingCode,
}) => {
  const appConfig = useContext(AppConfigContext);
  const breakpoints = useReactiveVar(breakpointsStateStore);
  const { isPwa } = useGetDevice();
  const { colors } = useTheme();
  const styles = getStyles(breakpoints, false, colors);
  const [data, setData] = useState({
    username: '',
    password: '',
    rememberMe: true,
  });
  const [inputError, setError] = useState({
    username: false,
    password: false,
  });
  const [focusObj, setFocus] = useState({
    username: false,
    password: false,
  });
  const [shouldFocusUsername, setShouldFocusUsername] = useState(false);
  const [shouldFocusPassword, setShouldFocusPassword] = useState(false);
  const [lastFocusedField, setLastFocusedField] = useState<FormTextField>(FormTextField.Username);
  const { onEnterKeyPress } = useOnEnterKeyPress();
  const { setStatusMessage, cleanStatusMessage } = useStatusMessage({ shouldTimeout: false });

  useEffect(() => {
    if (error) {
      setStatusMessage({ message: error, type: Variant.Error });
    }
  }, [error, setStatusMessage]);

  useEffect(() => {
    setData((prevState) => ({
      ...prevState,
      username: loadData(LOGIN_USER_ID) ?? '',
    }));
  }, []);

  const handleSubmit = useCallback(() => {
    cleanStatusMessage();
    const usernameIsValid = validateUsername(data.username.trim());
    const passwordIsValid = validateNonEmptyString(data.password);
    if (usernameIsValid && passwordIsValid) {
      return handleLogin(data);
    } else {
      setError({
        username: !usernameIsValid,
        password: !passwordIsValid,
      });
      handleLogin(data);
    }
  }, [data, handleLogin, validateUsername, cleanStatusMessage]);

  const handleInputBorderState = (error: boolean, focus: boolean) => {
    let borderProps = {};
    if (error) {
      borderProps = {
        borderWidth: 1,
        borderColor: inputHasErrorColor || 'red',
      };
    }
    if (focus) {
      borderProps = {
        borderWidth: 1,
        borderColor: inputIsFocusedColor || 'blue',
      };
    }
    return borderProps;
  };

  const onCheckButtonHandler = (arcEvt: ArcEvent) => {
    if (arcEvt.event === Button.Submit) {
      arcEvt.target.click();
    }
  };

  const onChange = useCallback(() => {
    if (!isLoading) {
      setData((prevState) => ({
        ...prevState,
        rememberMe: !data.rememberMe,
      }));
    }
  }, [data.rememberMe, isLoading]);

  const getCheckboxBlock = () => (
    <View style={styles.checkboxWrapper}>
      <EstadioCheckbox checked={data.rememberMe} onChange={onChange} />
      <TextContent style={[styles.rememberMeText, rememberMeTextStyle]}>{rememberMeText}</TextContent>
    </View>
  );

  const getUserEmailErrorMessage = (userEmail: string): string => {
    return !validateNonEmptyString(userEmail) ? emptyUsernameError : invalidUsernameError;
  };

  const getUserPasswordErrorMessage = (password: string): string => {
    if (!validateNonEmptyString(password)) {
      return emptyPasswordError;
    } else if (!validatePasswordRule(password)) {
      return invalidPasswordError;
    } else {
      return '';
    }
  };

  const setTextFieldFocus = useCallback(
    (field: FormTextField) => {
      if (lastFocusedField === field && field === FormTextField.Username) {
        setShouldFocusPassword(true);
        setShouldFocusUsername(false);
        setLastFocusedField(FormTextField.Password);
      } else if (lastFocusedField === field && field === FormTextField.Password) {
        setShouldFocusUsername(true);
        setShouldFocusPassword(false);
        setLastFocusedField(FormTextField.RememberMe);
      } else {
        setShouldFocusUsername(false);
        setShouldFocusPassword(false);
        setLastFocusedField(FormTextField.Username);
      }
    },
    [lastFocusedField]
  );

  const onKeyPress = useCallback(
    (e: NativeSyntheticEvent<TextInputKeyPressEventData>, field: FormTextField) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      if (e.keyCode === KeyCodes.Tab) {
        setTextFieldFocus(field);
      } else {
        onEnterKeyPress(e, handleSubmit);
      }
    },
    [setTextFieldFocus, onEnterKeyPress, handleSubmit]
  );

  const onBlurUsername = useCallback(() => {
    const username = data.username.trim();
    if (username.length > 0) {
      setError((prevState) => ({
        ...prevState,
        username: !validateUsername(username),
      }));
    }
    setShouldFocusUsername(false);
    setShouldFocusPassword(false);
    setLastFocusedField(FormTextField.Username);
  }, [data.username, validateUsername]);

  const onBlurPassword = useCallback(() => {
    const password = data.password;
    if (password.length > 0) {
      setError((prevState) => ({
        ...prevState,
        password: !validatePasswordRule(password),
      }));
    }
  }, [data.password]);

  const onLoginWithProvider = useCallback(() => {
    handleLoginWithProvider && handleLoginWithProvider();
  }, [handleLoginWithProvider]);

  const onForgotPasswordPress = useCallback(() => {
    handleForgotPasswordPress();
  }, [handleForgotPasswordPress]);

  return (
    <LayoutWrapper
      layoutType={LayoutType.Center}
      titleType={TitleType.Left}
      pageTitle={languageStrings.default.landingPage}
      subTitle={
        !isValidatingCode
          ? languageStrings.default.landingSubTitle
          : languageStrings.default.codeValidationLoginSubtitle
      }
      overrideTitleWrapperStyle={{ marginTop: error ? 16 : 48 }}
    >
      <View style={[styles.inputWrapper, inputWrapperStyle]}>
        {usernameLabel && (
          <TextContent accessible={false} style={[styles.inputLabel, inputLabelStyle]}>
            {usernameLabel}
          </TextContent>
        )}
        <EstadioTextInput
          style={[
            data.username.length < 1 ? styles.input : styles.inputFilled,
            inputStyle,
            handleInputBorderState(inputError.username, focusObj.username),
          ]}
          placeholder={usernamePlaceholder}
          onChangeText={(username: string) => {
            setData((prevState) => ({
              ...prevState,
              username,
            }));
          }}
          onBlur={onBlurUsername}
          onKeyPress={(e) => onKeyPress(e, FormTextField.Username)}
          errorString={getUserEmailErrorMessage(data.username)}
          error={inputError.username}
          value={data.username}
          shouldFocus={shouldFocusUsername}
          editable={!isLoading}
          focusable={!isLoading}
          whiteBorder
        />
      </View>
      <View style={[styles.inputWrapper, inputWrapperStyle, { marginTop: inputError.username ? 24 : 16 }]}>
        {passwordLabel && (
          <TextContent accessible={false} style={[styles.inputLabel, inputLabelStyle]}>
            {passwordLabel || languageStrings.default.passwordText}
          </TextContent>
        )}
        <EstadioTextInput
          style={[
            data.password.length < 1 ? styles.input : styles.inputFilled,
            inputStyle,
            handleInputBorderState(inputError.password, focusObj.password),
          ]}
          placeholder={passwordPlaceholder}
          onChangeText={(password) => {
            setData((prevState) => ({
              ...prevState,
              password,
            }));
            setError((prevState) => ({
              ...prevState,
              password: !validateNonEmptyString(password),
            }));
          }}
          onBlur={onBlurPassword}
          onKeyPress={(e) => onKeyPress(e, FormTextField.Password)}
          errorString={getUserPasswordErrorMessage(data.password)}
          error={inputError.password}
          shouldFocus={shouldFocusPassword}
          editable={!isLoading}
          focusable={!isLoading}
          secureTextEntry
          whiteBorder
        />
      </View>
      {isPwa ? <Scope onButton={onCheckButtonHandler}>{getCheckboxBlock()}</Scope> : getCheckboxBlock()}

      <EstadioButton
        onPress={() => handleSubmit()}
        disabled={isLoading}
        label={loginBtnText}
        btnStyle={styles.handleSubmitBtn}
        labelStyle={styles.handleSubmitBtnLabel}
      />

      <View style={styles.actionsContainer}>
        <EstadioLinkButton
          onPress={onForgotPasswordPress}
          label={forgotPassBtnText}
          btnStyle={styles.linkButton}
          labelStyle={styles.linkButtonLabel}
        />

        {showRegister && <View style={styles.crossBar} />}
        {showRegister && (
          <EstadioLinkButton
            onPress={() => onRegisterPress(data)}
            label={registerText}
            btnStyle={styles.linkButton}
            labelStyle={styles.linkButtonLabel}
          />
        )}
      </View>
      {handleLoginWithProvider && appConfig?.appConfig.tve.enabled && (
        <View style={styles.loginWithProviderBtnWrapper}>
          <EstadioButton
            label={loginWithProviderLabel}
            onPress={onLoginWithProvider}
            btnColor="transparent"
            btnStyle={styles.loginWithProviderBtn}
            labelStyle={styles.loginWithProviderLabel}
          />
        </View>
      )}
    </LayoutWrapper>
  );
};

export default EstadioLoginForm;
