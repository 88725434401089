import { useReactiveVar } from '@apollo/client';
import { TickerTile } from './ticker';
import React, { memo, useCallback, useContext, useEffect, useState } from 'react';
import { Dimensions, View } from 'react-native';
import {
  carouselBase,
  EstadioEventsCallTypes,
  QueryNames,
  useCMSQueryDataResults,
  useIsMountedRef,
  useSetCardIndex,
  useTickerData,
} from '@warnermmedia/gsp-core/brands/estadio/feature';
import {
  breakpointsStateStore,
  HistoryContext,
  isUserLoggedIn,
  SortDirections,
  SKELETON_NAVBAR_WIDTH,
} from '@warnermmedia/gsp-core/brands/estadio/data-access';
import { getDate, useGetDevice } from '@warnermmedia/gsp-core/sdk/ui';
import { useTheme } from 'react-native-paper';

const TickerWrapper = () => {
  const device = useGetDevice();
  const { colors } = useTheme();
  const isLoggedIn = useReactiveVar(isUserLoggedIn);
  const { pageData: tickerData } = useCMSQueryDataResults<Event[]>({
    callType: EstadioEventsCallTypes.Ticker,
    queryName: QueryNames.GET_TICKER_EVENTS,
    order: SortDirections.Asc,
  });
  const historyContext = useContext(HistoryContext);
  const history = historyContext?.ready ? historyContext?.useHistory() : null;
  const { formatData } = useTickerData();
  const [tickerIndex, setTickerIndex] = useState<number>(0);

  const [tickerDisplay, setTickerDisplay] = useState<carouselBase[]>();
  const breakpoints = useReactiveVar(breakpointsStateStore);
  const tickerWidth = device.isTv ? 264 : 155;
  const navMobile = !device.isPwa || (breakpoints.windowWidth <= breakpoints.breakpointSizes.lg && !device.isTv);
  const effectiveScreenWidth = navMobile ? breakpoints.windowWidth : breakpoints.windowWidth - SKELETON_NAVBAR_WIDTH; //Compensate for navbar width on web.
  const tickerPerScreen = Math.floor(effectiveScreenWidth / tickerWidth);
  const isMountedRef = useIsMountedRef();
  const { setIndex } = useSetCardIndex(tickerIndex, setTickerIndex);
  const { width } = Dimensions.get('window');

  const getStartIndex = (displayData: carouselBase[]): number | undefined => {
    return displayData.findIndex((data) => data.match?.startDateTime && getDate(data.match?.startDateTime).isToday());
  };

  const setNewTickerIndex = useCallback(
    (displayData: carouselBase[]) => {
      const startIndex = getStartIndex(displayData);

      if (startIndex && startIndex >= 0) {
        const itemLength = displayData.length;
        const totalItemFromStartIndex = itemLength ? itemLength - (startIndex || 0) : 0;

        if (totalItemFromStartIndex < tickerPerScreen && itemLength >= tickerPerScreen) {
          setTickerIndex(itemLength - tickerPerScreen);
        } else {
          setTickerIndex(itemLength - tickerPerScreen > 0 ? startIndex : 0);
        }
      }
    },
    [tickerPerScreen]
  );

  useEffect(() => {
    if (!!tickerDisplay && isMountedRef.current && width) {
      setNewTickerIndex(tickerDisplay);
    }
  }, [tickerDisplay, isMountedRef, tickerPerScreen, width, setNewTickerIndex]);

  useEffect(() => {
    if (!!tickerData && isMountedRef.current) {
      const displayData = formatData(tickerData);
      setNewTickerIndex(displayData);
      setTickerDisplay(formatData(tickerData));
    }
  }, [tickerData, isMountedRef, formatData, setNewTickerIndex]);

  // Gets called when item click/tap
  const clickAction = useCallback(
    (item: carouselBase) => {
      const eventId = item.match?.eventId;
      if (!isLoggedIn) {
        history?.push(`/login`);
        return;
      }
      if (typeof item !== 'undefined' && eventId) {
        history?.push(`/match/${eventId}`);
      }
    },
    [isLoggedIn, history]
  );

  return (
    <View style={[{ backgroundColor: colors.tenantBackground.dark.surface02, width: '100%', overflow: 'hidden' }]}>
      {tickerDisplay && tickerDisplay.length > 0 && (
        <TickerTile
          index={tickerIndex}
          indexUpdate={setIndex}
          data={tickerDisplay ?? []}
          action={clickAction}
          laneWidth={breakpoints.windowWidth}
          tickerPerScreen={tickerPerScreen}
          tickerWidth={tickerWidth}
          device={device}
          colors={colors}
          breakpoints={breakpoints}
        ></TickerTile>
      )}
    </View>
  );
};

export default memo(TickerWrapper);
