import {
  ConvivaAnalyticsConfig,
  DeepPartial,
  KeySystems,
  PlayConfig,
  PlayerConfigAdData,
  PlayerConfigBuilder,
  PlayerConfigDRMPlaybackData,
  PlayerConfigMetadata,
  PlayerConfigUIData,
  UIControlName,
  UISlate,
} from '@top/player-block-web';
import { SetupConfig, ConvivaSetupConfig } from '@top/tub-player-web';
import { useGetDevice } from '@warnermmedia/gsp-core/sdk/ui';
import { PLAYER_START_BITRATE, PLAYER_MAX_BITRATE } from '@warnermmedia/gsp-core/brands/estadio/feature';

const TOP_DEBUG_PARAM = 'videoDebug';
const DEFAULT_TUB_KEY = '6f48af99-edb0-4411-8979-2c7859eddd9d';

export interface PlayerMediaConfig {
  startBitrate: number;
  maxBitrate: number;
  capBitrateToSize: boolean;
}

export const usePlayerConfigBuilder = (withMediaConfig: PlayerMediaConfig) => {
  const getUrlParam = (name: string): string => {
    name = name.replace(/[[]/, '\\[').replace(/[\]]/, '\\]').toLowerCase();
    const regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
    const results = regex.exec(window.location.search.toLowerCase());
    return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
  };
  const { isNotWeb } = useGetDevice();

  const uiPlayerControlsDefault = {
    controls: [
      'Cast',
      UIControlName.Play,
      UIControlName.Progress_Bar,
      UIControlName.Fullscreen,
      UIControlName.Air_Play,
      UIControlName.Volume,
      UIControlName.Closed_Captions,
      UIControlName.Live,
      UIControlName.Time_Duration,
    ],
    slates: [UISlate.Buffering, UISlate.Start, UISlate.End, UISlate.Media_Playback, UISlate.Giant_Play],
    order: {
      left: [],
      right: [],
      center: [],
      topLeft: [],
      topRight: [],
    },
  };

  const uiLivePlayerControlsDefault = {
    ...uiPlayerControlsDefault,
    controls: [
      'Cast',
      UIControlName.Play,
      UIControlName.Progress_Bar,
      UIControlName.Fullscreen,
      UIControlName.Air_Play,
      UIControlName.Volume,
      UIControlName.Closed_Captions,
      UIControlName.Live,
    ],
  };

  const debugTop = () => {
    return getUrlParam(TOP_DEBUG_PARAM) === 'true';
  };

  const newSetupConfig = (appId: string, convivaConfig: ConvivaSetupConfig, autoplay = true): SetupConfig => {
    return {
      engine: {
        key: DEFAULT_TUB_KEY,
        playback: {
          autoplay,
        },
        tweaks: {
          hls_sync_via_media_sequence: false,
          enable_seek_for_live: true,
          app_id: appId,
          file_protocol: true,
        },
        adaptation: {
          desktop: {
            startupBitrate: PLAYER_START_BITRATE,
            maxStartupBitrate: PLAYER_MAX_BITRATE,
          },
        },
      },
      conviva: convivaConfig,
      useTizen: false,
      useWebos: false,
      debug: false,
    };
  };

  const setupConfig = (
    container: HTMLDivElement,
    autoPlay: boolean,
    appId: string,
    configUI: DeepPartial<PlayerConfigUIData>,
    convivaConfig: DeepPartial<ConvivaAnalyticsConfig>,
    additionalMetadata: PlayerConfigMetadata | undefined
  ) => {
    const additionalMeta = additionalMetadata ?? {};
    const setupConfiguration = PlayerConfigBuilder.forSetup()
      .withContainer(container)
      .withUI(configUI)
      .withPlayback({ autoPlay })
      .withConviva(convivaConfig)
      .withDebug({ enabled: true })
      .withMetadata({
        ...additionalMeta,
        appId,
      })
      .withMedia(withMediaConfig)
      .withMonitors({ contentStall: { enabled: false } })
      .build();
    return setupConfiguration;
  };

  const updateConfig = (
    metadata: PlayerConfigMetadata | undefined,
    ads: DeepPartial<PlayerConfigAdData> | undefined,
    conviva: DeepPartial<ConvivaAnalyticsConfig> | undefined,
    drm: DeepPartial<PlayerConfigDRMPlaybackData> | undefined
  ): DeepPartial<PlayConfig> => {
    let updateConfiguration = PlayerConfigBuilder.forUpdate();
    if (metadata) {
      updateConfiguration = updateConfiguration.withMetadata(metadata);
    }
    if (ads) {
      updateConfiguration = updateConfiguration.withAds(ads);
    }
    if (conviva) {
      updateConfiguration = updateConfiguration.withConviva(conviva);
    }
    if (drm) {
      updateConfiguration = updateConfiguration.withDrm(drm);
      updateConfiguration = updateConfiguration.withCms({
        ...(isNotWeb && { forceKeySystem: KeySystems.Widevine }),
      });
    }
    return updateConfiguration
      .withMedia(withMediaConfig)
      .withMonitors({ contentStall: { enabled: false } })
      .build();
  };

  return {
    setupConfig,
    updateConfig,
    uiPlayerControlsDefault,
    uiLivePlayerControlsDefault,
    newSetupConfig,
  };
};
