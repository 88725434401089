import Axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { DALTON } from './DaltonSingleton';
import { saveData } from './storage';
import { DALTON_AUTH_TOKEN, ErrorMessages, PathNames } from '../consts';
import {
  AttachPaymentAxiosResponse,
  AttachPaymentResponse,
  BillingSubscriptionResponse,
  CancelSubscriptionAxiosResponse,
  CancelSubscriptionResponse,
  ConfirmVerificationCodeAxiosResponse,
  ConfirmVerificationCodeParams,
  ConfirmVerificationCodeResponse,
  CouponDetailAxiosResponse,
  CouponDetailResponse,
  CreateIdentityAxiosResponse,
  CreateIdentityParams,
  CreateIdentityResponse,
  CreateProfileAxiosResponse,
  CreateProfileParams,
  EntitlementsAxiosResponse,
  EntitlementsResponse,
  ForgotPasswordAxiosResponse,
  ForgotPasswordParams,
  ForgotPasswordResponse,
  GetAuthTokenParams,
  GetAuthTokenResponse,
  GetBillingProfileResponse,
  GetPreferencesAxiosResponse,
  GetPreferencesResponse,
  GetProfileAxiosResponse,
  GetSessionIdAxiosResponse,
  GetSessionIdResponse,
  GetTransactionsResponse,
  LoginAxiosResponse,
  LoginParams,
  LogoutAxiosResponse,
  LogoutResponse,
  MapCouponAxiosResponse,
  MapCouponResponse,
  PayPalSubscriptionParams,
  PreAuthAxiosResponse,
  ProductsAxiosResponse,
  ProductsResponse,
  PurchaseAxiosResponse,
  PurchaseParams,
  PurchasePreviewAxiosResponse,
  PurchasePreviewParams,
  PurchaseResponse,
  PurchaseSubscriptionAxiosResponse,
  PurchaseSubscriptionParams,
  PurchaseSubscriptionResponse,
  RedeemCouponAxiosResponse,
  RedeemCouponParams,
  RedeemCouponResponse,
  RefreshTokenAxiosResponse,
  RefreshTokenResponse,
  RequestPaypalSubscriptionResponse,
  ResendVerificationEmailAxiosResponse,
  ResendVerificationEmailParams,
  ResendVerificationEmailResponse,
  SendSDKResponse,
  SignupParams,
  SubscriptionsAxiosResponse,
  SubscriptionsResponse,
  UpdatePasswordAxiosResponse,
  UpdatePasswordParams,
  UpdatePasswordResponse,
  UpdateUserAxiosResponse,
  UpdateUserContactNumberAxiosResponse,
  UpdateUserContactNumberParams,
  UpdateUserContactNumberResponse,
  UpdateUserParams,
  UpdateUserPreferencesAxiosResponse,
  UpdateUserPreferencesItemParams,
  UpdateUserPreferencesParams,
  UpdateUserPreferencesResponse,
  UpdateUserResponse,
  User,
  UserProfileResponse,
} from '../types/dalton-types';
import { AxiosTypes } from '../types/axios-types';
import { callApiEndpoint, createApiResponse } from './axiosHelper';
import { get } from 'lodash';

/**
 * @type DaltonProps
 * @description All Dalton functions to be returned for use by brand developers
 */
interface DaltonProps {
  // CORE (user API)
  signup(signupParams: SignupParams): Promise<SendSDKResponse<CreateIdentityResponse>>;
  createProfile(createProfileParams: CreateProfileParams): Promise<SendSDKResponse<UserProfileResponse>>;
  login(loginParams: LoginParams): Promise<SendSDKResponse<UserProfileResponse>>;
  logout(): Promise<SendSDKResponse<LogoutResponse>>;
  updateUser(updateUserParams: UpdateUserParams): Promise<SendSDKResponse<UpdateUserResponse>>;
  updateUserContactNumber(
    updateUserContactNumberParams: UpdateUserContactNumberParams
  ): Promise<SendSDKResponse<UpdateUserContactNumberResponse>>;
  removeUserContactNumber(contactNumberId: string): Promise<SendSDKResponse<UpdateUserContactNumberResponse>>;
  updateUserPreferences(
    updateUserPreferencesParams: UpdateUserPreferencesParams
  ): Promise<SendSDKResponse<UpdateUserPreferencesResponse>>;
  updateUserPreferencesItem(
    updateUserPreferencesItemParams: UpdateUserPreferencesItemParams
  ): Promise<SendSDKResponse<UpdateUserPreferencesResponse>>;
  forgotPassword(forgotPasswordParams: ForgotPasswordParams): Promise<SendSDKResponse<ForgotPasswordResponse>>;
  updatePassword(updatePasswordParams: UpdatePasswordParams): Promise<SendSDKResponse<UpdatePasswordResponse>>;
  getAuthToken(getAuthTokenParams: GetAuthTokenParams): Promise<SendSDKResponse<GetAuthTokenResponse>>;
  getProfile(): Promise<SendSDKResponse<User>>;
  getPreferences(): Promise<SendSDKResponse<GetPreferencesResponse>>;
  getBillingProfile(): Promise<SendSDKResponse<GetBillingProfileResponse>>;

  mapCoupon(couponCode: string, signal?: AbortSignal): Promise<SendSDKResponse<MapCouponResponse>>;

  getSessionId(): Promise<SendSDKResponse<GetSessionIdResponse>>;

  purchaseSubscription(
    purchaseSubscriptionParams: PurchaseSubscriptionParams
  ): Promise<SendSDKResponse<PurchaseSubscriptionResponse>>;

  subscriptionPurchaseRequest(
    purchaseSubscriptionParams: PayPalSubscriptionParams
  ): Promise<SendSDKResponse<RequestPaypalSubscriptionResponse>>;

  finalizeSubscriptionPurchase(vid: string): Promise<SendSDKResponse<PurchaseSubscriptionResponse>>;

  updateToken(token: string): void;

  redeemCoupon(redeemCouponParams: RedeemCouponParams): Promise<SendSDKResponse<RedeemCouponResponse>>;

  couponDetail(coupon: string, signal?: AbortSignal): Promise<SendSDKResponse<CouponDetailResponse>>;

  // GIZMO (products & payments API)
  getEntitlements(): Promise<SendSDKResponse<EntitlementsResponse>>;
  getProducts(): Promise<SendSDKResponse<ProductsResponse>>;
  getSubscriptions(): Promise<SendSDKResponse<SubscriptionsResponse>>;
  attachPaymentToUser(cardId: string): Promise<SendSDKResponse<AttachPaymentResponse>>;
  confirmPurchase(purchaseParams: PurchaseParams): Promise<SendSDKResponse<PurchaseResponse>>;
  purchasePreview(
    purchaseParams: PurchasePreviewParams,
    signal?: AbortSignal
  ): Promise<SendSDKResponse<BillingSubscriptionResponse>>;
  refreshToken(): Promise<SendSDKResponse<RefreshTokenResponse>>;
  resendVerificationEmail(
    resendVerificationEmailParams: ResendVerificationEmailParams
  ): Promise<SendSDKResponse<ResendVerificationEmailResponse>>;
  confirmVerificationCode(
    confirmVerificationCodeParams: ConfirmVerificationCodeParams
  ): Promise<SendSDKResponse<ConfirmVerificationCodeResponse>>;
  cancelUserSubscription(
    subscriptionId: string,
    cancelingReason: string
  ): Promise<SendSDKResponse<CancelSubscriptionResponse>>;

  getTransactions(): Promise<SendSDKResponse<GetTransactionsResponse>>;
}

/**
 * @function useDalton
 * @description Location of all scripts related to authorization of new and exisiting users
 * @param daltonAPI
 * @param errorMsgStore
 * @param isUserEmailVerified
 * @param isUserLoggedIn
 * @param loginAPIStateStore
 */

const useDalton = (daltonAPI: string, tenant: string, apps: string[]): DaltonProps => {
  const daltonUser = DALTON;
  daltonUser.endpoint = daltonAPI;

  const updateToken = (newToken: string): void => {
    daltonUser.token = newToken;
  };

  /**
   * @function callDaltonEndpoint
   * @description call Dalton with specified params
   *
   * @param axiosType axios call type (get/post)
   * @param endpointPath path required to Dalton endpoint
   * @param body JSON stringified body params for axios call
   * @param requireToken boolean to determine if auth token is required in call
   * @returns axios response or null
   */
  async function callDaltonEndpoint(
    axiosType: string,
    endpointPath: string,
    body: string | null,
    requireToken: boolean,
    signal?: AbortSignal
  ): Promise<AxiosResponse | AxiosError | null> {
    let axiosConfig: AxiosRequestConfig = {};
    const avoidRefreshPaths = [PathNames.REFRESH_TOKEN, PathNames.UPDATE_PASSWORD, PathNames.PROFILE];

    // Refresh token if required and set headers
    if (requireToken) {
      // Refresh auth token before making call
      if (!avoidRefreshPaths.includes(endpointPath) && daltonUser.token) {
        await refreshToken();
      }

      axiosConfig = {
        headers: {
          Authorization: `${daltonUser.token}`,
          'Content-Type': 'application/json',
        },
      };
    } else {
      axiosConfig = {
        headers: {
          'Content-Type': 'application/json',
        },
      };
    }
    return callApiEndpoint(
      axiosType,
      `${daltonUser.endpoint.replace(/\/$/, '')}/`,
      endpointPath,
      body,
      axiosConfig.headers,
      daltonUser.token,
      signal
    );
  }

  /**
   * @function saveLocalUserToken
   * @description locally saves user token to be used across the app and maintain user logged in
   *
   * @param userData user data to be saved
   * @returns void
   */
  const saveLocalUserToken = (key: string, token: string) => {
    const expiry = token.split(`^${tenant}.authn*`)[1].split('^')[0];
    saveData(key, {
      token,
      expiry,
    });
  };

  const getProfile = async (): Promise<SendSDKResponse<User>> => {
    const getProfileAxiosCall = (await callDaltonEndpoint(
      AxiosTypes.GET,
      PathNames.ACCOUNT,
      JSON.stringify({}),
      true
    )) as GetProfileAxiosResponse | AxiosError;

    // Logout user if profile returns 401.  This means the token is expired.
    if (getProfileAxiosCall && 'isAxiosError' in getProfileAxiosCall && getProfileAxiosCall?.response?.status === 401) {
      throw new Error('Profile token error');
    }

    return sendSDKResponse<User>(getProfileAxiosCall, ErrorMessages.ACCOUNT);
  };

  const getPreferences = async (): Promise<SendSDKResponse<GetPreferencesResponse>> => {
    const getPreferencesAxiosCall = (await callDaltonEndpoint(
      AxiosTypes.GET,
      PathNames.PREFERENCES,
      JSON.stringify({}),
      true
    )) as GetPreferencesAxiosResponse;

    return sendSDKResponse<GetPreferencesResponse>(getPreferencesAxiosCall, ErrorMessages.PREFERENCES);
  };

  const getBillingProfile = async (): Promise<SendSDKResponse<GetBillingProfileResponse>> => {
    const getBillingProfileAxiosCall = (await callDaltonEndpoint(
      AxiosTypes.POST,
      PathNames.PREAUTHORIZE,
      JSON.stringify({}),
      true
    )) as PreAuthAxiosResponse;

    return sendSDKResponse<GetBillingProfileResponse>(getBillingProfileAxiosCall, ErrorMessages.BILLING_PROFILE);
  };

  const mapCoupon = async (couponCode: string, signal?: AbortSignal): Promise<SendSDKResponse<MapCouponResponse>> => {
    // this will use callDalton when the coupon Mapper API is ready
    try {
      const url = `https://dhizvgvj1fcy7.cloudfront.net/default/devCouponService?couponCode=${couponCode}`;
      const mapCouponAxiosCall = await Axios.get<MapCouponAxiosResponse>(url, {
        headers: {
          'Content-Type': 'application/json',
        },
        signal,
      });
      return {
        success: true,
        data: mapCouponAxiosCall.data as MapCouponResponse,
      } as SendSDKResponse<MapCouponResponse>;
    } catch (error) {
      return {
        success: false,
        error: error as AxiosError,
      } as SendSDKResponse<MapCouponResponse>;
    }
  };

  const getSessionId = async (): Promise<SendSDKResponse<GetSessionIdResponse>> => {
    const getSessionIdAxiosCall = (await callDaltonEndpoint(
      AxiosTypes.GET,
      PathNames.SESSIONID_HASH,
      JSON.stringify({}),
      true
    )) as GetSessionIdAxiosResponse;

    return sendSDKResponse<GetSessionIdResponse>(getSessionIdAxiosCall, ErrorMessages.PREFERENCES);
  };

  const purchaseSubscription = async (
    purchaseSubscriptionParams: PurchaseSubscriptionParams
  ): Promise<SendSDKResponse<PurchaseSubscriptionResponse>> => {
    const purchaseSubscriptionAxiosCall = (await callDaltonEndpoint(
      AxiosTypes.POST,
      PathNames.PURSCHASE_SUBSCRIPTION,
      JSON.stringify(purchaseSubscriptionParams),
      true
    )) as PurchaseSubscriptionAxiosResponse;

    if (purchaseSubscriptionAxiosCall && purchaseSubscriptionAxiosCall.status === 220) {
      const billingProfile = await getBillingProfile();
      if (billingProfile) {
        return sendSDKResponse<PurchaseSubscriptionResponse>(purchaseSubscriptionAxiosCall, ErrorMessages.PREFERENCES);
      }
      return sendSDKResponse<PurchaseSubscriptionResponse>(purchaseSubscriptionAxiosCall, ErrorMessages.PREFERENCES);
    }
    return sendSDKResponse<PurchaseSubscriptionResponse>(purchaseSubscriptionAxiosCall, ErrorMessages.PREFERENCES);
  };

  /**
   * @function sendSDKResponse
   * @description updates auth token, check to see if response is successful and send appropriate response to the developer
   *
   * @param response axios response to return
   * @param errorMessage error message to return
   * @returns SDK response with success boolean and data or messsage
   */
  function sendSDKResponse<T>(response: AxiosResponse | null | AxiosError, errorMessage: string): SendSDKResponse<T> {
    const apiResponse = createApiResponse<T>(response);
    const authToken = get(apiResponse.data, 'authToken', '');
    if (apiResponse.success && authToken) {
      updateToken(authToken);
    }

    return apiResponse;
  }

  /**
   * @function createIdentity
   * @description internal function used by signup - uses data from signup form to obtain a User Identity Auth token from Dalton
   * @param createIdentityParams credentials of type CreateIdentityParams
   */
  const createIdentity = async (
    createIdentityParams: CreateIdentityParams
  ): Promise<SendSDKResponse<CreateIdentityResponse>> => {
    const data = {
      identityRequests: [
        {
          ...createIdentityParams,
        },
      ],
    };

    const createIdentityAxiosCall = (await callDaltonEndpoint(
      AxiosTypes.POST,
      PathNames.IDENTITY,
      JSON.stringify(data),
      false
    )) as CreateIdentityAxiosResponse;

    if (createIdentityAxiosCall && createIdentityAxiosCall.data) {
      updateToken(createIdentityAxiosCall.data);
    }

    return sendSDKResponse<CreateIdentityResponse>(createIdentityAxiosCall, ErrorMessages.SIGNUP);
  };

  /**
   * @function createProfile
   * @description internal function used by signup - uses data from signup form and auth token to create a User Profile in Dalton
   * @param createProfileParams credentials of type CreateProfileParams
   */
  const createProfile = async (
    createProfileParams: CreateProfileParams
  ): Promise<SendSDKResponse<UserProfileResponse>> => {
    const createProfileAxiosCall = (await callDaltonEndpoint(
      AxiosTypes.POST,
      PathNames.PROFILE,
      JSON.stringify(createProfileParams),
      true
    )) as CreateProfileAxiosResponse;

    if (createProfileAxiosCall && createProfileAxiosCall.status === 222) {
      saveLocalUserToken(DALTON_AUTH_TOKEN, createProfileAxiosCall.data.authToken);
    }
    return sendSDKResponse<UserProfileResponse>(createProfileAxiosCall, ErrorMessages.SIGNUP);
  };

  /**
   * @function refreshToken
   * @description internal function used in Dalton calls - sends request to dalton refresh auth token api
   * to update the auth token for upcoming dalton requests.
   * If this call fails, the function will return the token that was passed in.
   */
  const refreshToken = async (): Promise<SendSDKResponse<RefreshTokenResponse>> => {
    const refreshTokenAxiosCall = (await callDaltonEndpoint(
      AxiosTypes.POST,
      PathNames.REFRESH_TOKEN,
      JSON.stringify({
        apps,
      }),
      true
    )) as RefreshTokenAxiosResponse;

    if (refreshTokenAxiosCall && refreshTokenAxiosCall.data) {
      updateToken(refreshTokenAxiosCall.data);
      saveLocalUserToken(DALTON_AUTH_TOKEN, refreshTokenAxiosCall.data);
    }

    return sendSDKResponse<RefreshTokenResponse>(refreshTokenAxiosCall, ErrorMessages.REFRESH_TOKEN);
  };

  /**
   * @function signup
   * @description function to create a user in the Dalton system
   * @param signupParams signup credentials of type SignupParams
   */
  const signup = async (signupParams: SignupParams): Promise<SendSDKResponse<CreateIdentityResponse>> => {
    const identityParams = {
      principal: signupParams.principal,
      credential: signupParams.credential,
      identityType: signupParams.identityType,
    };
    return createIdentity(identityParams);
  };

  /**
   * @function login
   * @description function to log into the Dalton system
   * @param loginParams login credentials of type LoginParams
   */
  const login = async (loginParams: LoginParams): Promise<SendSDKResponse<UserProfileResponse>> => {
    const loginAxiosCall = (await callDaltonEndpoint(
      AxiosTypes.POST,
      PathNames.LOGIN,
      JSON.stringify(loginParams),
      true
    )) as LoginAxiosResponse;

    if (loginAxiosCall && loginAxiosCall.status === 220) {
      saveLocalUserToken(DALTON_AUTH_TOKEN, loginAxiosCall.data.authToken);
    }
    return sendSDKResponse<UserProfileResponse>(loginAxiosCall, ErrorMessages.SIGNIN);
  };

  /**
   * @function logout
   * @description function to log out of the Dalton system - uses user auth token in header
   */
  const logout = async (): Promise<SendSDKResponse<LogoutResponse>> => {
    const logoutAxiosCall = (await callDaltonEndpoint(
      AxiosTypes.POST,
      PathNames.LOGOUT,
      null,
      true
    )) as LogoutAxiosResponse;

    return sendSDKResponse<LogoutResponse>(logoutAxiosCall, ErrorMessages.LOGOUT);
  };

  /**
   * @function updateUser
   * @description update users profile within Dalton
   * @param updateUserParams - object based on data provided by user via Update Form (requires: First Name, Last Name, Password, Email)
   */
  const updateUser = async (updateUserParams: UpdateUserParams): Promise<SendSDKResponse<UpdateUserResponse>> => {
    const updateUserAxiosCall = (await callDaltonEndpoint(
      AxiosTypes.POST,
      PathNames.UPDATE_USER,
      JSON.stringify(updateUserParams),
      true
    )) as UpdateUserAxiosResponse;

    return sendSDKResponse<UpdateUserResponse>(updateUserAxiosCall, ErrorMessages.UPDATE_USER);
  };

  /**
   * @function updatePreferences
   * @description update users preferences within Dalton
   * @param updateUserPreferencesParams - array based on data provided by user via Update Form
   */
  const updateUserPreferences = async (
    updateUserPreferencesParams: UpdateUserPreferencesParams
  ): Promise<SendSDKResponse<UpdateUserPreferencesResponse>> => {
    const updateUserAxiosCall = (await callDaltonEndpoint(
      AxiosTypes.PUT,
      PathNames.PREFERENCES,
      JSON.stringify(updateUserPreferencesParams),
      true
    )) as UpdateUserPreferencesAxiosResponse;

    return sendSDKResponse<UpdateUserPreferencesResponse>(updateUserAxiosCall, ErrorMessages.UPDATE_USER);
  };

  /**
   * @function updateUserPreferencesItem
   * @description update one user preference item within Dalton
   * @param UpdateUserPreferencesItemParams - object based on data provided by user via Update Form
   */
  const updateUserPreferencesItem = async (
    updateUserPreferencesItemParams: UpdateUserPreferencesItemParams
  ): Promise<SendSDKResponse<UpdateUserPreferencesResponse>> => {
    const updateUserAxiosCall = (await callDaltonEndpoint(
      AxiosTypes.PUT,
      `${PathNames.PREFERENCES}/${updateUserPreferencesItemParams.collection}/${updateUserPreferencesItemParams.item}`,
      null,
      true
    )) as UpdateUserPreferencesAxiosResponse;

    return sendSDKResponse<UpdateUserPreferencesResponse>(updateUserAxiosCall, ErrorMessages.UPDATE_USER);
  };

  /**
   * @function updateContactNumber
   * @description update users preferences within Dalton
   * @param UpdateUserContactNumberParams - array based on data provided by user via Update Form
   */
  const updateUserContactNumber = async (
    updateUserContactNumberParams: UpdateUserContactNumberParams
  ): Promise<SendSDKResponse<UpdateUserContactNumberResponse>> => {
    const updateUserContactAxiosCall = (await callDaltonEndpoint(
      AxiosTypes.POST,
      PathNames.USER_CONTACT_NUMBER,
      JSON.stringify(updateUserContactNumberParams),
      true
    )) as UpdateUserContactNumberAxiosResponse;

    return sendSDKResponse<UpdateUserContactNumberResponse>(updateUserContactAxiosCall, ErrorMessages.UPDATE_USER);
  };

  /**
   * @function removesUserContactNumber
   * @description removes saved users phone number within Dalton
   * @param contactNumberId - string
   */
  const removeUserContactNumber = async (
    contactNumberId: string
  ): Promise<SendSDKResponse<UpdateUserContactNumberResponse>> => {
    const removeUserContactAxiosCall = (await callDaltonEndpoint(
      AxiosTypes.DELETE,
      `${PathNames.USER_CONTACT_NUMBER}/${contactNumberId}`,
      JSON.stringify({}),
      true
    )) as UpdateUserContactNumberAxiosResponse;

    return sendSDKResponse<UpdateUserContactNumberResponse>(removeUserContactAxiosCall, ErrorMessages.UPDATE_USER);
  };

  /**
   * @function forgotPassword
   * @description sends a trigger to call Braze email API and send password reset email to user
   * @param forgotPasswordParams object with user's email
   */
  const forgotPassword = async (
    forgotPasswordParams: ForgotPasswordParams
  ): Promise<SendSDKResponse<ForgotPasswordResponse>> => {
    const forgotAxiosCall = (await callDaltonEndpoint(
      AxiosTypes.POST,
      PathNames.FORGOT_PASSWORD,
      JSON.stringify(forgotPasswordParams),
      false
    )) as ForgotPasswordAxiosResponse;

    return sendSDKResponse<ForgotPasswordResponse>(forgotAxiosCall, ErrorMessages.FORGOT_PASSWORD);

    // TODO: on success call Braze API to send reset password email
  };

  /**
   * @function getAuthToken
   * @description trade reset token from email to auth token to update password
   * @param GetAuthTokenParams object with reset token
   */
  const getAuthToken = async (
    getAuthTokenParams: GetAuthTokenParams
  ): Promise<SendSDKResponse<GetAuthTokenResponse>> => {
    const forgotAxiosCall = (await callDaltonEndpoint(
      AxiosTypes.GET,
      `${PathNames.FORGOT_PASSWORD}?resetToken=${getAuthTokenParams.resetToken}`,
      null,
      false
    )) as ForgotPasswordAxiosResponse;

    return sendSDKResponse<GetAuthTokenResponse>(forgotAxiosCall, ErrorMessages.FORGOT_PASSWORD);
  };

  /**
   * @function updatePassword
   * @description updates user's password using the reset token generated from the forgot password call
   * @param updatePasswordParams object with user email and new password
   */
  const updatePassword = async (
    updatePasswordParams: UpdatePasswordParams
  ): Promise<SendSDKResponse<UpdatePasswordResponse>> => {
    updateToken(updatePasswordParams.authToken);
    const updatePasswordAxiosCall = (await callDaltonEndpoint(
      AxiosTypes.POST,
      `${PathNames.UPDATE_PASSWORD}`,
      JSON.stringify(updatePasswordParams),
      // null,
      true
    )) as UpdatePasswordAxiosResponse;
    // if returns success reset auth token to null to continue with login
    if (updatePasswordAxiosCall.status === 222) {
      updateToken('');
    }
    return sendSDKResponse<UpdatePasswordResponse>(updatePasswordAxiosCall, ErrorMessages.UPDATE_PASSWORD);
  };

  /**
   * @function getEntitlements
   * @description returns all user Entitlements stored in Dalton
   */
  const getEntitlements = async (): Promise<SendSDKResponse<EntitlementsResponse>> => {
    const getEntitlementsAxiosCall = (await callDaltonEndpoint(
      AxiosTypes.GET,
      PathNames.ENTITLEMENTS,
      null,
      true
    )) as EntitlementsAxiosResponse;

    return sendSDKResponse<EntitlementsResponse>(getEntitlementsAxiosCall, ErrorMessages.ENTITLEMENTS);
  };

  /**
   * @function getProducts
   * @description get all products in Dalton
   */
  const getProducts = async (): Promise<SendSDKResponse<ProductsResponse>> => {
    const getProductsAxiosCall = (await callDaltonEndpoint(
      AxiosTypes.GET,
      PathNames.PRODUCTS,
      null,
      false
    )) as ProductsAxiosResponse;

    return sendSDKResponse<ProductsResponse>(getProductsAxiosCall, ErrorMessages.PRODUCTS);
  };

  /**
   * @function getSubscriptions
   * @description get subscriptions tied to user from Dalton
   */
  const getSubscriptions = async (): Promise<SendSDKResponse<SubscriptionsResponse>> => {
    const getSubscriptionsAxiosCall = (await callDaltonEndpoint(
      AxiosTypes.GET,
      PathNames.SUBSCRIPTIONS,
      null,
      true
    )) as SubscriptionsAxiosResponse;

    return sendSDKResponse<SubscriptionsResponse>(getSubscriptionsAxiosCall, ErrorMessages.SUBSCRIPTIONS);
  };

  /**
   * @function attachUserToPayment
   * @description attach a payment method from Stripe to a specific user
   * @param cardId card ID resulting from entering payment information to Stripe
   * TODO: Dalton is returning a bad response currently, they will need to fix things before we can confirm this function is working
   */
  const attachPaymentToUser = async (cardId: string): Promise<SendSDKResponse<AttachPaymentResponse>> => {
    const data = {
      source: cardId,
    };

    const attachPaymentToUserAxiosCall = (await callDaltonEndpoint(
      AxiosTypes.POST,
      PathNames.ATTACH_USER_PAYMENT,
      JSON.stringify(data),
      true
    )) as AttachPaymentAxiosResponse;

    return sendSDKResponse<AttachPaymentResponse>(attachPaymentToUserAxiosCall, ErrorMessages.ATTACH_PAYMENT);
  };

  /**
   * @function purchasePreview
   * @description
   * @param
   */
  const purchasePreview = async (
    purchaseParams: PurchasePreviewParams,
    signal?: AbortSignal
  ): Promise<SendSDKResponse<BillingSubscriptionResponse>> => {
    const purchasePreviewAxiosCall = (await callDaltonEndpoint(
      AxiosTypes.POST,
      PathNames.PURCHASE_PREVIEW,
      JSON.stringify(purchaseParams),
      true,
      signal
    )) as PurchasePreviewAxiosResponse;

    return sendSDKResponse<BillingSubscriptionResponse>(purchasePreviewAxiosCall, ErrorMessages.PURCHASE_PREVIEW);
  };

  /**
   * @function redeemCoupon
   * @description
   * @param
   */
  const redeemCoupon = async ({
    subscriptionId,
    coupon,
  }: RedeemCouponParams): Promise<SendSDKResponse<RedeemCouponResponse>> => {
    const url = `${PathNames.REDEEM_COUPON}${subscriptionId}/redeem/${coupon}`;
    const redeemCouponAxiosCall = (await callDaltonEndpoint(
      AxiosTypes.POST,
      url,
      JSON.stringify({}),
      true
    )) as RedeemCouponAxiosResponse;

    return sendSDKResponse<RedeemCouponResponse>(redeemCouponAxiosCall, ErrorMessages.PURCHASE_PREVIEW);
  };

  /**
   * @function couponDetail
   * @description retreive coupon inforation
   * @param string coupon
   */
  const couponDetail = async (coupon: string, signal?: AbortSignal): Promise<SendSDKResponse<CouponDetailResponse>> => {
    const CouponDetailAxiosCall = (await callDaltonEndpoint(
      AxiosTypes.GET,
      `${PathNames.COUPON_DETAIL}/${coupon}`,
      null,
      true,
      signal
    )) as CouponDetailAxiosResponse;

    return sendSDKResponse<CouponDetailResponse>(CouponDetailAxiosCall, ErrorMessages.COUPON_DETAIL);
  };

  /**
   * @function confirmPurchase
   * @description
   * @param
   */
  const confirmPurchase = async (purchaseParams: PurchaseParams): Promise<SendSDKResponse<PurchaseResponse>> => {
    const { product, cardId } = purchaseParams;
    const data = {
      purchases: [
        {
          productId: product.gizmoProductId,
        },
      ],
      source: cardId,
    };

    const purchaseAxiosCall = (await callDaltonEndpoint(
      AxiosTypes.POST,
      PathNames.PURCHASE,
      JSON.stringify(data),
      true
    )) as PurchaseAxiosResponse;

    return sendSDKResponse<PurchaseResponse>(purchaseAxiosCall, ErrorMessages.PURCHASE);
  };

  /**
   * @function subscriptionPurchaseRequest
   * @description paypal subscription
   * @param
   */
  const subscriptionPurchaseRequest = async (
    purchaseParams: PayPalSubscriptionParams
  ): Promise<SendSDKResponse<RequestPaypalSubscriptionResponse>> => {
    const purchasePreviewAxiosCall = (await callDaltonEndpoint(
      AxiosTypes.POST,
      PathNames.PURSCHASE_SUBSCRIPTION_REQUEST,
      JSON.stringify(purchaseParams),
      true
    )) as PurchasePreviewAxiosResponse;

    return sendSDKResponse<RequestPaypalSubscriptionResponse>(purchasePreviewAxiosCall, ErrorMessages.PURCHASE_PREVIEW);
  };

  /**
   * @function finalizeSubscriptionPurchase
   * @description paypal subscription
   * @param
   */
  const finalizeSubscriptionPurchase = async (vid: string): Promise<SendSDKResponse<PurchaseSubscriptionResponse>> => {
    const purchasePreviewAxiosCall = (await callDaltonEndpoint(
      AxiosTypes.POST,
      `${PathNames.PURSCHASE_SUBSCRIPTION_FINALIZE}${vid}`,
      JSON.stringify({}),
      true
    )) as PurchasePreviewAxiosResponse;

    return sendSDKResponse<PurchaseSubscriptionResponse>(purchasePreviewAxiosCall, ErrorMessages.PURCHASE_PREVIEW);
  };

  /**
   * @function resendVerificationEmail
   * @description sends a trigger to call Braze email API and send verification reset email to user
   * @param resendVerificationEmailParams object with user's email
   */
  const resendVerificationEmail = async (
    resendVerificationEmailParams: ResendVerificationEmailParams
  ): Promise<SendSDKResponse<ResendVerificationEmailResponse>> => {
    const resendVerificationEmailCall = (await callDaltonEndpoint(
      AxiosTypes.POST,
      PathNames.REGENERATE_CONFIRMATION_CODE,
      JSON.stringify(resendVerificationEmailParams),
      true
    )) as ResendVerificationEmailAxiosResponse;

    return sendSDKResponse<ResendVerificationEmailResponse>(
      resendVerificationEmailCall,
      ErrorMessages.RESEND_VERIFICATION_EMAIL
    );
  };

  const getTransactions = async (): Promise<SendSDKResponse<GetTransactionsResponse>> => {
    const transactionsResponse = (await callDaltonEndpoint(
      AxiosTypes.GET,
      PathNames.TRANSACTION_CHARGES,
      null,
      true
    )) as GetTransactionsResponse;

    return sendSDKResponse<GetTransactionsResponse>(transactionsResponse, ErrorMessages.TRANSACTIONS_ERROR);
  };

  /**
   * @function confirmVerificationCode
   * @description sends a trigger to call User API sending a confirmation code as proof of ownership of the associated email address.
   * @param confirmVerificationCodeParams object with user's confirmation code
   */
  const confirmVerificationCode = async (
    confirmVerificationCodeParams: ConfirmVerificationCodeParams
  ): Promise<SendSDKResponse<ConfirmVerificationCodeResponse>> => {
    const confirmVerificationCodeCall = (await callDaltonEndpoint(
      AxiosTypes.POST,
      `${PathNames.CONFIRM_VERIFICATION_CODE}/${confirmVerificationCodeParams.confirmationCode}`,
      null,
      true
    )) as ConfirmVerificationCodeAxiosResponse;

    return sendSDKResponse<ConfirmVerificationCodeResponse>(
      confirmVerificationCodeCall,
      ErrorMessages.CONFIRM_VERIFICATION_EMAIL
    );
  };

  /**
   * @function cancelUserSubscription
   * @description cancel user's subscription immediately
   * @param subscriptionId string with subscription id
   */
  const cancelUserSubscription = async (
    subscriptionId: string,
    cancelingReason: string
  ): Promise<SendSDKResponse<CancelSubscriptionResponse>> => {
    const cancelSubscriptionCall = (await callDaltonEndpoint(
      AxiosTypes.POST,
      `${PathNames.CANCEL_SUBSCRIPTION}/${subscriptionId}/cancel/deferred`,
      JSON.stringify({
        reasonCode: cancelingReason,
      }),
      true
    )) as CancelSubscriptionAxiosResponse;

    return sendSDKResponse<CancelSubscriptionResponse>(cancelSubscriptionCall, ErrorMessages.CANCEL_SUBSCRIPTION);
  };

  // Functions to return (DaltonProps)
  return {
    // CORE
    login: async (loginParams: LoginParams): Promise<SendSDKResponse<UserProfileResponse>> => login(loginParams),
    logout: async (): Promise<SendSDKResponse<LogoutResponse>> => logout(),
    signup: async (signupParams: SignupParams): Promise<SendSDKResponse<CreateIdentityResponse>> =>
      signup(signupParams),
    createProfile: async (createProfileParams: CreateProfileParams): Promise<SendSDKResponse<UserProfileResponse>> =>
      createProfile(createProfileParams),
    updateUser: async (updateUserParams: UpdateUserParams): Promise<SendSDKResponse<UpdateUserResponse>> =>
      updateUser(updateUserParams),
    updateUserContactNumber: async (
      updateUserContactParams: UpdateUserContactNumberParams
    ): Promise<SendSDKResponse<UpdateUserContactNumberResponse>> => updateUserContactNumber(updateUserContactParams),
    removeUserContactNumber: async (
      contactNumberId: string
    ): Promise<SendSDKResponse<UpdateUserContactNumberResponse>> => removeUserContactNumber(contactNumberId),
    updateUserPreferences: async (
      updateUserPreferencesParams: UpdateUserPreferencesParams
    ): Promise<SendSDKResponse<UpdateUserPreferencesResponse>> => updateUserPreferences(updateUserPreferencesParams),
    updateUserPreferencesItem: async (
      updateUserPreferencesItemParams: UpdateUserPreferencesItemParams
    ): Promise<SendSDKResponse<UpdateUserPreferencesResponse>> =>
      updateUserPreferencesItem(updateUserPreferencesItemParams),
    forgotPassword: async (
      forgotPasswordParams: ForgotPasswordParams
    ): Promise<SendSDKResponse<ForgotPasswordResponse>> => forgotPassword(forgotPasswordParams),
    updatePassword: async (
      updatePasswordParams: UpdatePasswordParams
    ): Promise<SendSDKResponse<UpdatePasswordResponse>> => updatePassword(updatePasswordParams),
    refreshToken: async (): Promise<SendSDKResponse<RefreshTokenResponse>> => refreshToken(),

    getProfile: async (): Promise<SendSDKResponse<User>> => getProfile(),

    getPreferences: async (): Promise<SendSDKResponse<GetPreferencesResponse>> => getPreferences(),

    mapCoupon: async (couponCode: string, signal?: AbortSignal): Promise<SendSDKResponse<MapCouponResponse>> =>
      mapCoupon(couponCode, signal),

    getSessionId: async (): Promise<SendSDKResponse<GetSessionIdResponse>> => getSessionId(),

    getBillingProfile: async (): Promise<SendSDKResponse<GetBillingProfileResponse>> => getBillingProfile(),

    purchaseSubscription: async (
      purchaseParams: PurchaseSubscriptionParams
    ): Promise<SendSDKResponse<PurchaseSubscriptionResponse>> => purchaseSubscription(purchaseParams),

    subscriptionPurchaseRequest: async (
      purchaseParams: PayPalSubscriptionParams
    ): Promise<SendSDKResponse<RequestPaypalSubscriptionResponse>> => subscriptionPurchaseRequest(purchaseParams),

    finalizeSubscriptionPurchase: async (vid: string): Promise<SendSDKResponse<PurchaseSubscriptionResponse>> =>
      finalizeSubscriptionPurchase(vid),

    redeemCoupon: async (redeemCouponParams: RedeemCouponParams): Promise<SendSDKResponse<RedeemCouponResponse>> =>
      redeemCoupon(redeemCouponParams),

    couponDetail: async (coupon: string, signal?: AbortSignal): Promise<SendSDKResponse<CouponDetailResponse>> =>
      couponDetail(coupon, signal),

    getAuthToken: async (getAuthTokenParams: GetAuthTokenParams): Promise<SendSDKResponse<GetAuthTokenResponse>> =>
      getAuthToken(getAuthTokenParams),
    resendVerificationEmail: async (
      resendVerificationEmailParams: ResendVerificationEmailParams
    ): Promise<SendSDKResponse<ResendVerificationEmailResponse>> =>
      resendVerificationEmail(resendVerificationEmailParams),
    confirmVerificationCode: async (
      confirmVerificationCodeParams: ConfirmVerificationCodeParams
    ): Promise<SendSDKResponse<ConfirmVerificationCodeResponse>> =>
      confirmVerificationCode(confirmVerificationCodeParams),

    cancelUserSubscription: async (
      subscriptionId: string,
      cancelingReason: string
    ): Promise<SendSDKResponse<CancelSubscriptionResponse>> => cancelUserSubscription(subscriptionId, cancelingReason),

    updateToken: (token: string) => updateToken(token),

    // GIZMO
    getEntitlements: async (): Promise<SendSDKResponse<EntitlementsResponse>> => getEntitlements(),
    getProducts: async (): Promise<SendSDKResponse<ProductsResponse>> => getProducts(),
    getSubscriptions: async (): Promise<SendSDKResponse<SubscriptionsResponse>> => getSubscriptions(),
    attachPaymentToUser: async (cardId: string): Promise<SendSDKResponse<AttachPaymentResponse>> =>
      attachPaymentToUser(cardId),
    confirmPurchase: async (purchaseParams: PurchaseParams): Promise<SendSDKResponse<PurchaseResponse>> =>
      confirmPurchase(purchaseParams),
    purchasePreview: async (
      purchaseParams: PurchasePreviewParams,
      signal?: AbortSignal
    ): Promise<SendSDKResponse<BillingSubscriptionResponse>> => purchasePreview(purchaseParams, signal),
    getTransactions: async (): Promise<SendSDKResponse<GetTransactionsResponse>> => getTransactions(),
  };
};

export default useDalton;
