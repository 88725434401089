import { useEffect, useRef, useState } from 'react';
import { AppState, AppStateStatus } from 'react-native';
import { PlayerErrorResult, PlayerInstance, PlayerMediaStateChangedResult } from '@top/player-block-react-native';
import { errorMsgStore, playerStateStore, videoStates } from '@warnermmedia/gsp-core/brands/estadio/data-access';

import useHeartBeat, { HeartBeatParams } from '../useHeartBeat';
import {
  useIsMountedRef,
  useMparticleCustomEventObject,
  videoPlayerError,
} from '@warnermmedia/gsp-core/brands/estadio/feature';
import {
  MParticleCustomEventTypes,
  mParticleEventProcessor,
  playerMediaStateChangedEvents,
  recordVideoMparticleError,
} from '@warnermmedia/gsp-core/sdk/data-access';
import { getDiff } from '@warnermmedia/gsp-core/sdk/ui';

export const useMobilePlayer = (player: PlayerInstance | null, playerStartTime: string) => {
  const [contentState, setContentState] = useState(null);
  const [loading, setLoading] = useState<boolean>(false);
  const appState = useRef(AppState.currentState);
  const [videoError, setVideoError] = useState('');
  const [mediaState, setMediaState] = useState<PlayerMediaStateChangedResult>();
  const [refreshToken, setRefreshToken] = useState<boolean>(false);
  const [appStateVisible, setAppStateVisible] = useState(appState.current);
  const [chromeClicked, setchromeClicked] = useState<boolean>(false);
  const [videoPlaying, setVideoPlaying] = useState<boolean>(false);
  const [castStreaming, setCastStreaming] = useState<boolean | undefined>(false);

  const [heartBeatParams, setHeartbeatParams] = useState<HeartBeatParams>({
    mediaId: '',
    appId: '',
    tokenUrl: '',
    heartbeatToken: '',
    assetId: '',
  });

  const mParticleEventData = useMparticleCustomEventObject();

  const { mediaId, appId, assetId, tokenUrl, heartbeatToken } = heartBeatParams;

  const { heartBeatError, setPoll, setHeartBeatError } = useHeartBeat({
    mediaId,
    heartbeatToken,
    appId,
    assetId,
    tokenUrl,
  });
  const isMountedRef = useIsMountedRef();

  useEffect(() => {
    AppState.addEventListener('change', onAppStateChange);

    return () => {
      AppState.removeEventListener('change', onAppStateChange);
    };
  }, []);

  useEffect(() => {
    if (player) {
      if (appStateVisible === ('background' || 'inactive')) {
        setPoll(false);
        player.pause();
      } else if (appStateVisible === 'active' && !castStreaming) {
        player.replay();
      }
    }
  }, [appStateVisible]);

  useEffect(() => {
    if (heartBeatError) {
      const messageDisplay = videoPlayerError(heartBeatError);

      errorMsgStore({
        type: 'VIDEO_ERROR',
        message: messageDisplay,
      });

      setVideoError(heartBeatError);

      setPoll(false);
      setHeartBeatError('');
      player?.stop();
    }
  }, [heartBeatError]);

  useEffect(() => {
    if (player && isMountedRef.current) {
      mParticleEventProcessor.pushMParticleEvent(MParticleCustomEventTypes.PlayerLoadEvent, {
        ...mParticleEventData,
        ...{ player_timer: getDiff(playerStartTime) },
      });
      addPlayerEventListeners();
    }
  }, [player, isMountedRef]);

  useEffect(() => {
    recordVideoMparticleError(videoError, mParticleEventData);
  }, [videoError, mParticleEventData]);

  const onAppStateChange = (nextAppState: AppStateStatus) => {
    appState.current = nextAppState;
    setAppStateVisible(appState.current);
  };

  const addPlayerEventListeners = () => {
    player?.events.mediaStateChanged.listen((result) => {
      playerMediaStateChangedEvents(result, mParticleEventData, playerStartTime);
      setMediaState(result);
      playerStateStore({
        type: videoStates.MEDIA_STATES,
        message: '',
      });
    });

    player?.events.playerReady.listen((result) => {
      playerStateStore({
        type: videoStates.PLAYER_READY,
        message: '',
      });
    });

    player?.events.mediaPaused.listen((result) => {
      playerStateStore({
        type: videoStates.MEDIA_PAUSED,
        message: '',
      });
    });

    player?.events.mediaError.listen((result) => {
      const errorMsg = result?.error?.toJSON()?.metadata?.subErrorMessage;
      const messageDisplay = videoPlayerError(errorMsg);

      errorMsgStore({
        type: 'VIDEO_ERROR',
        message: messageDisplay,
      });

      setVideoError(errorMsg);
    });

    player?.events.playerError.listen((result: PlayerErrorResult) => {
      const errorMsg = result?.error?.toJSON()?.metadata?.subErrorMessage;

      const messageDisplay = videoPlayerError(errorMsg);
      errorMsgStore({
        type: 'VIDEO_ERROR',
        message: messageDisplay,
      });

      setVideoError(errorMsg);
    });

    player?.events.contentError.listen((evt) => {
      const errorMsg = evt.error.toJSON()?.metadata?.subErrorMessage;
      const errorDisplay = evt.error.toJSON()?.message;

      if (errorMsg === 'Token expired') {
        setRefreshToken(true);
      } else if (errorDisplay === 'Connection with content lost, playback stopped and reset') {
        setRefreshToken(true);
      } else {
        playerStateStore({
          type: videoStates.CONTENT_ERROR,
          message: errorMsg,
        });

        setVideoError(errorMsg);
      }
    });

    player?.events.contentEnded.listen((evt) => {
      setPoll(false);
      playerStateStore({
        type: videoStates.CONTENT_ENDED,
        message: '',
      });
    });

    player?.events.contentStateChanged.listen((result) => {
      const contentState = result.currentState.toString();
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      setContentState(contentState);
    });

    player?.events.viewStateChanged.listen((result) => {
      const currState = result.currentState;
      playerStateStore({
        type: videoStates.VIEW_STATE_CHANGED,
        message: currState,
      });
    });

    player?.events.mediaStateChanged.listen((result) => {
      const isPlaying = result?.currentState.toLowerCase() === 'playing';
      setVideoPlaying(isPlaying);
    });

    player?.events.messageFromUI.listen((result) => {
      if (result?.name === 'chromeCastRequested') {
        setchromeClicked((chromeClicked) => !chromeClicked);
      }
    });

    player?.events.tokenResponseReceived.listen((result) => {
      let assetId = '';
      if (player.playlistItem?.entry.files && player.playlistItem?.entry.files.length > 0) {
        player.playlistItem?.entry.files.forEach((file) => {
          if (file.contentProtection === 'widevine') {
            assetId = file.assetId ?? '';
          }
        });
      }
      setHeartbeatParams({
        ...heartBeatParams,
        mediaId: player.playlistItem?.entry.id ?? '',
        tokenUrl: result.requestUrl ?? '',
        assetId,
        appId: player.config.metadata.appId ?? '',
        heartbeatToken: result?.turnerToken?.token ?? '',
      });
      setPoll(!!result?.turnerToken?.token);
    });
  };

  useEffect(() => {
    return (): void => {
      if (player) {
        setPoll(false);
        player?.events?.unlistenAll();
        player.stop();
        player.destroy();
      }
    };
  }, []);

  return {
    loading,
    videoError,
    setVideoError,
    contentState,
    mediaState,
    addPlayerEventListeners,
    appStateVisible,
    chromeClicked,
    setchromeClicked,
    videoPlaying,
    refreshToken,
    setRefreshToken,
    castStreaming,
    setCastStreaming,
  };
};
