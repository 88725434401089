import React, { useState, useEffect, useRef } from 'react';
import { View, TouchableOpacity, StyleProp, ViewStyle, GestureResponderEvent } from 'react-native';
import { useLinkProps } from '@react-navigation/native';
import { NavigationAction } from 'react-navigation';
import { ArcEvent, Button } from '@warnermedia/gsp-core/sdk/arcade-machine';
import { useGetDevice } from '../../hooks';

interface LinkButtonProps {
  to: string;
  action: NavigationAction | undefined;
  hoveredStyle: StyleProp<ViewStyle>;
  children: React.ReactNode;
  onClick?: (e: Event | GestureResponderEvent) => void | boolean;
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const NullComponent = (props: any) => {
  return props.children;
};

const LinkButton = ({ to, action, hoveredStyle, children, onClick, ...rest }: LinkButtonProps) => {
  const { isWeb, isPwa } = useGetDevice();

  const { onPress, ...props } = useLinkProps({ to, action });
  const [Comp, setComp] = useState<React.ReactNode>(() => NullComponent);
  const _isMounted = useRef(true);

  const getArcade = async () => {
    import('@warnermedia/gsp-core/sdk/arcade-machine').then(({ Scope }) => {
      if (_isMounted.current) setComp(() => Scope);
    });
  };

  useEffect(() => {
    if (isPwa) {
      getArcade();
    }
    return () => {
      // ComponentWillUnmount in Class Component
      _isMounted.current = false;
    };
  }, [isPwa]);

  const [isHovered, setIsHovered] = React.useState(false);

  const handlePress = (params: Event | GestureResponderEvent) => {
    if (!isPwa) onPress(params as GestureResponderEvent);
  };

  const handleOutgoing = (arcEvt: ArcEvent): void => {
    if (
      // keep the drawer engaged if you're trying to go left or up from home or down from settings
      arcEvt.event === Button.Left ||
      (arcEvt.event === Button.Up && (to.includes('home') || to.includes('register'))) ||
      (arcEvt.event === Button.Down && to.includes('login'))
    ) {
      arcEvt.stopPropagation();
      arcEvt.preventDefault();
    }
  };

  if (isPwa) {
    // It's important to use a `View` or `Text` on web instead of `TouchableX`
    // Otherwise React Native for Web omits the `onClick` prop that's passed
    // You'll also need to pass `onPress` as `onClick` to the `View`
    // You can add hover effects using `onMouseEnter` and `onMouseLeave`
    return (
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      <Comp onOutgoing={handleOutgoing}>
        <View
          nativeID={to.split('/')[1]}
          id={to.split('/')[1]}
          tabIndex={0}
          onClick={handlePress}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          style={[{ transitionDuration: '150ms' }, isHovered && isWeb ? hoveredStyle : {}]}
          {...props}
          {...rest}
        >
          {children}
        </View>
      </Comp>
    );
  }

  return (
    <TouchableOpacity onPress={handlePress} {...props} {...rest}>
      {children}
    </TouchableOpacity>
  );
};

export default LinkButton;
