import { StyleProp, ViewStyle, StyleSheet, TextStyle, ImageStyle } from 'react-native';
import merge from 'lodash/merge';
import { Breakpoints } from '@warnermmedia/gsp-core/sdk/ui';

interface Styles {
  tickerWrapper: ViewStyle;
  tickerContainer: ViewStyle;
  tickerMain: ViewStyle;
  tickerTeam: TextStyle;
  tickerText: TextStyle;
  tickerTextScore: ViewStyle;
  tickerTextPenalty: TextStyle;
  tickerStatus: ViewStyle;
  status: TextStyle;
  playIcon: ViewStyle;
  tourneyImage: ImageStyle;
  playLabelStyle: ViewStyle;
  refreshLabelStyle: ViewStyle;
  ctaText: TextStyle;
  labelContentStyle: ViewStyle;
}

export const getStyles = (breakpoints: Breakpoints, isTv: boolean, colors: ReactNativePaper.ThemeColors) => {
  const base: StyleProp<Styles> = {
    tickerWrapper: {
      marginBottom: 5,
      marginTop: 10,
      borderRadius: 10,
    },
    tickerContainer: {
      width: 155,
      height: 55,
      borderStyle: 'solid',
      borderColor: colors.stroke.onDark.outline02,
      borderRightWidth: 1,
      justifyContent: 'space-between',
      marginVertical: 10,
    },
    tickerMain: {
      alignItems: 'flex-start',
      flexDirection: 'row',
      justifyContent: 'space-evenly',
      height: 31,
    },
    tickerTeam: {
      color: colors.keyboardFocus.light,
      fontFamily: 'Rubik-Regular',
      fontSize: 14,
    },
    tickerText: {
      color: colors.keyboardFocus.light,
      fontFamily: 'Rubik-Medium',
      fontSize: 14,
    },
    tickerTextScore: {
      alignItems: 'center',
    },
    tickerTextPenalty: {
      color: colors.keyboardFocus.light,
      fontFamily: 'Rubik-Regular',
      fontSize: 10,
    },
    tickerStatus: {
      flex: 1,
      alignSelf: 'center',
      justifyContent: 'center',
      alignItems: 'center',
    },
    status: {
      color: colors.keyboardFocus.light,
      display: 'flex',
      justifyContent: 'center',
      alignSelf: 'center',
      textTransform: 'uppercase',
      fontSize: 10,
      fontFamily: 'Rubik-Regular',
      height: 20,
      lineHeight: 20,
    },
    playIcon: {
      position: 'absolute',
      width: 20,
      height: 20,
      zIndex: 100,
      alignSelf: 'center',
      justifyContent: 'center',
      alignItems: 'center',
      top: '40%',
      elevation: 10000,
    },
    tourneyImage: {
      width: 150,
      height: 150,
      alignSelf: 'center',
      top: '8%',
    },
    playLabelStyle: {
      backgroundColor: colors.fill.action.accent01,
      borderRadius: 2,
    },
    refreshLabelStyle: {
      backgroundColor: colors.fill.action.accent04,
      borderRadius: 2,
    },
    ctaText: {
      fontSize: 10,
      fontFamily: 'Oswald-Regular',
      textTransform: 'uppercase',
      lineHeight: 14,
    },
    labelContentStyle: {
      marginLeft: 15,
      marginRight: 10,
    },
  };

  const mediaQueries = isTv
    ? ({
        tickerContainer: {
          width: 264,
          height: 118,
          alignItems: 'center',
        },
        tickerMain: {
          alignItems: 'center',
          marginVertical: 15,
          justifyContent: 'space-evenly',
        },
        tickerStatus: {
          marginVertical: 15,
        },
        tickerText: {
          fontSize: 30,
        },
        tickerTeam: {
          fontSize: 20,
        },
        status: {
          fontSize: 20,
          marginHorizontal: 10,
        },
        ctaText: {
          fontSize: 18,
          fontFamily: 'Oswald-Medium',
          textTransform: 'uppercase',
          lineHeight: 24,
        },
        labelContentStyle: {
          marginLeft: 30,
          marginRight: 15,
        },
        tickerTextScore: {
          marginLeft: 20,
        },
      } as StyleProp<Styles>)
    : {};

  const mergedStyles = merge(base, mediaQueries);

  return StyleSheet.create(mergedStyles);
};
