import React, { useState } from 'react';
import { StyleProp, TextStyle, TouchableHighlight, View, ViewStyle, Text, GestureResponderEvent } from 'react-native';
import { Avatar, useTheme } from 'react-native-paper';
import LinkButton from './LinkButton';
import { IconSource } from 'react-native-paper/lib/typescript/components/Icon';
import styles from './nav-item.styles';

/** Extend DrawerItem
 *  add active, expanded, iconSize, labelStyle
 *  remove roundness
 **/

export type NavItemProps = React.ComponentPropsWithRef<typeof View> & {
  /**
   * The label text of the item.
   */
  label?: string;
  /**
   * Icon to display for the `DrawerItem`.
   */
  icon?: IconSource;
  /**
   * Function to execute on press.
   */
  onPress?: (e: Event | GestureResponderEvent) => void | boolean;
  /**
   * Accessibility label for the button. This is read by the screen reader when the user taps the button.
   */
  accessibilityLabel?: string;
  style?: StyleProp<ViewStyle>;
  /**
   * Whether to highlight the drawer item as active.
   */
  active?: boolean;
  /**
   * Whether to expand the drawer item to show label.
   */
  expanded?: boolean;
  /**
   * Size of icon (because Avatar.Icon requires a size).
   */
  iconSize?: number;
  /**
   * StyleProp to customize the label
   */
  labelStyle?: StyleProp<TextStyle>;
  /**
   * Route name to navigate to
   */
  navigateTo?: string;
};

export function NavItem({
  icon,
  iconSize,
  label,
  active,
  expanded,
  style,
  labelStyle,
  onPress,
  accessibilityLabel,
  navigateTo,
  ...rest
}: NavItemProps) {
  const { colors, fonts } = useTheme();
  const contentColor = active ? colors.navItemActiveColor : colors.navbarColor || colors.primary;
  const font = fonts.medium;

  return (
    <LinkButton
      to={navigateTo ?? ''}
      action={undefined}
      onClick={onPress}
      hoveredStyle={{ backgroundColor: colors.navItemHoverBackground }}
    >
      <View {...rest} style={[styles.container]} nativeID="nav_item">
        <TouchableHighlight
          delayPressIn={0}
          onPress={onPress}
          style={style}
          accessibilityRole="button"
          accessibilityState={{ selected: active }}
          accessibilityLabel={accessibilityLabel}
        >
          <View style={styles.wrapper}>
            {icon && <Avatar.Icon icon={icon} style={{ backgroundColor: 'transparent' }} size={iconSize} />}
            <Text
              nativeID="navItemLabel"
              selectable={false}
              numberOfLines={1}
              style={[
                styles.label,
                {
                  color: contentColor,
                  ...font,
                },
                labelStyle,
                {
                  display: expanded ? 'flex' : 'none',
                },
              ]}
            >
              {label}
            </Text>
          </View>
        </TouchableHighlight>
      </View>
    </LinkButton>
  );
}

NavItem.displayName = 'Nav.Item';

export default NavItem;
