import { ImageStyle, StyleProp, StyleSheet, TextStyle, ViewStyle } from 'react-native';
import { Breakpoints } from '@warnermmedia/gsp-core/sdk/ui';
import merge from 'lodash/merge';

interface Styles {
  retentionImage: ImageStyle;
  activateBtn: ViewStyle;
  activateBtnText: TextStyle;
  cancelBtn: ViewStyle;
  cancelBtnText: TextStyle;
  returnBtn: ViewStyle;
  returnBtnText: TextStyle;
}

export const getStyles = (breakpoints: Breakpoints, colors: ReactNativePaper.ThemeColors) => {
  const base: StyleProp<Styles> = {
    retentionImage: {
      aspectRatio: 1,
      maxWidth: '100%',
      maxHeight: '100%',
      marginVertical: 12,
    },
    activateBtn: {
      maxWidth: 303,
      backgroundColor: '#000b5a50',
      borderWidth: 2,
      borderColor: colors.tenantBackground.light.surfaceBase,
      marginVertical: 12,
    },
    activateBtnText: {
      color: colors.keyboardFocus.light,
      fontFamily: 'Oswald-Medium',
      fontSize: 20,
    },
    cancelBtn: {
      maxWidth: 302,
      marginVertical: 12,
    },
    cancelBtnText: {
      color: colors.keyboardFocus.light,
      fontFamily: 'Oswald-Medium',
      fontSize: 20,
    },
    returnBtn: {
      maxWidth: 150,
      backgroundColor: 'transparent',
      paddingHorizontal: 0,
      justifyContent: 'flex-start',
    },
    returnBtnText: {
      color: colors.keyboardFocus.light,
      fontFamily: 'Oswald-Regular',
      textDecorationStyle: 'solid',
      textDecorationLine: 'underline',
      textDecorationColor: colors.keyboardFocus.light,
      fontSize: 14,
      textAlign: 'left',
    },
  };

  const mediaQueries =
    breakpoints.currentBreakpoints.isTiny ||
    breakpoints.currentBreakpoints.isTnySm ||
    breakpoints.currentBreakpoints.isSmMed
      ? ({
          retentionImage: {
            maxHeight: 181,
          },
          activateBtn: {
            maxWidth: '100%',
          },
          cancelBtn: {
            maxWidth: '100%',
          },
        } as StyleProp<Styles>)
      : breakpoints.currentBreakpoints.isMedLg
      ? ({
          retentionImage: {
            maxHeight: 347,
          },
          activateBtn: {
            maxWidth: 335,
          },
          cancelBtn: {
            maxWidth: 335,
          },
        } as StyleProp<Styles>)
      : breakpoints.currentBreakpoints.isLgXl ||
        breakpoints.currentBreakpoints.isXlXxl ||
        breakpoints.currentBreakpoints.isXxl
      ? ({
          retentionImage: {
            maxWidth: 619,
            maxHeight: 347,
          },
        } as StyleProp<Styles>)
      : {};

  const mergedStyles = merge(base, mediaQueries);

  return StyleSheet.create(mergedStyles);
};
