import React from 'react';
import { StyleProp, Text, View, ViewStyle } from 'react-native';
import { useReactiveVar } from '@apollo/client';
import { breakpointsStateStore, ModalType } from '@warnermmedia/gsp-core/brands/estadio/data-access';
import { getStyles } from './styles';
import { TermsCheckbox } from '../../termsCheckbox';
import { CustomButton, TextContent, useGetDevice } from '@warnermmedia/gsp-core/sdk/ui';
import { useTheme } from 'react-native-paper';
import ModalWrapper from '../modalWrapper';

interface CouponRedemptionProps {
  handleSubmit(): void;
  setAcceptTerms(val: boolean): void;
  onChangeVisible(): void;
  handleSetTermsError: (field: string, val: boolean) => void;
  visible: boolean;
  modalBackgroundStyle?: StyleProp<ViewStyle>;
  modalStyle?: StyleProp<ViewStyle>;
  title: string;
  description: string;
  buttonLabel: string;
  termsText: string;
  tooltipText: string;
  termsAccepted: boolean;
  termsError: boolean;
  isLoading: boolean;
}

export const CouponRedemptionModal = ({
  visible,
  modalStyle,
  title,
  description,
  termsText,
  tooltipText,
  buttonLabel,
  handleSubmit,
  onChangeVisible,
  setAcceptTerms,
  termsAccepted,
  termsError,
  isLoading,
  handleSetTermsError,
}: CouponRedemptionProps) => {
  const breakpoints = useReactiveVar(breakpointsStateStore);
  const { isTv } = useGetDevice();
  const { colors } = useTheme();
  const styles = getStyles(breakpoints, isTv, colors);
  const isMobileScreen = breakpoints.breakpointHelpers.isMobileScreen;

  return (
    <ModalWrapper
      visible={visible}
      modalType={visible ? ModalType.CouponRedemptionModal : null}
      onClose={onChangeVisible}
      showCloseButton={true}
      modalLayoutContainerStyle={isMobileScreen ? styles.centeredViewMobile : styles.fullScreenContainer}
      modalMainContainerStyle={styles.container}
      modalCloseButtonStyle={styles.closeBtn}
    >
      <>
        <Text style={styles.title}>{title}</Text>
        <View style={[styles.containerInner, modalStyle]}>
          <Text style={styles.description}>{description}</Text>
          <View style={[styles.termsContainer]}>
            <TermsCheckbox
              acceptTerms={termsAccepted}
              setAcceptTerms={(val) => setAcceptTerms(val)}
              hasError={termsError}
              handleSetFormError={handleSetTermsError}
              overrideContainerStyle={styles.overrideTermsContainer}
              overrideWrapperStyle={styles.overrideTermsWrapper}
              termsText={termsText}
              tooltipText={tooltipText}
              disabled={isLoading}
              focusable={!isLoading}
            />
          </View>
        </View>
        <View style={styles.btnContainer}>
          <CustomButton disabled={isLoading} onPress={handleSubmit} mode="contained" btnStyle={styles.btn}>
            <TextContent style={styles.btnLabel}>{buttonLabel}</TextContent>
          </CustomButton>
        </View>
      </>
    </ModalWrapper>
  );
};

export default CouponRedemptionModal;
