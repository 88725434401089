import merge from 'lodash/merge';
import { ImageStyle, Platform, StyleProp, StyleSheet, TextStyle, ViewStyle } from 'react-native';
import { Breakpoints } from '@warnermmedia/gsp-core/sdk/ui';
import { isCssSupported } from '@warnermmedia/gsp-core/brands/estadio/feature';

interface Styles {
  imageBackground: ViewStyle;
  imageBgStyle: ImageStyle;
  slideTop: ViewStyle;
  matchImages: ViewStyle;
  matchScore: TextStyle;
  matchPenalty: TextStyle;
  slideTopTitle: ViewStyle;
  clipDurationText: TextStyle;
  clipDurationView: ViewStyle;
  slideBottom: ViewStyle;
  slideBottomActive: ViewStyle;
  matchup: TextStyle;
  round: TextStyle;
  arrow: TextStyle;
  matchVs: TextStyle;
  playIcon: ViewStyle;
  textContainer: ViewStyle;
}

export const getStyles = (breakpoints: Breakpoints, isTv: boolean, colors: ReactNativePaper.ThemeColors) => {
  const isSpaceEvenlySupported = Platform.OS === 'web' ? isCssSupported('justify-content', 'space-evenly') : true;
  const base: StyleProp<Styles> = {
    imageBackground: {
      height: 152,
      width: 270,
    },
    imageBgStyle: {
      borderRadius: 5,
    },
    slideTop: {
      width: '100%',
      flexDirection: 'row',
      justifyContent: 'space-evenly',
      alignItems: 'center',
      paddingVertical: 18,
    },
    matchImages: {
      width: '100%',
      flexDirection: 'row',
      justifyContent: isSpaceEvenlySupported ? 'space-evenly' : 'space-around',
      alignItems: 'center',
    },
    matchScore: {
      fontSize: 32,
      fontFamily: 'Oswald-SemiBold',
      color: colors.keyboardFocus.light,
      textAlign: 'center',
    },
    matchVs: {
      fontSize: 32,
      fontFamily: 'Oswald-SemiBold',
      color: colors.keyboardFocus.light,
      opacity: 0.5,
      textAlign: 'center',
    },
    matchPenalty: {
      fontSize: 18,
      fontFamily: 'Oswald-Regular',
      color: colors.keyboardFocus.light,
      textAlign: 'center',
    },
    slideTopTitle: {
      elevation: 10000,
      flexDirection: 'row',
      justifyContent: 'flex-end',
      paddingHorizontal: 8,
      position: 'absolute',
      top: 8,
      width: '100%',
    },
    clipDurationText: {
      fontFamily: 'Rubik-Regular',
      color: colors.keyboardFocus.light,
      fontSize: 11,
    },
    clipDurationView: {
      alignSelf: 'flex-end',
      backgroundColor: colors.foreground.onLight.text01,
      height: 20,
      justifyContent: 'center',
      paddingHorizontal: 8,
    },
    slideBottom: {
      paddingTop: 15,
      marginLeft: 8,
      width: 253,
    },
    slideBottomActive: {},
    matchup: {
      color: colors.keyboardFocus.light,
      fontFamily: 'Rubik-Medium',
      fontSize: 16,
      fontWeight: '500',
      lineHeight: 20,
    },
    textContainer: {
      marginTop: 5,
    },
    round: {
      color: colors.keyboardFocus.light,
      fontFamily: 'Rubik-Regular',
      fontSize: 14,
      fontWeight: 'normal',
      lineHeight: 20,
    },
    arrow: {
      fontSize: 70,
      color: colors.keyboardFocus.light,
      zIndex: 102,
    },
    playIcon: {
      alignItems: 'center',
      alignSelf: 'center',
      elevation: 10000,
      height: '100%',
      justifyContent: 'center',
      position: 'absolute',
      width: '100%',
      zIndex: 100,
      shadowColor: colors.foreground.onLight.text01,
      shadowOffset: { width: 0, height: 2 },
      shadowOpacity: 0.5,
      shadowRadius: 2,
    },
  };

  const mediaQueries = isTv
    ? ({
        imageBackground: {
          height: 228,
          width: 405,
        },
        matchup: {
          color: colors.keyboardFocus.light,
          fontFamily: 'Rubik-Medium',
          fontSize: 20,
          fontWeight: '500',
          lineHeight: 28,
        },
        round: {
          color: colors.keyboardFocus.light,
          fontFamily: 'Rubik-Regular',
          fontSize: 16,
          fontWeight: 'normal',
          lineHeight: 24,
        },
        matchScore: {
          fontSize: 48,
          letterSpacing: -3,
        },
        matchVs: {
          fontSize: 48,
        },
        matchPenalty: {
          fontSize: 26,
        },
        slideTop: {
          paddingVertical: 36,
        },
        slideBottom: {
          width: 400,
          paddingRight: 20,
        },
      } as StyleProp<Styles>)
    : breakpoints.currentBreakpoints.isMedLg ||
      breakpoints.currentBreakpoints.isLgXl ||
      breakpoints.windowWidth === breakpoints.breakpointSizes.lg
    ? ({
        imageBackground: {
          height: 124,
          width: 218,
        },
        matchVs: {
          fontSize: 26,
        },
        slideBottom: {
          width: 202,
          paddingTop: 8,
        },
        matchScore: {
          fontSize: 26,
        },
      } as StyleProp<Styles>)
    : breakpoints.currentBreakpoints.isXlXxl || breakpoints.currentBreakpoints.isXxl
    ? ({
        imageBackground: {
          height: 135,
          width: 239,
        },
        matchup: {
          color: colors.keyboardFocus.light,
          fontFamily: 'Rubik-Medium',
          fontSize: 16,
          fontWeight: '500',
          lineHeight: 20,
        },
        round: {
          color: colors.keyboardFocus.light,
          fontFamily: 'Rubik-Regular',
          fontSize: 14,
          fontWeight: 'normal',
          lineHeight: 20,
        },
        matchVs: {
          fontSize: 28,
        },
        matchScore: {
          fontSize: 28,
        },
        slideBottom: {
          width: 223,
          paddingTop: 8,
        },
      } as StyleProp<Styles>)
    : {};

  const mergedStyles = merge(base, mediaQueries);

  return StyleSheet.create(mergedStyles);
};
