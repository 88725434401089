import React, { ReactNode } from 'react';
import { IconButton } from 'react-native-paper';
import { Pressable, View } from 'react-native';
import { getStyles } from './footer.styles';
import { getFooterImageLinks } from '@warnermmedia/gsp-core/brands/estadio/feature';
import { Breakpoints } from '@warnermmedia/gsp-core/sdk/ui';

export interface FooterImagesLinksProps {
  breakpoints: Breakpoints;
  colors: ReactNativePaper.ThemeColors;
}
export const renderFooterImagesLinks = ({ breakpoints, colors }: FooterImagesLinksProps): ReactNode => {
  const footerDataSliced = getFooterImageLinks();
  const styles = getStyles(breakpoints, colors);

  return (
    <>
      {footerDataSliced.map((item, index) =>
        index != footerDataSliced.length - 1 ? (
          <IconButton
            key={Number(item.id)}
            icon={() => item?.imageIcon}
            onPress={() => item.callback && item.callback(`${item.link}`)}
            style={styles.socialIconList}
            testID={`${item.link}`}
          />
        ) : (
          <Pressable
            key={Number(item.id)}
            onPress={() => item.callback && item.callback(`${item.link}`)}
            style={({ pressed }) => [
              {
                backgroundColor: pressed ? 'rgba(0, 0, 0, 0.32)' : 'white',
                borderRadius: 8,
              },
            ]}
            testID={`${item.link}`}
          >
            {item?.imageIcon}
          </Pressable>
        )
      )}
    </>
  );
};

export const FooterRight = ({ breakpoints, colors }: FooterImagesLinksProps) => {
  const styles = getStyles(breakpoints, colors);
  return <View style={styles.iconsContainer}>{renderFooterImagesLinks({ breakpoints, colors })}</View>;
};
