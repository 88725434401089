import React from 'react';
import { Image, ImageStyle, PressableProps, TextStyle, ViewStyle } from 'react-native';
import styles from './button.style';
import { TextContent } from '../../textcontent';
import { PressableWithOpacity } from '../pressableWithOpacity/pressableWithOpacity';

export interface ButtonProps extends PressableProps {
  btnStyle?: ViewStyle | ViewStyle[];
  mode?: 'text' | 'contained' | 'outline';
  label?: string;
  labelStyle?: TextStyle | TextStyle[];
  icon?: string;
  iconStyle?: ImageStyle;
}

export const CustomButton = ({
  btnStyle,
  mode,
  label,
  labelStyle,
  icon,
  iconStyle,
  children,
  ...props
}: ButtonProps) => {
  const getModeStyle = () => {
    switch (mode) {
      case 'text':
        return styles.textBtn;
      case 'contained':
        return styles.containedBtn;
      case 'outline':
        return styles.outlineBtn;
      default:
        return styles.containedBtn;
    }
  };

  const containedBtnLabelStyle = mode === 'contained' ? styles.containedBtnLabel : {};

  const renderIcon = () => <Image source={{ uri: icon }} style={[styles.icon, iconStyle]} />;

  return (
    <PressableWithOpacity overrideStyle={[getModeStyle(), styles.button, btnStyle as ViewStyle]} {...props}>
      {label ? (
        <TextContent accessible={false} style={[styles.label, containedBtnLabelStyle, labelStyle]}>
          {label}
        </TextContent>
      ) : (
        children
      )}
      {icon && renderIcon()}
    </PressableWithOpacity>
  );
};

export default CustomButton;
