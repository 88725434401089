import React, { FC, useCallback, useContext, useEffect, useState } from 'react';
import { BackHandler, TouchableOpacity, View } from 'react-native';
import { useReactiveVar } from '@apollo/client';
import { Text, useTheme } from 'react-native-paper';
import {
  EstadioButton,
  EstadioTextInput,
  LayoutType,
  LayoutWrapper,
  LoadingAppIndicator,
  ScrollViewRefContext,
  TitleType,
} from '@warnermmedia/gsp-core/brands/estadio/ui';
import {
  callbackNavigateExternal,
  getAuthData,
  getContentTitle,
  getUrlParam,
  languageStrings,
  ScreenEventType,
  Tve,
  useIsMountedRef,
  useMparticleCustomEventObject,
  useMparticleScreenEvent,
  usePinAndPairActions,
  useScrollToTop,
  useStatusMessage,
} from '@warnermmedia/gsp-core/brands/estadio/feature';
import { getStyles } from './providerLogin.styles';
import countryList, { Country } from './mockCountries';
import {
  AuthState,
  clearListData,
  loadData,
  MParticleCustomEventTypes,
  mParticleEventProcessor,
  ProviderLoginConfigObject,
  saveData,
  SubscriberType,
  TVE_AUTH_PROVIDER,
  TveCountryProviders,
  TveError,
  TveProvider,
} from '@warnermmedia/gsp-core/sdk/data-access';
import {
  AppConfigContext,
  breakpointsStateStore,
  HistoryContext,
  isAppTransitioning,
  LOGIN_TIMEOUT,
  loginAPIStateStore,
  PIN_CODE,
  PROVIDER_LOGIN_CODE_VALIDATION,
  PROVIDER_LOGIN_REDIRECT_TIME,
} from '@warnermmedia/gsp-core/brands/estadio/data-access';
import {
  getDiff,
  getUrlSearchParam,
  MetaTags,
  now,
  Provider,
  useGetDevice,
  validateAlphabeticCharacters,
  Variant,
} from '@warnermmedia/gsp-core/sdk/ui';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import { get } from 'lodash';
import { DiscoverMaxModal } from '../../modal/discoverMaxModal';

export const ProviderLogin: FC = () => {
  const { isWeb, isIosDevice, isAndroidDevice } = useGetDevice();
  const historyContext = useContext(HistoryContext);
  const configContext = useContext(AppConfigContext);
  const history = historyContext?.ready ? historyContext?.useHistory() : null;
  const location = historyContext?.ready ? historyContext?.useLocation() : null;
  const appConfig = useContext(AppConfigContext);
  const webVerificationLink = appConfig?.appConfig?.webUrl ?? '';
  const [query, setQuery] = useState('');
  const [loading, setLoading] = useState(false);
  const [showSearchBar, setShowSearchBar] = useState(false);
  const { setStatusMessage } = useStatusMessage({ timeout: LOGIN_TIMEOUT });
  const [tveProviderList, setTveProviderList] = useState<TveProvider[]>([]);
  const [showDiscoverMaxModal, setShowDiscoverMaxModal] = useState(false);
  const defaultCountry = { country: 'Argentina', countryCode: 'AR', flagURL: '' };
  const [selectedCountry] = useState<Country>(
    countryList.find((country) => country.countryCode === defaultCountry.countryCode) ?? defaultCountry
  );
  const [providerList, setProviderList] = useState<TveCountryProviders>();
  const breakpoints = useReactiveVar(breakpointsStateStore);
  const { colors } = useTheme();
  const styles = getStyles(breakpoints, colors);
  const mParticleEventData = useMparticleCustomEventObject();
  useMparticleScreenEvent(ScreenEventType.ProviderLogin, languageStrings.default.providerLoginTitle);
  const isMountedRef = useIsMountedRef();
  const code = isWeb ? getUrlSearchParam(location?.search, 'code') : null;
  const { claimCode } = usePinAndPairActions();
  const loginErrorMessage = history ? getUrlParam('loginError', history.location.search) : '';
  const { scrollToTop } = useScrollToTop(ScrollViewRefContext);
  const useTve = Tve();

  const providerIsOnMax = (providerShortName: string) => {
    if (!Array.isArray(configContext?.appConfig?.mvpdBlackList?.idp)) {
      return false;
    }
    return configContext?.appConfig.mvpdBlackList.idp.includes(providerShortName);
  };

  const handleNavigation = useCallback(
    (routeName: string) => {
      history?.replace(routeName);
    },
    [history]
  );

  const fetchProvider = useCallback(async () => {
    setLoading(true);
    await useTve.getProviders(
      (data: TveCountryProviders) => {
        if (isMountedRef.current) {
          if (!data) {
            setStatusMessage({ message: languageStrings.default.providerLoginProviderError, type: Variant.Error });
          } else {
            setProviderList(data);
          }
          setLoading(false);
        }
      },
      (e: TveError) => {
        if (isMountedRef.current) {
          setStatusMessage({
            message: languageStrings.default.providerLoginProviderListLoadingError,
            type: Variant.Error,
          });
          setLoading(false);
        }
      },
      selectedCountry.countryCode
    );
  }, [isMountedRef, selectedCountry, useTve, setStatusMessage]);

  useEffect(() => {
    if (isMountedRef.current && selectedCountry) {
      fetchProvider();
    }
  }, [isMountedRef, selectedCountry]);

  const handleSearch = useCallback(() => {
    let matchList: TveProvider[] = [];
    if (validateAlphabeticCharacters(query)) {
      const pattern = new RegExp(query, 'ig');
      const idp = get(providerList, 'idp', []);
      matchList = idp.filter((p) => pattern.test(p.description));
    }
    setTveProviderList(matchList);
  }, [query, providerList]);

  useEffect(() => {
    if (providerList?.countryCode && isMountedRef.current) {
      handleSearch();
    }
  }, [query, providerList, isMountedRef, handleSearch]);

  useEffect(() => {
    if (providerList?.countryCode && isMountedRef.current) {
      setShowSearchBar(get(providerList, 'idp', []).length > 15);
    }
  }, [providerList, isMountedRef]);

  const backAction = useCallback(() => {
    handleNavigation('/');
    return true;
  }, [handleNavigation]);

  useEffect(() => {
    BackHandler.addEventListener('hardwareBackPress', backAction);

    return () => BackHandler.removeEventListener('hardwareBackPress', backAction);
  }, [backAction]);

  const getProviderRedirectionTime = useCallback((eventName: MParticleCustomEventTypes) => {
    let redirectTime = loadData(PROVIDER_LOGIN_REDIRECT_TIME);
    if (eventName === MParticleCustomEventTypes.LoginStartEvent) {
      redirectTime = now().format();
      saveData(PROVIDER_LOGIN_REDIRECT_TIME, redirectTime);
    }
    return redirectTime;
  }, []);

  const pushMParticleEvents = useCallback(
    (event: MParticleCustomEventTypes, providerLoginConfigObject: ProviderLoginConfigObject) => {
      const { auth_state, service_provider, error_code } = providerLoginConfigObject;
      const finalEvents = [MParticleCustomEventTypes.LoginCompleteEvent, MParticleCustomEventTypes.LoginErrorEvent];
      const redirectTime = getProviderRedirectionTime(event);
      mParticleEventProcessor.pushMParticleEvent(event, {
        ...mParticleEventData,
        ...(auth_state ? { auth_state } : {}),
        ...(error_code ? { error_code } : {}),
        ...{ subscriber_type: SubscriberType.TVE, service_provider },
        ...(finalEvents.includes(event) ? { redirect_timing: getDiff(redirectTime) } : {}),
      });
    },
    [mParticleEventData, getProviderRedirectionTime]
  );

  const handleClaimCode = useCallback(async (): Promise<void> => {
    const validationCode = loadData(PIN_CODE);
    if (!isEmpty(validationCode)) {
      await claimCode(
        validationCode,
        loginAPIStateStore().tveUserId ?? '',
        () => null,
        () => {
          handleNavigation('/logintv-success');
        }
      );
    }
  }, [claimCode, handleNavigation]);

  const handleLoginError = useCallback(
    (response: TveError, provider: TveProvider) => {
      scrollToTop();
      const message = response.error ? response.error : languageStrings.default.providerLoginAuthenticationError;
      setStatusMessage({ message, type: Variant.Error });
      pushMParticleEvents(MParticleCustomEventTypes.LoginErrorEvent, {
        service_provider: provider.description,
        error_code: message,
      });
      clearListData([TVE_AUTH_PROVIDER, PROVIDER_LOGIN_CODE_VALIDATION]);
    },
    [pushMParticleEvents, setStatusMessage, scrollToTop]
  );

  const handleWebLoginError = useCallback(() => {
    scrollToTop();
    const provider = getAuthData(TVE_AUTH_PROVIDER);
    handleLoginError({ error: loginErrorMessage }, provider);
    setLoading(false);
    handleNavigation('/login');
  }, [loginErrorMessage, handleLoginError, handleNavigation, scrollToTop]);

  useEffect(() => {
    if (isMountedRef.current && loginErrorMessage) {
      handleWebLoginError();
    }
  }, [isMountedRef, loginErrorMessage]);

  const handleLogin = useCallback(
    async (provider: TveProvider) => {
      if (providerIsOnMax(provider.shortName)) {
        setShowDiscoverMaxModal(true);
        return;
      }
      setLoading(true);
      isAppTransitioning(true);
      pushMParticleEvents(MParticleCustomEventTypes.LoginStartEvent, {
        service_provider: provider.description,
      });
      await useTve.login(
        selectedCountry.countryCode,
        provider,
        async () => {
          pushMParticleEvents(MParticleCustomEventTypes.LoginCompleteEvent, {
            auth_state: AuthState.Authenticated,
            service_provider: provider.description,
          });
          await handleClaimCode();
          scrollToTop();
        },
        (e: TveError) => {
          handleLoginError(e, provider);
        }
      );
      isMountedRef.current && setLoading(false);
      isAppTransitioning(false);
    },
    [useTve, selectedCountry, isMountedRef, handleLoginError, pushMParticleEvents, handleClaimCode, scrollToTop]
  );

  const verifyTVEAuthorizationCode = useCallback(async () => {
    const provider = getAuthData(TVE_AUTH_PROVIDER);
    const authCode = loadData(PROVIDER_LOGIN_CODE_VALIDATION);
    if (isEmpty(provider)) {
      return;
    }
    setLoading(true);
    isAppTransitioning(true);

    if (code && !isEqual(code, authCode)) {
      saveData(PROVIDER_LOGIN_CODE_VALIDATION, code);
      await useTve.handleRedirectionLogin(
        code,
        provider,
        async () => {
          pushMParticleEvents(MParticleCustomEventTypes.LoginCompleteEvent, {
            auth_state: AuthState.Authenticated,
            service_provider: provider.description,
          });
          await handleClaimCode();
        },
        (e: TveError) => {
          handleNavigation(`/login?loginError=${e.error}`);
        }
      );
      isMountedRef.current && setLoading(false);
      isAppTransitioning(false);
    }
  }, [code, handleClaimCode, isMountedRef, useTve, pushMParticleEvents, handleNavigation]);

  useEffect(() => {
    if (isMountedRef.current && code && !loginErrorMessage) {
      verifyTVEAuthorizationCode();
    }
  }, [isMountedRef, code, verifyTVEAuthorizationCode, loginErrorMessage]);

  const handleRegisterPress = useCallback(() => {
    if (isWeb) {
      handleNavigation('/register');
    }
    if (isAndroidDevice) {
      callbackNavigateExternal(webVerificationLink ? `${webVerificationLink}/register` : '');
    }
  }, [isWeb, isAndroidDevice, webVerificationLink, handleNavigation]);

  return (
    <LayoutWrapper
      layoutType={LayoutType.Center}
      titleType={TitleType.Left}
      pageTitle={languageStrings.default.landingPage}
      subTitle={!loading ? languageStrings.default.providerLoginDescription : ''}
      overrideWrapperStyle={styles.overrideWrapper}
      overrideTitleStyle={styles.overrideTitle}
    >
      {isWeb && <MetaTags data={[]} title={getContentTitle(languageStrings.default.providerLoginTitle)} />}
      <View>
        {showSearchBar && !loading ? (
          <View style={styles.searchSection}>
            <Text style={styles.searchLabel}>{languageStrings.default.providerLoginSearchLabel}</Text>
            <EstadioTextInput
              style={styles.searchBar}
              placeholder={languageStrings.default.providerLoginSearchPlaceholder}
              onChangeText={setQuery}
              errorString={''}
              error={false}
              value={query}
              editable={!loading}
              focusable={!loading}
              whiteBorder
            />
          </View>
        ) : null}

        {loading && <LoadingAppIndicator style={styles.loadingIndicator} />}

        {tveProviderList.length ? (
          <View style={styles.category}>
            {tveProviderList.map((provider) => (
              <Provider disabled={loading} key={provider.shortName} provider={provider} onPress={handleLogin} />
            ))}
            <DiscoverMaxModal visible={showDiscoverMaxModal} onClose={() => setShowDiscoverMaxModal(false)} />
          </View>
        ) : null}
      </View>

      {query ? (
        <TouchableOpacity onPress={() => setQuery('')} style={styles.showAllContainer}>
          <Text style={styles.showAll}>{languageStrings.default.providerLoginShowAllLabel}</Text>
        </TouchableOpacity>
      ) : null}

      {!isIosDevice ? (
        <EstadioButton
          label={languageStrings.default.providerLoginNoAccountLabel}
          onPress={handleRegisterPress}
          btnColor="transparent"
          btnStyle={styles.accountLoginBtn}
          labelStyle={styles.accountLoginLabel}
          disabled={loading}
          disabledLabelColor={colors.tenantBackground.light.surfaceFilm}
          disabledBgColor="transparent"
        />
      ) : null}
    </LayoutWrapper>
  );
};

export default ProviderLogin;
