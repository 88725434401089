import React, { memo } from 'react';
import { View } from 'react-native';
import { getStyles } from './footer.styles';
import { useTheme } from 'react-native-paper';
import { breakpointsStateStore } from '@warnermmedia/gsp-core/brands/estadio/data-access';
import { useReactiveVar } from '@apollo/client';
import {
  getFooterAppVersionText,
  getFooterCopyrightText,
  getFooterGoToText,
  getFooterLinks,
} from '@warnermmedia/gsp-core/brands/estadio/feature';
import { FooterLeft } from '../footerleft';
import { FooterRight } from '../footerright';
import { FooterMiddle } from '../footermiddle';

const footerDataSliced = { links: getFooterLinks() };

const leftProps = {
  goTo: getFooterGoToText(),
  copyright: getFooterCopyrightText(),
  appVersion: getFooterAppVersionText(),
};

const MainFooter = () => {
  const breakpoints = useReactiveVar(breakpointsStateStore);
  const { colors } = useTheme();
  const styles = getStyles(breakpoints, colors);

  return (
    <View style={styles.footer}>
      <View style={styles.middleContainer}>
        <View style={styles.middle}>
          <FooterMiddle {...{ ...footerDataSliced, breakpoints, colors }} />
        </View>
      </View>
      <View style={styles.rightContainer}>
        <View style={styles.right}>
          <FooterRight breakpoints={breakpoints} colors={colors} />
        </View>
      </View>
      <View style={styles.leftContainer}>
        <View style={styles.left}>
          <FooterLeft {...{ ...leftProps, breakpoints, colors }} />
        </View>
      </View>
    </View>
  );
};

export default memo(MainFooter);
