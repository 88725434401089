import { IElementStore } from './';

const NATIVE_FOCUS_DELAY = 100;
/**
 * NativeElementStore is an IElementStore that uses the browser's native focus
 * for dealing with the focused element.
 */
export class NativeElementStore implements IElementStore {
  get element(): HTMLElement {
    return document.activeElement as HTMLElement;
  }

  set element(element: HTMLElement) {
    setTimeout(() => {
      element.focus();
    }, NATIVE_FOCUS_DELAY);
  }
}
