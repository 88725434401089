import React, { useCallback, useContext, useEffect, useState } from 'react';
import { VideoErrorMessages } from '@warnermmedia/gsp-core/sdk/data-access';
import { languageStrings } from '@warnermmedia/gsp-core/brands/estadio/feature';
import { breakpointsStateStore, HistoryContext, ModalType } from '@warnermmedia/gsp-core/brands/estadio/data-access';
import ModalWrapper, { ModalContentContainer } from '../modalWrapper';
import { Text, View } from 'react-native';
import { AutoFocus } from '@warnermedia/gsp-core/sdk/arcade-machine';
import { getStyles } from './videoErrorModal.styles';
import { useReactiveVar } from '@apollo/client';
import { useTheme } from 'react-native-paper';
import { EstadioButton } from '../../components';

interface Props {
  errMessage: string;
  setVideoError: React.Dispatch<React.SetStateAction<string>>;
  exitFullScreen?: () => void;
  isTv: boolean;
}

export const VideoErrorModal = ({ errMessage, setVideoError, exitFullScreen, isTv }: Props) => {
  const historyContext = useContext(HistoryContext);
  const history = historyContext?.ready ? historyContext?.useHistory() : null;
  const [visible, setVisible] = useState(false);
  const breakpoints = useReactiveVar(breakpointsStateStore);
  const { colors } = useTheme();
  const styles = getStyles(breakpoints, colors);
  const isGeoBlock = errMessage === VideoErrorMessages.GEOBLOCK_EVENT_ERROR;
  const ModalButtonWrapper = isTv ? AutoFocus : React.Fragment;

  const goBack = useCallback(() => {
    setVisible(false);
    setVideoError('');
    history?.push('/home');
  }, [history, setVideoError]);

  const onVideoErrorModal = useCallback(() => {
    exitFullScreen && exitFullScreen();
  }, [exitFullScreen]);

  useEffect(() => {
    if (
      !!errMessage &&
      !visible &&
      [VideoErrorMessages.GEOBLOCK_EVENT_ERROR, VideoErrorMessages.CONCURRENCY_LIMIT_EVENT_ERROR].includes(errMessage)
    ) {
      setVisible(true);
      onVideoErrorModal();
    }
  }, [errMessage, visible]);

  return (
    <ModalWrapper
      visible={visible}
      modalType={visible ? ModalType.VideoErrorModal : null}
      onClose={goBack}
      showCloseButton={true}
      modalContentContainerType={ModalContentContainer.Full}
      modalMainContainerStyle={styles.container}
      modalCloseButtonStyle={styles.closeBtn}
      modalLayoutContainerStyle={styles.fullScreenContainer}
      modalCloseButtonLabelStyle={styles.closeButtonLabelStyle}
    >
      <View style={styles.textContainer}>
        <Text style={styles.title}>
          {isGeoBlock ? languageStrings.default.geoBlockMessageTitle : languageStrings.default.concurrencyModalTitle}
        </Text>
        <View style={styles.containerInner}>
          <Text style={styles.description}>
            {isGeoBlock
              ? languageStrings.default.geoBlockMessageDescription
              : languageStrings.default.concurrencyModalDescription}
          </Text>
        </View>
        <ModalButtonWrapper>
          <EstadioButton
            mode="outline"
            label={isGeoBlock ? languageStrings.default.closeButton : languageStrings.default.concurrencyModalCTALabel}
            onPress={goBack}
            btnStyle={styles.buttonStyle}
            labelStyle={styles.buttonLabelStyle}
          />
        </ModalButtonWrapper>
      </View>
    </ModalWrapper>
  );
};
