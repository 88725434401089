import React, { FC, useEffect, useState } from 'react';
import { GestureResponderEvent, TextStyle, View, ViewStyle } from 'react-native';
import { MessageDisplay, Variant } from '../../messageDisplay/messageDisplay';
import { TextContent } from '../../textcontent/textcontent';
import { CustomButton } from '../../formElements/button/button';
import styles from './resendVerificationEmailForm.styles';

export interface FooterSection {
  id: string;
  message: string;
  linkLabel: string;
  link?: string;
  directionStyle: ViewStyle;
  messageTextStyle?: TextStyle;
  callback?(e?: Event | GestureResponderEvent): void;
}

export interface ResendVerificationEmailFormProps {
  email: string;
  verificationMessage?: string;
  buttonText: string;
  buttonAction(email: string): void;
  isLoading: boolean;
  requestSuccessfulMessage?: string;
  requestErrorMessage?: string;
  checkValidationMessage?: string;
  footerSection?: FooterSection[] | null;
  isUserEmailConfirmed?: boolean;
}

export const ResendVerificationEmailForm: FC<ResendVerificationEmailFormProps> = ({
  email,
  buttonAction,
  isLoading,
  verificationMessage,
  requestSuccessfulMessage,
  requestErrorMessage,
  buttonText = 'Resend Email',
  checkValidationMessage,
  isUserEmailConfirmed,
  footerSection = [
    {
      id: '',
      message: 'You are still not getting an email?',
      linkLabel: 'Get in touch with us',
      link: '',
      directionStyle: {
        flexDirection: 'row',
        alignItems: 'center',
        alignContent: 'center',
        justifyContent: 'space-evenly',
      },
    },
  ],
}) => {
  const [userEmail, setUserEmail] = useState('');
  const disabled = !email || isLoading;
  const showVerificationMessage =
    !!verificationMessage && !requestErrorMessage && !requestSuccessfulMessage && !checkValidationMessage;
  const showSuccess = !!requestSuccessfulMessage && !requestErrorMessage;
  const showError = !!requestErrorMessage && !requestSuccessfulMessage;

  useEffect(() => {
    if (email) {
      setUserEmail(email);
    }
  }, [email]);

  return (
    <View style={styles.wrapper}>
      {showError && (
        <MessageDisplay
          displayMessage={requestErrorMessage ?? ''}
          variant={Variant.Error}
          wrapperStyle={styles.errorMessage}
        />
      )}
      {!!checkValidationMessage && (
        <MessageDisplay
          displayMessage={checkValidationMessage}
          variant={Variant.Error}
          wrapperStyle={styles.errorMessage}
        />
      )}
      {showVerificationMessage && (
        <MessageDisplay
          displayMessage={verificationMessage ?? ''}
          variant={Variant.Generic}
          wrapperStyle={styles.message}
          textStyle={styles.messageText}
          displayIcon={isUserEmailConfirmed ? 'check-circle-outline' : 'alert-circle-outline'}
        />
      )}
      {showSuccess && (
        <MessageDisplay
          displayMessage={requestSuccessfulMessage ?? ''}
          variant={Variant.Generic}
          wrapperStyle={styles.message}
          displayIcon={'check-circle-outline'}
        />
      )}
      <CustomButton
        mode="contained"
        disabled={!!disabled}
        btnStyle={[styles.resendButton, { backgroundColor: disabled ? '#710d53' : '#ff0085', borderWidth: 0 }]}
        onPress={() => buttonAction(userEmail)}
        label={buttonText}
      />
      {footerSection &&
        footerSection.map((elem: FooterSection) => {
          const { callback = () => null, message, messageTextStyle, directionStyle, id } = elem as FooterSection;
          return (
            <View style={[styles.footerSectionContainer, directionStyle]} key={id}>
              {message && <TextContent style={[styles.footerSectionMessage, messageTextStyle]}>{message}</TextContent>}
              <CustomButton
                mode="text"
                btnStyle={styles.checkBtn}
                labelStyle={styles.underlinedText}
                onPress={(e) => callback(e)}
                label={elem?.linkLabel}
              />
            </View>
          );
        })}
    </View>
  );
};

export default ResendVerificationEmailForm;
