import { useEffect } from 'react';
import { MparticleEventDefaultPropertyValue, useMparticleCustomEventObject } from './useMparticleCustomEventObject';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import {
  logScreenEvent,
  MParticleAppConfigParams,
  MParticleCustomEventTypes,
} from '@warnermmedia/gsp-core/sdk/data-access';
import { useIsMountedRef } from './useIsMountedRef';
import { getContentTitle } from '../Services/CommonFunctions';

export enum ScreenEventType {
  ProviderLogin = 'provider login',
  RedeemCoupon = 'redeem coupon',
  Profile = 'profile',
  Billing = 'billing',
  ResendVerificationEmail = 'resend verification email',
  Match = 'match',
  Tournament = 'tournament',
  Team = 'team',
  Invoice = 'invoice',
  Unsubscribe = 'unsubscribe',
  SubscriptionSuccess = 'subscription success',
  SubscriptionFailure = 'subscription failure',
  PasswordRecovery = 'password recovery',
  ResetPassword = 'reset password',
  Show = 'show',
  CodeValidation = 'code validation',
  CodeValidationSuccess = 'code validation success',
  TvLanding = 'tv landing',
  TvPinDisplay = 'tv code display',
  TvPinError = 'tv code error',
}

export function useMparticleScreenEvent(
  type = '',
  title = '',
  cmsId = '',
  mParticleEventProps: MParticleAppConfigParams = {}
) {
  const mParticleEventData = useMparticleCustomEventObject({
    ...{ section: type, contentTitle: getContentTitle(title), contentId: cmsId },
    ...mParticleEventProps,
  });
  const isMountedRef = useIsMountedRef();

  const isReady = () => {
    return (
      !!type &&
      !!title &&
      isMountedRef.current &&
      !!mParticleEventData &&
      !!mParticleEventData.section &&
      !!mParticleEventData.content_title &&
      mParticleEventData.section !== MparticleEventDefaultPropertyValue &&
      mParticleEventData.content_title === getContentTitle(title) &&
      (!mParticleEventProps || Object.values(mParticleEventProps).every((value) => !!value))
    );
  };

  useEffect(() => {
    if (isReady()) {
      logScreenEvent(MParticleCustomEventTypes.PageNameChangeEvent, mParticleEventData);
    }
  }, [mParticleEventData.section, mParticleEventData.content_title, type, isMountedRef, title]);

  return null;
}
