import React, { FC, ReactElement, ReactNode, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
  Dimensions,
  FlatList,
  GestureResponderEvent,
  ImageStyle,
  LayoutChangeEvent,
  ListRenderItemInfo,
  Modal,
  Platform,
  Pressable,
  Text,
  TextStyle,
  TouchableOpacity,
  View,
  ViewStyle,
} from 'react-native';
import { IconButton } from 'react-native-paper';
import styles from './dropdown.style';
import { Hoverable } from 'react-native-web-hover';

interface DropDownArrowIcon {
  up: string;
  down: string;
}

export interface DropDownItem {
  label: string;
  value: string | number;
  disabled: boolean;
  custom?: ReactNode;
}

interface Props {
  label: string | number;
  data: DropDownItem[];
  onSelect: (value: string | number) => void;
  dropDownContainerMaxHeight?: number;
  dropDownContainerHeight?: number;
  hoverStyle?: ViewStyle;
  activeIconName?: string;
  activeIconColor?: string;
  activeIconStyle?: ImageStyle | ImageStyle[];
  dropDownItemStyle?: ViewStyle;
  dropDownItemSelectedTextStyle?: TextStyle;
  dropDownItemSelectedStyle?: ViewStyle;
  dropDownItemDisabledTextStyle?: TextStyle;
  dropDownItemHoverStyle?: TextStyle;
  dropDownIconColor?: string;
  dropDownArrowIcon?: DropDownArrowIcon;
  inputStyle?: ViewStyle;
  inputTextStyle?: TextStyle;
  inputClickStyle?: ViewStyle;
  dropDownItemTextStyle?: TextStyle;
  shouldAutoFocus?: boolean;
  showsVerticalScrollIndicator?: boolean;
  showsHorizontalScrollIndicator?: boolean;
  disabled?: boolean;
}

export const Dropdown: FC<Props> = ({
  label,
  data,
  onSelect,
  dropDownContainerMaxHeight,
  dropDownContainerHeight,
  activeIconName,
  activeIconColor = 'black',
  activeIconStyle = {},
  hoverStyle = {},
  dropDownItemStyle = {},
  dropDownItemSelectedTextStyle = {},
  dropDownItemSelectedStyle = {},
  dropDownItemDisabledTextStyle = {},
  dropDownItemHoverStyle = {},
  dropDownIconColor = 'black',
  dropDownArrowIcon = {
    up: 'chevron-up',
    down: 'chevron-down',
  },
  inputStyle = {},
  inputTextStyle = {},
  inputClickStyle = {},
  dropDownItemTextStyle = {},
  shouldAutoFocus = false,
  showsVerticalScrollIndicator = false,
  showsHorizontalScrollIndicator = false,
  disabled = false,
}) => {
  const dropdownRef = useRef(null);
  const screenHeight = Dimensions.get('window').height;
  const screenWidth = Dimensions.get('window').width;
  const [visible, setVisible] = useState(false);
  const [selected, setSelected] = useState<DropDownItem>();
  const [dropdownTop, setDropdownTop] = useState(0);
  const [dropdownLeft, setDropdownLeft] = useState(0);
  const [inputLayout, setInputLayout] = useState({
    height: 0,
    width: 0,
    x: 0,
    y: 0,
  });

  const updateMeasurements = (_fx: number, _fy: number, _w: number, h: number, _px: number, py: number) => {
    setDropdownTop(py + h + 1);
    setDropdownLeft(_px);
  };

  const updateDropdownStyle = useCallback(() => {
    if (dropdownRef.current) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      dropdownRef.current.measure(updateMeasurements);
    }
  }, []);

  const onLayout = useCallback(
    (event: LayoutChangeEvent) => {
      setInputLayout(event.nativeEvent.layout);
      updateDropdownStyle();
    },
    [setInputLayout, updateDropdownStyle]
  );

  const toggleDropdown = useCallback(
    (e: GestureResponderEvent): void => {
      e.preventDefault();
      setVisible(!visible);
    },
    [visible, setVisible]
  );

  const onItemPress = (e: GestureResponderEvent, item: DropDownItem): void => {
    e.preventDefault();
    setSelected(item);
    setVisible(false);
  };

  const isActive = (item: DropDownItem) => {
    return selected?.value === item.value || label === item.value;
  };

  useEffect(() => {
    if (selected) {
      onSelect(selected.value);
    }
  }, [selected]);

  useEffect(() => {
    // Excluding ios because TouchableOpacity tag if falling on ios while adding the focus
    if (dropdownRef.current && shouldAutoFocus && Platform.OS !== 'ios') {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      dropdownRef.current.focus();
    }
  }, [dropdownRef, shouldAutoFocus]);

  useEffect(() => {
    const closeList = () => {
      setVisible(false);
    };
    if (Platform.OS === 'web' && window) {
      window.addEventListener('scroll', closeList);
      return () => window.removeEventListener('scroll', closeList);
    }
    return () => null;
  }, []);

  useEffect(() => {
    if (visible) {
      updateDropdownStyle();
    }
  }, [screenHeight, screenWidth, visible]);

  const renderItem = useCallback(
    ({ item }: ListRenderItemInfo<DropDownItem>): ReactElement => {
      const onPress = item.disabled ? () => null : (e: GestureResponderEvent) => onItemPress(e, item);
      const itemTextWithIconStyle = {
        paddingLeft: isActive(item) && activeIconName ? 0 : activeIconName ? 30 : 10,
      };
      return (
        <Hoverable>
          {({ hovered }) => {
            return (
              <TouchableOpacity
                onPress={onPress}
                style={[dropDownItemStyle, isActive(item) && dropDownItemSelectedStyle, hovered && hoverStyle]}
              >
                <View style={styles.item}>
                  {isActive(item) && activeIconName && (
                    <IconButton
                      icon={activeIconName}
                      color={activeIconColor}
                      size={15}
                      style={[styles.activeIcon, activeIconStyle]}
                    />
                  )}
                  <Text
                    style={[
                      styles.itemText,
                      itemTextWithIconStyle,
                      dropDownItemTextStyle,
                      hovered && dropDownItemHoverStyle,
                      isActive(item) && dropDownItemSelectedTextStyle,
                      item.disabled && dropDownItemDisabledTextStyle,
                    ]}
                  >
                    {item.label}
                  </Text>
                </View>
              </TouchableOpacity>
            );
          }}
        </Hoverable>
      );
    },
    [
      activeIconName,
      activeIconStyle,
      activeIconColor,
      dropDownItemStyle,
      dropDownItemSelectedStyle,
      hoverStyle,
      dropDownItemTextStyle,
      dropDownItemHoverStyle,
      dropDownItemSelectedTextStyle,
      dropDownItemDisabledTextStyle,
      onItemPress,
      isActive,
    ]
  );

  const dropdown = useMemo(
    () => (
      <Modal visible={visible} transparent={true}>
        <Pressable style={styles.overlay} onPress={toggleDropdown}>
          <View
            style={[
              styles.dropdown,
              {
                top: dropdownTop,
                left: dropdownLeft,
                maxWidth: inputLayout?.width,
              },
            ]}
          >
            {visible && (
              <FlatList
                bounces={false}
                style={{
                  height: dropDownContainerHeight || 200,
                  maxHeight: dropDownContainerMaxHeight || 200,
                }}
                data={data}
                renderItem={renderItem}
                decelerationRate={0}
                keyExtractor={(item, index) => index.toString()}
                showsVerticalScrollIndicator={showsVerticalScrollIndicator}
                showsHorizontalScrollIndicator={showsHorizontalScrollIndicator}
              />
            )}
          </View>
        </Pressable>
      </Modal>
    ),
    [
      visible,
      data,
      dropdownTop,
      dropdownLeft,
      inputLayout,
      dropDownContainerHeight,
      dropDownContainerMaxHeight,
      toggleDropdown,
      renderItem,
    ]
  );

  return (
    <TouchableOpacity
      ref={dropdownRef}
      style={[styles.button, inputStyle, visible && inputClickStyle]}
      onPress={toggleDropdown}
      onLayout={onLayout}
      disabled={disabled}
    >
      <Text numberOfLines={1} style={[styles.buttonText, inputTextStyle]}>
        {(!!selected && selected.label) || label}
      </Text>
      <IconButton
        icon={visible ? dropDownArrowIcon.up : dropDownArrowIcon.down}
        color={dropDownIconColor}
        size={22}
        onPress={toggleDropdown}
        disabled={disabled}
      />
      {dropdown}
    </TouchableOpacity>
  );
};

export default React.memo(Dropdown);
