import React, { useContext } from 'react';
import { Register } from '@warnermmedia/gsp-core/brands/estadio/ui-web';
import { AppConfigContext } from '@warnermmedia/gsp-core/brands/estadio/data-access';
import { StaticLanding } from '@warnermmedia/gsp-core/brands/estadio/ui';

const RegisterScreen: React.FC = () => {
  const appConfig = useContext(AppConfigContext);
  const isAppEnabled = appConfig?.appConfig?.appEnabled ?? true;

  return isAppEnabled ? <Register /> : <StaticLanding />;
};

export default RegisterScreen;
