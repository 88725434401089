/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { emitCustomEvent, useCustomEventListener } from 'react-custom-events';
import {
  isJwtTokenExpired,
  languageStrings,
  useIsMountedRef,
  useVideo,
  videoPlayerError,
} from '@warnermmedia/gsp-core/brands/estadio/feature';
import { PlayerMediaConfig, usePlayerConfigBuilder } from '@warnermedia/gsp-core/sdk/video';
import { useReactiveVar } from '@apollo/client';
import {
  AppConfigContext,
  errorMsgStore,
  EstadioContext,
  loginAPIStateStore,
  PlayerState,
  playerStateStore,
  videoStates,
} from '@warnermmedia/gsp-core/brands/estadio/data-access';

import {
  AuthTokenType,
  ConvivaSetupConfig,
  CustomContentMetadata,
  ListenableEventBase,
  ListenableEvents as PlayerEvent,
  PlayerConfigEnvType,
} from '@top/tub-player-web';
import { Player } from './Player';
import './VideoPlayer.scss';
import { Button as ARC_Button } from '@warnermedia/gsp-core/sdk/arcade-machine';
import { CSSProperties } from '@material-ui/core/styles/withStyles';
import { useGetDevice, useGetEnvironment, APP_VERSION } from '@warnermmedia/gsp-core/sdk/ui';
import PlayerControlsOverlay from './PlayerControlsOverlay';

import { loadData } from '@warnermmedia/gsp-core/sdk/data-access';

import { ImageAssets } from '@warnermmedia/gsp-core/brands/estadio/assets';

interface Props {
  mediaId: string;
  authToken: string;
  isLive: boolean;
  title?: string;
  convivaTags?: { [x: string]: string | undefined } | undefined;
  showBackBtn: boolean;
  containerStyle?: CSSProperties;
  videoPlayerOverride?: CSSProperties;
  useRatioSizing?: boolean;
  withMediaConfig: PlayerMediaConfig;
  posterImage?: string | null | undefined;
  autoPlay?: boolean;
}

export const TvVideoPlayer: React.FC<Props> = ({
  mediaId,
  authToken,
  isLive,
  containerStyle,
  videoPlayerOverride,
  withMediaConfig,
  useRatioSizing = true,
  title,
  convivaTags,
  posterImage,
  autoPlay = true,
}): JSX.Element => {
  const evt = new Event('mouseenter');
  const appConfig = useContext(AppConfigContext);
  const { device, isNotWeb } = useGetDevice();
  const [videoContainer, setVideoContainer] = useState<HTMLDivElement>();
  const videoContainerRef = useCallback((videoContainer: HTMLDivElement) => {
    if (videoContainer) {
      setVideoContainer(videoContainer);
    }
  }, []);
  const { isDevelopment } = useGetEnvironment();

  const { newSetupConfig } = usePlayerConfigBuilder(withMediaConfig);
  // change to useCallback for ref
  const [topPlayer, setTopPlayer] = useState<Player | null>();

  const isMountedRef = useIsMountedRef();
  const [controlsVisible, setControlsVisibility] = useState<boolean>(true);
  const [playerReady, setPlayerReady] = useState<boolean>(false);
  const [videoToken, setVideoToken] = useState<string | undefined>(undefined);
  const playerState = useReactiveVar<PlayerState>(playerStateStore);
  const { token, getNewVideoToken } = useVideo({ mediaId, token: authToken });
  const { magicRemoteVisible } = useContext(EstadioContext);
  const [isPlaying, setIsPlaying] = useState(false);
  const [contentEnded, setContentEnded] = useState(false);
  const [isPaused, setIsPaused] = useState(false);
  const [seekValue, setSeekValue] = useState(100);
  const [timer, setTimer] = useState(0);
  const [duration, setDuration] = useState(100);
  const [refreshToken, setRefreshToken] = useState(false);

  const ratioDivStyles: CSSProperties = {
    paddingTop: '56.24%',
    backgroundColor: 'black',
  };
  const playerStyles: CSSProperties = { zIndex: 0, position: 'absolute', width: '100%', height: '100%', top: 0 };

  const companyId = isLive ? appConfig?.appConfig.video.live.compId : appConfig?.appConfig.video.vod.compId;
  const env: PlayerConfigEnvType = isLive
    ? (appConfig?.appConfig.video.live.env as PlayerConfigEnvType)
    : ((appConfig?.appConfig.video.vod.env !== undefined
        ? appConfig?.appConfig.video.vod.env
        : '') as PlayerConfigEnvType);

  const tokenRequest = async () => {
    try {
      const vidToken = await getNewVideoToken();
      if (vidToken.length && isMountedRef.current) {
        setVideoToken(vidToken);
      } else {
        throw new Error(
          vidToken.length && isJwtTokenExpired(token) ? 'Token expired' : languageStrings?.default?.cantPlayContentError
        );
      }
    } catch (error) {
      setRefreshToken(false);
      playerStateStore({
        type: videoStates.CONTENT_ERROR,
        message: error?.message,
      });
    }
  };

  const convivaConfig: ConvivaSetupConfig = {
    enabled: true,
    customerKey: appConfig?.appConfig.video.convivaId,
    applicationVersion: loadData(APP_VERSION),
    config: {
      ...(isDevelopment && {
        gatewayUrl: appConfig?.appConfig.video.touchStoneApi,
      }),
    },
    contentMetadata: {
      assetName: title,
      applicationName: `${appConfig?.appConfig?.dalton.tenant ?? ''} - ${device}`,
      viewerId: !loginAPIStateStore().isTveAuth ? loginAPIStateStore().user?.tid : loginAPIStateStore().tveUserId || '',
      custom: convivaTags as CustomContentMetadata,
    },
  };

  const handleEnter = () => {
    if (isPlaying && topPlayer) {
      topPlayer?.pause();
    } else if (isPaused) {
      topPlayer?.play();
    } else {
      topPlayer?.play();
    }
  };

  const onButtonHandler = (event: KeyboardEvent) => {
    emitCustomEvent('BUTTON_PRESS', event.keyCode);
    setControlsVisibility(true);
    if (
      ((device === 'tizen' || device === 'webos') && event.code === 'Enter') ||
      (device === 'androidtv' && event.keyCode === 13)
    ) {
      handleEnter();
    }
  };

  const handleTimeChange = (event: ListenableEventBase) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const time = event?.absoluteTime;
    if (time) {
      const duration = topPlayer?.getDuration() || 0;
      const progressPercent = Math.round((time / duration) * 100);
      setSeekValue(progressPercent);
      setTimer(time);
    }
  };

  useEffect(() => {
    if (topPlayer) {
      try {
        topPlayer?.addListener(PlayerEvent.Play, (event) => {
          setIsPlaying(true);
          setIsPaused(false);
        });
        topPlayer?.addListener(PlayerEvent.Ready, (event) => {
          setPlayerReady(true);
          setControlsVisibility(true);
          setSeekValue(topPlayer?.isLive() ? 100 : 0);
          setDuration(topPlayer?.getDuration() === Infinity ? 100 : topPlayer?.getDuration() || 0);
        });
        topPlayer?.addListener(PlayerEvent.Paused, (event) => {
          setIsPlaying(false);
          setIsPaused(true);
        });
        topPlayer?.addListener(PlayerEvent.LicenseValidated, (event) => console.log(event, 'LicenseValidated'));
        topPlayer?.addListener(PlayerEvent.TimeChanged, (event) => {
          if (!topPlayer?.isLive()) {
            handleTimeChange(event);
          }
        });
        topPlayer?.addListener(PlayerEvent.Warning, (event) => {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          if (event.name === videoStates.PLAYBACK_COULD_NOT_BE_STARTED) {
            setContentEnded(true);
          }
          console.log(event, 'Warning');
        });
        topPlayer?.addListener(PlayerEvent.PlaybackFinished, (event) => {
          setIsPlaying(false);
          setIsPaused(false);
          setContentEnded(true);
        });
        topPlayer?.addListener(PlayerEvent.Error, () => {
          setIsPlaying(false);
          setIsPaused(false);
          setContentEnded(true);
        });
      } catch (error) {
        errorMsgStore({
          type: 'AXIOS_ERROR',
          message: languageStrings?.default?.cantPlayContentError,
        });
        setContentEnded(true);
        console.warn(error);
      }
    }
  }, [topPlayer, handleTimeChange]);

  useEffect(() => {
    return () => {
      if (topPlayer) {
        topPlayer.close();
      }
    };
  }, [topPlayer]);

  const appVisibilityHandler = () => {
    if (document.visibilityState === 'hidden' && device === 'tizen') {
      topPlayer?.pause();
    }
  };

  useEffect(() => {
    window.document.addEventListener('keydown', onButtonHandler, false);
    return () => {
      window.document.removeEventListener('keydown', onButtonHandler);
    };
  }, [isPlaying, isPaused]);

  useEffect(() => {
    let timeoutId: ReturnType<typeof setTimeout>;
    if (controlsVisible) {
      timeoutId = setTimeout(() => setControlsVisibility(false), 10000);
    }
    return () => clearTimeout(timeoutId);
  }, [controlsVisible]);

  useEffect(() => {
    document.addEventListener('visibilitychange', appVisibilityHandler);
    return () => {
      document.removeEventListener('visibilitychange', appVisibilityHandler);
    };
  }, [isPlaying, isPaused]);

  useEffect(() => {
    const getToken = async () => {
      if (isMountedRef.current && refreshToken) {
        tokenRequest();
        setRefreshToken(false);
      }
    };
    getToken();
  }, [refreshToken, isMountedRef]);

  const playPauseBtnSelect = (keyCode: number) => {
    const ppBtns = [
      ARC_Button.FireMediaPlayPause,
      ARC_Button.Pause,
      ARC_Button.Play,
      ARC_Button.SAMSUNG_PLAYPAUSE,
      ARC_Button.PlayPause,
    ];

    return ppBtns.includes(keyCode);
  };

  useCustomEventListener('BUTTON_PRESS', (msg) => {
    const playPauseSelect = playPauseBtnSelect(msg as number);
    if (topPlayer && playPauseSelect) {
      const isPlaying = topPlayer?.isPlaying();
      if (isPlaying) {
        topPlayer.pause();
      } else if (isPlaying === false) {
        topPlayer?.play();
      }
    }
    if (msg === ARC_Button.ArrowUp || ARC_Button.ArrowDown) {
      document.getElementById('top-container-1')?.dispatchEvent(evt);
    }
    if (magicRemoteVisible) {
      document.getElementById('top-container-1')?.dispatchEvent(evt);
    }
  });

  useEffect(() => {
    if (isMountedRef.current) {
      if (playerState.type === videoStates.TUB_ERROR) {
        const messageDisplay = videoPlayerError((playerState?.message as string) || '');
        errorMsgStore({
          type: 'AXIOS_ERROR',
          message: messageDisplay,
        });
      }
      if (playerState.type === videoStates.MODULE_READY) {
        const messageDisplay = videoPlayerError((playerState?.message as string) || '');
        errorMsgStore({
          type: 'AXIOS_ERROR',
          message: messageDisplay,
        });
      }
      if (playerState.type === videoStates.CONTENT_ERROR) {
        const messageDisplay = videoPlayerError((playerState?.message as string) || '');
        setContentEnded(true);
        errorMsgStore({
          type: 'AXIOS_ERROR',
          message: messageDisplay,
        });
      }
      if (!mediaId) {
        errorMsgStore({
          type: 'AXIOS_ERROR',
          message: languageStrings?.default?.cantPlayContentError,
        });
      }
    }
  }, [isMountedRef, playerState, mediaId]);

  useEffect(() => {
    if (isMountedRef && token?.indexOf('error') === -1 && token?.indexOf('pending') === -1) {
      setVideoToken(token);
    }
  }, [isMountedRef, token]);

  const handlePlayPauseClick = () => {
    if (topPlayer) {
      if (isPlaying) {
        topPlayer.pause();
      } else {
        topPlayer?.play();
      }
    }
  };

  const setUp = async () => {
    const media = {
      mediaId,
      env,
      appId: appConfig?.appConfig.video.appId,
      accessToken: videoToken,
      accessTokenType: AuthTokenType.JWS,
      companyId,
    };
    const playerElementID = 'player-container';
    const playerContainerDomElem = document.getElementById(playerElementID);
    let setupConfig = newSetupConfig(appConfig?.appConfig.video.appId ?? '', convivaConfig, autoPlay);
    setupConfig = {
      ...setupConfig,
      ui: {
        enabled: true,
      },
    };
    if (playerContainerDomElem) {
      const player = new Player(playerContainerDomElem, setupConfig);
      if (posterImage) {
        player.setPosterImage(posterImage, false);
      }
      await player.open({ mediaData: media, isLive: isLive });
      setTopPlayer(player);
      setContentEnded(false);
    }
  };

  useEffect(() => {
    if (isMountedRef.current && videoContainer && appConfig?.appConfig.video.appId && videoToken) {
      setUp();
    }
  }, [isMountedRef, videoContainer, appConfig?.appConfig?.video?.appId, videoToken]);

  const handleSeekValue = (index: number) => {
    let value = index;
    const newTime = (duration / 100) * value;

    // eslint-disable-next-line prefer-const
    let timeoutId: ReturnType<typeof setTimeout> | undefined;

    if (index < 0) {
      value = 1;
    }
    if (newTime > duration) {
      return;
    }

    // Clear the existing timeout, if any
    if (timeoutId) {
      clearTimeout(timeoutId);
    }

    timeoutId = setTimeout(() => {
      setSeekValue(value);
      topPlayer?.seekTo(newTime);
    }, 500);
  };

  const handleLiveClick = () => {
    topPlayer?.play();
  };

  const handleReplay = async () => {
    setTopPlayer(null);
    setContentEnded(false);
    setPlayerReady(false);
    setRefreshToken(true);
    setControlsVisibility(false);
  };

  return (
    <>
      {!playerReady && !contentEnded && (
        <div className="overlay-modal">
          <div className="loader"></div>
        </div>
      )}

      {contentEnded && !!topPlayer && (
        <div className="overlay-modal">
          <div onClick={() => handleReplay()} tabIndex={0} className="replay-modal">
            <button>
              <img src={ImageAssets.replay} alt="Replay" />
            </button>
          </div>
        </div>
      )}

      <div className="videoPlayer videoPlayerContain" style={containerStyle}>
        {useRatioSizing && <div style={ratioDivStyles}></div>}
        <div
          id="player-container"
          className="videoPlayer__video"
          ref={videoContainerRef}
          style={videoPlayerOverride ? videoPlayerOverride : playerStyles}
        ></div>
        {playerReady && (
          <PlayerControlsOverlay
            controlsVisible={controlsVisible}
            seekValue={seekValue}
            handleLiveClick={handleLiveClick}
            handlePlayPauseClick={handlePlayPauseClick}
            handleSeekValue={handleSeekValue}
            isPlaying={isPlaying}
            contentEnded={contentEnded}
            handleReplay={handleReplay}
            isPaused={isPaused}
            timer={timer}
            isLive={(topPlayer && topPlayer.isLive()) || false}
            duration={topPlayer?.getDuration() || 0}
            playerReady={playerReady}
          />
        )}
      </div>
    </>
  );
};

// Avoid unnecessary re-renders
export default React.memo(TvVideoPlayer);
