import { PinCodeStages } from './types';
import { ErrorPinLoginScreen } from './error';
import { LandingPinLoginScreen } from './landing';
import { PinLoginScreen } from './pin';

import React, { useCallback, useContext, useEffect, useState } from 'react';
import { ImageBackground, StyleSheet, Text, useWindowDimensions, View } from 'react-native';
import {
  BreakpointsSize,
  languageStrings,
  PagesUrl,
  QueryNames,
  ScreenEventType,
  useCMSQueryDataResults,
  useIsMountedRef,
  useMparticleScreenEvent,
  usePinAndPairActions,
  useScaleSizing,
} from '@warnermmedia/gsp-core/brands/estadio/feature';
import { getStyles } from './pinLoginUI.styles';
import { useReactiveVar } from '@apollo/client';
import {
  AppConfigContext,
  breakpointsStateStore,
  HistoryContext,
  Page,
} from '@warnermmedia/gsp-core/brands/estadio/data-access';
import { ChevronLeft, ImageAssets, NavbarWide } from '@warnermmedia/gsp-core/brands/estadio/assets';
import QrCodeModal from '../modal/qrcodeModal';
import { useGetDevice } from '@warnermmedia/gsp-core/sdk/ui';
import { CheckCodeResponse } from '@warnermmedia/gsp-core/sdk/data-access';
import { EstadioButton } from '@warnermmedia/gsp-core/brands/estadio/ui';
import { get } from 'lodash';
import { useTheme } from 'react-native-paper';

export const PinLoginUI = () => {
  const [stage, changeStage] = useState<PinCodeStages>(PinCodeStages.LANDING);
  const [registerModalVisible, setRegisterModalVisible] = useState(false);
  const [imageBackgroundUrl, setImageBackgroundUrl] = useState<string | null>(null);
  const { width, height } = useWindowDimensions();
  const scaling = useScaleSizing();
  const breakpoints = useReactiveVar(breakpointsStateStore);
  const { colors } = useTheme();
  const styles = getStyles(breakpoints, scaling, colors);
  const appConfig = useContext(AppConfigContext);
  const { device } = useGetDevice();
  const language = languageStrings.default;
  const webVerificationLink = appConfig?.appConfig?.webUrl ?? '';
  const { successPinLoginRedirect } = usePinAndPairActions();
  const historyContext = useContext(HistoryContext);
  const history = historyContext?.ready ? historyContext?.useHistory() : null;
  const { pageData: pageUriData } = useCMSQueryDataResults<Page>({
    queryName: QueryNames.GET_COMMON_PAGE_DATA,
    uri: PagesUrl.TvLanding,
  });
  const pageType =
    stage === PinCodeStages.LANDING
      ? ScreenEventType.TvLanding
      : stage === PinCodeStages.PIN_DISPLAY
      ? ScreenEventType.TvPinDisplay
      : ScreenEventType.TvPinError;
  const pageTitle =
    stage === PinCodeStages.LANDING
      ? languageStrings.default.pinPairLandingTitle
      : stage === PinCodeStages.PIN_DISPLAY
      ? languageStrings.default.pinPairTitle
      : languageStrings.default.pinPairErrorTitle;
  useMparticleScreenEvent(pageType, pageTitle);
  const isMountedRef = useIsMountedRef();

  useEffect(() => {
    if (pageUriData && isMountedRef.current) {
      const pageComponent = pageUriData?.components?.find((item) => item?.id === 'tv_landing_image');
      setImageBackgroundUrl(get(pageComponent, 'images[0].image.url', null));
    }
  }, [isMountedRef, pageUriData]);

  //This method will switch the current screen to the next/ defined stage
  const setStage = useCallback((stage: PinCodeStages) => {
    changeStage(stage);
  }, []);

  /* A callback function that is called when the user enters the wrong pin. */
  const errorCallback = useCallback(() => {
    setStage(PinCodeStages.ERROR);
  }, [setStage]);

  const onSuccessNavigation = useCallback(() => {
    history?.push('/home');
  }, [history]);

  /**
   * A callback function that is called when the user successfully logs in.
   * @param [cb] - a callback function that will be called after the user has successfully logged in.
   */
  const onCodeCheckSuccess = useCallback(
    (resp: CheckCodeResponse | undefined) => {
      successPinLoginRedirect(resp, onSuccessNavigation);
    },
    [successPinLoginRedirect, onSuccessNavigation]
  );

  /**
   * The function sets the register modal visibility to false when the user presses the OK button
   */
  const handleModalOkPress = () => {
    setRegisterModalVisible(false);
  };

  const onRegister = () => {
    setRegisterModalVisible(true);
  };

  /**
   * If the stage is PINDISPLAY, set the stage to LANDING. Otherwise, set the stage to LANDING
   */
  const onBackPress = () => {
    switch (stage) {
      case PinCodeStages.PIN_DISPLAY:
        setStage(PinCodeStages.LANDING);
        break;
      case PinCodeStages.ERROR:
        setStage(PinCodeStages.PIN_DISPLAY);
        break;
      default:
        setStage(PinCodeStages.LANDING);
    }
  };

  const stages = {
    [PinCodeStages.LANDING]: (
      <LandingPinLoginScreen
        onNext={() => {
          setStage(PinCodeStages.PIN_DISPLAY);
        }}
        onRegister={onRegister}
      />
    ),
    [PinCodeStages.PIN_DISPLAY]: (
      <PinLoginScreen
        onSuccess={onCodeCheckSuccess}
        onBack={onBackPress}
        onError={errorCallback}
        background={{ uri: imageBackgroundUrl ?? ImageAssets.pinLoginBackground }}
      />
    ),
    [PinCodeStages.ERROR]: <ErrorPinLoginScreen onRetry={onBackPress} />,
  };

  const showEstadioLogo = stage === PinCodeStages.PIN_DISPLAY;
  const blurRadius = showEstadioLogo ? 20 : 0;

  return (
    <ImageBackground
      source={{ uri: imageBackgroundUrl ?? ImageAssets.pinLoginBackground }}
      resizeMode={'cover'}
      style={{ width, height }}
      blurRadius={blurRadius}
    >
      <View style={styles.container}>
        {showEstadioLogo ? (
          <View style={styles.navbar}>
            <EstadioButton onPress={onBackPress} btnStyle={StyleSheet.flatten([styles.row, styles.transparent])}>
              <ChevronLeft style={styles.chevron} />
              <Text style={styles.navBarText}>{languageStrings.default.pinPairBack}</Text>
            </EstadioButton>
          </View>
        ) : (
          <NavbarWide
            width={scaling.scaleWidth(248.88, BreakpointsSize.Tv)}
            height={scaling.scaleHeight(56, BreakpointsSize.Tv)}
          />
        )}
        {stages[stage]}
      </View>
      <QrCodeModal
        visible={registerModalVisible}
        onChangeVisible={() => setRegisterModalVisible(!registerModalVisible)}
        title={language.registerQRTitle}
        description={language.registerModalDescription(webVerificationLink)}
        buttonLabel={language.registerQRButtonLabel}
        qrCodeString={webVerificationLink}
        onOkPress={handleModalOkPress}
        device={device}
      />
    </ImageBackground>
  );
};

export default PinLoginUI;
