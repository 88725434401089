import React, { FC } from 'react';
import Dialog from '@material-ui/core/Dialog';
import { withStyles } from '@material-ui/core/styles';
import DialogContentText from '@material-ui/core/DialogContentText';
import TrapFocus from '@material-ui/core/Unstable_TrapFocus';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { FocusTrap } from '@warnermedia/gsp-core/sdk/arcade-machine';
import { languageStrings } from '@warnermmedia/gsp-core/brands/estadio/feature';

import { createTheme, MuiThemeProvider } from '@material-ui/core';

const theme = createTheme({
  props: {
    MuiButtonBase: {
      disableRipple: true,
      autoFocus: true,
    },
  },
});

const useStyles = makeStyles({
  root: {
    backgroundColor: '#ff0085',
    border: 0,
    borderRadius: 4,
    height: 40,
    width: '80%',
    maxWidth: 455,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    '&:hover': { background: '#ff0085' },
  },
  buttonTypography: {
    fontFamily: 'Oswald-SemiBold',
    color: 'white',
    fontWeight: 'bold',
    fontSize: 20,
  },
  buttonWrapper: {
    width: '100%',
    padding: 0,
    display: 'block',
    marginBottom: 16,
  },
  rootTitle: {
    color: 'white',
  },
  rootTitleTypography: {
    fontSize: '25px',
    fontWeight: 'bold',
    textTransform: 'uppercase',
    fontFamily: 'Oswald-SemiBold',
  },
  contents: {
    color: 'white',
    fontSize: 16,
    fontFamily: 'Rubik-Regular',
  },
});

const ServiceWorkerWrapper: FC = () => {
  const classes = useStyles();

  const reloadPage = () => {
    window.location.reload();
  };

  const StyledDialog = withStyles({
    paper: {
      backgroundColor: '#000a5a',
      width: '100%',
      padding: 20,
      textAlign: 'center',
      alignItems: 'center',
    },
  })(Dialog);

  return (
    <MuiThemeProvider theme={theme}>
      <TrapFocus open={true} isEnabled={() => true} getDoc={() => document}>
        <StyledDialog open={true} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
          <DialogTitle className={classes.rootTitle} id="alert-dialog-title">
            <Typography className={classes.rootTitleTypography}>
              {languageStrings.default.updateAppModalTitle}
            </Typography>
          </DialogTitle>
          <DialogContent>
            <DialogContentText className={classes.contents} id="alert-dialog-description">
              {languageStrings.default.updateAppModalDescription}
            </DialogContentText>
          </DialogContent>
          <DialogActions className={classes.buttonWrapper}>
            <FocusTrap>
              <Button
                fullWidth={true}
                className={classes.root}
                autoFocus
                color="inherit"
                onClick={reloadPage}
                tabIndex={0}
              >
                <Typography className={classes.buttonTypography}>
                  {languageStrings.default.updateAppModalBtnText}
                </Typography>
              </Button>
            </FocusTrap>
          </DialogActions>
        </StyledDialog>
      </TrapFocus>
    </MuiThemeProvider>
  );
};

export default ServiceWorkerWrapper;
