import React from 'react';
import { View } from 'react-native';
import {
  CMSPageBuilder,
  LayoutType,
  LayoutWrapper,
  SkeletonDetailPage,
  TitleType,
} from '@warnermmedia/gsp-core/brands/estadio/ui';
import { useReactiveVar } from '@apollo/client';
import { PagesUrl, QueryNames, useCMSQueryDataResults, useRouter } from '@warnermmedia/gsp-core/brands/estadio/feature';
import { Page, breakpointsStateStore } from '@warnermmedia/gsp-core/brands/estadio/data-access';

import { getStyles } from './showDetails.styles';

type ShowDetailsParams = { id: string };

export const ShowDetails = () => {
  const breakpoints = useReactiveVar(breakpointsStateStore);
  const styles = getStyles(breakpoints);

  const params = useRouter().useParams<ShowDetailsParams>();
  const { pageData: pageUriData, loading } = useCMSQueryDataResults<Page>({
    queryName: QueryNames.GET_PROGRAM,
    uri: PagesUrl.Shows,
    showId: params.id,
  });
  const showHeadingsObj = pageUriData?.components?.find((item) => item && item?.type === 'screen');

  return (
    <View>
      {loading ? (
        <SkeletonDetailPage windowHeightStartTop={200} />
      ) : (
        <LayoutWrapper
          layoutType={LayoutType.Full}
          titleType={TitleType.Left}
          pageTitle={showHeadingsObj?.headline || ''}
          subTitle={showHeadingsObj?.description || ''}
          overrideChildrenWrapper={styles.layoutWrapperStyle}
          overrideTitleStyle={styles.title}
          overrideSubTitleStyle={styles.subtitle}
        >
          <CMSPageBuilder data={pageUriData} />
        </LayoutWrapper>
      )}
    </View>
  );
};

export default ShowDetails;
