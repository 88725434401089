import { useEffect } from 'react';
import { useGetDevice } from '@warnermmedia/gsp-core/sdk/ui';
import { useLocation } from 'react-router-dom';
import { useTheme } from 'react-native-paper';

export const useFocusType = () => {
  const { isTv, isPwa } = useGetDevice();
  const isHelpPage = useLocation().pathname === '/help';
  const { colors } = useTheme();

  useEffect(() => {
    const styleTag = isPwa && document.getElementById('styleFontsTag');
    const otherPlatformStyle = `:focus {outline: 2px solid ${colors.stroke.onDark.outline01}; box-shadow: none;}`;
    const generalStyle = `
    #main_nav #nav_item :focus {
      background-color: ${colors.stroke.onDark.outline01}; 
    } 
    #main_nav #nav_item :focus #navItemLabel {
      color: ${colors.fill.action.accent04}
    } 
  `;
    const focusStyle = `${generalStyle}
      :focus {
        outline: none;
        box-shadow:inset 0 0 0 4pt ${colors.stroke.onDark.outline01};
        -webkit-box-shadow: inset 0 0 0 4pt ${colors.stroke.onDark.outline01};
        -moz-box-shadow: inset 0 0 0 4pt ${colors.stroke.onDark.outline01};
      }
    `;

    const helpScreenStyle = `${generalStyle}
      :focus {
        outline: none;
        box-shadow:none;
        -webkit-box-shadow: none;
        -moz-box-shadow:none;
      }
    `;

    const focus = isTv ? focusStyle : otherPlatformStyle;
    const helpScreenFocus = isTv ? helpScreenStyle : otherPlatformStyle;

    if (styleTag && !isHelpPage) {
      styleTag.append(focus);
    }
    if (styleTag && isHelpPage) {
      styleTag.append(helpScreenFocus);
    }
  }, [isTv, isPwa, isHelpPage]);
};

export default useFocusType;
