import React, { memo, useCallback } from 'react';
import { Pressable, View } from 'react-native';
import { CardProps } from '@warnermmedia/gsp-core/sdk/ui';
import MatchCard from '../match/matchCard';
import GenericCard from '../generic/genericCard';
import VideoCard from '../video/videoCard';
import { getStyles } from './baseCard.styles';
import DpgHeader from '../dpgHeader/dpgHeader';
import { Hoverable } from 'react-native-web-hover';
import { carouselBase } from '@warnermmedia/gsp-core/brands/estadio/feature';

const BaseCard = ({
  item,
  index,
  currentIndex,
  laneWidth,
  action,
  type,
  separators,
  cardBg,
  device,
  colors,
  breakpoints,
}: CardProps<carouselBase>) => {
  const styles = getStyles(breakpoints, device.isTv);

  const largeScreenSize =
    device.isWeb &&
    (breakpoints.currentBreakpoints.isMedLg ||
      breakpoints.currentBreakpoints.isLgXl ||
      breakpoints.currentBreakpoints.isXlXxl);
  const mediumScreenSize =
    device.isWeb && (breakpoints.currentBreakpoints.isSmMed || breakpoints.currentBreakpoints.isTnySm);

  const transformValue = useCallback(() => {
    return largeScreenSize
      ? {
          transform: [{ scale: 1.1 }],
        }
      : mediumScreenSize
      ? {
          transform: [{ scale: 1.05 }],
        }
      : {};
  }, [largeScreenSize, mediumScreenSize]);

  const baseParams = useCallback(() => {
    return {
      currentIndex: currentIndex,
      laneWidth: laneWidth,
      action: action,
      item: item,
      index: index,
      separators: separators,
      type,
      cardBg,
      colors,
      device,
      breakpoints,
    } as CardProps<carouselBase>;
  }, [currentIndex, laneWidth, action, index, item, type, separators, cardBg, colors, device, breakpoints]);

  const cardType = useCallback((item: carouselBase, baseParams: CardProps<carouselBase>) => {
    return ['video', 'manual', 'epg'].includes(item.type) ? (
      <VideoCard {...baseParams} />
    ) : item.type === 'match' ? (
      <MatchCard {...baseParams} />
    ) : (
      <GenericCard {...baseParams} />
    );
  }, []);

  const handlePress = useCallback(() => {
    return action ? action(item) : null;
  }, [action, item]);

  const getCardMarginTop = useCallback(
    (type: string | undefined) => {
      const isDpgAndEpg = type === 'dpg' || type === 'epg';
      if (isDpgAndEpg && device.isTv) {
        return 4;
      } else if (isDpgAndEpg && !device.isTv) {
        return 0;
      } else if (!isDpgAndEpg && !device.isTv) {
        return 4;
      } else return 9;
    },
    [device.isTv]
  );

  const renderBaseCards = useCallback(
    (hovered: boolean) => {
      const transformValueResult = hovered ? transformValue() : {};
      const params = baseParams();
      return (
        <View style={[styles.slide, transformValueResult, { marginTop: getCardMarginTop(type) }]}>
          {(type === 'dpg' || type === 'epg') && (
            <DpgHeader
              currentIndex={currentIndex}
              laneWidth={laneWidth}
              action={action}
              item={item}
              index={index}
              separators={separators}
              device={device}
              colors={colors}
              breakpoints={breakpoints}
            />
          )}
          <View style={styles.content}>{cardType(item, params)}</View>
        </View>
      );
    },
    [
      transformValue,
      styles,
      currentIndex,
      laneWidth,
      action,
      index,
      item,
      separators,
      cardType,
      type,
      baseParams,
      getCardMarginTop,
      breakpoints,
      colors,
      device,
    ]
  );

  const renderHoveredBaseCards = useCallback(() => {
    return <Hoverable>{({ hovered }) => renderBaseCards(hovered)}</Hoverable>;
  }, [renderBaseCards]);

  return (
    <Pressable style={styles.slideLayout} onPress={handlePress}>
      {renderHoveredBaseCards()}
    </Pressable>
  );
};

export default memo(BaseCard);
