import React from 'react';
import { Image, ImageBackground, View } from 'react-native';
import { Maybe } from '@warnermmedia/gsp-core/brands/estadio/data-access';
import { Breakpoints, DeviceTypes, PressableWithOpacity, TextContent } from '@warnermmedia/gsp-core/sdk/ui';
import { getStyles } from './teamCard.styles';
import {
  CardDefaultBackground,
  carouselBase,
  getImageWithStyle,
  useCardItemImage,
} from '@warnermmedia/gsp-core/brands/estadio/feature';

interface TeamsCardProps {
  item: carouselBase;
  type: Maybe<string>;
  index: number;
  onPress?: (item: carouselBase | null) => void | null;
  breakpoints: Breakpoints;
  device: DeviceTypes;
  colors: ReactNativePaper.ThemeColors;
}

export const TeamCard = ({ item, onPress, breakpoints, colors, device }: TeamsCardProps) => {
  const styles = getStyles(breakpoints, device.isTv, colors);
  const { getCardImageSource } = useCardItemImage();
  const teamLogo = getCardImageSource({
    fallbackName: CardDefaultBackground.Team,
    image: item?.image ? getImageWithStyle(item?.image) : null,
  });

  return (
    <PressableWithOpacity onPress={() => (onPress ? onPress(item) : null)} style={styles.teamCardLayout}>
      <View style={styles.wrapper}>
        <ImageBackground source={teamLogo} style={styles.background}>
          <View style={styles.overlay} />
          <Image source={teamLogo} style={styles.teamLogoStyle} />
        </ImageBackground>
        <TextContent style={styles.titleStyle}>{item?.title || ''}</TextContent>
      </View>
    </PressableWithOpacity>
  );
};

export default TeamCard;
