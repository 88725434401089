import { useReactiveVar } from '@apollo/client';
import {
  HistoryContext,
  isUserEmailVerified,
  isUserLoggedIn,
  loginAPIStateStore,
} from '@warnermmedia/gsp-core/brands/estadio/data-access';
import { useCallback, useContext, useEffect, useState } from 'react';
import { Dalton } from '../dalton';
import {
  getUrlParam,
  languageStrings,
  useIsMountedRef,
  useMparticleCustomEventObject,
} from '@warnermmedia/gsp-core/brands/estadio/feature';
import {
  CALLED_USER_EMAIL_VALIDATION,
  EMAIL_VERIFICATION_STATUS,
  loadData,
  MParticleCustomEventTypes,
  mParticleEventProcessor,
  saveData,
  USER_EMAIL_STATUS,
  UserEmailResponsesEntity,
} from '@warnermmedia/gsp-core/sdk/data-access';

type VerifiedRouteType = {
  pathname: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  state?: any;
};

export const useValidateEmailCode = () => {
  const dalton = Dalton();
  const historyContext = useContext(HistoryContext);
  const history = historyContext?.ready ? historyContext?.useHistory() : null;
  const isMountedRef = useIsMountedRef();
  const verificationCode = history ? getUrlParam('verifyEmail', history.location.search) : '';
  const isLoggedIn = useReactiveVar(isUserLoggedIn);
  const isEmailVerified = useReactiveVar(isUserEmailVerified);
  const { user, checkUserEmail } = useReactiveVar(loginAPIStateStore);
  const [verifiedEmailCode, setVerifiedEmailCode] = useState<string>(verificationCode);
  const [verifiedRoute, setVerifiedRoute] = useState<VerifiedRouteType | null>(null);
  const [verifyingEmailCode, setVerifyingEmailCode] = useState(isLoggedIn && !!verifiedEmailCode && !isEmailVerified);
  const mParticleEventData = useMparticleCustomEventObject();

  const handleConfirmVerificationCode = useCallback(async (): Promise<void> => {
    saveData(CALLED_USER_EMAIL_VALIDATION, 'true');
    mParticleEventProcessor.pushMParticleEvent(MParticleCustomEventTypes.EmailValidationStartEvent, mParticleEventData);
    await dalton.confirmVerificationCode(
      verifiedEmailCode,
      () => {
        setVerifiedRoute({
          pathname: '/resend-email',
          state: {
            verificationMessage: languageStrings.default.emailVericationSuccessRouteMessage,
            emailStatus: EMAIL_VERIFICATION_STATUS.CONFIRMED,
          },
        });
        mParticleEventProcessor.pushMParticleEvent(
          MParticleCustomEventTypes.EmailValidationCompleteEvent,
          mParticleEventData
        );
      },
      (error) => {
        const errorMessage = error?.message ?? '';
        const resendRouteState = errorMessage.toLowerCase().includes('expired')
          ? {
              verificationMessage: languageStrings.default.verificationMessageTokenExpired,
            }
          : {};
        setVerifiedRoute({
          pathname: '/resend-email',
          state: resendRouteState,
        });
      }
    );
  }, [dalton, setVerifiedRoute, verifiedEmailCode, mParticleEventData]);

  useEffect(() => {
    (async (): Promise<void> => {
      const called = loadData(CALLED_USER_EMAIL_VALIDATION);
      if (
        isMountedRef.current &&
        isLoggedIn &&
        !!verifiedEmailCode &&
        !isEmailVerified &&
        checkUserEmail &&
        !!user &&
        !called
      ) {
        setVerifyingEmailCode(true);
        let emailStatus = loadData(USER_EMAIL_STATUS);
        if (!emailStatus) {
          const primaryEmail: UserEmailResponsesEntity | undefined = user.userEmailResponses?.find(
            (email: UserEmailResponsesEntity) => email.primary === true
          );
          emailStatus = primaryEmail?.status;
        }
        if (emailStatus === EMAIL_VERIFICATION_STATUS.NEW) {
          await handleConfirmVerificationCode();
        }
        saveData(CALLED_USER_EMAIL_VALIDATION, 'true');
        setVerifiedEmailCode('');
        setVerifyingEmailCode(false);
      }
    })();
  }, [
    user,
    isLoggedIn,
    isMountedRef,
    checkUserEmail,
    isEmailVerified,
    verifiedEmailCode,
    handleConfirmVerificationCode,
  ]);

  useEffect(() => {
    if (isMountedRef.current && verifiedRoute?.pathname) {
      setVerifiedEmailCode('');
      setVerifyingEmailCode(false);
      history?.push(verifiedRoute);
    }
  }, [isMountedRef, verifiedRoute, history]);

  return { verifyingEmailCode, verifiedRoute, verifiedEmailCode };
};

export default useValidateEmailCode;
