import { Breakpoints, Carousel, CarouselPropsExtra, DeviceTypes } from '@warnermmedia/gsp-core/sdk/ui';
import { TickerCard } from '../../cards/ticker/tickerCard';
import { DirectionalArrow } from '../directionalArrow/directionalArrow';
import React, { useCallback } from 'react';
import { ListRenderItemInfo, View } from 'react-native';
import { carouselBase, useHideSwimlaneArrow } from '@warnermmedia/gsp-core/brands/estadio/feature';
import { getStyles } from './ticker.styles';

export interface TickerProps extends CarouselPropsExtra<carouselBase> {
  /**
   * Array of carousel items
   */
  data: carouselBase[];
  tickerPerScreen: number;
  tickerWidth: number;
  breakpoints: Breakpoints;
  device: DeviceTypes;
  colors: ReactNativePaper.ThemeColors;
}

export const TickerTile = ({
  index: slideIndex,
  laneWidth,
  indexUpdate,
  data,
  action,
  tickerPerScreen,
  tickerWidth,
  device,
  colors,
  breakpoints,
}: TickerProps) => {
  const styles = getStyles(breakpoints, device.isTv);
  const tickerPadding = breakpoints.breakpointHelpers.isMobileScreen ? 10 : 25;
  const dataLength = data.length;
  const { hideRightArrow } = useHideSwimlaneArrow(tickerPerScreen, slideIndex, dataLength);

  const tickerSlide = useCallback(
    (slideProps: ListRenderItemInfo<carouselBase>) => (
      <TickerCard
        currentIndex={slideIndex}
        laneWidth={laneWidth}
        action={action}
        device={device}
        colors={colors}
        breakpoints={breakpoints}
        {...slideProps}
      />
    ),
    [slideIndex, laneWidth, action, breakpoints, colors, device]
  );

  return (
    <View style={{ paddingLeft: tickerPadding, backgroundColor: colors.tenantBackground.dark.surfaceBase }}>
      {slideIndex !== 0 && dataLength > 0 && slideIndex in data && (
        <DirectionalArrow
          dataLength={dataLength}
          index={slideIndex}
          indexUpdate={indexUpdate}
          isLeftPoint={true}
          device={device}
          colors={colors}
          breakpoints={breakpoints}
          includeGrad={true}
          overrideStyle={styles.backArrow}
          gradStyle={styles.backArrowGrad}
        />
      )}
      <Carousel
        index={slideIndex}
        indexUpdate={indexUpdate}
        data={data}
        renderItem={tickerSlide}
        laneWidth={laneWidth}
        itemWidth={tickerWidth}
        action={action}
        containerStyle={{}}
        initialScrollIndex={slideIndex}
        contentContainerStyle={styles.carouselStyle}
        onEndReachedThreshold={0.01}
        snapToOffsets={data?.map((x, index) => index * tickerWidth)}
        itemWidthMarginAllowance={1}
        device={device}
      />
      {!hideRightArrow && (
        <DirectionalArrow
          dataLength={dataLength}
          index={slideIndex}
          indexUpdate={indexUpdate}
          isLeftPoint={false}
          device={device}
          colors={colors}
          breakpoints={breakpoints}
          includeGrad={true}
          overrideStyle={styles.forwardArrow}
          gradStyle={styles.forwardArrowGrad}
        />
      )}
    </View>
  );
};

export default TickerTile;
