import React, { memo } from 'react';
import { Image, ImageBackground, Pressable, Text, View } from 'react-native';
import { CardProps } from '@warnermmedia/gsp-core/sdk/ui';
import { getStyles } from './genericCard.styles';
import {
  CardDefaultBackground,
  carouselBase,
  getImageWithStyle,
  useCardItemImage,
} from '@warnermmedia/gsp-core/brands/estadio/feature';

const GenericCard = ({
  item,
  index,
  currentIndex,
  action,
  type,
  cardBg,
  breakpoints,
  colors,
  device,
}: CardProps<carouselBase>) => {
  const styles = getStyles(breakpoints, device.isTv, colors);
  const { getCardImageSource } = useCardItemImage();
  const itemImage = item?.image ? getImageWithStyle(item.image ?? '') : null;
  const image = getCardImageSource({ fallbackName: CardDefaultBackground.Tournament, image: itemImage });
  const background = getCardImageSource({
    fallbackName: cardBg ? cardBg : CardDefaultBackground.TournamentBg,
    image: type === 'tournaments' ? getImageWithStyle(item.background) : itemImage,
  });

  return (
    <Pressable onPress={() => (action ? action(item) : null)} style={styles.cardLayout}>
      <View style={styles.cardContainer}>
        <ImageBackground source={background} style={styles.genericTop} imageStyle={styles.slideBgImageStyle}>
          {type === 'tournaments' && image ? (
            <Image style={styles.genericImage} source={image} resizeMethod="auto" resizeMode="contain" />
          ) : null}
        </ImageBackground>
        <View style={[styles.slideBottom, index === currentIndex && styles.slideBottomActive]}>
          <Text style={styles.slideBottomTitle}>{item.title}</Text>
        </View>
      </View>
    </Pressable>
  );
};

export default memo(GenericCard);
