import { StyleSheet, ViewStyle, StyleProp, TextStyle } from 'react-native';
import { Breakpoints } from '@warnermmedia/gsp-core/sdk/ui';
import merge from 'lodash/merge';

interface Styles {
  container: ViewStyle;
  personalInfoContain: ViewStyle;
  subscriptionDetailsContain: ViewStyle;
  subContainer: ViewStyle;
  info: TextStyle;
  label: TextStyle;
  title: TextStyle;
  bottomContainer: ViewStyle;
  btnWrapper: ViewStyle;
  viewWrapper: ViewStyle;
  textBtn: TextStyle;
  subscriptionWrapper: ViewStyle;
  subscriptionContent: ViewStyle;
  subTitleStyle: TextStyle;
  profileLink: TextStyle;
  messageDisplay: ViewStyle;
  displayIcon: ViewStyle;
  messageDisplayText: TextStyle;
  couponBtn: ViewStyle;
}

export const getStyles = (breakpoints: Breakpoints, colors: ReactNativePaper.ThemeColors) => {
  const base: StyleProp<Styles> = {
    container: {
      flex: 1,
      width: '100%',
      paddingLeft: 4,
    },
    personalInfoContain: {},
    subscriptionDetailsContain: {},
    subscriptionWrapper: {
      width: '100%',
      alignSelf: 'center',
    },
    subscriptionContent: {},
    subContainer: {
      borderBottomColor: 'rgba(255, 255, 255, .2)',
      borderBottomWidth: 2,
      paddingBottom: 24,
    },
    title: {
      color: colors.keyboardFocus.light,
      fontSize: 32,
      fontFamily: 'Oswald-SemiBold',
      fontWeight: '600',
      textTransform: 'uppercase',
    },
    subTitleStyle: {
      color: colors.keyboardFocus.light,
      fontSize: 22,
      fontFamily: 'Oswald-SemiBold',
      fontWeight: '600',
      textTransform: 'uppercase',
    },
    label: {
      color: colors.keyboardFocus.light,
      fontFamily: 'Rubik-Medium',
      fontSize: 16,
      fontWeight: '500',
      lineHeight: 20,
      height: 20,
      width: 300,
      marginVertical: 10,
    },
    bottomContainer: {
      width: '100%',
      alignSelf: 'center',
    },
    btnWrapper: { width: 300, marginVertical: 20 },
    viewWrapper: {
      marginVertical: 8,
      flex: 1,
    },
    info: {
      color: colors.keyboardFocus.light,
      fontFamily: 'Rubik-Regular',
      fontSize: 20,
      lineHeight: 24,
      marginVertical: 8,
      fontWeight: 'normal',
      width: '100%',
      maxWidth: 600,
    },
    textBtn: {
      textDecorationLine: 'underline',
      textTransform: 'uppercase',
      marginVertical: 12,
      fontFamily: 'Oswald-Regular',
      fontSize: 20,
      fontWeight: 'normal',
      alignSelf: 'flex-start',
    },
    profileLink: {
      fontWeight: '600',
      fontFamily: 'Rubik-Bold',
    },
    messageDisplay: {
      paddingBottom: 20,
    },
    messageDisplayText: {
      paddingTop: 8,
    },
    displayIcon: {
      margin: 0,
      padding: 0,
      alignSelf: 'baseline',
    },
    couponBtn: {
      alignSelf: 'flex-start',
    },
  };

  const mediaQueries =
    breakpoints.currentBreakpoints.isTiny ||
    breakpoints.currentBreakpoints.isTnySm ||
    breakpoints.currentBreakpoints.isSmMed
      ? ({
          btnWrapper: { width: '100%', marginVertical: 5 },
        } as StyleProp<Styles>)
      : breakpoints.currentBreakpoints.isMedLg
      ? ({
          subscriptionContent: {},
        } as StyleProp<Styles>)
      : breakpoints.currentBreakpoints.isLgXl ||
        breakpoints.currentBreakpoints.isXlXxl ||
        breakpoints.currentBreakpoints.isXxl
      ? ({
          subscriptionContent: {},
        } as StyleProp<Styles>)
      : {};

  const mergedStyles = merge(base, mediaQueries);

  return StyleSheet.create(mergedStyles);
};
