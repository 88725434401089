import React, { useCallback, useContext } from 'react';
import { View } from 'react-native';
import { HistoryContext } from '@warnermmedia/gsp-core/brands/estadio/data-access';
import { carouselBase, carouselFormat, useCardFilters } from '@warnermmedia/gsp-core/brands/estadio/feature';
import { FocusArea } from '@warnermedia/gsp-core/sdk/arcade-machine';
import CmsPageSwimlane from './cmsPageSwimlane';
import { HeroWrapper } from '../../swimlanes/hero';
import { useGetDevice } from '@warnermmedia/gsp-core/sdk/ui';

export const CmsCarousel: React.FC<carouselFormat> = (props) => {
  const historyContext = useContext(HistoryContext);
  const history = historyContext?.ready ? historyContext?.useHistory() : null;
  const filteredItems = useCardFilters(props.allItems);
  const { isPwa } = useGetDevice();

  const CarouselComponent = props.format === 'hero' ? HeroWrapper : CmsPageSwimlane;

  const clickAction = useCallback(
    (item: carouselBase) => {
      if (typeof item === 'undefined') {
        return;
      }
      let route: string | null = null;
      if (item.type === 'match' && item.status === 3 && item.match?.eventId) {
        route = `/video/match/${item.match?.eventId}`;
      } else if (item.type === 'match' && item.match?.eventId) {
        route = `/match/${item.match?.eventId}`;
      } else if (item.type === 'manual' && item.status === 3 && !!item.id) {
        // stand-alone/manual events only link if they are live
        route = `/video/manual/${item.id}`;
      } else if (item.type === 'video' && !!item.key) {
        route = `/video/media/${item.key}`;
      } else if (item.type === 'epg' && !!item.key && !!item.status) {
        if ([1, 2, 4].includes(item.status)) {
          // Pre, upcoming, finished go to show details
          route = `/show/${item.key}`;
        } else if (item.status === 3) {
          // Live go to senal tv
          route = `/tvsignal`;
        }
      }
      route && history?.push(route);
    },
    [history]
  );

  return (
    <View>
      {isPwa ? (
        <FocusArea>
          <CarouselComponent {...props} clickAction={clickAction} allItems={filteredItems} />
        </FocusArea>
      ) : (
        <CarouselComponent {...props} clickAction={clickAction} allItems={filteredItems} />
      )}
    </View>
  );
};

export default CmsCarousel;
