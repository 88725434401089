import React, { useState, useEffect } from 'react';
import { TextStyle, View, ViewStyle } from 'react-native';
import { TextContent } from '../../textcontent/index';
import styles from './radioButtonGroup.styles';
import { RadioButton } from '../radioButton';

export interface RadioBtnGroupProps {
  onValueChange: (name: string, value: string) => void;
  value: string;
  options: string[];
  overrideStyle?: ViewStyle;
  overrideLabelStyle?: TextStyle;
  name: string;
  checkedColor: string;
  uncheckedColor: string;
  disabled?: boolean;
}

export const RadioButtonGroup = ({
  onValueChange,
  value,
  options,
  overrideStyle,
  overrideLabelStyle,
  name,
  checkedColor,
  uncheckedColor,
  disabled = false,
}: RadioBtnGroupProps) => {
  const [radioValue, setValue] = useState(value);

  useEffect(() => {
    setValue(value);
  }, [value]);

  const handleValueChange = (value: string) => {
    setValue(value);
    onValueChange(value, name);
  };

  return (
    <View style={[overrideStyle]}>
      {options.map((item) => (
        <View style={styles.radioBtnWrapper} key={item}>
          <RadioButton
            value={item}
            borderColor="#FFF"
            checkedColor={checkedColor}
            uncheckedColor={uncheckedColor}
            status={item === radioValue ? 'checked' : 'unchecked'}
            onPress={() => handleValueChange(item)}
            disabled={disabled}
          />
          <TextContent style={[styles.label, overrideLabelStyle]}>{item}</TextContent>
        </View>
      ))}
    </View>
  );
};

export default RadioButtonGroup;
