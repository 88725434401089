import { StyleProp, ViewStyle, StyleSheet, TextStyle } from 'react-native';
import merge from 'lodash/merge';
import { Breakpoints } from '@warnermmedia/gsp-core/sdk/ui';

interface Styles {
  container: ViewStyle;
  linkContainer: ViewStyle;
  itemStyleNoBorder: ViewStyle;
  itemTitleStyle: TextStyle;
  stadium: TextStyle;
  version: TextStyle;
  iconsContainer: ViewStyle;
  goToLinkContainer: ViewStyle;
  gotoIcon: TextStyle;
  openInNew: ViewStyle;
  gotoIconSelf: ViewStyle;
  icon: TextStyle;
  titleStyles: TextStyle;
  footerBottom: TextStyle;
  socialIconList: ViewStyle;
}

export const getStyles = (breakpoints: Breakpoints, colors: ReactNativePaper.ThemeColors) => {
  const base: StyleProp<Styles> = {
    container: {
      backgroundColor: colors.tenantBackground.light.surfaceBase,
      paddingBottom: 60,
    },
    linkContainer: {
      alignItems: 'flex-end',
      justifyContent: 'flex-end',
      flexWrap: 'wrap',
      borderWidth: 1,
      borderColor: 'transparent',
    },
    itemStyleNoBorder: {
      borderLeftWidth: 0,
    },
    itemTitleStyle: {
      alignSelf: 'center',
      color: colors.tenantBackground.dark.surface03,
      fontSize: 12,
      fontFamily: 'Rubik-Regular',
      fontWeight: '700',
      textTransform: 'uppercase',
      height: 'auto',
      width: 'auto',
      minWidth: 0,
      paddingHorizontal: 8,
      marginVertical: 2,
    },
    stadium: {
      color: colors.tenantBackground.dark.surface03,
      fontFamily: 'Rubik-Bold',
      fontSize: 11,
      fontWeight: '700',
      textTransform: 'uppercase',
    },
    version: {
      color: colors.tenantBackground.dark.surface03,
      fontFamily: 'Rubik-Regular',
      fontSize: 9,
      fontWeight: '400',
      paddingBottom: 15,
      marginTop: 5,
    },
    iconsContainer: {
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'row',
    },
    goToLinkContainer: {},
    gotoIcon: {
      backgroundColor: colors.tenantBackground.dark.surface02,
      width: 20,
      height: 20,
      padding: 5,
      paddingLeft: 6,
      borderRadius: 50,
      color: colors.tenantBackground.light.surfaceBase,
      justifyContent: 'center',
      alignItems: 'center',
    },
    openInNew: {
      width: '100%',
    },
    gotoIconSelf: {},
    icon: {
      marginTop: 1,
    },
    titleStyles: {
      fontFamily: 'Oswald-Bold',
      fontWeight: 'bold',
      fontSize: 12,
      textTransform: 'uppercase',
      color: colors.tenantBackground.dark.surface02,
    },
    footerBottom: {
      color: colors.tenantBackground.dark.surface02,
      fontSize: 12,
      textTransform: 'uppercase',
      fontFamily: 'Oswald-Bold',
      marginTop: 15,
      marginBottom: 10,
    },
    socialIconList: {},
  };

  const mediaQueries =
    breakpoints.currentBreakpoints.isTiny ||
    breakpoints.currentBreakpoints.isTnySm ||
    breakpoints.currentBreakpoints.isSmMed
      ? ({
          gotoIconSelf: {
            alignSelf: 'center',
          },
          linkContainer: {
            flexDirection: 'column',
            alignItems: 'flex-end',
          },
          itemTitleStyle: {
            borderLeftWidth: 0,
            marginVertical: 10,
          },
          footerBottom: {
            width: 200,
            alignSelf: 'center',
            textAlign: 'center',
          },
          openInNew: {
            alignItems: 'center',
          },
        } as StyleProp<Styles>)
      : breakpoints.currentBreakpoints.isMedLg || breakpoints.windowWidth === breakpoints.breakpointSizes.lg
      ? ({
          gotoIconSelf: {
            alignSelf: 'center',
          },
          linkContainer: {
            flexDirection: 'column',
          },
          itemTitleStyle: {
            borderLeftWidth: 0,
            marginVertical: 10,
          },
          footerBottom: {
            width: 200,
            alignSelf: 'center',
            textAlign: 'center',
          },
          socialIconList: {
            marginLeft: 3,
            marginRight: 3,
          },
        } as StyleProp<Styles>)
      : breakpoints.currentBreakpoints.isLgXl ||
        breakpoints.currentBreakpoints.isXlXxl ||
        breakpoints.currentBreakpoints.isXxl
      ? ({
          gotoIconSelf: {
            alignSelf: 'flex-start',
            marginLeft: 125,
          },
          linkContainer: {
            flexDirection: 'row',
          },
          iconsContainer: {
            justifyContent: 'flex-start',
            marginLeft: '5%',
          },
          openInNew: {
            width: '100%',
          },
          footerBottom: {
            alignItems: 'flex-end',
            marginRight: 5,
            marginTop: 5,
            height: 10,
          },
          itemTitleStyle: {
            borderLeftColor: colors.tenantBackground.dark.surface03,
            borderLeftWidth: 2,
          },
          version: {
            paddingBottom: 0,
          },
          goToLinkContainer: {
            width: '30%',
            alignItems: 'flex-start',
          },
          socialIconList: {
            marginLeft: 2,
            marginRight: 2,
          },
        } as StyleProp<Styles>)
      : {};

  const mergedStyles = merge(base, mediaQueries);

  return StyleSheet.create(mergedStyles);
};
