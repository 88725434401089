import {
  carouselBase,
  QueryNames,
  useCMSQueryDataResults,
  useFormatCarouselData,
  useIsMountedRef,
} from '@warnermmedia/gsp-core/brands/estadio/feature';
import React, { useEffect, useState } from 'react';
import { View } from 'react-native';
import { CmsCarousel } from '../cmsPage';
import { Video } from '@warnermmedia/gsp-core/brands/estadio/data-access';

export type LatestClipsSwimlaneProps = React.ComponentPropsWithRef<typeof View> & {
  /**
   * Team id on sports Radar
   */
  sportsRadarId: string;
};

const LatestClipsSwimlane = ({ sportsRadarId }: LatestClipsSwimlaneProps) => {
  const [data, setData] = useState<carouselBase[]>();
  const { pageData: latestClips } = useCMSQueryDataResults<Video[]>({
    queryName: QueryNames.GET_LATEST_CLIPS,
    id: sportsRadarId,
  });

  const isMountedRef = useIsMountedRef();
  const { formatVideos } = useFormatCarouselData();

  useEffect(() => {
    if (latestClips && isMountedRef.current) {
      setData(formatVideos(latestClips));
    }
  }, [latestClips, isMountedRef, formatVideos]);

  return (
    <View>
      {latestClips && latestClips.length ? (
        <CmsCarousel
          id="latest-videos"
          type="video"
          format="generic"
          content={[]}
          allItems={data}
          headline="ÚLTIMAS NOTICIAS Y CLIPS"
          weight={1}
        />
      ) : null}
    </View>
  );
};

export default LatestClipsSwimlane;
