import React, { useEffect, useRef, useState } from 'react';
import {
  Image,
  ImageStyle,
  NativeSyntheticEvent,
  Pressable,
  Text,
  TextInput,
  TextInputKeyPressEventData,
  TextInputProps,
  TextStyle,
  View,
  ViewStyle,
} from 'react-native';
import styles from './textInput.style';
import { useGetDevice } from '@warnermmedia/gsp-core/sdk/ui';

export interface Props extends TextInputProps {
  inputStyle?: TextStyle | TextStyle[];
  icon?: string | JSX.Element;
  iconStyle?: ImageStyle;
  containerStyle?: ViewStyle | ViewStyle[];
  iconPosition?: 'left' | 'right';
  handleIconClick?: () => void;
  error?: boolean;
  onBlur?: () => void;
  onKeyPress?: (e: NativeSyntheticEvent<TextInputKeyPressEventData>) => void;
  inputLabel?: string | JSX.Element | React.ReactNode;
  inputLabelStyle?: TextStyle;
  shouldFocus?: boolean;
}

export const CustomTextInput = ({
  inputStyle,
  icon,
  iconStyle,
  containerStyle,
  iconPosition,
  handleIconClick,
  error,
  onBlur,
  onKeyPress,
  inputLabel,
  inputLabelStyle,
  shouldFocus = false,
  focusable = true,
  ...props
}: Props) => {
  const [isFocused, setIsFocused] = useState(false);
  const { isTv, isWeb } = useGetDevice();
  const iconIsLeft = iconPosition === 'left';
  const iconPositionStyle = iconIsLeft ? styles.leftIcon : styles.rightIcon;
  let inputWithIconStyle = {};
  const errorStyle = isFocused ? styles.focused : error ? styles.error : {};
  if (icon) {
    inputWithIconStyle = iconIsLeft ? styles.inputWithLeftIcon : styles.inputWithRightIcon;
  }
  const textRef = useRef<TextInput>(null);

  useEffect(() => {
    if (textRef.current && shouldFocus) {
      textRef.current.focus();
      setIsFocused(true);
    }
  }, [textRef, shouldFocus]);

  const renderIcon = () => (
    <Pressable
      onPress={() => {
        handleIconClick && handleIconClick();
      }}
      style={[styles.iconWrapper, iconPositionStyle]}
    >
      {typeof icon === 'string' ? (
        <Image source={{ uri: icon }} style={[styles.icon, iconStyle]} />
      ) : (
        <View style={[styles.svgIcon, iconStyle]}>{icon}</View>
      )}
    </Pressable>
  );

  const content = typeof inputLabel === 'string' ? <Text style={inputLabelStyle}>{inputLabel}</Text> : inputLabel;

  return (
    <View style={[styles.container, containerStyle]}>
      {inputLabel ? content : null}
      <TextInput
        ref={textRef}
        style={[
          styles.input,
          inputWithIconStyle,
          inputStyle,
          errorStyle,
          focusable && isFocused && !isTv && { borderColor: '#359fd7', borderWidth: 2 },
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          /* @ts-ignore: properties for correct outlining. */
          focusable && isFocused && isWeb ? { outline: '2px solid #359fd7', opacity: 1 } : {},
        ]}
        onFocus={() => setIsFocused(true)}
        onBlur={() => {
          setIsFocused(false);
          onBlur && onBlur();
        }}
        onKeyPress={onKeyPress}
        focusable={focusable}
        {...props}
      />
      {icon && renderIcon()}
    </View>
  );
};

export default CustomTextInput;
