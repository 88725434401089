import React, { useContext } from 'react';
import { View } from 'react-native';
import qs from 'qs';
import { useLocation } from 'react-router';
import { getStyles } from './forgotPassword.styles';
import { forgotPasswordContext, Pages } from './forgotPasswordContext';
import { PasswordRecovery } from './passwordRecovery/passwordRecovery';
import { ResetPassword } from './resetPassword/resetPassword';
import { HistoryContext, Page, breakpointsStateStore } from '@warnermmedia/gsp-core/brands/estadio/data-access';
import {
  PagesUrl,
  QueryNames,
  useCMSPageMetaTags,
  useCMSQueryDataResults,
  useMparticleScreenEvent,
} from '@warnermmedia/gsp-core/brands/estadio/feature';
import { MetaTags, useGetDevice } from '@warnermmedia/gsp-core/sdk/ui';
import { useReactiveVar } from '@apollo/client';
import { useTheme } from 'react-native-paper';

export interface PasswordResetProps {
  currentPage: Pages;
}

export function ForgotPassword({ currentPage }: PasswordResetProps) {
  const location = useLocation();
  const { isWeb } = useGetDevice();
  const breakpoints = useReactiveVar(breakpointsStateStore);
  const { colors } = useTheme();
  const styles = getStyles(breakpoints, colors);
  const params = qs.parse(location.search.slice(1));
  const { token, validationCode } = params;
  const historyContext = useContext(HistoryContext);
  const history = historyContext?.ready ? historyContext?.useHistory() : null;
  const { pageData: pageUriData } = useCMSQueryDataResults<Page>({
    queryName: QueryNames.GET_COMMON_PAGE_DATA,
    uri: PagesUrl.ForgotPassword,
  });
  const { metaTags, title, type, cmsId } = useCMSPageMetaTags(pageUriData);
  useMparticleScreenEvent(type, title, cmsId);

  function handleSuccess() {
    // TODO: Review why this wasn't doing anything
    history?.push('/login');
  }

  function getCurrentScreen(currentPage: Pages) {
    const components = {
      1: <PasswordRecovery validationCode={validationCode ? String(validationCode) : ''} />,
      2: <ResetPassword userEmail={String(params?.email)} resetToken={token ? String(token) : ''} />,
    };
    return components[currentPage];
  }

  return (
    <View style={styles.container}>
      {isWeb && <MetaTags data={metaTags} title={title} />}
      <forgotPasswordContext.Provider
        value={{ handleEmailSuccess: handleSuccess, handlePasswordSuccess: handleSuccess }}
      >
        {getCurrentScreen(currentPage)}
      </forgotPasswordContext.Provider>
    </View>
  );
}

export default React.memo(ForgotPassword);
