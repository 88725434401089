import { GraphQLResolveInfo, GraphQLScalarType, GraphQLScalarTypeConfig } from 'graphql';
import { gql } from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type RequireFields<T, K extends keyof T> = { [X in Exclude<keyof T, K>]?: T[X] } & { [P in K]-?: NonNullable<T[P]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Date: any;
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: any;
  /** The `ID` scalar type represents a unique MongoDB identifier in collection. MongoDB by default use 12-byte ObjectId value (https://docs.mongodb.com/manual/reference/bson-types/#objectid). But MongoDB also may accepts string or integer as correct values for _id field. */
  MongoID: any;
};

export type Article = {
  __typename?: 'Article';
  schemaVersion?: Maybe<Scalars['String']>;
  tenant?: Maybe<Scalars['String']>;
  uuid: Scalars['String'];
  cmsId: Scalars['String'];
  isPublished?: Maybe<Scalars['Boolean']>;
  language?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  created?: Maybe<Scalars['String']>;
  changed?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['Boolean']>;
  promote?: Maybe<Scalars['Boolean']>;
  body?: Maybe<ArticleBody>;
  _id: Scalars['MongoID'];
};

export type ArticleBody = {
  __typename?: 'ArticleBody';
  value?: Maybe<Scalars['String']>;
  summary?: Maybe<Scalars['String']>;
  format?: Maybe<Scalars['String']>;
  _id?: Maybe<Scalars['MongoID']>;
};

export type Author = {
  __typename?: 'Author';
  /** The author name for internal articles */
  name?: Maybe<Scalars['String']>;
};

export enum CacheControlScope {
  Public = 'PUBLIC',
  Private = 'PRIVATE'
}

export type Clock = {
  __typename?: 'Clock';
  hours?: Maybe<Scalars['String']>;
  milliseconds?: Maybe<Scalars['String']>;
  minutes?: Maybe<Scalars['String']>;
  seconds?: Maybe<Scalars['String']>;
  stoppage?: Maybe<Scalars['String']>;
};

export type Competitor = {
  __typename?: 'Competitor';
  hash?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  draws?: Maybe<Scalars['String']>;
  losses?: Maybe<Scalars['String']>;
  market?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  overtime_losses?: Maybe<Scalars['String']>;
  rank?: Maybe<Scalars['String']>;
  sportradar_id?: Maybe<Scalars['String']>;
  wins?: Maybe<Scalars['String']>;
  sport?: Maybe<Sport>;
  league?: Maybe<League>;
  tournaments?: Maybe<Array<Maybe<Tournament>>>;
  leagues?: Maybe<Array<Maybe<League>>>;
  id?: Maybe<Scalars['ID']>;
  team_alias?: Maybe<Scalars['String']>;
  team_abbr?: Maybe<Scalars['String']>;
  display_name_1?: Maybe<Scalars['String']>;
  display_name_2?: Maybe<Scalars['String']>;
  header_image_bucket?: Maybe<Scalars['String']>;
  logo_dark?: Maybe<Scalars['String']>;
  logo_light?: Maybe<Scalars['String']>;
  logo_split?: Maybe<Scalars['String']>;
};


export type CompetitorTournamentsArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type CompetitorLeaguesArgs = {
  id?: Maybe<Scalars['ID']>;
};

export type Competitors = {
  __typename?: 'Competitors';
  competitors?: Maybe<Array<Maybe<Competitor>>>;
  total?: Maybe<Scalars['Int']>;
  index_start?: Maybe<Scalars['Int']>;
  index_end?: Maybe<Scalars['Int']>;
};

export type Component = {
  __typename?: 'Component';
  type?: Maybe<Scalars['String']>;
  weight?: Maybe<Scalars['Float']>;
  headline?: Maybe<Scalars['String']>;
  subheadline?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  startTime?: Maybe<Scalars['Date']>;
  endTime?: Maybe<Scalars['Date']>;
  link?: Maybe<Link>;
  id?: Maybe<Scalars['String']>;
  uuid: Scalars['String'];
  format?: Maybe<Scalars['String']>;
  isFavoriteTeam?: Maybe<Scalars['Boolean']>;
  image?: Maybe<Image>;
  images?: Maybe<Array<Maybe<ImageWithStyle>>>;
  content?: Maybe<Array<Maybe<Content>>>;
  _id?: Maybe<Scalars['MongoID']>;
  /** The reference stream */
  referenceStream?: Maybe<ReferenceStream>;
};

export type Conference = {
  __typename?: 'Conference';
  league?: Maybe<League>;
  name?: Maybe<Scalars['String']>;
  sport?: Maybe<Sport>;
  sportradar_id?: Maybe<Scalars['String']>;
  divisions?: Maybe<Array<Maybe<Division>>>;
  teams?: Maybe<Array<Maybe<Competitor>>>;
  id?: Maybe<Scalars['ID']>;
};


export type ConferenceDivisionsArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type ConferenceTeamsArgs = {
  id?: Maybe<Scalars['ID']>;
};

export type Conferences = {
  __typename?: 'Conferences';
  conferences?: Maybe<Array<Maybe<Conference>>>;
  total?: Maybe<Scalars['Int']>;
  index_start?: Maybe<Scalars['Int']>;
  index_end?: Maybe<Scalars['Int']>;
};

export type Content = {
  __typename?: 'Content';
  hash?: Maybe<Scalars['String']>;
  weight?: Maybe<Scalars['Float']>;
  allowedTypes?: Maybe<Array<Maybe<Scalars['String']>>>;
  filters?: Maybe<ContentFilters>;
  sort?: Maybe<ContentSort>;
  /** @deprecated This field will be removed. Please use the "clips" (plural) field to get Video entity data */
  clip?: Maybe<Scalars['String']>;
  /** @deprecated This field is deprecated. Please use the "events" (plural) field to get the full Event entity */
  event?: Maybe<Scalars['String']>;
  liveStream?: Maybe<Scalars['String']>;
  league?: Maybe<Scalars['String']>;
  /** @deprecated This field is deprecated. Please use the "tournaments" (plural) field to get the full Tournament entity */
  tournament?: Maybe<Scalars['String']>;
  /** @deprecated This field is deprecated. Please use the "teams" (plural) field to get the full Competitor entity */
  team?: Maybe<Scalars['String']>;
  product?: Maybe<Array<Maybe<Product>>>;
  form?: Maybe<ContentForm>;
  show?: Maybe<ContentShow>;
  image?: Maybe<Image>;
  item?: Maybe<Item>;
  _id?: Maybe<Scalars['MongoID']>;
  clips?: Maybe<Array<Maybe<Video>>>;
  episodes?: Maybe<Array<Maybe<Show>>>;
  events?: Maybe<Array<Maybe<Event>>>;
  series?: Maybe<Array<Maybe<Series>>>;
  teams?: Maybe<Array<Maybe<Competitor>>>;
  tournaments?: Maybe<Array<Maybe<Tournament>>>;
};

export type ContentFilters = {
  __typename?: 'ContentFilters';
  isFavoriteTeam?: Maybe<Scalars['Boolean']>;
  maxItems?: Maybe<Scalars['Float']>;
  status?: Maybe<Array<Maybe<Scalars['String']>>>;
  timeWindow?: Maybe<Array<Maybe<Scalars['Float']>>>;
  shows?: Maybe<Array<Maybe<Scalars['String']>>>;
  collections?: Maybe<Array<Maybe<Scalars['String']>>>;
  tags?: Maybe<Array<Maybe<Scalars['String']>>>;
  _id?: Maybe<Scalars['MongoID']>;
};

export type ContentForm = {
  __typename?: 'ContentForm';
  element?: Maybe<FormElement>;
};

export type ContentShow = {
  __typename?: 'ContentShow';
  id?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  image?: Maybe<Image>;
  weight?: Maybe<Scalars['Float']>;
  _id?: Maybe<Scalars['MongoID']>;
};

export type ContentSort = {
  __typename?: 'ContentSort';
  field?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['String']>;
};


export type Division = {
  __typename?: 'Division';
  conference?: Maybe<Conference>;
  league?: Maybe<League>;
  name?: Maybe<Scalars['String']>;
  sport?: Maybe<Sport>;
  sportradar_id?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  division_alias?: Maybe<Scalars['String']>;
  teams?: Maybe<Array<Maybe<Competitor>>>;
};


export type DivisionTeamsArgs = {
  id?: Maybe<Scalars['ID']>;
};

export type Divisions = {
  __typename?: 'Divisions';
  divisions?: Maybe<Array<Maybe<Division>>>;
  total?: Maybe<Scalars['Int']>;
  index_start?: Maybe<Scalars['Int']>;
  index_end?: Maybe<Scalars['Int']>;
};

export type Event = {
  __typename?: 'Event';
  hash?: Maybe<Scalars['String']>;
  card_type?: Maybe<Scalars['String']>;
  color_1?: Maybe<Scalars['String']>;
  color_2?: Maybe<Scalars['String']>;
  derived_status?: Maybe<Scalars['String']>;
  free_event?: Maybe<Scalars['Boolean']>;
  free_preview?: Maybe<Scalars['Boolean']>;
  has_media?: Maybe<Scalars['Boolean']>;
  media_asset_id_live?: Maybe<Scalars['String']>;
  media_asset_id_vod?: Maybe<Scalars['String']>;
  hidden?: Maybe<Scalars['Boolean']>;
  is_live?: Maybe<Scalars['Boolean']>;
  league?: Maybe<League>;
  medium_vod?: Maybe<Scalars['Boolean']>;
  recommended?: Maybe<Scalars['Boolean']>;
  scheduled_utc?: Maybe<Scalars['String']>;
  season?: Maybe<Scalars['String']>;
  season_type?: Maybe<Scalars['String']>;
  series_name?: Maybe<Scalars['String']>;
  series_id?: Maybe<Scalars['Int']>;
  sponsor_text?: Maybe<Scalars['String']>;
  sport?: Maybe<Sport>;
  sportradar_id?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  game?: Maybe<Game>;
  territories_available?: Maybe<Array<Maybe<Territories>>>;
  trending?: Maybe<Scalars['Boolean']>;
  week?: Maybe<Scalars['String']>;
  time_zone?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['String']>;
  tournament?: Maybe<Tournament>;
  venue?: Maybe<Venue>;
  home?: Maybe<Competitor>;
  away?: Maybe<Competitor>;
  id?: Maybe<Scalars['ID']>;
  title?: Maybe<Scalars['String']>;
  home_team_points?: Maybe<Scalars['String']>;
  away_team_points?: Maybe<Scalars['String']>;
  event_image?: Maybe<Scalars['String']>;
  header_image_bucket?: Maybe<Scalars['String']>;
  event_image_bucket?: Maybe<Scalars['String']>;
  period?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  logo_dark?: Maybe<Scalars['String']>;
  logo_light?: Maybe<Scalars['String']>;
};

export enum EventFilterNames {
  Series = 'series',
  Tournament = 'tournament',
  Team = 'team',
  League = 'league',
  Sport = 'sport',
  SportRadar = 'sportRadar'
}

export type EventFilterType = {
  name: EventFilterNames;
  id: Scalars['String'];
};

export enum EventSort {
  Date = 'date',
  Rating = 'rating'
}

export enum EventTypes {
  Manual = 'manual',
  Match = 'match'
}

export type Events = {
  __typename?: 'Events';
  events?: Maybe<Array<Maybe<Event>>>;
  total?: Maybe<Scalars['Int']>;
  index_start?: Maybe<Scalars['Int']>;
  index_end?: Maybe<Scalars['Int']>;
};

export type Feed = {
  __typename?: 'Feed';
  code?: Maybe<Scalars['String']>;
  link?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  shows?: Maybe<Array<Maybe<Show>>>;
};

export enum Feeds {
  CdfhCl = 'CDFH_CL',
  TntspoAr = 'TNTSPO_AR'
}

export type FormElement = {
  __typename?: 'FormElement';
  weight?: Maybe<Scalars['Float']>;
  type?: Maybe<Scalars['String']>;
  headline?: Maybe<Scalars['String']>;
  options?: Maybe<Scalars['JSON']>;
  _id?: Maybe<Scalars['MongoID']>;
};

export type Game = {
  __typename?: 'Game';
  sportradar_id?: Maybe<Scalars['String']>;
  away?: Maybe<Team>;
  gameDate?: Maybe<GameDate>;
  gameProgress?: Maybe<GameProgress>;
  home?: Maybe<Team>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  score?: Maybe<Score>;
  sourceGameId?: Maybe<Scalars['String']>;
  sport?: Maybe<SportMetaData>;
  stateStatus?: Maybe<StateStatus>;
  status?: Maybe<Scalars['String']>;
  weekId?: Maybe<Scalars['String']>;
};

export type GameDate = {
  __typename?: 'GameDate';
  epoch?: Maybe<Scalars['Int']>;
  iso8501?: Maybe<Scalars['String']>;
};

export type GameProgress = {
  __typename?: 'GameProgress';
  clock?: Maybe<Clock>;
  currentPeriod?: Maybe<Scalars['String']>;
  displayPrimary?: Maybe<Scalars['String']>;
  displaySecondary?: Maybe<Scalars['String']>;
  totalPeriods?: Maybe<Scalars['String']>;
};

export type Image = {
  __typename?: 'Image';
  url: Scalars['String'];
  alt?: Maybe<Scalars['String']>;
  width?: Maybe<Scalars['String']>;
  height?: Maybe<Scalars['String']>;
  focalPointX?: Maybe<Scalars['Float']>;
  focalPointY?: Maybe<Scalars['Float']>;
  _id?: Maybe<Scalars['MongoID']>;
};

export type ImageWithStyle = {
  __typename?: 'ImageWithStyle';
  image?: Maybe<Image>;
  style?: Maybe<Scalars['String']>;
  _id?: Maybe<Scalars['MongoID']>;
};

export type Item = {
  __typename?: 'Item';
  headline?: Maybe<Scalars['String']>;
  subheadline?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  style?: Maybe<Scalars['String']>;
  link?: Maybe<Link>;
  startTime?: Maybe<Scalars['Date']>;
  endTime?: Maybe<Scalars['Date']>;
  images?: Maybe<Array<Maybe<ImageWithStyle>>>;
  _id?: Maybe<Scalars['MongoID']>;
  id?: Maybe<Scalars['String']>;
};


export type League = {
  __typename?: 'League';
  alias?: Maybe<Scalars['String']>;
  current_season?: Maybe<Scalars['String']>;
  display_name?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  season_end?: Maybe<Scalars['String']>;
  season_start?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  sport?: Maybe<Sport>;
  display_name_short?: Maybe<Scalars['String']>;
};

export type Leagues = {
  __typename?: 'Leagues';
  leagues?: Maybe<Array<Maybe<League>>>;
  total?: Maybe<Scalars['Int']>;
  index_start?: Maybe<Scalars['Int']>;
  index_end?: Maybe<Scalars['Int']>;
};

export type Legacy = {
  __typename?: 'Legacy';
  league_id?: Maybe<Scalars['Int']>;
  sport_id?: Maybe<Scalars['Int']>;
};

export type Link = {
  __typename?: 'Link';
  uri?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
  _id?: Maybe<Scalars['MongoID']>;
};

export type Media = {
  __typename?: 'Media';
  image?: Maybe<Image>;
  clip?: Maybe<Scalars['String']>;
  _id?: Maybe<Scalars['MongoID']>;
};

export type Menu = {
  __typename?: 'Menu';
  schemaVersion: Scalars['String'];
  tenant: Scalars['String'];
  uuid?: Maybe<Scalars['String']>;
  cmsId?: Maybe<Scalars['String']>;
  isPublished?: Maybe<Scalars['Boolean']>;
  language: Scalars['String'];
  id: Scalars['String'];
  title: Scalars['String'];
  links?: Maybe<Array<Maybe<MenuLinks>>>;
  _id: Scalars['MongoID'];
};

export type MenuImage = {
  __typename?: 'MenuImage';
  type?: Maybe<Scalars['String']>;
  image?: Maybe<Image>;
  _id?: Maybe<Scalars['MongoID']>;
};

export type MenuLinks = {
  __typename?: 'MenuLinks';
  text: Scalars['String'];
  url?: Maybe<Scalars['String']>;
  weight: Scalars['String'];
  options?: Maybe<MenuOptions>;
  _id?: Maybe<Scalars['MongoID']>;
  children?: Maybe<Array<Maybe<MenuLinks>>>;
};

export type MenuOptions = {
  __typename?: 'MenuOptions';
  target?: Maybe<Scalars['String']>;
  expanded?: Maybe<Scalars['Boolean']>;
  images?: Maybe<Array<Maybe<MenuImage>>>;
  attributes?: Maybe<MenuOptionsAttributes>;
  _id?: Maybe<Scalars['MongoID']>;
};

export type MenuOptionsAttributes = {
  __typename?: 'MenuOptionsAttributes';
  title?: Maybe<Scalars['String']>;
  class?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type Metadata = {
  __typename?: 'Metadata';
  cdn?: Maybe<Scalars['String']>;
};


export type Mutation = {
  __typename?: 'Mutation';
  login?: Maybe<Scalars['String']>;
};


export type MutationLoginArgs = {
  email: Scalars['String'];
  password: Scalars['String'];
};

export type Page = {
  __typename?: 'Page';
  schemaVersion?: Maybe<Scalars['String']>;
  tenant?: Maybe<Scalars['String']>;
  uuid: Scalars['String'];
  cmsId: Scalars['String'];
  isPublished?: Maybe<Scalars['Boolean']>;
  language?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  uri: Scalars['String'];
  title?: Maybe<Scalars['String']>;
  headline?: Maybe<Scalars['String']>;
  body?: Maybe<PageBody>;
  created?: Maybe<Scalars['String']>;
  changed?: Maybe<Scalars['String']>;
  metatags?: Maybe<Array<Maybe<PageMetatags>>>;
  components?: Maybe<Array<Maybe<Component>>>;
  _id: Scalars['MongoID'];
};

export type PageBody = {
  __typename?: 'PageBody';
  value?: Maybe<Scalars['String']>;
  summary?: Maybe<Scalars['String']>;
  format?: Maybe<Scalars['String']>;
  _id?: Maybe<Scalars['MongoID']>;
};

export type PageComponentFiltersInput = {
  /** Filter Page Components by a User's favorite Team. Value passed should be **sportradar_id** (ex. `sr:competitor:1234`) */
  team?: Maybe<Scalars['String']>;
};

export type PageMetatags = {
  __typename?: 'PageMetatags';
  type?: Maybe<Scalars['String']>;
  key?: Maybe<Scalars['String']>;
  content?: Maybe<Scalars['String']>;
  _id?: Maybe<Scalars['MongoID']>;
};

export type Playlist = {
  __typename?: 'Playlist';
  schemaVersion?: Maybe<Scalars['String']>;
  tenant?: Maybe<Scalars['String']>;
  uuid: Scalars['String'];
  cmsId: Scalars['String'];
  isPublished?: Maybe<Scalars['Boolean']>;
  language?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  headline?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  created?: Maybe<Scalars['String']>;
  changed?: Maybe<Scalars['String']>;
  tagging?: Maybe<Tagging>;
  media?: Maybe<Array<Maybe<Media>>>;
  _id: Scalars['MongoID'];
};

export type Product = {
  __typename?: 'Product';
  weight?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['String']>;
  cta?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['String']>;
  headline?: Maybe<Scalars['String']>;
  subHeadline?: Maybe<Scalars['String']>;
  disclaimer?: Maybe<Scalars['String']>;
  isPayPalEnabled?: Maybe<Scalars['Boolean']>;
  _id?: Maybe<Scalars['MongoID']>;
};

export type Query = {
  __typename?: 'Query';
  /** getArticleById | CMS API */
  getArticleById?: Maybe<Article>;
  /** getAllArticles | CMS API */
  getAllArticles?: Maybe<Array<Maybe<Article>>>;
  /** getAllVideos | CMS API */
  getAllVideos?: Maybe<Array<Maybe<Video>>>;
  /** getVideosByEventId | CMS API */
  getVideosByEventId?: Maybe<Array<Maybe<Video>>>;
  /** getVideoById | CMS API */
  getVideoById?: Maybe<Video>;
  /** getVideoByCmsId | CMS API */
  getVideoByCmsId?: Maybe<Video>;
  /** getVideosByTeamId | CMS API */
  getVideosByTeamId?: Maybe<Array<Maybe<Video>>>;
  /** getPageByUri | CMS API */
  getPageByUri?: Maybe<Page>;
  /** getAllPlaylists | CMS API */
  getAllPlaylists?: Maybe<Array<Maybe<Playlist>>>;
  /** getPlaylistById | CMS API */
  getPlaylistById?: Maybe<Playlist>;
  /** getPlaylistByCmsId | CMS API */
  getPlaylistByCmsId?: Maybe<Playlist>;
  /** getPlaylistByEventId | CMS API */
  getPlaylistByEventId?: Maybe<Playlist>;
  /** getTaxonomyByIdAndType | CMS API */
  getTaxonomyByIdAndType?: Maybe<TaxonomyTerm>;
  /** getAllMenus | CMS API */
  getAllMenus?: Maybe<Array<Maybe<Menu>>>;
  /** getMenuById | CMS API */
  getMenuById?: Maybe<Menu>;
  /** @deprecated No longer needed */
  getHelloWorld?: Maybe<Scalars['String']>;
  /** getScheduleByFeeds | Episode API | EPG API */
  getScheduleByFeeds?: Maybe<Schedule>;
  /** getAllCompetitors | Will be deprecated February 01, 2023 | Use getCompetitorList */
  getAllCompetitors?: Maybe<Array<Maybe<Competitor>>>;
  /** getCompetitorList | Event API | LET API */
  getCompetitorList?: Maybe<Competitors>;
  /** getCompetitorById | Event API | LET API */
  getCompetitorById?: Maybe<Competitor>;
  /** getAllConferences | Will be deprecated February 01, 2023 | Use getConferenceList */
  getAllConferences?: Maybe<Array<Maybe<Conference>>>;
  /** getConferenceList | Event API | LET API */
  getConferenceList?: Maybe<Conferences>;
  /** getConferenceById | Event API | LET API */
  getConferenceById?: Maybe<Conference>;
  /** getAllDivisions | Will be deprecated February 01, 2023 | Use getDivisionList */
  getAllDivisions?: Maybe<Array<Maybe<Division>>>;
  /** getDivisionList | Event API | LET API */
  getDivisionList?: Maybe<Divisions>;
  /** getDivisionById | Event API | LET API */
  getDivisionById?: Maybe<Division>;
  /** getAllEvents | Will be deprecated February 01, 2023 | Use getEventList */
  getAllEvents?: Maybe<Array<Maybe<Event>>>;
  /** getEventList | Event API | LET API */
  getEventList?: Maybe<Events>;
  /** getEventById | Event API | LET API */
  getEventById?: Maybe<Event>;
  /** getAllLeagues | Will be deprecated February 01, 2023 | Use getLeagueList */
  getAllLeagues?: Maybe<Array<Maybe<League>>>;
  /** getLeagueList | Event API | LET API */
  getLeagueList?: Maybe<Leagues>;
  /** getLeagueById | Event API | LET API */
  getLeagueById?: Maybe<League>;
  /** getAllSeries | Will be deprecated February 01, 2023 | Use getSeriesList */
  getAllSeries?: Maybe<Array<Maybe<Series>>>;
  /** getSeriesList | Event API | LET API */
  getSeriesList?: Maybe<Serieses>;
  /** getSeriesById | Event API | LET API */
  getSeriesById?: Maybe<Series>;
  /** getAllSports | Will be deprecated February 01, 2023 | Use getSportList */
  getAllSports?: Maybe<Array<Maybe<Sport>>>;
  /** getSportList | Event API | LET API */
  getSportList?: Maybe<Sports>;
  /** getSportById | Event API | LET API */
  getSportById?: Maybe<Sport>;
  /** getAllTournaments | Will be deprecated February 01, 2023 | Use getTournamentList */
  getAllTournaments?: Maybe<Array<Maybe<Tournament>>>;
  /** getTournamentList | Event API | LET API */
  getTournamentList?: Maybe<Tournaments>;
  /** getTournamentById | Event API | LET API */
  getTournamentById?: Maybe<Tournament>;
  /** getAllVenues | To be deprecated December, 2022 */
  getAllVenues?: Maybe<Array<Maybe<Venue>>>;
  /** getVenueList | Event API | LET API | Includes Pagination (Use instead of getAllVenues) */
  getVenueList?: Maybe<Venues>;
  /** getVenueById | Event API | LET API */
  getVenueById?: Maybe<Venue>;
  /** @deprecated No longer needed. */
  viewer: User;
  getMetadata?: Maybe<Metadata>;
  /** Grab reference stream by name with optional limit */
  getReferenceStreamByName?: Maybe<Array<Reference>>;
  /** getGamesByGameDate | Stat API | Statmilk API */
  getGamesByGameDate?: Maybe<Array<Maybe<Game>>>;
  /** getGamesByGameIds | Stat API | Statmilk API */
  getGamesByGameIds?: Maybe<Array<Maybe<Game>>>;
  /** getGamesBySportRadarIds | Stat API | Statmilk API */
  getGamesBySportRadarIds?: Maybe<Array<Maybe<Game>>>;
  getTagById: Tag;
  getTagsByPermalinks?: Maybe<Array<Maybe<Tag>>>;
  getAllTeamsByPermalink: Array<Tag>;
  getSiteMap: Tag;
  /** search for a tag by name or fragment. it is recommended (but not enforced) to provide at least 3 characters */
  findTagByMatchTerm: Array<Tag>;
};


export type QueryGetArticleByIdArgs = {
  id?: Maybe<Scalars['String']>;
  tenant: Tenant;
  publishedOnly?: Maybe<Scalars['Boolean']>;
};


export type QueryGetAllArticlesArgs = {
  tenant: Tenant;
  publishedOnly?: Maybe<Scalars['Boolean']>;
};


export type QueryGetAllVideosArgs = {
  limit?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Int']>;
  videoFilters?: Maybe<VideoFiltersInput>;
  tenant: Tenant;
  publishedOnly?: Maybe<Scalars['Boolean']>;
};


export type QueryGetVideosByEventIdArgs = {
  eventId?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Int']>;
  tenant: Tenant;
  publishedOnly?: Maybe<Scalars['Boolean']>;
};


export type QueryGetVideoByIdArgs = {
  id?: Maybe<Scalars['String']>;
  tenant: Tenant;
  publishedOnly?: Maybe<Scalars['Boolean']>;
};


export type QueryGetVideoByCmsIdArgs = {
  cmsId?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  tenant: Tenant;
  publishedOnly?: Maybe<Scalars['Boolean']>;
};


export type QueryGetVideosByTeamIdArgs = {
  teamId?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Int']>;
  tenant: Tenant;
  publishedOnly?: Maybe<Scalars['Boolean']>;
};


export type QueryGetPageByUriArgs = {
  uri: Scalars['String'];
  componentFilters?: Maybe<PageComponentFiltersInput>;
  tenant: Tenant;
  publishedOnly?: Maybe<Scalars['Boolean']>;
};


export type QueryGetAllPlaylistsArgs = {
  tenant: Tenant;
  publishedOnly?: Maybe<Scalars['Boolean']>;
};


export type QueryGetPlaylistByIdArgs = {
  id?: Maybe<Scalars['String']>;
  tenant: Tenant;
  publishedOnly?: Maybe<Scalars['Boolean']>;
};


export type QueryGetPlaylistByCmsIdArgs = {
  cmsId?: Maybe<Scalars['String']>;
  tenant: Tenant;
  publishedOnly?: Maybe<Scalars['Boolean']>;
};


export type QueryGetPlaylistByEventIdArgs = {
  tenant: Tenant;
  eventId?: Maybe<Scalars['String']>;
  publishedOnly?: Maybe<Scalars['Boolean']>;
};


export type QueryGetTaxonomyByIdAndTypeArgs = {
  id: Scalars['String'];
  type: TaxonomyType;
  tenant: Tenant;
  publishedOnly?: Maybe<Scalars['Boolean']>;
};


export type QueryGetAllMenusArgs = {
  tenant: Tenant;
  publishedOnly?: Maybe<Scalars['Boolean']>;
};


export type QueryGetMenuByIdArgs = {
  id?: Maybe<Scalars['String']>;
  tenant: Tenant;
  publishedOnly?: Maybe<Scalars['Boolean']>;
};


export type QueryGetScheduleByFeedsArgs = {
  feed: Feeds;
  from?: Maybe<Scalars['String']>;
  to?: Maybe<Scalars['String']>;
  count?: Maybe<Scalars['Int']>;
};


export type QueryGetAllCompetitorsArgs = {
  has_media?: Maybe<Scalars['Boolean']>;
  territories?: Maybe<Territories>;
  tenant: Tenants;
};


export type QueryGetCompetitorListArgs = {
  has_media?: Maybe<Scalars['Boolean']>;
  territories?: Maybe<Territories>;
  tenant: Tenants;
  count_start?: Maybe<Scalars['Int']>;
  count_end?: Maybe<Scalars['Int']>;
};


export type QueryGetCompetitorByIdArgs = {
  id: Scalars['String'];
  tenant: Tenants;
};


export type QueryGetAllConferencesArgs = {
  tenant: Tenants;
};


export type QueryGetConferenceListArgs = {
  tenant: Tenants;
  count_start?: Maybe<Scalars['Int']>;
  count_end?: Maybe<Scalars['Int']>;
};


export type QueryGetConferenceByIdArgs = {
  id: Scalars['String'];
  tenant: Tenants;
};


export type QueryGetAllDivisionsArgs = {
  tenant: Tenants;
};


export type QueryGetDivisionListArgs = {
  tenant: Tenants;
  count_start?: Maybe<Scalars['Int']>;
  count_end?: Maybe<Scalars['Int']>;
};


export type QueryGetDivisionByIdArgs = {
  id: Scalars['String'];
  tenant: Tenants;
};


export type QueryGetAllEventsArgs = {
  start_date?: Maybe<Scalars['String']>;
  end_date?: Maybe<Scalars['String']>;
  count_start?: Maybe<Scalars['String']>;
  count_end?: Maybe<Scalars['String']>;
  sort?: Maybe<EventSort>;
  order?: Maybe<SortDirections>;
  media_filter?: Maybe<Scalars['String']>;
  has_media?: Maybe<Scalars['Boolean']>;
  season?: Maybe<Scalars['Int']>;
  territories?: Maybe<Territories>;
  type?: Maybe<EventTypes>;
  tenant: Tenants;
  filterId?: Maybe<Array<Maybe<EventFilterType>>>;
};


export type QueryGetEventListArgs = {
  start_date?: Maybe<Scalars['String']>;
  end_date?: Maybe<Scalars['String']>;
  count_start?: Maybe<Scalars['String']>;
  count_end?: Maybe<Scalars['String']>;
  sort?: Maybe<EventSort>;
  order?: Maybe<SortDirections>;
  media_filter?: Maybe<Scalars['String']>;
  has_media?: Maybe<Scalars['Boolean']>;
  season?: Maybe<Scalars['Int']>;
  territories?: Maybe<Territories>;
  type?: Maybe<EventTypes>;
  tenant: Tenants;
  filterId?: Maybe<Array<Maybe<EventFilterType>>>;
};


export type QueryGetEventByIdArgs = {
  id: Scalars['String'];
  tenant: Tenants;
  isSportRadar?: Maybe<Scalars['Boolean']>;
};


export type QueryGetAllLeaguesArgs = {
  has_media?: Maybe<Scalars['Boolean']>;
  territories?: Maybe<Territories>;
  tenant: Tenants;
};


export type QueryGetLeagueListArgs = {
  has_media?: Maybe<Scalars['Boolean']>;
  territories?: Maybe<Territories>;
  tenant: Tenants;
  count_start?: Maybe<Scalars['Int']>;
  count_end?: Maybe<Scalars['Int']>;
};


export type QueryGetLeagueByIdArgs = {
  id: Scalars['String'];
  tenant: Tenants;
};


export type QueryGetAllSeriesArgs = {
  has_media?: Maybe<Scalars['Boolean']>;
  territories?: Maybe<Territories>;
  tenant: Tenants;
  tournament_id?: Maybe<Scalars['String']>;
  competitor_id?: Maybe<Scalars['String']>;
  season?: Maybe<Scalars['String']>;
  sort?: Maybe<SeriesSort>;
  order?: Maybe<SortDirections>;
};


export type QueryGetSeriesListArgs = {
  has_media?: Maybe<Scalars['Boolean']>;
  territories?: Maybe<Territories>;
  tenant: Tenants;
  tournament_id?: Maybe<Scalars['String']>;
  competitor_id?: Maybe<Scalars['String']>;
  season?: Maybe<Scalars['String']>;
  sort?: Maybe<SeriesSort>;
  order?: Maybe<SortDirections>;
  count_start?: Maybe<Scalars['Int']>;
  count_end?: Maybe<Scalars['Int']>;
};


export type QueryGetSeriesByIdArgs = {
  id: Scalars['String'];
  tenant: Tenants;
};


export type QueryGetAllSportsArgs = {
  tenant: Tenants;
};


export type QueryGetSportListArgs = {
  tenant: Tenants;
  count_start?: Maybe<Scalars['Int']>;
  count_end?: Maybe<Scalars['Int']>;
};


export type QueryGetSportByIdArgs = {
  id: Scalars['String'];
  tenant: Tenants;
};


export type QueryGetAllTournamentsArgs = {
  has_media?: Maybe<Scalars['Boolean']>;
  territories?: Maybe<Territories>;
  tenant: Tenants;
};


export type QueryGetTournamentListArgs = {
  has_media?: Maybe<Scalars['Boolean']>;
  territories?: Maybe<Territories>;
  tenant: Tenants;
  count_start?: Maybe<Scalars['Int']>;
  count_end?: Maybe<Scalars['Int']>;
};


export type QueryGetTournamentByIdArgs = {
  id: Scalars['String'];
  tenant: Tenants;
};


export type QueryGetAllVenuesArgs = {
  tenant: Tenants;
};


export type QueryGetVenueListArgs = {
  tenant: Tenants;
  count_start?: Maybe<Scalars['Int']>;
  count_end?: Maybe<Scalars['Int']>;
};


export type QueryGetVenueByIdArgs = {
  id: Scalars['String'];
  tenant: Tenants;
};


export type QueryGetReferenceStreamByNameArgs = {
  referenceStreamName: Scalars['String'];
  limit?: Maybe<Scalars['Int']>;
};


export type QueryGetGamesByGameDateArgs = {
  startDate: Scalars['String'];
  endDate: Scalars['String'];
  timezone: Scalars['Int'];
  sortOrder?: Maybe<SortOrder>;
  status?: Maybe<Array<Maybe<Status>>>;
  stateStatus?: Maybe<Array<Maybe<StateStatus>>>;
  leagues?: Maybe<Array<Maybe<StatsLeagues>>>;
};


export type QueryGetGamesByGameIdsArgs = {
  gameIds: Array<Maybe<Scalars['String']>>;
};


export type QueryGetGamesBySportRadarIdsArgs = {
  gameIds: Array<Maybe<Scalars['String']>>;
};


export type QueryGetTagByIdArgs = {
  id: Scalars['ID'];
  tenant: Tenant;
};


export type QueryGetTagsByPermalinksArgs = {
  permalinks: Array<Scalars['String']>;
  tenant: Tenant;
};


export type QueryGetAllTeamsByPermalinkArgs = {
  permalink: Scalars['String'];
  tenant: Tenant;
};


export type QueryGetSiteMapArgs = {
  permalink: Scalars['String'];
  client: Scalars['String'];
  tenant: Tenant;
};


export type QueryFindTagByMatchTermArgs = {
  term: Scalars['String'];
  tenant: Tenant;
};

export type Reference = {
  __typename?: 'Reference';
  id: Scalars['ID'];
  /** Title of the reference */
  title: Scalars['String'];
  /** Description of the reference */
  description?: Maybe<Scalars['String']>;
  /** Illustration to display of the reference */
  thumbnailUrl: Scalars['String'];
  /** Permalink of the reference */
  permalink: Scalars['String'];
  /** Url of the reference content */
  url: Scalars['String'];
  /** Author of the reference */
  author?: Maybe<Author>;
  /** Tags of the reference from tagapi */
  tag: Tag;
  /** Type of reference */
  type?: Maybe<ReferenceType>;
  /** Metadata of reference */
  metadata?: Maybe<ReferenceMetadata>;
  /** When reference was created */
  createdAt: Scalars['String'];
  /** When reference was updated */
  updatedAt: Scalars['String'];
};

export type ReferenceMetadata = {
  __typename?: 'ReferenceMetadata';
  /** Metadata's genres (i.e. breaking_news, analysis, rumors, game_action, live_event, etc.) */
  genres?: Maybe<Array<Scalars['String']>>;
  /** Metadata's provider name */
  providerName?: Maybe<Scalars['String']>;
  /** Image accreditation display information */
  photoCredit?: Maybe<Scalars['String']>;
  /** Metadata's video name */
  video?: Maybe<VideoMetadata>;
};

export type ReferenceStream = {
  __typename?: 'ReferenceStream';
  id: Scalars['ID'];
  /** The Stream name */
  name: Scalars['String'];
  /** Tags of the reference from tagapi */
  tag: Tag;
  /** The Stream type */
  type: Scalars['String'];
  /** A list of References with optional limit query parameter */
  references?: Maybe<Array<Reference>>;
};


export type ReferenceStreamReferencesArgs = {
  limit?: Maybe<Scalars['Int']>;
};

/** GSP ReferenceType enumeration */
export enum ReferenceType {
  InternalArticle = 'InternalArticle',
  Video = 'Video',
  LiveVideo = 'LiveVideo',
  Highlight = 'Highlight'
}

export type Schedule = {
  __typename?: 'Schedule';
  dateFrom?: Maybe<Scalars['String']>;
  dateTo?: Maybe<Scalars['String']>;
  offset?: Maybe<Scalars['String']>;
  feeds?: Maybe<Array<Maybe<Feed>>>;
};

export type Score = {
  __typename?: 'Score';
  away?: Maybe<Scalars['Int']>;
  home?: Maybe<Scalars['Int']>;
};

export type Season = {
  __typename?: 'Season';
  id?: Maybe<Scalars['String']>;
  current?: Maybe<Scalars['Int']>;
  end?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  start?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type Series = {
  __typename?: 'Series';
  hash?: Maybe<Scalars['String']>;
  taxonomy?: Maybe<TaxonomyTerm>;
  season?: Maybe<Scalars['String']>;
  tournament?: Maybe<Tournament>;
  id?: Maybe<Scalars['ID']>;
  series_name?: Maybe<Scalars['String']>;
  territories_available?: Maybe<Array<Maybe<Territories>>>;
  start_date?: Maybe<Scalars['String']>;
  events?: Maybe<Array<Maybe<Event>>>;
};

export enum SeriesSort {
  Season = 'season',
  StartDate = 'start_date',
  Id = 'id',
  SeriesName = 'series_name'
}

export type Serieses = {
  __typename?: 'Serieses';
  series?: Maybe<Array<Maybe<Series>>>;
  total?: Maybe<Scalars['Int']>;
  index_start?: Maybe<Scalars['Int']>;
  index_end?: Maybe<Scalars['Int']>;
};

export type Show = {
  __typename?: 'Show';
  hash?: Maybe<Scalars['String']>;
  taxonomy?: Maybe<TaxonomyTerm>;
  id?: Maybe<Scalars['String']>;
  titleId?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  originalTitle?: Maybe<Scalars['String']>;
  genreList?: Maybe<Scalars['String']>;
  highlightList?: Maybe<Scalars['String']>;
  airDateTime?: Maybe<Scalars['String']>;
  lang?: Maybe<Scalars['String']>;
  begin?: Maybe<Scalars['String']>;
  end?: Maybe<Scalars['String']>;
  rate?: Maybe<Scalars['String']>;
  serie?: Maybe<Scalars['String']>;
  season?: Maybe<Scalars['String']>;
  episode?: Maybe<Scalars['String']>;
  storyline?: Maybe<Scalars['String']>;
  offset?: Maybe<Scalars['String']>;
  franchiseName?: Maybe<Scalars['String']>;
  franchiseId?: Maybe<Scalars['String']>;
  ratingArgentina?: Maybe<Scalars['String']>;
  ratingBrasil?: Maybe<Scalars['String']>;
  ratingMexico?: Maybe<Scalars['String']>;
  length?: Maybe<Scalars['String']>;
  summary?: Maybe<Scalars['String']>;
  /** Status Values (derived from "begin", "end" and "offset" values): "estimated" (before)  || "current" (during)  || "actual" (after) */
  status?: Maybe<Scalars['String']>;
};


export type ShowTaxonomyArgs = {
  tenant: Tenant;
};

export enum SortDirections {
  Asc = 'asc',
  Desc = 'desc'
}

export enum SortOrder {
  Ascending = 'Ascending',
  Descending = 'Descending'
}

export type Sport = {
  __typename?: 'Sport';
  has_media?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  territories_available?: Maybe<Array<Maybe<Territories>>>;
  leagues?: Maybe<Array<Maybe<League>>>;
  id?: Maybe<Scalars['ID']>;
  event_image?: Maybe<Scalars['String']>;
  icon_light?: Maybe<Scalars['String']>;
  icon_dark?: Maybe<Scalars['String']>;
  gracenote_id?: Maybe<Scalars['String']>;
  sportradar_id?: Maybe<Scalars['String']>;
};


export type SportLeaguesArgs = {
  id?: Maybe<Scalars['ID']>;
};

export type SportMetaData = {
  __typename?: 'SportMetaData';
  league?: Maybe<StatsLeagues>;
  sport?: Maybe<StatsSport>;
};

export type Sports = {
  __typename?: 'Sports';
  sports?: Maybe<Array<Maybe<Sport>>>;
  total?: Maybe<Scalars['Int']>;
  index_start?: Maybe<Scalars['Int']>;
  index_end?: Maybe<Scalars['Int']>;
};

export enum StateStatus {
  Scheduled = 'Scheduled',
  InProgress = 'InProgress',
  Complete = 'Complete'
}

export enum StatsLeagues {
  Mlb = 'MLB',
  Soccer = 'Soccer',
  Nhl = 'NHL',
  Ncaaf = 'NCAAF',
  Ncaabb = 'NCAABB',
  Nfl = 'NFL',
  Nba = 'NBA',
  Wnba = 'WNBA',
  OlympicBasketball = 'OlympicBasketball',
  GLeague = 'GLeague',
  Other = 'Other'
}

export enum StatsSport {
  Football = 'Football',
  Basketball = 'Basketball',
  Baseball = 'Baseball',
  Hockey = 'Hockey',
  Soccer = 'Soccer',
  Other = 'Other',
  Golf = 'Golf',
  Tennis = 'Tennis',
  Motorsports = 'Motorsports'
}

export enum Status {
  Scheduled = 'Scheduled',
  Postponed = 'Postponed',
  Unnecessary = 'Unnecessary',
  Suspended = 'Suspended',
  WDelay = 'WDelay',
  FDelay = 'FDelay',
  ODelay = 'ODelay',
  Created = 'Created',
  Cancelled = 'Cancelled',
  InProgress = 'InProgress',
  Reopened = 'Reopened',
  Complete = 'Complete',
  Closed = 'Closed',
  TimeTbd = 'TimeTBD',
  Maintenance = 'Maintenance',
  IfNecessary = 'IfNecessary',
  FlexSchedule = 'FlexSchedule',
  Halftime = 'Halftime',
  Delayed = 'Delayed',
  Interrupted = 'Interrupted',
  Abandoned = 'Abandoned'
}

export type Tag = {
  __typename?: 'Tag';
  id: Scalars['ID'];
  /** A unique name for the tag in a URL friendly format (i.e. nfl) */
  permalink: Scalars['String'];
  /** The root tag in the particular subtree */
  root?: Maybe<Tag>;
  menu?: Maybe<Menu>;
  /** Full display name for the tag */
  name: Scalars['String'];
  /** Programmable abbreviation to avoid complicated rules in the client */
  abbreviation?: Maybe<Scalars['String']>;
  /** Active is a control for discoverability; inactive tags are hidden by default in searchTags */
  active?: Maybe<Scalars['Boolean']>;
  /** The logo filename (currently) assumes that it is the tail of a URI */
  logoFileName?: Maybe<Scalars['String']>;
  /** Type of the tag and the entity it represents */
  type?: Maybe<TagType>;
  /** The primary color associated with the tag, represented as a string in hexadecimal format */
  color1?: Maybe<Scalars['String']>;
  /** The secondary color associated with the tag, represented as a string in hexadecimal format */
  color2?: Maybe<Scalars['String']>;
  /** A boolean value indicating whether the tag should display a logo or not */
  displayLogo?: Maybe<Scalars['Boolean']>;
  /** The date and time when the tag expires, represented as a string in ISO 8601 format */
  expiresAt?: Maybe<Scalars['String']>;
  /** A URL for the tag the origin resource */
  href?: Maybe<Scalars['String']>;
  /** Indicates if a tag  */
  newsletterEnabled?: Maybe<Scalars['Boolean']>;
  /** The parent tag */
  parent?: Maybe<Tag>;
  /** A display hint for relative positioning. Siblings should be well-ordered, but positions don't freely translate elsewhere */
  position?: Maybe<Scalars['Int']>;
  /** The URL BR Shop related to tag or campaign */
  shopUrl?: Maybe<Scalars['String']>;
  /** A short name for representing the tag */
  shortName?: Maybe<Scalars['String']>;
  /** Site section the tag belongs to */
  site?: Maybe<Scalars['String']>;
  /** The color associated with a team, represented as a string in hexadecimal format */
  teamColor?: Maybe<Scalars['String']>;
  /** A URL linking to a page where tickets for events associated with the tag can be purchased */
  ticketsLink?: Maybe<Scalars['String']>;
  /** A list of child tags associated with a tag */
  children: Array<Tag>;
  /** A list of parents tags associated with a tag */
  parents: Array<Tag>;
};


export type TagMenuArgs = {
  tenant: Tenant;
};

export enum TagType {
  Archived = 'Archived',
  Blog = 'Blog',
  CampaignHub = 'CampaignHub',
  Chain = 'Chain',
  City = 'City',
  Division = 'Division',
  Editorial = 'Editorial',
  Event = 'Event',
  Grouping = 'Grouping',
  League = 'League',
  Location = 'Location',
  Person = 'Person',
  Sport = 'Sport',
  State = 'State',
  Tag = 'Tag',
  Team = 'Team',
  Topic = 'Topic',
  Venue = 'Venue',
  Video = 'Video'
}

export type Tagging = {
  __typename?: 'Tagging';
  tags?: Maybe<Array<Maybe<Scalars['String']>>>;
  shows?: Maybe<Array<Maybe<Scalars['String']>>>;
  _id?: Maybe<Scalars['MongoID']>;
  events?: Maybe<Array<Maybe<Event>>>;
  tournaments?: Maybe<Array<Maybe<Tournament>>>;
  competitors?: Maybe<Array<Maybe<Competitor>>>;
};

export type TaxonomyTerm = {
  __typename?: 'TaxonomyTerm';
  schemaVersion?: Maybe<Scalars['String']>;
  tenant?: Maybe<Scalars['String']>;
  uuid: Scalars['String'];
  cmsId: Scalars['String'];
  isPublished?: Maybe<Scalars['Boolean']>;
  language?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  parentId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  created?: Maybe<Scalars['String']>;
  changed?: Maybe<Scalars['String']>;
  sportsRadarId?: Maybe<Scalars['String']>;
  tournamentId?: Maybe<Scalars['String']>;
  year?: Maybe<Scalars['String']>;
  image?: Maybe<Image>;
  _id: Scalars['MongoID'];
};

export enum TaxonomyType {
  SeriesTaxonomy = 'seriesTaxonomy',
  ShowTaxonomy = 'showTaxonomy'
}

export type Team = {
  __typename?: 'Team';
  abbreviation?: Maybe<Scalars['String']>;
  draws?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  losses?: Maybe<Scalars['String']>;
  market?: Maybe<Scalars['String']>;
  mascot?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  wins?: Maybe<Scalars['String']>;
};

/** GSP Tenant enumeration */
export enum Tenant {
  AewGame = 'aewGame',
  BleacherReport = 'bleacherReport',
  EstadioArgentina = 'estadioArgentina',
  EstadioBrazil = 'estadioBrazil',
  EstadioChile = 'estadioChile',
  Ncaa = 'ncaa'
}

export enum Tenants {
  EstadioChile = 'estadioChile',
  HboMaxBrazil = 'hboMaxBrazil',
  HboMaxMexico = 'hboMaxMexico',
  BleacherReport = 'bleacherReport',
  EstadioArgentina = 'estadioArgentina'
}

export enum Territories {
  Usa = 'USA'
}

export type Tournament = {
  __typename?: 'Tournament';
  hash?: Maybe<Scalars['String']>;
  display_name?: Maybe<Scalars['String']>;
  has_media?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  territories_available?: Maybe<Array<Maybe<Territories>>>;
  sport?: Maybe<Sport>;
  league?: Maybe<League>;
  team_tournament?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['ID']>;
  display_name_short?: Maybe<Scalars['String']>;
  is_popular?: Maybe<Scalars['Boolean']>;
  is_featured?: Maybe<Scalars['Boolean']>;
};

export type Tournaments = {
  __typename?: 'Tournaments';
  tournaments?: Maybe<Array<Maybe<Tournament>>>;
  total?: Maybe<Scalars['Int']>;
  index_start?: Maybe<Scalars['Int']>;
  index_end?: Maybe<Scalars['Int']>;
};

export type User = {
  __typename?: 'User';
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
};

export type Venue = {
  __typename?: 'Venue';
  address?: Maybe<Scalars['String']>;
  capacity?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  zip?: Maybe<Scalars['String']>;
  time_zone?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
};

export type Venues = {
  __typename?: 'Venues';
  venues?: Maybe<Array<Maybe<Venue>>>;
  total?: Maybe<Scalars['Int']>;
  index_start?: Maybe<Scalars['Int']>;
  index_end?: Maybe<Scalars['Int']>;
};

export type Video = {
  __typename?: 'Video';
  schemaVersion?: Maybe<Scalars['String']>;
  tenant?: Maybe<Scalars['String']>;
  uuid: Scalars['String'];
  cmsId: Scalars['String'];
  isPublished?: Maybe<Scalars['Boolean']>;
  language?: Maybe<Scalars['String']>;
  thumbnail?: Maybe<Scalars['String']>;
  mediaId?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  headline?: Maybe<Scalars['String']>;
  subHeadline?: Maybe<Scalars['String']>;
  duration?: Maybe<Scalars['String']>;
  cardLabel?: Maybe<Scalars['String']>;
  created?: Maybe<Scalars['String']>;
  changed?: Maybe<Scalars['String']>;
  sortDate?: Maybe<Scalars['String']>;
  collectionName?: Maybe<Scalars['String']>;
  episodeNumber?: Maybe<Scalars['String']>;
  tagging?: Maybe<Tagging>;
  _id: Scalars['MongoID'];
  /** @deprecated Use Video.tagging.events. */
  events?: Maybe<Array<Maybe<Event>>>;
};

export type VideoAnalytics = {
  __typename?: 'VideoAnalytics';
  /** Video Analytics id */
  id: Scalars['String'];
  /** Video Analytics creationTimePeriod */
  creationTimePeriod?: Maybe<Scalars['String']>;
  /** Video Analytics publishMethod */
  publishMethod?: Maybe<Scalars['String']>;
  /** Video Analytics publishedAt time */
  publishedAt?: Maybe<Scalars['String']>;
  /** Video Analytics rating */
  rating?: Maybe<Scalars['String']>;
  /** Video Analytics rule Id */
  ruleId?: Maybe<Scalars['String']>;
  /** Video Analytics rule Name */
  ruleName?: Maybe<Scalars['String']>;
  /** Video Analytics stream */
  stream?: Maybe<Scalars['String']>;
  /** Video Analytics title */
  title?: Maybe<Scalars['String']>;
  /** Video Analytics video Id */
  videoId?: Maybe<Scalars['Int']>;
  /** Video Analytics video Type from vid - (i.e. video/highlight) */
  videoType?: Maybe<Scalars['String']>;
  /** Video Analytics team */
  team?: Maybe<Scalars['String']>;
  /** Video Analytics league */
  league?: Maybe<Scalars['String']>;
};

/** Passing multiple filter values is currently **not supported** */
export type VideoFiltersInput = {
  /** Provides the ability to filter video clips to a specific Team. Value passed should be the **sportradar_id** (ex. `sr:competitor:1234`) */
  team?: Maybe<Scalars['String']>;
  /** Provides the ability to filter video clips to a specific Event. Value passed should be the **sportradar_id** (ex. `sr:match:1234`) */
  event?: Maybe<Scalars['String']>;
};

export type VideoImpressionTracking = {
  __typename?: 'VideoImpressionTracking';
  /** Video Impression Tracking name */
  name?: Maybe<Scalars['String']>;
  /** Video Impression Tracking url */
  url?: Maybe<Scalars['String']>;
};

export type VideoMetadata = {
  __typename?: 'VideoMetadata';
  /** Video's analytics */
  analytics?: Maybe<VideoAnalytics>;
  /** Video's duration */
  duration?: Maybe<Scalars['Int']>;
  /** Video's hlsUrl */
  hlsUrl?: Maybe<Scalars['String']>;
  /** Video's mp4Url */
  mp4Url?: Maybe<Scalars['String']>;
  /** Video's videoUrl */
  videoUrl?: Maybe<Scalars['String']>;
  /** Video's Id */
  videoId?: Maybe<Scalars['Int']>;
  /** Video's impressionTracking */
  impressionTracking?: Maybe<Array<Maybe<VideoImpressionTracking>>>;
  /** Video's width */
  width?: Maybe<Scalars['Int']>;
  /** Video's height */
  height?: Maybe<Scalars['Int']>;
  /** Video's state from airchain and hotmic (i.e. live, upcoming, etc.) */
  state?: Maybe<Scalars['String']>;
};



export type ResolverTypeWrapper<T> = Promise<T> | T;


export type ResolverWithResolve<TResult, TParent, TContext, TArgs> = {
  resolve: ResolverFn<TResult, TParent, TContext, TArgs>;
};

export type LegacyStitchingResolver<TResult, TParent, TContext, TArgs> = {
  fragment: string;
  resolve: ResolverFn<TResult, TParent, TContext, TArgs>;
};

export type NewStitchingResolver<TResult, TParent, TContext, TArgs> = {
  selectionSet: string;
  resolve: ResolverFn<TResult, TParent, TContext, TArgs>;
};
export type StitchingResolver<TResult, TParent, TContext, TArgs> = LegacyStitchingResolver<TResult, TParent, TContext, TArgs> | NewStitchingResolver<TResult, TParent, TContext, TArgs>;
export type Resolver<TResult, TParent = {}, TContext = {}, TArgs = {}> =
  | ResolverFn<TResult, TParent, TContext, TArgs>
  | ResolverWithResolve<TResult, TParent, TContext, TArgs>
  | StitchingResolver<TResult, TParent, TContext, TArgs>;

export type ResolverFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => Promise<TResult> | TResult;

export type SubscriptionSubscribeFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => AsyncIterator<TResult> | Promise<AsyncIterator<TResult>>;

export type SubscriptionResolveFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => TResult | Promise<TResult>;

export interface SubscriptionSubscriberObject<TResult, TKey extends string, TParent, TContext, TArgs> {
  subscribe: SubscriptionSubscribeFn<{ [key in TKey]: TResult }, TParent, TContext, TArgs>;
  resolve?: SubscriptionResolveFn<TResult, { [key in TKey]: TResult }, TContext, TArgs>;
}

export interface SubscriptionResolverObject<TResult, TParent, TContext, TArgs> {
  subscribe: SubscriptionSubscribeFn<any, TParent, TContext, TArgs>;
  resolve: SubscriptionResolveFn<TResult, any, TContext, TArgs>;
}

export type SubscriptionObject<TResult, TKey extends string, TParent, TContext, TArgs> =
  | SubscriptionSubscriberObject<TResult, TKey, TParent, TContext, TArgs>
  | SubscriptionResolverObject<TResult, TParent, TContext, TArgs>;

export type SubscriptionResolver<TResult, TKey extends string, TParent = {}, TContext = {}, TArgs = {}> =
  | ((...args: any[]) => SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>)
  | SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>;

export type TypeResolveFn<TTypes, TParent = {}, TContext = {}> = (
  parent: TParent,
  context: TContext,
  info: GraphQLResolveInfo
) => Maybe<TTypes> | Promise<Maybe<TTypes>>;

export type IsTypeOfResolverFn<T = {}, TContext = {}> = (obj: T, context: TContext, info: GraphQLResolveInfo) => boolean | Promise<boolean>;

export type NextResolverFn<T> = () => Promise<T>;

export type DirectiveResolverFn<TResult = {}, TParent = {}, TContext = {}, TArgs = {}> = (
  next: NextResolverFn<TResult>,
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => TResult | Promise<TResult>;

/** Mapping between all available schema types and the resolvers types */
export type ResolversTypes = {
  Article: ResolverTypeWrapper<Article>;
  String: ResolverTypeWrapper<Scalars['String']>;
  Boolean: ResolverTypeWrapper<Scalars['Boolean']>;
  ArticleBody: ResolverTypeWrapper<ArticleBody>;
  Author: ResolverTypeWrapper<Author>;
  CacheControlScope: CacheControlScope;
  Clock: ResolverTypeWrapper<Clock>;
  Competitor: ResolverTypeWrapper<Competitor>;
  ID: ResolverTypeWrapper<Scalars['ID']>;
  Competitors: ResolverTypeWrapper<Competitors>;
  Int: ResolverTypeWrapper<Scalars['Int']>;
  Component: ResolverTypeWrapper<Component>;
  Float: ResolverTypeWrapper<Scalars['Float']>;
  Conference: ResolverTypeWrapper<Conference>;
  Conferences: ResolverTypeWrapper<Conferences>;
  Content: ResolverTypeWrapper<Content>;
  ContentFilters: ResolverTypeWrapper<ContentFilters>;
  ContentForm: ResolverTypeWrapper<ContentForm>;
  ContentShow: ResolverTypeWrapper<ContentShow>;
  ContentSort: ResolverTypeWrapper<ContentSort>;
  Date: ResolverTypeWrapper<Scalars['Date']>;
  Division: ResolverTypeWrapper<Division>;
  Divisions: ResolverTypeWrapper<Divisions>;
  Event: ResolverTypeWrapper<Event>;
  EventFilterNames: EventFilterNames;
  EventFilterType: EventFilterType;
  EventSort: EventSort;
  EventTypes: EventTypes;
  Events: ResolverTypeWrapper<Events>;
  Feed: ResolverTypeWrapper<Feed>;
  Feeds: Feeds;
  FormElement: ResolverTypeWrapper<FormElement>;
  Game: ResolverTypeWrapper<Game>;
  GameDate: ResolverTypeWrapper<GameDate>;
  GameProgress: ResolverTypeWrapper<GameProgress>;
  Image: ResolverTypeWrapper<Image>;
  ImageWithStyle: ResolverTypeWrapper<ImageWithStyle>;
  Item: ResolverTypeWrapper<Item>;
  JSON: ResolverTypeWrapper<Scalars['JSON']>;
  League: ResolverTypeWrapper<League>;
  Leagues: ResolverTypeWrapper<Leagues>;
  Legacy: ResolverTypeWrapper<Legacy>;
  Link: ResolverTypeWrapper<Link>;
  Media: ResolverTypeWrapper<Media>;
  Menu: ResolverTypeWrapper<Menu>;
  MenuImage: ResolverTypeWrapper<MenuImage>;
  MenuLinks: ResolverTypeWrapper<MenuLinks>;
  MenuOptions: ResolverTypeWrapper<MenuOptions>;
  MenuOptionsAttributes: ResolverTypeWrapper<MenuOptionsAttributes>;
  Metadata: ResolverTypeWrapper<Metadata>;
  MongoID: ResolverTypeWrapper<Scalars['MongoID']>;
  Mutation: ResolverTypeWrapper<{}>;
  Page: ResolverTypeWrapper<Page>;
  PageBody: ResolverTypeWrapper<PageBody>;
  PageComponentFiltersInput: PageComponentFiltersInput;
  PageMetatags: ResolverTypeWrapper<PageMetatags>;
  Playlist: ResolverTypeWrapper<Playlist>;
  Product: ResolverTypeWrapper<Product>;
  Query: ResolverTypeWrapper<{}>;
  Reference: ResolverTypeWrapper<Reference>;
  ReferenceMetadata: ResolverTypeWrapper<ReferenceMetadata>;
  ReferenceStream: ResolverTypeWrapper<ReferenceStream>;
  ReferenceType: ReferenceType;
  Schedule: ResolverTypeWrapper<Schedule>;
  Score: ResolverTypeWrapper<Score>;
  Season: ResolverTypeWrapper<Season>;
  Series: ResolverTypeWrapper<Series>;
  SeriesSort: SeriesSort;
  Serieses: ResolverTypeWrapper<Serieses>;
  Show: ResolverTypeWrapper<Show>;
  SortDirections: SortDirections;
  SortOrder: SortOrder;
  Sport: ResolverTypeWrapper<Sport>;
  SportMetaData: ResolverTypeWrapper<SportMetaData>;
  Sports: ResolverTypeWrapper<Sports>;
  StateStatus: StateStatus;
  StatsLeagues: StatsLeagues;
  StatsSport: StatsSport;
  Status: Status;
  Tag: ResolverTypeWrapper<Tag>;
  TagType: TagType;
  Tagging: ResolverTypeWrapper<Tagging>;
  TaxonomyTerm: ResolverTypeWrapper<TaxonomyTerm>;
  TaxonomyType: TaxonomyType;
  Team: ResolverTypeWrapper<Team>;
  Tenant: Tenant;
  Tenants: Tenants;
  Territories: Territories;
  Tournament: ResolverTypeWrapper<Tournament>;
  Tournaments: ResolverTypeWrapper<Tournaments>;
  User: ResolverTypeWrapper<User>;
  Venue: ResolverTypeWrapper<Venue>;
  Venues: ResolverTypeWrapper<Venues>;
  Video: ResolverTypeWrapper<Video>;
  VideoAnalytics: ResolverTypeWrapper<VideoAnalytics>;
  VideoFiltersInput: VideoFiltersInput;
  VideoImpressionTracking: ResolverTypeWrapper<VideoImpressionTracking>;
  VideoMetadata: ResolverTypeWrapper<VideoMetadata>;
};

/** Mapping between all available schema types and the resolvers parents */
export type ResolversParentTypes = {
  Article: Article;
  String: Scalars['String'];
  Boolean: Scalars['Boolean'];
  ArticleBody: ArticleBody;
  Author: Author;
  Clock: Clock;
  Competitor: Competitor;
  ID: Scalars['ID'];
  Competitors: Competitors;
  Int: Scalars['Int'];
  Component: Component;
  Float: Scalars['Float'];
  Conference: Conference;
  Conferences: Conferences;
  Content: Content;
  ContentFilters: ContentFilters;
  ContentForm: ContentForm;
  ContentShow: ContentShow;
  ContentSort: ContentSort;
  Date: Scalars['Date'];
  Division: Division;
  Divisions: Divisions;
  Event: Event;
  EventFilterType: EventFilterType;
  Events: Events;
  Feed: Feed;
  FormElement: FormElement;
  Game: Game;
  GameDate: GameDate;
  GameProgress: GameProgress;
  Image: Image;
  ImageWithStyle: ImageWithStyle;
  Item: Item;
  JSON: Scalars['JSON'];
  League: League;
  Leagues: Leagues;
  Legacy: Legacy;
  Link: Link;
  Media: Media;
  Menu: Menu;
  MenuImage: MenuImage;
  MenuLinks: MenuLinks;
  MenuOptions: MenuOptions;
  MenuOptionsAttributes: MenuOptionsAttributes;
  Metadata: Metadata;
  MongoID: Scalars['MongoID'];
  Mutation: {};
  Page: Page;
  PageBody: PageBody;
  PageComponentFiltersInput: PageComponentFiltersInput;
  PageMetatags: PageMetatags;
  Playlist: Playlist;
  Product: Product;
  Query: {};
  Reference: Reference;
  ReferenceMetadata: ReferenceMetadata;
  ReferenceStream: ReferenceStream;
  Schedule: Schedule;
  Score: Score;
  Season: Season;
  Series: Series;
  Serieses: Serieses;
  Show: Show;
  Sport: Sport;
  SportMetaData: SportMetaData;
  Sports: Sports;
  Tag: Tag;
  Tagging: Tagging;
  TaxonomyTerm: TaxonomyTerm;
  Team: Team;
  Tournament: Tournament;
  Tournaments: Tournaments;
  User: User;
  Venue: Venue;
  Venues: Venues;
  Video: Video;
  VideoAnalytics: VideoAnalytics;
  VideoFiltersInput: VideoFiltersInput;
  VideoImpressionTracking: VideoImpressionTracking;
  VideoMetadata: VideoMetadata;
};

export type ArticleResolvers<ContextType = any, ParentType extends ResolversParentTypes['Article'] = ResolversParentTypes['Article']> = {
  schemaVersion?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  tenant?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  uuid?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  cmsId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  isPublished?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  language?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  created?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  changed?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  promote?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  body?: Resolver<Maybe<ResolversTypes['ArticleBody']>, ParentType, ContextType>;
  _id?: Resolver<ResolversTypes['MongoID'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ArticleBodyResolvers<ContextType = any, ParentType extends ResolversParentTypes['ArticleBody'] = ResolversParentTypes['ArticleBody']> = {
  value?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  summary?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  format?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  _id?: Resolver<Maybe<ResolversTypes['MongoID']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AuthorResolvers<ContextType = any, ParentType extends ResolversParentTypes['Author'] = ResolversParentTypes['Author']> = {
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ClockResolvers<ContextType = any, ParentType extends ResolversParentTypes['Clock'] = ResolversParentTypes['Clock']> = {
  hours?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  milliseconds?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  minutes?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  seconds?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  stoppage?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CompetitorResolvers<ContextType = any, ParentType extends ResolversParentTypes['Competitor'] = ResolversParentTypes['Competitor']> = {
  hash?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  draws?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  losses?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  market?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  overtime_losses?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  rank?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  sportradar_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  wins?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  sport?: Resolver<Maybe<ResolversTypes['Sport']>, ParentType, ContextType>;
  league?: Resolver<Maybe<ResolversTypes['League']>, ParentType, ContextType>;
  tournaments?: Resolver<Maybe<Array<Maybe<ResolversTypes['Tournament']>>>, ParentType, ContextType, RequireFields<CompetitorTournamentsArgs, never>>;
  leagues?: Resolver<Maybe<Array<Maybe<ResolversTypes['League']>>>, ParentType, ContextType, RequireFields<CompetitorLeaguesArgs, never>>;
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  team_alias?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  team_abbr?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  display_name_1?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  display_name_2?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  header_image_bucket?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  logo_dark?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  logo_light?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  logo_split?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CompetitorsResolvers<ContextType = any, ParentType extends ResolversParentTypes['Competitors'] = ResolversParentTypes['Competitors']> = {
  competitors?: Resolver<Maybe<Array<Maybe<ResolversTypes['Competitor']>>>, ParentType, ContextType>;
  total?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  index_start?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  index_end?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ComponentResolvers<ContextType = any, ParentType extends ResolversParentTypes['Component'] = ResolversParentTypes['Component']> = {
  type?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  weight?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  headline?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  subheadline?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  startTime?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>;
  endTime?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>;
  link?: Resolver<Maybe<ResolversTypes['Link']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  uuid?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  format?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  isFavoriteTeam?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  image?: Resolver<Maybe<ResolversTypes['Image']>, ParentType, ContextType>;
  images?: Resolver<Maybe<Array<Maybe<ResolversTypes['ImageWithStyle']>>>, ParentType, ContextType>;
  content?: Resolver<Maybe<Array<Maybe<ResolversTypes['Content']>>>, ParentType, ContextType>;
  _id?: Resolver<Maybe<ResolversTypes['MongoID']>, ParentType, ContextType>;
  referenceStream?: Resolver<Maybe<ResolversTypes['ReferenceStream']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ConferenceResolvers<ContextType = any, ParentType extends ResolversParentTypes['Conference'] = ResolversParentTypes['Conference']> = {
  league?: Resolver<Maybe<ResolversTypes['League']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  sport?: Resolver<Maybe<ResolversTypes['Sport']>, ParentType, ContextType>;
  sportradar_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  divisions?: Resolver<Maybe<Array<Maybe<ResolversTypes['Division']>>>, ParentType, ContextType, RequireFields<ConferenceDivisionsArgs, never>>;
  teams?: Resolver<Maybe<Array<Maybe<ResolversTypes['Competitor']>>>, ParentType, ContextType, RequireFields<ConferenceTeamsArgs, never>>;
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ConferencesResolvers<ContextType = any, ParentType extends ResolversParentTypes['Conferences'] = ResolversParentTypes['Conferences']> = {
  conferences?: Resolver<Maybe<Array<Maybe<ResolversTypes['Conference']>>>, ParentType, ContextType>;
  total?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  index_start?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  index_end?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ContentResolvers<ContextType = any, ParentType extends ResolversParentTypes['Content'] = ResolversParentTypes['Content']> = {
  hash?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  weight?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  allowedTypes?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  filters?: Resolver<Maybe<ResolversTypes['ContentFilters']>, ParentType, ContextType>;
  sort?: Resolver<Maybe<ResolversTypes['ContentSort']>, ParentType, ContextType>;
  clip?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  event?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  liveStream?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  league?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  tournament?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  team?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  product?: Resolver<Maybe<Array<Maybe<ResolversTypes['Product']>>>, ParentType, ContextType>;
  form?: Resolver<Maybe<ResolversTypes['ContentForm']>, ParentType, ContextType>;
  show?: Resolver<Maybe<ResolversTypes['ContentShow']>, ParentType, ContextType>;
  image?: Resolver<Maybe<ResolversTypes['Image']>, ParentType, ContextType>;
  item?: Resolver<Maybe<ResolversTypes['Item']>, ParentType, ContextType>;
  _id?: Resolver<Maybe<ResolversTypes['MongoID']>, ParentType, ContextType>;
  clips?: Resolver<Maybe<Array<Maybe<ResolversTypes['Video']>>>, ParentType, ContextType>;
  episodes?: Resolver<Maybe<Array<Maybe<ResolversTypes['Show']>>>, ParentType, ContextType>;
  events?: Resolver<Maybe<Array<Maybe<ResolversTypes['Event']>>>, ParentType, ContextType>;
  series?: Resolver<Maybe<Array<Maybe<ResolversTypes['Series']>>>, ParentType, ContextType>;
  teams?: Resolver<Maybe<Array<Maybe<ResolversTypes['Competitor']>>>, ParentType, ContextType>;
  tournaments?: Resolver<Maybe<Array<Maybe<ResolversTypes['Tournament']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ContentFiltersResolvers<ContextType = any, ParentType extends ResolversParentTypes['ContentFilters'] = ResolversParentTypes['ContentFilters']> = {
  isFavoriteTeam?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  maxItems?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  status?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  timeWindow?: Resolver<Maybe<Array<Maybe<ResolversTypes['Float']>>>, ParentType, ContextType>;
  shows?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  collections?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  tags?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  _id?: Resolver<Maybe<ResolversTypes['MongoID']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ContentFormResolvers<ContextType = any, ParentType extends ResolversParentTypes['ContentForm'] = ResolversParentTypes['ContentForm']> = {
  element?: Resolver<Maybe<ResolversTypes['FormElement']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ContentShowResolvers<ContextType = any, ParentType extends ResolversParentTypes['ContentShow'] = ResolversParentTypes['ContentShow']> = {
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  image?: Resolver<Maybe<ResolversTypes['Image']>, ParentType, ContextType>;
  weight?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  _id?: Resolver<Maybe<ResolversTypes['MongoID']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ContentSortResolvers<ContextType = any, ParentType extends ResolversParentTypes['ContentSort'] = ResolversParentTypes['ContentSort']> = {
  field?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  order?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface DateScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['Date'], any> {
  name: 'Date';
}

export type DivisionResolvers<ContextType = any, ParentType extends ResolversParentTypes['Division'] = ResolversParentTypes['Division']> = {
  conference?: Resolver<Maybe<ResolversTypes['Conference']>, ParentType, ContextType>;
  league?: Resolver<Maybe<ResolversTypes['League']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  sport?: Resolver<Maybe<ResolversTypes['Sport']>, ParentType, ContextType>;
  sportradar_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  division_alias?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  teams?: Resolver<Maybe<Array<Maybe<ResolversTypes['Competitor']>>>, ParentType, ContextType, RequireFields<DivisionTeamsArgs, never>>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DivisionsResolvers<ContextType = any, ParentType extends ResolversParentTypes['Divisions'] = ResolversParentTypes['Divisions']> = {
  divisions?: Resolver<Maybe<Array<Maybe<ResolversTypes['Division']>>>, ParentType, ContextType>;
  total?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  index_start?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  index_end?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EventResolvers<ContextType = any, ParentType extends ResolversParentTypes['Event'] = ResolversParentTypes['Event']> = {
  hash?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  card_type?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  color_1?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  color_2?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  derived_status?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  free_event?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  free_preview?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  has_media?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  media_asset_id_live?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  media_asset_id_vod?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  hidden?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  is_live?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  league?: Resolver<Maybe<ResolversTypes['League']>, ParentType, ContextType>;
  medium_vod?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  recommended?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  scheduled_utc?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  season?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  season_type?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  series_name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  series_id?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  sponsor_text?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  sport?: Resolver<Maybe<ResolversTypes['Sport']>, ParentType, ContextType>;
  sportradar_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  game?: Resolver<Maybe<ResolversTypes['Game']>, ParentType, ContextType>;
  territories_available?: Resolver<Maybe<Array<Maybe<ResolversTypes['Territories']>>>, ParentType, ContextType>;
  trending?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  week?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  time_zone?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  tournament?: Resolver<Maybe<ResolversTypes['Tournament']>, ParentType, ContextType>;
  venue?: Resolver<Maybe<ResolversTypes['Venue']>, ParentType, ContextType>;
  home?: Resolver<Maybe<ResolversTypes['Competitor']>, ParentType, ContextType>;
  away?: Resolver<Maybe<ResolversTypes['Competitor']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  home_team_points?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  away_team_points?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  event_image?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  header_image_bucket?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  event_image_bucket?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  period?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  logo_dark?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  logo_light?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EventsResolvers<ContextType = any, ParentType extends ResolversParentTypes['Events'] = ResolversParentTypes['Events']> = {
  events?: Resolver<Maybe<Array<Maybe<ResolversTypes['Event']>>>, ParentType, ContextType>;
  total?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  index_start?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  index_end?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FeedResolvers<ContextType = any, ParentType extends ResolversParentTypes['Feed'] = ResolversParentTypes['Feed']> = {
  code?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  link?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  logo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  shows?: Resolver<Maybe<Array<Maybe<ResolversTypes['Show']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FormElementResolvers<ContextType = any, ParentType extends ResolversParentTypes['FormElement'] = ResolversParentTypes['FormElement']> = {
  weight?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  headline?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  options?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  _id?: Resolver<Maybe<ResolversTypes['MongoID']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GameResolvers<ContextType = any, ParentType extends ResolversParentTypes['Game'] = ResolversParentTypes['Game']> = {
  sportradar_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  away?: Resolver<Maybe<ResolversTypes['Team']>, ParentType, ContextType>;
  gameDate?: Resolver<Maybe<ResolversTypes['GameDate']>, ParentType, ContextType>;
  gameProgress?: Resolver<Maybe<ResolversTypes['GameProgress']>, ParentType, ContextType>;
  home?: Resolver<Maybe<ResolversTypes['Team']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  score?: Resolver<Maybe<ResolversTypes['Score']>, ParentType, ContextType>;
  sourceGameId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  sport?: Resolver<Maybe<ResolversTypes['SportMetaData']>, ParentType, ContextType>;
  stateStatus?: Resolver<Maybe<ResolversTypes['StateStatus']>, ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  weekId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GameDateResolvers<ContextType = any, ParentType extends ResolversParentTypes['GameDate'] = ResolversParentTypes['GameDate']> = {
  epoch?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  iso8501?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GameProgressResolvers<ContextType = any, ParentType extends ResolversParentTypes['GameProgress'] = ResolversParentTypes['GameProgress']> = {
  clock?: Resolver<Maybe<ResolversTypes['Clock']>, ParentType, ContextType>;
  currentPeriod?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  displayPrimary?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  displaySecondary?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  totalPeriods?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ImageResolvers<ContextType = any, ParentType extends ResolversParentTypes['Image'] = ResolversParentTypes['Image']> = {
  url?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  alt?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  width?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  height?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  focalPointX?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  focalPointY?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  _id?: Resolver<Maybe<ResolversTypes['MongoID']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ImageWithStyleResolvers<ContextType = any, ParentType extends ResolversParentTypes['ImageWithStyle'] = ResolversParentTypes['ImageWithStyle']> = {
  image?: Resolver<Maybe<ResolversTypes['Image']>, ParentType, ContextType>;
  style?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  _id?: Resolver<Maybe<ResolversTypes['MongoID']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ItemResolvers<ContextType = any, ParentType extends ResolversParentTypes['Item'] = ResolversParentTypes['Item']> = {
  headline?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  subheadline?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  style?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  link?: Resolver<Maybe<ResolversTypes['Link']>, ParentType, ContextType>;
  startTime?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>;
  endTime?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>;
  images?: Resolver<Maybe<Array<Maybe<ResolversTypes['ImageWithStyle']>>>, ParentType, ContextType>;
  _id?: Resolver<Maybe<ResolversTypes['MongoID']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface JsonScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['JSON'], any> {
  name: 'JSON';
}

export type LeagueResolvers<ContextType = any, ParentType extends ResolversParentTypes['League'] = ResolversParentTypes['League']> = {
  alias?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  current_season?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  display_name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  season_end?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  season_start?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  sport?: Resolver<Maybe<ResolversTypes['Sport']>, ParentType, ContextType>;
  display_name_short?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LeaguesResolvers<ContextType = any, ParentType extends ResolversParentTypes['Leagues'] = ResolversParentTypes['Leagues']> = {
  leagues?: Resolver<Maybe<Array<Maybe<ResolversTypes['League']>>>, ParentType, ContextType>;
  total?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  index_start?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  index_end?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LegacyResolvers<ContextType = any, ParentType extends ResolversParentTypes['Legacy'] = ResolversParentTypes['Legacy']> = {
  league_id?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  sport_id?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LinkResolvers<ContextType = any, ParentType extends ResolversParentTypes['Link'] = ResolversParentTypes['Link']> = {
  uri?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  text?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  _id?: Resolver<Maybe<ResolversTypes['MongoID']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MediaResolvers<ContextType = any, ParentType extends ResolversParentTypes['Media'] = ResolversParentTypes['Media']> = {
  image?: Resolver<Maybe<ResolversTypes['Image']>, ParentType, ContextType>;
  clip?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  _id?: Resolver<Maybe<ResolversTypes['MongoID']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MenuResolvers<ContextType = any, ParentType extends ResolversParentTypes['Menu'] = ResolversParentTypes['Menu']> = {
  schemaVersion?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  tenant?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  uuid?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  cmsId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  isPublished?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  language?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  links?: Resolver<Maybe<Array<Maybe<ResolversTypes['MenuLinks']>>>, ParentType, ContextType>;
  _id?: Resolver<ResolversTypes['MongoID'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MenuImageResolvers<ContextType = any, ParentType extends ResolversParentTypes['MenuImage'] = ResolversParentTypes['MenuImage']> = {
  type?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  image?: Resolver<Maybe<ResolversTypes['Image']>, ParentType, ContextType>;
  _id?: Resolver<Maybe<ResolversTypes['MongoID']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MenuLinksResolvers<ContextType = any, ParentType extends ResolversParentTypes['MenuLinks'] = ResolversParentTypes['MenuLinks']> = {
  text?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  url?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  weight?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  options?: Resolver<Maybe<ResolversTypes['MenuOptions']>, ParentType, ContextType>;
  _id?: Resolver<Maybe<ResolversTypes['MongoID']>, ParentType, ContextType>;
  children?: Resolver<Maybe<Array<Maybe<ResolversTypes['MenuLinks']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MenuOptionsResolvers<ContextType = any, ParentType extends ResolversParentTypes['MenuOptions'] = ResolversParentTypes['MenuOptions']> = {
  target?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  expanded?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  images?: Resolver<Maybe<Array<Maybe<ResolversTypes['MenuImage']>>>, ParentType, ContextType>;
  attributes?: Resolver<Maybe<ResolversTypes['MenuOptionsAttributes']>, ParentType, ContextType>;
  _id?: Resolver<Maybe<ResolversTypes['MongoID']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MenuOptionsAttributesResolvers<ContextType = any, ParentType extends ResolversParentTypes['MenuOptionsAttributes'] = ResolversParentTypes['MenuOptionsAttributes']> = {
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  class?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MetadataResolvers<ContextType = any, ParentType extends ResolversParentTypes['Metadata'] = ResolversParentTypes['Metadata']> = {
  cdn?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface MongoIdScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['MongoID'], any> {
  name: 'MongoID';
}

export type MutationResolvers<ContextType = any, ParentType extends ResolversParentTypes['Mutation'] = ResolversParentTypes['Mutation']> = {
  login?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType, RequireFields<MutationLoginArgs, 'email' | 'password'>>;
};

export type PageResolvers<ContextType = any, ParentType extends ResolversParentTypes['Page'] = ResolversParentTypes['Page']> = {
  schemaVersion?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  tenant?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  uuid?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  cmsId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  isPublished?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  language?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  uri?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  headline?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  body?: Resolver<Maybe<ResolversTypes['PageBody']>, ParentType, ContextType>;
  created?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  changed?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  metatags?: Resolver<Maybe<Array<Maybe<ResolversTypes['PageMetatags']>>>, ParentType, ContextType>;
  components?: Resolver<Maybe<Array<Maybe<ResolversTypes['Component']>>>, ParentType, ContextType>;
  _id?: Resolver<ResolversTypes['MongoID'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PageBodyResolvers<ContextType = any, ParentType extends ResolversParentTypes['PageBody'] = ResolversParentTypes['PageBody']> = {
  value?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  summary?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  format?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  _id?: Resolver<Maybe<ResolversTypes['MongoID']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PageMetatagsResolvers<ContextType = any, ParentType extends ResolversParentTypes['PageMetatags'] = ResolversParentTypes['PageMetatags']> = {
  type?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  key?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  content?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  _id?: Resolver<Maybe<ResolversTypes['MongoID']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PlaylistResolvers<ContextType = any, ParentType extends ResolversParentTypes['Playlist'] = ResolversParentTypes['Playlist']> = {
  schemaVersion?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  tenant?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  uuid?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  cmsId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  isPublished?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  language?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  headline?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  created?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  changed?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  tagging?: Resolver<Maybe<ResolversTypes['Tagging']>, ParentType, ContextType>;
  media?: Resolver<Maybe<Array<Maybe<ResolversTypes['Media']>>>, ParentType, ContextType>;
  _id?: Resolver<ResolversTypes['MongoID'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ProductResolvers<ContextType = any, ParentType extends ResolversParentTypes['Product'] = ResolversParentTypes['Product']> = {
  weight?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  productId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  cta?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  price?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  headline?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  subHeadline?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  disclaimer?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  isPayPalEnabled?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  _id?: Resolver<Maybe<ResolversTypes['MongoID']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type QueryResolvers<ContextType = any, ParentType extends ResolversParentTypes['Query'] = ResolversParentTypes['Query']> = {
  getArticleById?: Resolver<Maybe<ResolversTypes['Article']>, ParentType, ContextType, RequireFields<QueryGetArticleByIdArgs, 'tenant' | 'publishedOnly'>>;
  getAllArticles?: Resolver<Maybe<Array<Maybe<ResolversTypes['Article']>>>, ParentType, ContextType, RequireFields<QueryGetAllArticlesArgs, 'tenant' | 'publishedOnly'>>;
  getAllVideos?: Resolver<Maybe<Array<Maybe<ResolversTypes['Video']>>>, ParentType, ContextType, RequireFields<QueryGetAllVideosArgs, 'limit' | 'order' | 'skip' | 'tenant' | 'publishedOnly'>>;
  getVideosByEventId?: Resolver<Maybe<Array<Maybe<ResolversTypes['Video']>>>, ParentType, ContextType, RequireFields<QueryGetVideosByEventIdArgs, 'limit' | 'order' | 'skip' | 'tenant' | 'publishedOnly'>>;
  getVideoById?: Resolver<Maybe<ResolversTypes['Video']>, ParentType, ContextType, RequireFields<QueryGetVideoByIdArgs, 'tenant' | 'publishedOnly'>>;
  getVideoByCmsId?: Resolver<Maybe<ResolversTypes['Video']>, ParentType, ContextType, RequireFields<QueryGetVideoByCmsIdArgs, 'limit' | 'skip' | 'tenant' | 'publishedOnly'>>;
  getVideosByTeamId?: Resolver<Maybe<Array<Maybe<ResolversTypes['Video']>>>, ParentType, ContextType, RequireFields<QueryGetVideosByTeamIdArgs, 'limit' | 'order' | 'skip' | 'tenant' | 'publishedOnly'>>;
  getPageByUri?: Resolver<Maybe<ResolversTypes['Page']>, ParentType, ContextType, RequireFields<QueryGetPageByUriArgs, 'uri' | 'tenant' | 'publishedOnly'>>;
  getAllPlaylists?: Resolver<Maybe<Array<Maybe<ResolversTypes['Playlist']>>>, ParentType, ContextType, RequireFields<QueryGetAllPlaylistsArgs, 'tenant' | 'publishedOnly'>>;
  getPlaylistById?: Resolver<Maybe<ResolversTypes['Playlist']>, ParentType, ContextType, RequireFields<QueryGetPlaylistByIdArgs, 'tenant' | 'publishedOnly'>>;
  getPlaylistByCmsId?: Resolver<Maybe<ResolversTypes['Playlist']>, ParentType, ContextType, RequireFields<QueryGetPlaylistByCmsIdArgs, 'tenant' | 'publishedOnly'>>;
  getPlaylistByEventId?: Resolver<Maybe<ResolversTypes['Playlist']>, ParentType, ContextType, RequireFields<QueryGetPlaylistByEventIdArgs, 'tenant' | 'publishedOnly'>>;
  getTaxonomyByIdAndType?: Resolver<Maybe<ResolversTypes['TaxonomyTerm']>, ParentType, ContextType, RequireFields<QueryGetTaxonomyByIdAndTypeArgs, 'id' | 'type' | 'tenant' | 'publishedOnly'>>;
  getAllMenus?: Resolver<Maybe<Array<Maybe<ResolversTypes['Menu']>>>, ParentType, ContextType, RequireFields<QueryGetAllMenusArgs, 'tenant' | 'publishedOnly'>>;
  getMenuById?: Resolver<Maybe<ResolversTypes['Menu']>, ParentType, ContextType, RequireFields<QueryGetMenuByIdArgs, 'tenant' | 'publishedOnly'>>;
  getHelloWorld?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  getScheduleByFeeds?: Resolver<Maybe<ResolversTypes['Schedule']>, ParentType, ContextType, RequireFields<QueryGetScheduleByFeedsArgs, 'feed'>>;
  getAllCompetitors?: Resolver<Maybe<Array<Maybe<ResolversTypes['Competitor']>>>, ParentType, ContextType, RequireFields<QueryGetAllCompetitorsArgs, 'tenant'>>;
  getCompetitorList?: Resolver<Maybe<ResolversTypes['Competitors']>, ParentType, ContextType, RequireFields<QueryGetCompetitorListArgs, 'tenant'>>;
  getCompetitorById?: Resolver<Maybe<ResolversTypes['Competitor']>, ParentType, ContextType, RequireFields<QueryGetCompetitorByIdArgs, 'id' | 'tenant'>>;
  getAllConferences?: Resolver<Maybe<Array<Maybe<ResolversTypes['Conference']>>>, ParentType, ContextType, RequireFields<QueryGetAllConferencesArgs, 'tenant'>>;
  getConferenceList?: Resolver<Maybe<ResolversTypes['Conferences']>, ParentType, ContextType, RequireFields<QueryGetConferenceListArgs, 'tenant'>>;
  getConferenceById?: Resolver<Maybe<ResolversTypes['Conference']>, ParentType, ContextType, RequireFields<QueryGetConferenceByIdArgs, 'id' | 'tenant'>>;
  getAllDivisions?: Resolver<Maybe<Array<Maybe<ResolversTypes['Division']>>>, ParentType, ContextType, RequireFields<QueryGetAllDivisionsArgs, 'tenant'>>;
  getDivisionList?: Resolver<Maybe<ResolversTypes['Divisions']>, ParentType, ContextType, RequireFields<QueryGetDivisionListArgs, 'tenant'>>;
  getDivisionById?: Resolver<Maybe<ResolversTypes['Division']>, ParentType, ContextType, RequireFields<QueryGetDivisionByIdArgs, 'id' | 'tenant'>>;
  getAllEvents?: Resolver<Maybe<Array<Maybe<ResolversTypes['Event']>>>, ParentType, ContextType, RequireFields<QueryGetAllEventsArgs, 'tenant'>>;
  getEventList?: Resolver<Maybe<ResolversTypes['Events']>, ParentType, ContextType, RequireFields<QueryGetEventListArgs, 'tenant'>>;
  getEventById?: Resolver<Maybe<ResolversTypes['Event']>, ParentType, ContextType, RequireFields<QueryGetEventByIdArgs, 'id' | 'tenant' | 'isSportRadar'>>;
  getAllLeagues?: Resolver<Maybe<Array<Maybe<ResolversTypes['League']>>>, ParentType, ContextType, RequireFields<QueryGetAllLeaguesArgs, 'tenant'>>;
  getLeagueList?: Resolver<Maybe<ResolversTypes['Leagues']>, ParentType, ContextType, RequireFields<QueryGetLeagueListArgs, 'tenant'>>;
  getLeagueById?: Resolver<Maybe<ResolversTypes['League']>, ParentType, ContextType, RequireFields<QueryGetLeagueByIdArgs, 'id' | 'tenant'>>;
  getAllSeries?: Resolver<Maybe<Array<Maybe<ResolversTypes['Series']>>>, ParentType, ContextType, RequireFields<QueryGetAllSeriesArgs, 'tenant'>>;
  getSeriesList?: Resolver<Maybe<ResolversTypes['Serieses']>, ParentType, ContextType, RequireFields<QueryGetSeriesListArgs, 'tenant'>>;
  getSeriesById?: Resolver<Maybe<ResolversTypes['Series']>, ParentType, ContextType, RequireFields<QueryGetSeriesByIdArgs, 'id' | 'tenant'>>;
  getAllSports?: Resolver<Maybe<Array<Maybe<ResolversTypes['Sport']>>>, ParentType, ContextType, RequireFields<QueryGetAllSportsArgs, 'tenant'>>;
  getSportList?: Resolver<Maybe<ResolversTypes['Sports']>, ParentType, ContextType, RequireFields<QueryGetSportListArgs, 'tenant'>>;
  getSportById?: Resolver<Maybe<ResolversTypes['Sport']>, ParentType, ContextType, RequireFields<QueryGetSportByIdArgs, 'id' | 'tenant'>>;
  getAllTournaments?: Resolver<Maybe<Array<Maybe<ResolversTypes['Tournament']>>>, ParentType, ContextType, RequireFields<QueryGetAllTournamentsArgs, 'tenant'>>;
  getTournamentList?: Resolver<Maybe<ResolversTypes['Tournaments']>, ParentType, ContextType, RequireFields<QueryGetTournamentListArgs, 'tenant'>>;
  getTournamentById?: Resolver<Maybe<ResolversTypes['Tournament']>, ParentType, ContextType, RequireFields<QueryGetTournamentByIdArgs, 'id' | 'tenant'>>;
  getAllVenues?: Resolver<Maybe<Array<Maybe<ResolversTypes['Venue']>>>, ParentType, ContextType, RequireFields<QueryGetAllVenuesArgs, 'tenant'>>;
  getVenueList?: Resolver<Maybe<ResolversTypes['Venues']>, ParentType, ContextType, RequireFields<QueryGetVenueListArgs, 'tenant'>>;
  getVenueById?: Resolver<Maybe<ResolversTypes['Venue']>, ParentType, ContextType, RequireFields<QueryGetVenueByIdArgs, 'id' | 'tenant'>>;
  viewer?: Resolver<ResolversTypes['User'], ParentType, ContextType>;
  getMetadata?: Resolver<Maybe<ResolversTypes['Metadata']>, ParentType, ContextType>;
  getReferenceStreamByName?: Resolver<Maybe<Array<ResolversTypes['Reference']>>, ParentType, ContextType, RequireFields<QueryGetReferenceStreamByNameArgs, 'referenceStreamName'>>;
  getGamesByGameDate?: Resolver<Maybe<Array<Maybe<ResolversTypes['Game']>>>, ParentType, ContextType, RequireFields<QueryGetGamesByGameDateArgs, 'startDate' | 'endDate' | 'timezone'>>;
  getGamesByGameIds?: Resolver<Maybe<Array<Maybe<ResolversTypes['Game']>>>, ParentType, ContextType, RequireFields<QueryGetGamesByGameIdsArgs, 'gameIds'>>;
  getGamesBySportRadarIds?: Resolver<Maybe<Array<Maybe<ResolversTypes['Game']>>>, ParentType, ContextType, RequireFields<QueryGetGamesBySportRadarIdsArgs, 'gameIds'>>;
  getTagById?: Resolver<ResolversTypes['Tag'], ParentType, ContextType, RequireFields<QueryGetTagByIdArgs, 'id' | 'tenant'>>;
  getTagsByPermalinks?: Resolver<Maybe<Array<Maybe<ResolversTypes['Tag']>>>, ParentType, ContextType, RequireFields<QueryGetTagsByPermalinksArgs, 'permalinks' | 'tenant'>>;
  getAllTeamsByPermalink?: Resolver<Array<ResolversTypes['Tag']>, ParentType, ContextType, RequireFields<QueryGetAllTeamsByPermalinkArgs, 'permalink' | 'tenant'>>;
  getSiteMap?: Resolver<ResolversTypes['Tag'], ParentType, ContextType, RequireFields<QueryGetSiteMapArgs, 'permalink' | 'client' | 'tenant'>>;
  findTagByMatchTerm?: Resolver<Array<ResolversTypes['Tag']>, ParentType, ContextType, RequireFields<QueryFindTagByMatchTermArgs, 'term' | 'tenant'>>;
};

export type ReferenceResolvers<ContextType = any, ParentType extends ResolversParentTypes['Reference'] = ResolversParentTypes['Reference']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  thumbnailUrl?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  permalink?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  url?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  author?: Resolver<Maybe<ResolversTypes['Author']>, ParentType, ContextType>;
  tag?: Resolver<ResolversTypes['Tag'], ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['ReferenceType']>, ParentType, ContextType>;
  metadata?: Resolver<Maybe<ResolversTypes['ReferenceMetadata']>, ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ReferenceMetadataResolvers<ContextType = any, ParentType extends ResolversParentTypes['ReferenceMetadata'] = ResolversParentTypes['ReferenceMetadata']> = {
  genres?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>;
  providerName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  photoCredit?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  video?: Resolver<Maybe<ResolversTypes['VideoMetadata']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ReferenceStreamResolvers<ContextType = any, ParentType extends ResolversParentTypes['ReferenceStream'] = ResolversParentTypes['ReferenceStream']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  tag?: Resolver<ResolversTypes['Tag'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  references?: Resolver<Maybe<Array<ResolversTypes['Reference']>>, ParentType, ContextType, RequireFields<ReferenceStreamReferencesArgs, never>>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ScheduleResolvers<ContextType = any, ParentType extends ResolversParentTypes['Schedule'] = ResolversParentTypes['Schedule']> = {
  dateFrom?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  dateTo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  offset?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  feeds?: Resolver<Maybe<Array<Maybe<ResolversTypes['Feed']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ScoreResolvers<ContextType = any, ParentType extends ResolversParentTypes['Score'] = ResolversParentTypes['Score']> = {
  away?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  home?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SeasonResolvers<ContextType = any, ParentType extends ResolversParentTypes['Season'] = ResolversParentTypes['Season']> = {
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  current?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  end?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  start?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SeriesResolvers<ContextType = any, ParentType extends ResolversParentTypes['Series'] = ResolversParentTypes['Series']> = {
  hash?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  taxonomy?: Resolver<Maybe<ResolversTypes['TaxonomyTerm']>, ParentType, ContextType>;
  season?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  tournament?: Resolver<Maybe<ResolversTypes['Tournament']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  series_name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  territories_available?: Resolver<Maybe<Array<Maybe<ResolversTypes['Territories']>>>, ParentType, ContextType>;
  start_date?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  events?: Resolver<Maybe<Array<Maybe<ResolversTypes['Event']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SeriesesResolvers<ContextType = any, ParentType extends ResolversParentTypes['Serieses'] = ResolversParentTypes['Serieses']> = {
  series?: Resolver<Maybe<Array<Maybe<ResolversTypes['Series']>>>, ParentType, ContextType>;
  total?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  index_start?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  index_end?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ShowResolvers<ContextType = any, ParentType extends ResolversParentTypes['Show'] = ResolversParentTypes['Show']> = {
  hash?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  taxonomy?: Resolver<Maybe<ResolversTypes['TaxonomyTerm']>, ParentType, ContextType, RequireFields<ShowTaxonomyArgs, 'tenant'>>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  titleId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  originalTitle?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  genreList?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  highlightList?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  airDateTime?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  lang?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  begin?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  end?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  rate?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  serie?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  season?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  episode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  storyline?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  offset?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  franchiseName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  franchiseId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ratingArgentina?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ratingBrasil?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ratingMexico?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  length?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  summary?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SportResolvers<ContextType = any, ParentType extends ResolversParentTypes['Sport'] = ResolversParentTypes['Sport']> = {
  has_media?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  territories_available?: Resolver<Maybe<Array<Maybe<ResolversTypes['Territories']>>>, ParentType, ContextType>;
  leagues?: Resolver<Maybe<Array<Maybe<ResolversTypes['League']>>>, ParentType, ContextType, RequireFields<SportLeaguesArgs, never>>;
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  event_image?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  icon_light?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  icon_dark?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  gracenote_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  sportradar_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SportMetaDataResolvers<ContextType = any, ParentType extends ResolversParentTypes['SportMetaData'] = ResolversParentTypes['SportMetaData']> = {
  league?: Resolver<Maybe<ResolversTypes['StatsLeagues']>, ParentType, ContextType>;
  sport?: Resolver<Maybe<ResolversTypes['StatsSport']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SportsResolvers<ContextType = any, ParentType extends ResolversParentTypes['Sports'] = ResolversParentTypes['Sports']> = {
  sports?: Resolver<Maybe<Array<Maybe<ResolversTypes['Sport']>>>, ParentType, ContextType>;
  total?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  index_start?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  index_end?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TagResolvers<ContextType = any, ParentType extends ResolversParentTypes['Tag'] = ResolversParentTypes['Tag']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  permalink?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  root?: Resolver<Maybe<ResolversTypes['Tag']>, ParentType, ContextType>;
  menu?: Resolver<Maybe<ResolversTypes['Menu']>, ParentType, ContextType, RequireFields<TagMenuArgs, 'tenant'>>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  abbreviation?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  active?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  logoFileName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['TagType']>, ParentType, ContextType>;
  color1?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  color2?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  displayLogo?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  expiresAt?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  href?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  newsletterEnabled?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  parent?: Resolver<Maybe<ResolversTypes['Tag']>, ParentType, ContextType>;
  position?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  shopUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  shortName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  site?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  teamColor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ticketsLink?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  children?: Resolver<Array<ResolversTypes['Tag']>, ParentType, ContextType>;
  parents?: Resolver<Array<ResolversTypes['Tag']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaggingResolvers<ContextType = any, ParentType extends ResolversParentTypes['Tagging'] = ResolversParentTypes['Tagging']> = {
  tags?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  shows?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  _id?: Resolver<Maybe<ResolversTypes['MongoID']>, ParentType, ContextType>;
  events?: Resolver<Maybe<Array<Maybe<ResolversTypes['Event']>>>, ParentType, ContextType>;
  tournaments?: Resolver<Maybe<Array<Maybe<ResolversTypes['Tournament']>>>, ParentType, ContextType>;
  competitors?: Resolver<Maybe<Array<Maybe<ResolversTypes['Competitor']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaxonomyTermResolvers<ContextType = any, ParentType extends ResolversParentTypes['TaxonomyTerm'] = ResolversParentTypes['TaxonomyTerm']> = {
  schemaVersion?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  tenant?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  uuid?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  cmsId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  isPublished?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  language?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  parentId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  created?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  changed?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  sportsRadarId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  tournamentId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  year?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  image?: Resolver<Maybe<ResolversTypes['Image']>, ParentType, ContextType>;
  _id?: Resolver<ResolversTypes['MongoID'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TeamResolvers<ContextType = any, ParentType extends ResolversParentTypes['Team'] = ResolversParentTypes['Team']> = {
  abbreviation?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  draws?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  logo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  losses?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  market?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  mascot?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  wins?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TournamentResolvers<ContextType = any, ParentType extends ResolversParentTypes['Tournament'] = ResolversParentTypes['Tournament']> = {
  hash?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  display_name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  has_media?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  territories_available?: Resolver<Maybe<Array<Maybe<ResolversTypes['Territories']>>>, ParentType, ContextType>;
  sport?: Resolver<Maybe<ResolversTypes['Sport']>, ParentType, ContextType>;
  league?: Resolver<Maybe<ResolversTypes['League']>, ParentType, ContextType>;
  team_tournament?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  display_name_short?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  is_popular?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  is_featured?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TournamentsResolvers<ContextType = any, ParentType extends ResolversParentTypes['Tournaments'] = ResolversParentTypes['Tournaments']> = {
  tournaments?: Resolver<Maybe<Array<Maybe<ResolversTypes['Tournament']>>>, ParentType, ContextType>;
  total?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  index_start?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  index_end?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserResolvers<ContextType = any, ParentType extends ResolversParentTypes['User'] = ResolversParentTypes['User']> = {
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type VenueResolvers<ContextType = any, ParentType extends ResolversParentTypes['Venue'] = ResolversParentTypes['Venue']> = {
  address?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  capacity?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  city?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  country?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  state?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  zip?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  time_zone?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type VenuesResolvers<ContextType = any, ParentType extends ResolversParentTypes['Venues'] = ResolversParentTypes['Venues']> = {
  venues?: Resolver<Maybe<Array<Maybe<ResolversTypes['Venue']>>>, ParentType, ContextType>;
  total?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  index_start?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  index_end?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type VideoResolvers<ContextType = any, ParentType extends ResolversParentTypes['Video'] = ResolversParentTypes['Video']> = {
  schemaVersion?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  tenant?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  uuid?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  cmsId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  isPublished?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  language?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  thumbnail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  mediaId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  headline?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  subHeadline?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  duration?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  cardLabel?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  created?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  changed?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  sortDate?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  collectionName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  episodeNumber?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  tagging?: Resolver<Maybe<ResolversTypes['Tagging']>, ParentType, ContextType>;
  _id?: Resolver<ResolversTypes['MongoID'], ParentType, ContextType>;
  events?: Resolver<Maybe<Array<Maybe<ResolversTypes['Event']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type VideoAnalyticsResolvers<ContextType = any, ParentType extends ResolversParentTypes['VideoAnalytics'] = ResolversParentTypes['VideoAnalytics']> = {
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  creationTimePeriod?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  publishMethod?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  publishedAt?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  rating?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ruleId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ruleName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  stream?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  videoId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  videoType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  team?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  league?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type VideoImpressionTrackingResolvers<ContextType = any, ParentType extends ResolversParentTypes['VideoImpressionTracking'] = ResolversParentTypes['VideoImpressionTracking']> = {
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  url?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type VideoMetadataResolvers<ContextType = any, ParentType extends ResolversParentTypes['VideoMetadata'] = ResolversParentTypes['VideoMetadata']> = {
  analytics?: Resolver<Maybe<ResolversTypes['VideoAnalytics']>, ParentType, ContextType>;
  duration?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  hlsUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  mp4Url?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  videoUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  videoId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  impressionTracking?: Resolver<Maybe<Array<Maybe<ResolversTypes['VideoImpressionTracking']>>>, ParentType, ContextType>;
  width?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  height?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  state?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Resolvers<ContextType = any> = {
  Article?: ArticleResolvers<ContextType>;
  ArticleBody?: ArticleBodyResolvers<ContextType>;
  Author?: AuthorResolvers<ContextType>;
  Clock?: ClockResolvers<ContextType>;
  Competitor?: CompetitorResolvers<ContextType>;
  Competitors?: CompetitorsResolvers<ContextType>;
  Component?: ComponentResolvers<ContextType>;
  Conference?: ConferenceResolvers<ContextType>;
  Conferences?: ConferencesResolvers<ContextType>;
  Content?: ContentResolvers<ContextType>;
  ContentFilters?: ContentFiltersResolvers<ContextType>;
  ContentForm?: ContentFormResolvers<ContextType>;
  ContentShow?: ContentShowResolvers<ContextType>;
  ContentSort?: ContentSortResolvers<ContextType>;
  Date?: GraphQLScalarType;
  Division?: DivisionResolvers<ContextType>;
  Divisions?: DivisionsResolvers<ContextType>;
  Event?: EventResolvers<ContextType>;
  Events?: EventsResolvers<ContextType>;
  Feed?: FeedResolvers<ContextType>;
  FormElement?: FormElementResolvers<ContextType>;
  Game?: GameResolvers<ContextType>;
  GameDate?: GameDateResolvers<ContextType>;
  GameProgress?: GameProgressResolvers<ContextType>;
  Image?: ImageResolvers<ContextType>;
  ImageWithStyle?: ImageWithStyleResolvers<ContextType>;
  Item?: ItemResolvers<ContextType>;
  JSON?: GraphQLScalarType;
  League?: LeagueResolvers<ContextType>;
  Leagues?: LeaguesResolvers<ContextType>;
  Legacy?: LegacyResolvers<ContextType>;
  Link?: LinkResolvers<ContextType>;
  Media?: MediaResolvers<ContextType>;
  Menu?: MenuResolvers<ContextType>;
  MenuImage?: MenuImageResolvers<ContextType>;
  MenuLinks?: MenuLinksResolvers<ContextType>;
  MenuOptions?: MenuOptionsResolvers<ContextType>;
  MenuOptionsAttributes?: MenuOptionsAttributesResolvers<ContextType>;
  Metadata?: MetadataResolvers<ContextType>;
  MongoID?: GraphQLScalarType;
  Mutation?: MutationResolvers<ContextType>;
  Page?: PageResolvers<ContextType>;
  PageBody?: PageBodyResolvers<ContextType>;
  PageMetatags?: PageMetatagsResolvers<ContextType>;
  Playlist?: PlaylistResolvers<ContextType>;
  Product?: ProductResolvers<ContextType>;
  Query?: QueryResolvers<ContextType>;
  Reference?: ReferenceResolvers<ContextType>;
  ReferenceMetadata?: ReferenceMetadataResolvers<ContextType>;
  ReferenceStream?: ReferenceStreamResolvers<ContextType>;
  Schedule?: ScheduleResolvers<ContextType>;
  Score?: ScoreResolvers<ContextType>;
  Season?: SeasonResolvers<ContextType>;
  Series?: SeriesResolvers<ContextType>;
  Serieses?: SeriesesResolvers<ContextType>;
  Show?: ShowResolvers<ContextType>;
  Sport?: SportResolvers<ContextType>;
  SportMetaData?: SportMetaDataResolvers<ContextType>;
  Sports?: SportsResolvers<ContextType>;
  Tag?: TagResolvers<ContextType>;
  Tagging?: TaggingResolvers<ContextType>;
  TaxonomyTerm?: TaxonomyTermResolvers<ContextType>;
  Team?: TeamResolvers<ContextType>;
  Tournament?: TournamentResolvers<ContextType>;
  Tournaments?: TournamentsResolvers<ContextType>;
  User?: UserResolvers<ContextType>;
  Venue?: VenueResolvers<ContextType>;
  Venues?: VenuesResolvers<ContextType>;
  Video?: VideoResolvers<ContextType>;
  VideoAnalytics?: VideoAnalyticsResolvers<ContextType>;
  VideoImpressionTracking?: VideoImpressionTrackingResolvers<ContextType>;
  VideoMetadata?: VideoMetadataResolvers<ContextType>;
};


/**
 * @deprecated
 * Use "Resolvers" root object instead. If you wish to get "IResolvers", add "typesPrefix: I" to your config.
 */
export type IResolvers<ContextType = any> = Resolvers<ContextType>;
