import React from 'react';
import { View, Image } from 'react-native';
import { ImageAssets } from '@warnermmedia/gsp-core/brands/estadio/assets';

/* eslint-disable-next-line */
export interface TntSportsProps {
  width?: number | string;
  height?: number | string;
}

export function TntSports(props: TntSportsProps) {
  const width = props.width || 50;
  const height = props.height || 50;
  return (
    <View>
      <Image style={{ height: height, width: width }} source={ImageAssets.logoTntRebrand} />
    </View>
  );
}

export default TntSports;
