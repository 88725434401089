import React from 'react';
import { Avatar, List, useTheme } from 'react-native-paper';
import { Text, View } from 'react-native';
import { callbackNavigateExternal, getFooterGoToLink } from '@warnermmedia/gsp-core/brands/estadio/feature';
import { breakpointsStateStore } from '@warnermmedia/gsp-core/brands/estadio/data-access';
import { useReactiveVar } from '@apollo/client';
import { getStyles } from './footer.styles';
import { Hoverable } from 'react-native-web-hover';
import { Breakpoints } from '@warnermmedia/gsp-core/sdk/ui';

/* eslint-disable-next-line */
export interface LeftProps {
  goTo: string;
  copyright: string;
  appVersion: string;
  breakpoints: Breakpoints;
  colors: ReactNativePaper.ThemeColors;
}

export const FooterLeft = (props: LeftProps) => {
  const styles = getStyles(props.breakpoints, props.colors);

  const renderGotoLink = () => {
    return (
      <Hoverable>
        {({ hovered }) => (
          <List.Item
            title={props.goTo}
            style={styles.gotoIconSelf}
            onPress={() => callbackNavigateExternal(getFooterGoToLink())}
            titleStyle={styles.titleStyles}
            left={() => (
              <Avatar.Icon
                size={24}
                icon="open-in-new"
                color={'white'}
                style={[
                  styles.icon,
                  {
                    backgroundColor: hovered
                      ? props.colors.tenantBackground.dark.surface04
                      : props.colors.tenantBackground.dark.surfaceBase,
                  },
                ]}
              />
            )}
          />
        )}
      </Hoverable>
    );
  };

  return (
    <View style={styles.openInNew}>
      <View style={styles.footerBottom}>
        <Text style={styles.stadium}>{props.copyright}</Text>
        <Text style={styles.version}>{props.appVersion}</Text>
      </View>
      <View style={styles.goToLinkContainer}>{renderGotoLink()}</View>
    </View>
  );
};
