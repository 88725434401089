import React, { useEffect, useState } from 'react';
import { Text } from 'react-native';
import { Button, useTheme } from 'react-native-paper';
import { useReactiveVar } from '@apollo/client';
import { errorMsgStore, ModalType } from '@warnermmedia/gsp-core/brands/estadio/data-access';
import { FocusTrap } from '@warnermedia/gsp-core/sdk/arcade-machine';
import { styles } from './styles';
import { ErrorMsgState } from '@warnermmedia/gsp-core/sdk/data-access';
import { useGetDevice } from '@warnermmedia/gsp-core/sdk/ui';
import { ModalWrapper } from '@warnermmedia/gsp-core/brands/estadio/ui';

const ErrorModal = () => {
  const [error, setError] = useState('We are having issues');
  const errorStore = useReactiveVar<ErrorMsgState>(errorMsgStore);
  const { isPwa } = useGetDevice();
  const { colors } = useTheme();
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    if (errorStore.message) {
      setError(errorStore.message);
      setVisible(true);
    }
  }, [errorStore]);

  const hideModal = () => {
    setError('');
    setVisible(false);
    errorMsgStore({
      ...errorMsgStore(),
      message: null,
    });
  };
  const containerStyle = { backgroundColor: colors.tenantBackground.light.surfaceBase, padding: 20 };

  return (
    <ModalWrapper
      visible={visible}
      animationType={'slide'}
      presentationStyle={'overFullScreen'}
      onClose={hideModal}
      modalType={visible ? ModalType.ErrorModal : null}
      modalLayoutContainerStyle={isPwa ? styles.webModalContainer : styles.modalContainer}
      modalMainContainerStyle={containerStyle}
    >
      <>
        <Text style={styles.textStyle}>We are having issues.</Text>
        <Text style={styles.textStyle}>{error}</Text>
        {isPwa ? (
          <FocusTrap>
            <Button style={styles.btnStyle} onPress={hideModal}>
              OK
            </Button>
          </FocusTrap>
        ) : (
          <Button style={styles.btnStyle} onPress={hideModal}>
            OK
          </Button>
        )}
      </>
    </ModalWrapper>
  );
};

export default ErrorModal;
