import React, { useCallback, useMemo } from 'react';
import { ListRenderItemInfo, Text, View } from 'react-native';
import { DirectionalArrow } from '../directionalArrow/directionalArrow';
import { Breakpoints, Carousel, CarouselPropsExtra, DeviceTypes } from '@warnermmedia/gsp-core/sdk/ui';
import BaseCard from '../../cards/base/baseCard';
import { getStyles } from './universalSwimlane.styels';
import { carouselBase, useHideSwimlaneArrow } from '@warnermmedia/gsp-core/brands/estadio/feature';

export interface CardProps extends CarouselPropsExtra<carouselBase> {
  /**
   * Array of carousel items
   */
  data: carouselBase[];
  pagingEnabled?: boolean;
  cardBg?: string;
  breakpoints: Breakpoints;
  device: DeviceTypes;
  colors: ReactNativePaper.ThemeColors;
}

export const UniversalSwimlane = ({
  headline,
  index: slideIndex,
  laneWidth,
  indexUpdate,
  data,
  itemWidth,
  action,
  type,
  format,
  cardBg,
  device,
  breakpoints,
  colors,
  ...props
}: CardProps) => {
  const styles = getStyles(breakpoints, device.isTv, device.isAndroidtv, colors);

  // These values set the height of the card body that
  // we want to center the arrows on.
  const cardHeight = useMemo(
    () =>
      format === 'dpg'
        ? breakpoints.currentBreakpoints.isMedLg ||
          breakpoints.currentBreakpoints.isLgXl ||
          breakpoints.windowWidth === breakpoints.breakpointSizes.lg
          ? 205
          : breakpoints.currentBreakpoints.isXlXxl || breakpoints.currentBreakpoints.isXxl
          ? 217
          : device.isTv
          ? 310
          : 234
        : breakpoints.currentBreakpoints.isMedLg ||
          breakpoints.currentBreakpoints.isLgXl ||
          breakpoints.windowWidth === breakpoints.breakpointSizes.lg
        ? 144
        : breakpoints.currentBreakpoints.isXlXxl || breakpoints.currentBreakpoints.isXxl
        ? 155
        : device.isTv
        ? 270
        : 195,
    [
      format,
      device.isTv,
      breakpoints.currentBreakpoints.isLgXl,
      breakpoints.currentBreakpoints.isMedLg,
      breakpoints.currentBreakpoints.isXlXxl,
      breakpoints.currentBreakpoints.isXxl,
    ]
  );

  // This is card width + left/right margin = entire width of card
  // So the carousel knows how much to scroll for each item.
  const cardWidth: number = useMemo(
    () =>
      breakpoints.currentBreakpoints.isMedLg || breakpoints.currentBreakpoints.isLgXl
        ? 234
        : breakpoints.currentBreakpoints.isXlXxl || breakpoints.currentBreakpoints.isXxl
        ? 255
        : device.isTv
        ? 421
        : 286,
    [
      breakpoints.currentBreakpoints.isLgXl,
      breakpoints.currentBreakpoints.isMedLg,
      breakpoints.currentBreakpoints.isXlXxl,
      breakpoints.currentBreakpoints.isXxl,
      device.isTv,
    ]
  );

  const slidesToMove = (cardWidth ? Math.round(breakpoints.windowWidth / cardWidth) - 1 : 1) || 1;
  const { hideRightArrow, hideLeftArrow } = useHideSwimlaneArrow(slidesToMove, slideIndex, data.length);
  const cardSlide = useCallback(
    ({ item, index, separators }: ListRenderItemInfo<carouselBase>) =>
      slideIndex in data ? (
        <BaseCard
          currentIndex={slideIndex}
          laneWidth={laneWidth}
          action={action}
          item={item}
          index={index}
          type={format ?? ''}
          separators={separators}
          cardBg={cardBg}
          colors={colors}
          breakpoints={breakpoints}
          device={device}
        />
      ) : (
        <View />
      ),
    [slideIndex, laneWidth, action, format, data, breakpoints, cardBg, colors, device]
  );

  const rightMargin = useCallback(() => {
    //ipad Pro 12.9
    if (device.isMobileDevice && breakpoints.currentBreakpoints.isLgXl) {
      return 55;
      //ipad Pro 11, ipad
    } else if (device.isMobileDevice && breakpoints.currentBreakpoints.isMedLg) {
      return 55;
      //iphone 13,13max, Android
    } else if (device.isMobileDevice && breakpoints.currentBreakpoints.isTnySm) {
      return 40;
    } else return 50;
  }, [device.isMobileDevice, breakpoints.currentBreakpoints]);

  return (
    <View style={styles.cardsLayout}>
      <Text style={styles.headline}>{headline}</Text>
      <View>
        {!hideLeftArrow && (
          <DirectionalArrow
            dataLength={data.length}
            itemWidth={cardWidth}
            index={slideIndex}
            indexUpdate={indexUpdate}
            isLeftPoint={true}
            slidesToMove={slidesToMove}
            device={device}
            colors={colors}
            breakpoints={breakpoints}
            overrideStyle={{ marginTop: cardHeight / 2 }}
          />
        )}
        <View>
          <Carousel
            data={data}
            index={slideIndex}
            renderItem={cardSlide}
            indexUpdate={indexUpdate}
            laneWidth={laneWidth}
            itemWidth={cardWidth}
            contentContainerStyle={[
              styles.contentContainerStyle,
              { width: cardWidth * (data.length || 1) + rightMargin() },
            ]}
            onEndReachedThreshold={0.01}
            snapToOffsets={data?.map((x, index) => index * cardWidth)}
            device={device}
            windowSize={7}
            {...props}
          />
        </View>

        {!hideRightArrow && (
          <DirectionalArrow
            dataLength={data.length}
            itemWidth={cardWidth}
            index={slideIndex}
            indexUpdate={indexUpdate}
            isLeftPoint={false}
            slidesToMove={slidesToMove}
            device={device}
            colors={colors}
            breakpoints={breakpoints}
            overrideStyle={{ marginTop: cardHeight / 2 }}
          />
        )}
      </View>
    </View>
  );
};

export default UniversalSwimlane;
