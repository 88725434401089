import dayjs, { QUnitType } from 'dayjs';
import isTodayDate from 'dayjs/plugin/isToday';
import isTomorrow from 'dayjs/plugin/isTomorrow';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import isBetween from 'dayjs/plugin/isBetween';
import utc from 'dayjs/plugin/utc';
import tz from 'dayjs/plugin/timezone';
import advanced from 'dayjs/plugin/advancedFormat';
import duration, { Duration, DurationUnitType } from 'dayjs/plugin/duration';
import { Platform } from 'react-native';
import moment from 'moment-timezone';

dayjs.extend(isSameOrBefore);
dayjs.extend(isTodayDate);
dayjs.extend(isTomorrow);
dayjs.extend(advanced);
dayjs.extend(duration);
dayjs.extend(isBetween);
dayjs.extend(utc);
dayjs.extend(tz);

// eslint-disable-next-line @typescript-eslint/no-var-requires
const timeZone = Platform.OS === 'web' ? dayjs.tz.guess() : require('react-native-localize').getTimeZone();
dayjs.tz.setDefault(timeZone);

const getToTimezone = (date: string | number, dateTz: string): string => {
  // Using moment-timezone solves the issue as work around when we don't know the date timezone,
  // We need to parse the requested timezone
  return moment.tz(date, dateTz).format('YYYY-MM-DDTHH:mm:ssZ');
};
const getToUtcOffset = (utc: dayjs.Dayjs): dayjs.Dayjs => {
  // Dayjs has an open bug related to the TZ conversion https://github.com/iamkun/dayjs/issues/1827
  // Using moment-timezone solves the issue as work around
  const formattedDate = moment(utc.format()).tz(timeZone);

  // We disable converting to local timezone in dayjs by passing in the original timezone as an additional argument in the dayjs constructor
  // This solves the issue in Tizen
  const formattedDateISOString = formattedDate.toISOString(true);
  const originalTimezone = formattedDateISOString.slice(-6);
  return dayjs(formattedDateISOString).utcOffset(originalTimezone);
};

export const now = (): dayjs.Dayjs => {
  return dayjs();
};

export const getDate = (date: Date | string | number): dayjs.Dayjs => {
  return dayjs(date);
};

export const getDuration = (duration: number, unit?: DurationUnitType): Duration => {
  return dayjs.duration(duration, unit);
};
export const getLocalDate = (date: string | number, dateTz?: string | null): dayjs.Dayjs | null => {
  const utc = dayjs.utc(dateTz ? getToTimezone(date, dateTz) : date);

  if (!utc.isValid()) {
    return null;
  }
  return getToUtcOffset(utc);
};

export const getDurationTime = (duration: number, format = 'HH:mm:ss'): string => {
  return dayjs.duration(Math.abs(duration)).format(format);
};

export const getTimestamp = (): string => {
  return dayjs().unix().toString();
};

export const formatTimestamp = (timestamp: number): dayjs.Dayjs => {
  return dayjs.unix(timestamp);
};

export const getDiff = (date: string, unit?: QUnitType): string => {
  return dayjs().diff(date, unit).toString();
};
