import React, { useCallback, useContext, useState } from 'react';
import { Linking, View } from 'react-native';
import { IconButton, useTheme } from 'react-native-paper';
import {
  Dalton,
  languageStrings,
  ScreenEventType,
  useMparticleScreenEvent,
} from '@warnermmedia/gsp-core/brands/estadio/feature';
import { CustomButton, MetaTags, TextContent, useGetDevice } from '@warnermmedia/gsp-core/sdk/ui';
import { breakpointsStateStore, HistoryContext } from '@warnermmedia/gsp-core/brands/estadio/data-access';
import { useReactiveVar } from '@apollo/client';
import { getStyles } from './subscriptionSuccess.styles';
import { EstadioButton } from '../../components';
import get from 'lodash/get';

const zendeskLinks: Record<string, string> = {
  questions: 'https://estadiocl.zendesk.com/hc/es/articles/206068176--Qu%C3%A9-es-y-c%C3%B3mo-funciona-Estadio-',
  recommendations:
    'https://estadiocl.zendesk.com/hc/es/articles/360022350152-Recomendaciones-ante-problemas-en-la-se%C3%B1al-de-Estadio',
  solutions:
    'https://estadiocl.zendesk.com/hc/es/articles/360022539051--Por-qu%C3%A9-tengo-problemas-de-se%C3%B1al-en-Estadio-y-no-en-otras-plataformas-de-video-',
};

type source = 'redeemCoupon' | 'subscriptions';

interface SubscriptionSuccessProps {
  source: source;
}

const gotoBtnAction = (type: string) => {
  Linking.openURL(zendeskLinks[type]);
};

export const SubscriptionSuccess = ({ source }: SubscriptionSuccessProps) => {
  const [loading, setLoading] = useState(false);
  const historyContext = useContext(HistoryContext);
  const history = historyContext?.ready ? historyContext?.useHistory() : null;
  const dalton = Dalton();
  const language = languageStrings.default;
  const breakpoints = useReactiveVar(breakpointsStateStore);
  const { isTv } = useGetDevice();
  const { colors } = useTheme();
  const styles = getStyles(breakpoints, isTv, colors);
  useMparticleScreenEvent(ScreenEventType.SubscriptionSuccess, languageStrings.default.subscriptionSuccessContentTitle);

  const handleRedirectAfterPurchase = useCallback(async () => {
    setLoading(true);
    let result = await dalton.getBillingProfile();
    const shouldCallAgain =
      get(result, 'data.expirationTimestampMillis') && get(result, 'data.preAuthorizedEntitlements', []).length === 0;
    if (shouldCallAgain) {
      // making this extra call because getBillingProfile returns an empty preAuthorizedEntitlements
      // sometimes even though the subscription was successful
      result = await dalton.getBillingProfile();
    }
    if (result.success) {
      history?.push('/home');
    } else {
      // TODO: what happens when subscription is successful but call to retrieve sub details fails after 2 calls
      // the call fails atimes probably due to lag in backend services communication.
      console.log('Subscription Successful');
    }
  }, [dalton, history]);

  return (
    <View style={styles.container}>
      <MetaTags title={languageStrings.default.subscriptionSuccessContentTitle} />
      <View style={styles.centeredWrapper}>
        <View style={[styles.titleContainer, styles.centerContainerTitle]}>
          <View style={styles.titleWrapper}>
            <TextContent accessible={false} style={[styles.title, styles.centerLayoutTitle]}>
              {language.subscriptionSuccessTitle}
            </TextContent>
          </View>
        </View>

        <View style={styles.childrenWrapper}>
          <View style={styles.infoWrapper}>
            <View style={styles.iconWrapper}>
              <IconButton icon="check" color={colors.tenantBackground.light.surfaceBase} />
            </View>
            <View style={styles.infoTextWrapper}>
              <TextContent style={styles.info}>{language.subscriptionSuccessText1}</TextContent>
              {source === 'redeemCoupon' && (
                <TextContent style={styles.info}>{language.subscriptionSuccessText2}</TextContent>
              )}
              <TextContent style={styles.info}>{language.subscriptionSuccessText3}</TextContent>
              <TextContent style={styles.info}>{language.subscriptionSuccessText4}</TextContent>
            </View>
          </View>
          <EstadioButton
            loading={loading}
            label={language.subscriptionSuccessHomeBtnText}
            onPress={() => {
              handleRedirectAfterPurchase();
            }}
            mode="contained"
            btnStyle={styles.homeBtn}
            labelStyle={styles.btnLabel}
          />
          <CustomButton
            label={language.subscriptionSuccessHomeQuestionBtnText}
            onPress={() => gotoBtnAction('questions')}
            mode="outline"
            btnStyle={styles.btn}
            labelStyle={styles.btnLabel}
          />
          <CustomButton
            label={language.subscriptionSuccessRecommendationBtnText}
            onPress={() => gotoBtnAction('recommendations')}
            mode="outline"
            btnStyle={styles.btn}
            labelStyle={styles.btnLabel}
          />
          <CustomButton
            label={language.subscriptionSuccessSolutionBtnText}
            onPress={() => gotoBtnAction('solutions')}
            mode="outline"
            btnStyle={styles.btn}
            labelStyle={styles.btnLabel}
          />
        </View>
      </View>
    </View>
  );
};

export default SubscriptionSuccess;
