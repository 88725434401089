import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { GestureResponderEvent, ScrollView, Text, View } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';

import { getStyles } from './layout.styles';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { useGetDevice } from '@warnermmedia/gsp-core/sdk/ui';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import {
  AppConfigContext,
  breakpointsStateStore,
  HistoryContext,
  isNavOpen,
  isUserLoggedIn,
  removePageNavHeader,
} from '@warnermmedia/gsp-core/brands/estadio/data-access';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import {
  isInRoute,
  LayoutUpdateProvider,
  RenderCurrentModalHOC,
  useDisableBodyScroll,
  useFocusType,
  useIsMountedRef,
  useLogout,
  useMparticleEventProcessor,
  useTveRestoreSession,
  useZendesk,
} from '@warnermmedia/gsp-core/brands/estadio/feature';
import { TickerWrapper } from '../swimlanes/ticker';
import { useLocation } from 'react-router';
import { useReactiveVar } from '@apollo/client';
import { useTheme } from 'react-native-paper';
import { ArcadeHOC } from '../arcadeHOC/arcadeHOC';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { EMAIL_VERIFICATION_STATUS, loadData, USER_EMAIL_STATUS } from '@warnermmedia/gsp-core/sdk/data-access';
import FavoriteTeam from '../favoriteTeam/favoriteTeam';
import Header from '../header/header';
import { MainFooter } from '../mainFooter';
import { Sidebar } from '../sidebar';

interface RouteLink {
  label: string;
  link: string;
  icon: React.ElementType;
  detailColor?: string;
  handlePress?: (e: Event | GestureResponderEvent) => void;
}

interface LayoutProps {
  pageTitle: string;
  children: React.ReactNode;
}

export const ScrollViewRefContext = React.createContext(null);

export const LayoutComponent: React.FC<LayoutProps> = ({ pageTitle, children }) => {
  useZendesk();
  useTveRestoreSession();
  const devices = useGetDevice();
  const { isTv, isPwa, isMobileDevice, isWeb, isIosDevice, isIpadOldBrowser, isIosDeviceBrowser } = devices;
  const breakpoints = useReactiveVar(breakpointsStateStore);
  const currentWindowWidth = breakpoints.windowWidth;
  const navMobile = !isPwa || (currentWindowWidth <= breakpoints.breakpointSizes.lg && !isTv);
  const [expanded, setExpanded] = useState(navMobile);
  const [opened, setOpened] = useState(navMobile);
  const historyContext = useContext(HistoryContext);
  const history = historyContext?.ready ? historyContext?.useHistory() : null;
  const location = useLocation();
  const isMountedRef = useIsMountedRef();
  const bodyScrollViewRef = useRef();
  const removeNav = useReactiveVar(removePageNavHeader);
  const isLogged = useReactiveVar(isUserLoggedIn);
  useMparticleEventProcessor();
  const { logout } = useLogout();
  const appConfig = useContext(AppConfigContext);
  const isAppEnabled = appConfig?.appConfig?.appEnabled ?? true;

  const isVideoPage = isInRoute('/video/', location.pathname);
  const isLiveVideoPage = isInRoute(['/tvsignal', '/golfchannel'], location.pathname);
  const isCodeValidationPage = isInRoute('/logintv', location.pathname);
  const isEmailRoute = isInRoute('/resend-email', location.pathname);
  const isLoginPage = isInRoute('/login', location.pathname) || (!isWeb && !isLogged && location.pathname === '/');

  const isUserEmailVerified = loadData(USER_EMAIL_STATUS) === EMAIL_VERIFICATION_STATUS.CONFIRMED;
  const renderNav = !(removeNav || (isTv && (isLiveVideoPage || isVideoPage || isLoginPage)) || (isPwa && isVideoPage));

  const hideTicker =
    !isAppEnabled || removeNav || isVideoPage || (isTv && (isLiveVideoPage || isVideoPage || isLoginPage));
  const { colors } = useTheme();
  const styles = getStyles(
    navMobile,
    isLogged,
    isPwa,
    isVideoPage,
    isUserEmailVerified,
    renderNav,
    isEmailRoute,
    colors,
    isMobileDevice
  );
  const renderSidebar = isLogged && renderNav && isUserEmailVerified && !isEmailRoute;
  useDisableBodyScroll(opened && navMobile && renderSidebar);
  useFocusType();

  // Make sure nav is set up for current screen size
  // Then update if the screen size changes
  useEffect(() => {
    setOpened(!navMobile && renderSidebar);
    setExpanded(navMobile);
  }, [navMobile, renderSidebar]);

  const toggleDrawer = useCallback(() => {
    setOpened(!opened);
  }, [opened]);

  const toggleDrawerSize = useCallback(() => {
    setExpanded(!expanded);
  }, [expanded]);

  const handleToggleNav = useCallback(() => {
    if (!navMobile) {
      toggleDrawerSize();
    } else {
      if (expanded) {
        toggleDrawer();
      }
    }
  }, [expanded, navMobile, toggleDrawer, toggleDrawerSize]);

  const closeNavIsTv = () => {
    setExpanded(false);
    isNavOpen(false);
  };

  const handleHomeNavigation = useCallback(
    (e: Event | GestureResponderEvent) => {
      e.preventDefault();
      history?.push('/login');
      if (navMobile) setOpened(false);
    },
    [history, navMobile]
  );

  let activeRouteName = '/login';
  activeRouteName = location.pathname;

  const handleLogout = useCallback(
    (e: Event | GestureResponderEvent) => {
      e.preventDefault();
      logout(e);
      isTv && closeNavIsTv();
      if (isMountedRef.current && !isLogged) {
        if (navMobile) setOpened(false);
      }
    },
    [navMobile, isMountedRef, setOpened, isTv]
  );

  const goToHome = useCallback(
    (e: Event | GestureResponderEvent) => {
      setExpanded(false);
      history?.push('/home');

      if (navMobile) {
        setOpened(false);
        setExpanded(true);
      }
    },
    [history, navMobile]
  );

  const ContainerType = !isWeb ? ScrollView : View;

  const mainAreaProps = {
    focusIn: '[tabindex="0"]',
  };

  const mainAreaScopeProps = {
    arcFocusLeft: '#main_nav',
  };

  const contentWrapperStyle = location.pathname === '/register' ? {} : styles.content;
  const showLoggedOutNavBar = !isLogged || !isUserEmailVerified || (isUserEmailVerified && isEmailRoute);
  const showMobile = (!isPwa || navMobile) && isLogged;

  const showLoginButtonInHeader =
    !isLogged && location.pathname !== '/login' && !isMobileDevice && !isTv && !isCodeValidationPage && isAppEnabled;
  const containerBackground =
    isIosDevice && (isVideoPage || isLiveVideoPage) && breakpoints.breakpointHelpers.isLandScape
      ? colors.keyboardFocus.dark
      : colors.tenantBackground.dark.surfaceBase;

  const renderHeaderContents = useCallback(() => {
    return (
      <View style={[showMobile && styles.header]}>
        <Header
          menuToggle={toggleDrawer}
          showLoggedOutNavBar={showLoggedOutNavBar}
          currentPath={location.pathname}
          isWeb={isPwa}
          showLoginButtonInHeader={showLoginButtonInHeader}
          navigateHome={handleHomeNavigation}
          showMobileNav={showMobile}
          isIpadOldBrowser={isIpadOldBrowser}
          isTv={isTv}
        />
      </View>
    );
  }, [
    handleHomeNavigation,
    isIpadOldBrowser,
    isPwa,
    location.pathname,
    showLoggedOutNavBar,
    showLoginButtonInHeader,
    showMobile,
    styles.header,
    toggleDrawer,
  ]);

  const renderSideBarContents = useCallback(() => {
    return (
      <Sidebar
        colors={colors}
        opened={opened}
        devices={devices}
        expanded={expanded}
        isLogged={isLogged}
        goToHome={goToHome}
        navMobile={navMobile}
        setExpanded={setExpanded}
        breakpoints={breakpoints}
        closeNavIsTv={closeNavIsTv}
        handleLogout={handleLogout}
        handleToggleNav={handleToggleNav}
        activeRouteName={activeRouteName}
        isIpadOldBrowser={isIpadOldBrowser}
      />
    );
  }, [
    activeRouteName,
    breakpoints,
    colors,
    devices,
    expanded,
    goToHome,
    handleLogout,
    handleToggleNav,
    isIpadOldBrowser,
    isLogged,
    navMobile,
    opened,
  ]);

  return (
    <View style={[styles.rootContainer, { backgroundColor: containerBackground }]}>
      {/* For ios Browser we need to render nav bar and header outside of SafeAreaView */}
      {renderSidebar && isIosDeviceBrowser && renderSideBarContents()}
      {renderNav && isIosDeviceBrowser && renderHeaderContents()}
      <SafeAreaView style={styles.topOffLimits} />
      <SafeAreaView style={styles.bottomOffLimits}>
        {renderSidebar && !isIosDeviceBrowser && renderSideBarContents()}
        <ArcadeHOC id="main_area" additionalProps={mainAreaProps} arcadeType="FocusArea">
          <ArcadeHOC arcadeType="Scope" additionalProps={mainAreaScopeProps}>
            <ContainerType
              /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
              /* @ts-ignore: Used to scroll the page */
              ref={bodyScrollViewRef}
              style={styles.container}
              keyboardShouldPersistTaps="handled"
              scrollEnabled={!(isVideoPage || isLiveVideoPage)}
              stickyHeaderIndices={removeNav ? [1] : [0]}
            >
              {renderNav && !isIosDeviceBrowser && renderHeaderContents()}
              <LayoutUpdateProvider>
                <View style={styles.layoutContainer}>
                  {!hideTicker && (
                    <View style={[isLogged && styles.tickerContainer]}>
                      <TickerWrapper />
                    </View>
                  )}
                  <ScrollViewRefContext.Provider
                    /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
                    /* @ts-ignore: Using context to make scroll ref available app wide. */
                    value={bodyScrollViewRef}
                  >
                    <View style={[contentWrapperStyle]}>
                      <ArcadeHOC arcadeType="FocusArea">{children}</ArcadeHOC>
                    </View>
                  </ScrollViewRefContext.Provider>
                  {isWeb && (
                    <View style={styles.footerContainer}>
                      <MainFooter />
                    </View>
                  )}
                  <FavoriteTeam />
                </View>
              </LayoutUpdateProvider>
            </ContainerType>
          </ArcadeHOC>
        </ArcadeHOC>
        {/*
        Modal Presenter Hook -- Please Plug Modals into it
        Has Temporal Modal View Placement Holders
        */}
        <RenderCurrentModalHOC
          PrivacyPreferenceCenterModal={
            <View>
              <Text>PrivacyPreferenceCenterModal</Text>
            </View>
          }
          MinimumRequirementsModal={
            <View>
              <Text>MinimumRequirementsModal</Text>
            </View>
          }
          HelpModal={
            <View>
              <Text>HelpModal</Text>
            </View>
          }
        />
      </SafeAreaView>
    </View>
  );
};

export default LayoutComponent;
