import React from 'react';

import { Pause, Play, ImageAssets } from '@warnermmedia/gsp-core/brands/estadio/assets';

type Props = {
  playerReady: boolean;
  controlsVisible: boolean;
  seekValue: number;
  handleSeekValue: (value: number) => void;
  isPlaying: boolean;
  handlePlayPauseClick: () => void;
  contentEnded: boolean;
  handleReplay: () => void;
  isPaused: boolean;
  timer: number;
  handleLiveClick: () => void;
  isLive: boolean;
  duration: number;
};

const PlayerControlsOverlay: React.FC<Props> = ({
  controlsVisible,
  seekValue,
  handleSeekValue,
  isPlaying,
  handlePlayPauseClick,
  contentEnded,
  handleReplay,
  isPaused,
  timer,
  handleLiveClick,
  isLive,
  duration,
}) => {
  const formatTime = (timeInSeconds: number): string => {
    const minutes = Math.floor(timeInSeconds / 60);
    const seconds = Math.floor(timeInSeconds % 60);
    return `${minutes < 10 ? '0' : ''}${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
  };

  return (
    <>
      {controlsVisible && (
        <div className="videoPlayer__controls">
          <input
            type="range"
            min="0"
            max={100}
            value={seekValue}
            onChange={(e) => {
              handleSeekValue(Number(e.target.value || 0));
            }}
            style={{
              background: `linear-gradient(to right, #0070f3, #0070f3 ${seekValue}%, rgba(255, 255, 255, 0.2) ${seekValue}%, rgba(255, 255, 255, 0.2) 100%)`,
            }}
          />
          <div className="buttom_controls">
            <button style={{ width: 50, height: 50 }} tabIndex={0} onClick={handlePlayPauseClick}>
              {isPlaying ? (
                <Pause color="#fff" iconColor="#fff" height={30} width={30} opacity={0.2} />
              ) : (
                <Play color="#fff" iconColor="#fff" height={40} width={40} opacity={0.2} />
              )}
            </button>
            {!isLive && (
              <div className="videoPlayer__timer">
                <span>{formatTime(timer)}</span>
                <span>{' / '}</span>
                <span>{formatTime(duration || 0)}</span>
              </div>
            )}
            {isLive && (
              <button tabIndex={0} className="videoPlayer__live-button" onClick={handleLiveClick}>
                Live
              </button>
            )}
          </div>
        </div>
      )}

      {contentEnded && (
        <div className="overlay-modal">
          <div onClick={() => handleReplay()} tabIndex={0} className="replay-modal">
            <button>
              <img src={ImageAssets.replay} alt="Replay" />
            </button>
          </div>
        </div>
      )}
      {isPaused && (
        <div className="overlay-modal">
          <div onClick={handlePlayPauseClick} tabIndex={0} className="replay-modal">
            <button>
              <Play color="#fff" iconColor="#fff" height={80} width={80} opacity={0.2} />
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default PlayerControlsOverlay;
