import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Dimensions, GestureResponderEvent, NativeModules, ScrollView, TouchableOpacity, View } from 'react-native';
import BuildConfig from 'react-native-build-config';

import { getStyles } from './sidebar.styles';
import LinearGradient from 'react-native-linear-gradient';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import {
  Competition,
  CreditCard,
  Home,
  NavbarNarrow,
  NavbarNarrowNoGradient,
  NavbarWide,
  NavbarWideNoGradient,
  Question,
  Signal,
  SignOut,
  Team,
  Tv,
  User,
} from '@warnermmedia/gsp-core/brands/estadio/assets';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { Breakpoints, DeviceTypes, Navbar, NavItem } from '@warnermmedia/gsp-core/sdk/ui';
import { IconSource } from 'react-native-paper/lib/typescript/components/Icon';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import {
  HistoryContext,
  isNavOpen,
  LAST_ROUTE,
  userHasSubscription,
} from '@warnermmedia/gsp-core/brands/estadio/data-access';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import {
  callbackNavigateExternal,
  Constants,
  isInRoute,
  languageStrings,
} from '@warnermmedia/gsp-core/brands/estadio/feature';

import { useReactiveVar } from '@apollo/client';
import { IconButton } from 'react-native-paper';
import { ArcFocusEvent, Button } from '@warnermedia/gsp-core/sdk/arcade-machine';
import { ArcadeHOC } from '../arcadeHOC/arcadeHOC';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import {
  EMAIL_VERIFICATION_STATUS,
  loadData,
  saveData,
  USER_EMAIL_STATUS,
} from '@warnermmedia/gsp-core/sdk/data-access';
import { SkeletonNavBar } from '../skeletonDisplay';

interface RouteLink {
  label: string;
  link: string;
  icon: React.ElementType;
  detailColor?: string;
  handlePress?: (e: Event | GestureResponderEvent) => void;
}

interface SidebarProps {
  opened: boolean;
  isLogged: boolean;
  expanded: boolean;
  navMobile: boolean;
  activeRouteName: string;
  closeNavIsTv: () => void;
  handleToggleNav: () => void;
  setExpanded: (expanded: boolean) => void;
  goToHome: (e: Event | GestureResponderEvent) => void;
  handleLogout: (e: Event | GestureResponderEvent) => void;
  devices: DeviceTypes;
  breakpoints: Breakpoints;
  colors: ReactNativePaper.ThemeColors;
  isIpadOldBrowser: boolean;
}

const helpLink = Constants.HELP_LINK;

export const Sidebar = ({
  opened,
  colors,
  devices,
  expanded,
  goToHome,
  isLogged,
  navMobile,
  breakpoints,
  setExpanded,
  handleLogout,
  closeNavIsTv,
  handleToggleNav,
  activeRouteName,
  isIpadOldBrowser,
}: SidebarProps) => {
  const screenHeight = Dimensions.get('window').height;
  const scrollViewRef = useRef();
  const historyContext = useContext(HistoryContext);
  const history = historyContext?.ready ? historyContext?.useHistory() : null;
  const { isTv, isPwa, isMobileDevice, isIosDevice, isAndroidDevice, isAndroidtv } = devices;

  const [hoveredControl, setHoveredControl] = useState(false);
  const [routeLinks, setRouteLinks] = useState<RouteLink[]>([]);

  const isFocused = isTv && isLogged;
  const isOpen = isFocused && (expanded || hoveredControl);
  isNavOpen(isOpen);

  const hasSubscription = useReactiveVar(userHasSubscription);
  const isUserEmailVerified = loadData(USER_EMAIL_STATUS) === EMAIL_VERIFICATION_STATUS.CONFIRMED;

  const isVideoPage = isInRoute('/video/', activeRouteName);

  const styles = getStyles(navMobile, isPwa, isVideoPage);

  const currentWindowWidth = breakpoints.windowWidth;

  const navIconColor = colors.tenantBackground.dark.surface04;
  const navbarIconWidth = 50;
  const navbarIconHeight = 50;
  const iconSettingsDefault = useMemo(
    () => ({
      height: navbarIconHeight,
      width: navbarIconWidth,
      iconColor: navIconColor,
    }),
    [navIconColor]
  );

  const iconSettingsSelected = useMemo(
    () => ({
      height: navbarIconHeight,
      width: navbarIconWidth,
      color: colors.fill.action.accent01,
      iconColor: colors.tenantBackground.light.surfaceBase,
    }),
    [colors.fill.action.accent01, colors.tenantBackground.light.surfaceBase]
  );

  const navItemProps = useMemo(
    () => ({
      expanded: expanded,
      style: styles.viewStyle,
      labelStyle: styles.labelStyle,
      iconSize: 50,
    }),
    [expanded, styles.labelStyle, styles.viewStyle]
  );

  const scrollSidebarToTop = useCallback(() => {
    /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
    /* @ts-ignore: Using scrollTo to reset scrollview */
    scrollViewRef?.current?.scrollTo({ y: 0, animated: true });
  }, []);

  useEffect(() => {
    if (!hoveredControl) {
      scrollSidebarToTop();
    }
  }, [hoveredControl, scrollSidebarToTop]);

  const authLinks: RouteLink[] = useMemo(() => {
    return [
      {
        label: languageStrings.default.navHome,
        link: '/home',
        icon: Home,
        detailColor: 'none',
      },
      {
        label: languageStrings.default.navSignalTvPage,
        link: '/tvsignal',
        icon: Signal,
        detailColor: navIconColor,
      },
      {
        label: languageStrings.default.navTeamsPage,
        link: '/teams',
        icon: Team,
      },
      {
        label: languageStrings.default.navTournamentsPage,
        link: '/tournaments',
        icon: Competition,
        detailColor: colors.fill.action.accent02,
      },
      {
        label: languageStrings.default.navShowsPage,
        link: '/shows',
        icon: Tv,
      },
      {
        label: languageStrings.default.navProfilePage,
        link: '/profile',
        icon: User,
      },
      {
        label: languageStrings.default.navHelp,
        link: '/help',
        icon: Question,
      },
      {
        label: languageStrings.default.navLogout,
        link: '/signout',
        icon: SignOut,
        handlePress: (e) => {
          handleLogout(e);
          setHoveredControl(false);
        },
      },
    ];
  }, [handleLogout, colors.fill.action.accent02, navIconColor]);

  const unSubLinks: RouteLink[] = useMemo(() => {
    return [
      {
        // Changing the route name for Ios
        label: isIosDevice ? languageStrings.default.navSubscription : languageStrings.default.navSubscribe,
        link: '/subscribe',
        icon: CreditCard,
        detailColor: 'none',
      },
      {
        label: languageStrings.default.navProfilePage,
        link: '/profile',
        icon: User,
      },
      {
        label: languageStrings.default.navHelp,
        link: '/help',
        icon: Question,
      },
      {
        label: languageStrings.default.navLogout,
        link: '/signout',
        icon: SignOut,
        handlePress: (e) => {
          handleLogout(e);
          setHoveredControl(false);
        },
      },
    ];
  }, [handleLogout, isIosDevice]);

  const addRoute = useCallback(async () => {
    if (isIosDevice) {
      const ProdChecker = NativeModules.ProdChecker;
      const val = await ProdChecker.isTestflight();
      if (val === 'TESTFLIGHT' || __DEV__) {
        setRouteLinks((prevState) => [...prevState, { label: 'App Info', link: '/appinfo', icon: CreditCard }]);
      }
    }
    if (isAndroidDevice && BuildConfig.BUILD_TYPE !== 'release') {
      setRouteLinks((prevState) => [...prevState, { label: 'App Info', link: '/appinfo', icon: CreditCard }]);
    }
    if (isPwa && process.env.NODE_ENV && !['prod', 'production'].includes(process.env.NODE_ENV)) {
      setRouteLinks((prevState) => [...prevState, { label: 'App Info', link: '/appinfo', icon: CreditCard }]);
    }
  }, [isAndroidDevice, isIosDevice, isPwa]);

  useEffect(() => {
    if (isLogged && hasSubscription) {
      setRouteLinks(authLinks);
      addRoute();
    }
    if (isLogged && !hasSubscription && hasSubscription !== null && isUserEmailVerified) {
      setRouteLinks(unSubLinks);
    }
  }, [isLogged, hasSubscription, authLinks, unSubLinks, isUserEmailVerified, addRoute]);

  const handleEnterNavbar = () => {
    setHoveredControl(true);
  };

  const handleLeaveNavbar = () => {
    setHoveredControl(false);
  };

  const handleNavigation = useCallback(
    (routeName: string, e: Event | GestureResponderEvent) => {
      e.preventDefault();
      if (routeName === '/help' && !isTv) {
        callbackNavigateExternal(helpLink);
      } else {
        if (navMobile) handleToggleNav();
        history?.push(routeName);
      }

      if (!isPwa) {
        saveData(LAST_ROUTE, routeName);
      }
      isTv && closeNavIsTv();
    },
    [helpLink, isTv, history, navMobile, isPwa, handleToggleNav]
  );

  const getNavbarLogo = useCallback(
    (shouldExpand: boolean) => {
      const navProps = shouldExpand ? { width: 187, height: 90 } : { width: 78, height: 90 };
      if (isIpadOldBrowser) {
        return shouldExpand ? <NavbarWideNoGradient {...navProps} /> : <NavbarNarrowNoGradient {...navProps} />;
      } else {
        return shouldExpand ? <NavbarWide {...navProps} /> : <NavbarNarrow {...navProps} />;
      }
    },
    [isIpadOldBrowser]
  );

  const renderNavBar = useCallback(() => {
    const shouldExpand = hoveredControl || expanded;
    const getNavLogo = getNavbarLogo(shouldExpand);

    const handleIncoming = (): void => {
      setExpanded(true);

      scrollSidebarToTop();
    };

    const handleOutgoing = (arcEvt: ArcFocusEvent): void => {
      if (arcEvt.event === Button.Right) {
        setExpanded(false);
        isAndroidtv && handleLeaveNavbar();
      }
    };

    const isActivePage = (activeRouteName: string, link: string): boolean => {
      const isLoginPage = activeRouteName === '/' && link === '/login';
      const isHomePage = activeRouteName === '/' && link === '/home' && isLogged;
      const isTeamPage = activeRouteName.startsWith('/team/') && link === '/teams';
      const isShowPage = activeRouteName.startsWith('/show/') && link === '/shows';
      const profileRoutes = ['/cancel-subscription', '/redeemcoupon'];
      const isProfilePage =
        profileRoutes.some((profileRoute) => activeRouteName.startsWith(profileRoute)) && link === '/profile';

      return activeRouteName.startsWith(link) || isLoginPage || isHomePage || isTeamPage || isShowPage || isProfilePage;
    };

    const addlProps = {
      onOutgoing: handleOutgoing,
      onIncoming: handleIncoming,
      arcFocusRight: '#main_area',
    };

    const expandedWidth =
      shouldExpand && currentWindowWidth <= breakpoints.breakpointSizes.lg
        ? styles.navWebFullWidth
        : shouldExpand && breakpoints.currentBreakpoints.isLgXl
        ? styles.navWebMediumWidth
        : {};

    return (opened && navMobile) || !navMobile ? (
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      <ArcadeHOC id="main_nav" arcadeType="FocusArea">
        <ArcadeHOC arcadeType="Scope" additionalProps={addlProps}>
          <LinearGradient
            colors={[colors.tenantBackground.dark.surface05.start, colors.tenantBackground.dark.surface05.end]}
            style={[
              styles.navContain,
              expandedWidth,
              isPwa && { overflow: 'visible' },
              isMobileDevice && { maxWidth: 375 },
            ]}
          >
            <ScrollView
              style={{ height: screenHeight }}
              showsVerticalScrollIndicator={false}
              /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
              /* @ts-ignore: Using refs. */
              ref={scrollViewRef}
              onMouseLeave={handleLeaveNavbar}
              onMouseEnter={handleEnterNavbar}
              bounces={false}
            >
              <Navbar type="vertical" active={activeRouteName} opened={opened} backgroundColor="transparent">
                <View style={shouldExpand ? styles.expandedHeader : styles.collapsedHeader}>
                  <View style={styles.navHeader}>
                    <View style={styles.navHeaderLeft}>
                      {isTv ? (
                        <View pointerEvents="none">{getNavLogo}</View>
                      ) : (
                        <TouchableOpacity onPress={goToHome}>
                          <View pointerEvents="none">{getNavLogo}</View>
                        </TouchableOpacity>
                      )}
                    </View>
                    {navMobile && (
                      <View style={styles.navHeaderRight}>
                        <IconButton
                          color={colors.tenantBackground.light.surfaceBase}
                          icon="close"
                          size={25}
                          onPress={handleToggleNav}
                        />
                      </View>
                    )}
                  </View>
                </View>
                <View style={styles.navBarScroll}>
                  {routeLinks.map((routeLink) => {
                    const LinkIcon = routeLink.icon;
                    const isActive = isActivePage(activeRouteName, routeLink.link);
                    const iconData = isActive ? iconSettingsSelected : iconSettingsDefault;
                    return (
                      <NavItem
                        key={routeLink.link}
                        navigateTo={routeLink.link}
                        label={routeLink.label}
                        accessibilityLabel={routeLink.label}
                        icon={() =>
                          (<LinkIcon detailColor={!isActive && routeLink?.detailColor} {...iconData} />) as IconSource
                        }
                        onPress={(e) => {
                          routeLink.handlePress ? routeLink.handlePress(e) : handleNavigation(routeLink.link, e);
                        }}
                        {...navItemProps}
                        expanded={shouldExpand}
                      />
                    );
                  })}
                </View>
              </Navbar>
            </ScrollView>
          </LinearGradient>
        </ArcadeHOC>
      </ArcadeHOC>
    ) : null;
  }, [
    isMobileDevice,
    isPwa,
    activeRouteName,
    breakpoints.breakpointSizes.lg,
    breakpoints.currentBreakpoints.isLgXl,
    colors.tenantBackground.dark.surface05.end,
    colors.tenantBackground.dark.surface05.start,
    colors.tenantBackground.light.surfaceBase,
    currentWindowWidth,
    expanded,
    goToHome,
    handleNavigation,
    handleToggleNav,
    hoveredControl,
    iconSettingsDefault,
    iconSettingsSelected,
    isLogged,
    isTv,
    navItemProps,
    navMobile,
    opened,
    routeLinks,
    scrollSidebarToTop,
    setExpanded,
    screenHeight,
    styles.collapsedHeader,
    styles.expandedHeader,
    styles.navBarScroll,
    styles.navContain,
    styles.navHeader,
    styles.navHeaderLeft,
    styles.navHeaderRight,
    styles.navWebFullWidth,
    styles.navWebMediumWidth,
    getNavbarLogo,
  ]);

  if (isVideoPage && !navMobile) {
    return null;
  }
  if (routeLinks.length === 0 && !navMobile) {
    return <SkeletonNavBar />;
  } else if (opened && !navMobile) {
    return <View style={styles.navContain}>{renderNavBar()}</View>;
  } else {
    return renderNavBar();
  }
};

export default Sidebar;
