import React, { useEffect } from 'react';
import { View } from 'react-native';
import { Theme } from '@warnermmedia/gsp-core/brands/estadio/ui';
import materialCommunityFont from 'react-native-vector-icons/Fonts/MaterialCommunityIcons.ttf';
import {
  AppApolloProvider,
  AppConfigProvider,
  EstadioProvider,
} from '@warnermmedia/gsp-core/brands/estadio/data-access';
import ServiceWorkerWrapper from './ServiceWorkerWrapper/ServiceWorkerWrapper';
import { SafeAreaProvider } from 'react-native-safe-area-context';

import Routes from './navigation/Routes';
import { PreLoadFonts } from './components/PreLoadFonts';
import { ArcRoot, defaultOptions, Scope } from '@warnermedia/gsp-core/sdk/arcade-machine';
import * as serviceWorker from '../serviceWorkerRegistration';
import ZendeskInc from './components/ZendeskInc/ZendeskInc';
import { useGetDevice, useWebAppsVersion } from '@warnermmedia/gsp-core/sdk/ui';

const App = (): JSX.Element => {
  const [showWrapper, setShowWrapper] = React.useState(false);
  const isPwa = true;
  const { device, isWeb } = useGetDevice();
  useWebAppsVersion();

  const onSWUpdate = (registration: ServiceWorkerRegistration) => {
    setShowWrapper(true);
  };

  useEffect(() => {
    serviceWorker.register({ onUpdate: onSWUpdate });
  }, []);

  return (
    <View>
      {isWeb && <PreLoadFonts />}
      {showWrapper ? (
        <ServiceWorkerWrapper />
      ) : (
        <AppConfigProvider>
          {isWeb && <ZendeskInc />}
          <AppApolloProvider>
            <EstadioProvider device={device}>
              <Scope>
                <Theme>
                  {isPwa ? (
                    <style type="text/css">{`
                    @font-face {
                      font-family: 'MaterialCommunityIcons';
                      src: url(${materialCommunityFont}) format('truetype');
                    }
                  `}</style>
                  ) : null}
                  <SafeAreaProvider>
                    <Routes />
                  </SafeAreaProvider>
                </Theme>
              </Scope>
            </EstadioProvider>
          </AppApolloProvider>
        </AppConfigProvider>
      )}
    </View>
  );
};

const Root = ArcRoot(
  () => {
    return <App />;
  },
  { ...defaultOptions() }
);

export default Root;
