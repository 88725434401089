/* eslint-disable @typescript-eslint/no-explicit-any */
import { FocusTrap } from '@warnermedia/gsp-core/sdk/arcade-machine';
import React from 'react';
import { StyleProp, Text, View, ViewStyle } from 'react-native';
import QRCode from 'react-qr-code';
import { EstadioButton } from '../../components/button';
import { getStyles } from './styles';
import { useTheme } from 'react-native-paper';
import { ModalType } from '@warnermmedia/gsp-core/brands/estadio/data-access';
import ModalWrapper from '../modalWrapper';

interface QrCodeModalProps {
  onChangeVisible: () => void;
  onOkPress: () => void;
  visible: boolean;
  modalBackgroundStyle?: StyleProp<ViewStyle>;
  modalStyle?: StyleProp<ViewStyle>;
  title: string;
  description: string | JSX.Element;
  buttonLabel: string;
  qrCodeString: string;
  device?: string;
  hideQr?: boolean;
}

export const QrCodeModal = ({
  visible,
  onChangeVisible,
  modalStyle,
  title,
  description,
  buttonLabel,
  qrCodeString,
  onOkPress,
  device,
  hideQr,
}: QrCodeModalProps) => {
  const isAndroidTv = device === 'androidtv' || device === 'firetv';
  const { colors } = useTheme();
  const styles = getStyles(device, colors);

  const onChange = () => {
    onChangeVisible();
  };

  if (!visible) return null;

  return (
    <ModalWrapper
      visible={visible}
      modalType={visible ? ModalType.QrCodeModal : null}
      onClose={onChange}
      animationType="slide"
      modalLayoutContainerStyle={styles.fullScreenContainer}
      modalMainContainerStyle={styles.container}
    >
      <>
        <View style={[styles.containerInner, modalStyle]}>
          <View style={[!hideQr && styles.leftContainer]}>
            <Text style={styles.title}>{title}</Text>
            <Text style={styles.description}>{description}</Text>
            <FocusTrap>
              <EstadioButton
                onPress={onOkPress}
                label={buttonLabel}
                btnStyle={{ marginTop: 43, width: 475, height: 54 }}
                size="medium"
                accessible={true}
                labelStyle={{
                  fontFamily: 'Oswald-Medium',
                  fontSize: 24,
                  paddingVertical: 10,
                }}
              />
            </FocusTrap>
          </View>
          {!hideQr && (
            <View style={styles.qrContainer}>
              <View style={styles.qrContainerBorder}>
                <QRCode value={qrCodeString} size={isAndroidTv ? 300 : 406} />
              </View>
            </View>
          )}
        </View>
      </>
    </ModalWrapper>
  );
};

export default QrCodeModal;
