import React, { useState, useEffect, useRef } from 'react';
import { useGetDevice } from '@warnermmedia/gsp-core/sdk/ui';

export interface nullProps {
  arcadeType: 'FocusArea' | 'AutoFocus' | 'FocusTrap' | 'Scope';
  id?: string;
  additionalProps?: unknown;
  children?: React.ReactNode;
}

const NullComponent = (props: nullProps) => {
  return props.children;
};

export const ArcadeHOC = (props: nullProps) => {
  const [FocusState, setFocusState] = useState<React.ReactNode>(() => NullComponent);
  const _isMounted = useRef(true);
  const { isPwa } = useGetDevice();

  useEffect(() => {
    return () => {
      // ComponentWillUnmount in Class Component
      _isMounted.current = false;
    };
  }, []);

  if (isPwa) {
    import('@warnermedia/gsp-core/sdk/arcade-machine').then((arcadeElements) => {
      if (_isMounted.current) {
        const elem = arcadeElements[props.arcadeType];
        setFocusState(() => elem);
      }
    });
  }

  return (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    <FocusState id={props.id ?? ''} {...props.additionalProps}>
      {props.children}
    </FocusState>
  );
};
