import React from 'react';
import Svg from 'react-native-svg';

/* eslint-disable-next-line */
export interface LineSeparatorProps {
  width?: number;
  height?: number;
  color?: string;
  isLeftPoint?: boolean;
}

export function LineSeparator(props: LineSeparatorProps) {
  const width = props.width || 146;
  const height = props.height || 1;
  const color = props.color || '#D9D9D9';

  return (
    <Svg viewBox="0 0 146 1" fill="none" width={width} height={height}>
      <rect width="145.5" height="1" fill={color} />
    </Svg>
  );
}

export default LineSeparator;
