import { ArcEvent } from './arc-event';

/**
 * Looks for the element by its selector, if given, or returns it.
 */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const findElement = (container: HTMLElement, target?: string | HTMLElement) =>
  typeof target === 'string' ? (container.querySelector(target) as HTMLElement | null) : target || null;

/**
 * Returns the focusable element in the container, optionally filtering
 * to the given target.
 */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const findFocusable = (container: HTMLElement, target?: string | HTMLElement) =>
  findElement(container, target) || (container.querySelector('[tabIndex]') as HTMLElement | null);

/**
 * returns whether stopPropogation has been called on the event.
 */
export function propogationStoped(ev: ArcEvent): unknown {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return (ev as any).propogationStopped;
}

/**
 * resets state (propogation and default prevention) of the event.
 */
export function resetEvent(ev: ArcEvent): void {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (ev as any).propogationStopped = false;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (ev as any).defaultPrevented = false;
}
