import { StyleProp, StyleSheet, TextStyle, ViewStyle } from 'react-native';
import { Breakpoints } from '@warnermmedia/gsp-core/sdk/ui';
import merge from 'lodash/merge';

interface Styles {
  cardsLayout: ViewStyle;
  cards: ViewStyle;
  headline: TextStyle;
  contentContainerStyle: TextStyle;
}
export const getStyles = (
  breakpoints: Breakpoints,
  isAndroidtv: boolean,
  isTv: boolean,
  colors: ReactNativePaper.ThemeColors
) => {
  const base: StyleProp<Styles> = {
    cardsLayout: {
      paddingBottom: 17,
    },
    contentContainerStyle: {
      paddingLeft: 20,
    },
    cards: {},
    headline: {
      fontFamily: 'Oswald-SemiBold',
      fontSize: 22,
      textTransform: 'uppercase',
      color: colors.keyboardFocus.light,
      paddingLeft: 29,
      fontWeight: '600',
    },
  };

  const mediaQueries = isTv
    ? ({
        headline: {
          fontSize: 33,
        },
      } as StyleProp<Styles>)
    : breakpoints.currentBreakpoints.isTiny || breakpoints.currentBreakpoints.isTnySm
    ? ({
        contentContainerStyle: {
          paddingLeft: 21,
        },
        headline: {
          paddingLeft: 29,
        },
      } as StyleProp<Styles>)
    : breakpoints.currentBreakpoints.isSmMed
    ? ({
        contentContainerStyle: {
          paddingLeft: 32,
        },
        headline: {
          paddingLeft: 40,
        },
      } as StyleProp<Styles>)
    : breakpoints.currentBreakpoints.isMedLg || breakpoints.windowWidth === breakpoints.breakpointSizes.lg
    ? ({
        cardsLayout: {
          paddingBottom: 17,
        },
        contentContainerStyle: {
          paddingLeft: 32,
        },
        headline: {
          paddingLeft: 40,
          fontSize: 22,
        },
      } as StyleProp<Styles>)
    : {};
  const mergedStyles = merge(base, mediaQueries);

  return StyleSheet.create(mergedStyles);
};
