import merge from 'lodash/merge';
import { StyleProp, StyleSheet, TextStyle, ViewStyle } from 'react-native';
import { Breakpoints } from '@warnermmedia/gsp-core/sdk/ui';

interface Styles {
  pageContainer: ViewStyle;
  headerViewStyle: ViewStyle;
  headerTextStyle: TextStyle;
  subHeaderTextStyle: TextStyle;
  loginButtonStyle: ViewStyle;
  buttonLabelStyle: TextStyle;
  needSubscribeWrapperStyle: TextStyle;
  needSubscribeStyle: TextStyle;
  subscribeButtonStyle: ViewStyle;
}

export const getStyles = (breakpoints: Breakpoints, colors: ReactNativePaper.ThemeColors) => {
  const base: StyleProp<Styles> = {
    pageContainer: {
      width: 504,
    },
    headerViewStyle: {
      paddingBottom: 32,
    },
    headerTextStyle: {
      fontFamily: 'Oswald-Regular',
      fontStyle: 'normal',
      fontWeight: '600',
      fontSize: 44,
      lineHeight: 55,
      textTransform: 'uppercase',
      color: colors.keyboardFocus.light,
    },
    subHeaderTextStyle: {
      fontFamily: 'Oswald-light',
      fontStyle: 'normal',
      fontSize: 44,
      lineHeight: 55,
      textTransform: 'uppercase',
      color: colors.keyboardFocus.light,
      marginTop: 15,
    },
    loginButtonStyle: {
      borderColor: 'transparent',
      borderWidth: 2,
    },
    buttonLabelStyle: {
      fontFamily: 'Oswald-Medium',
      fontSize: 20,
    },
    needSubscribeWrapperStyle: {
      flex: 1,
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginVertical: 24,
    },
    needSubscribeStyle: {
      fontFamily: 'Rubik-Regular',
      fontStyle: 'normal',
      fontWeight: '400',
      fontSize: 16,
      lineHeight: 19,
      letterSpacing: 0.5,
      textTransform: 'uppercase',
      color: colors.tenantBackground.light.surfaceBase,
    },
    subscribeButtonStyle: {
      borderColor: colors.tenantBackground.light.surfaceBase,
      borderWidth: 2,
    },
  };

  const mediaQueries =
    breakpoints.currentBreakpoints.isTiny ||
    breakpoints.currentBreakpoints.isTnySm ||
    breakpoints.currentBreakpoints.isSmMed
      ? {
          pageContainer: {
            width: 338,
          },
          headerTextStyle: {
            fontWeight: '700',
            fontSize: 33,
            lineHeight: 36,
          },
          subHeaderTextStyle: {
            fontSize: 33,
            lineHeight: 36,
          },
        }
      : breakpoints.currentBreakpoints.isMedLg
      ? {
          pageContainer: {
            width: 338,
          },
        }
      : breakpoints.currentBreakpoints.isLgXl ||
        breakpoints.currentBreakpoints.isXlXxl ||
        breakpoints.currentBreakpoints.isXxl
      ? ({
          pageContainer: {
            width: 504,
          },
        } as StyleProp<Styles>)
      : {};

  const mergedStyles = merge(base, mediaQueries);

  return StyleSheet.create(mergedStyles);
};
