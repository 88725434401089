import React, { FC, useEffect, useState } from 'react';
import { Image, ImageBackground, View } from 'react-native';
import {
  breakpointsStateStore,
  Competitor,
  Event,
  SortDirections,
} from '@warnermmedia/gsp-core/brands/estadio/data-access';
import {
  CardDefaultBackground,
  carouselBase,
  EstadioEventsCallTypes,
  getContentTitle,
  getImageWithStyle,
  languageStrings,
  QueryNames,
  ScreenEventType,
  useCardItemImage,
  useCMSQueryDataResults,
  useFormatCarouselData,
  useMparticleScreenEvent,
  useRouter,
} from '@warnermmedia/gsp-core/brands/estadio/feature';

import { useReactiveVar } from '@apollo/client';
import { CmsCarousel } from '../cmsPage';
import { getStyles } from './teamDetails.styles';
import LatestClipsSwimlane from './latestClipsSwimlane';
import { MetaTags, TextContent, useGetDevice } from '@warnermmedia/gsp-core/sdk/ui';
import { SkeletonDetailPage } from '../skeletonDisplay';
import { useTheme } from 'react-native-paper';

type TeamDetailsParams = {
  teamId: string;
};

export const TeamDetailsComponent: FC = () => {
  const { colors } = useTheme();
  const params = useRouter().useParams<TeamDetailsParams>();
  const teamId = params.teamId ?? '';
  const breakpoints = useReactiveVar(breakpointsStateStore);
  const [data, setData] = useState<carouselBase[]>();
  const { isWeb, isTv } = useGetDevice();
  const styles = getStyles(breakpoints, isTv, colors);
  const { pageData: competitor, loading } = useCMSQueryDataResults<Competitor>({
    queryName: QueryNames.GET_COMPETITOR_BY_ID,
    id: teamId,
  });
  const { pageData: upcomingGames } = useCMSQueryDataResults<Event[]>({
    queryName: QueryNames.GET_ALL_EVENTS,
    callType: EstadioEventsCallTypes.Team,
    id: teamId,
    countStart: '0',
    countEnd: '20',
    order: SortDirections.Asc,
  });
  const { formatEvents } = useFormatCarouselData();
  const { getCardImageSource } = useCardItemImage();
  const teamLogo = getCardImageSource({
    fallbackName: CardDefaultBackground.Team,
    image: getImageWithStyle(competitor?.logo_dark),
  });
  const pageTitle = getContentTitle(competitor?.name ?? '');
  useMparticleScreenEvent(ScreenEventType.Team, pageTitle, competitor?.sportradar_id ?? '');

  useEffect(() => {
    if (upcomingGames) {
      setData(formatEvents(upcomingGames));
    }
  }, [formatEvents, upcomingGames]);

  return (
    <View>
      {!loading ? (
        <View style={styles.teamDetailLayout}>
          {isWeb && <MetaTags title={pageTitle} />}
          <View style={styles.wrapper}>
            <ImageBackground source={teamLogo} style={styles.background}>
              <View style={styles.overlay} />
              <Image source={teamLogo} style={styles.teamLogoStyle} />
            </ImageBackground>
            <View style={styles.textBoxStyle}>
              <TextContent style={styles.titleStyle}>{competitor?.name || ''}</TextContent>
              <TextContent style={styles.descriptionStyle}>{competitor?.description || ''}</TextContent>
            </View>
          </View>

          {data && data.length ? (
            <CmsCarousel
              id="latest-matches"
              type="match"
              format="generic"
              content={[]}
              allItems={data}
              headline={languageStrings.default.teamDetailCarouselHeadline}
              weight={1}
            />
          ) : null}
          {competitor?.sportradar_id ? <LatestClipsSwimlane sportsRadarId={competitor?.sportradar_id} /> : null}
        </View>
      ) : (
        <SkeletonDetailPage windowHeightStartTop={200} />
      )}
    </View>
  );
};
