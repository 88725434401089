import { useReactiveVar } from '@apollo/client';
import { breakpointsStateStore } from '@warnermmedia/gsp-core/brands/estadio/data-access';
import React from 'react';
import { Image, ImageStyle, Text, View, ViewStyle } from 'react-native';
import { getStyles } from './team.styles';
import {
  CardDefaultBackground,
  getImageWithStyle,
  team,
  useCardItemImage,
} from '@warnermmedia/gsp-core/brands/estadio/feature';
import { useGetDevice } from '@warnermmedia/gsp-core/sdk/ui';
import { useTheme } from 'react-native-paper';

export type TeamProps = React.ComponentPropsWithRef<typeof View> & {
  /**
   * Team object
   */
  team: team;
  /**
   * Width of logo
   */
  width?: number;
  /**
   * Height of logo
   */
  height?: number;
  /**
   * Styles for View wrapper
   */
  teamStyle?: ViewStyle;
  /**
   * Styles for logo
   */
  teamLogoStyle?: ImageStyle;
  /**
   * Hide tricode
   */
  hideTricode?: boolean;
};

export const Team = ({ team, width, height, teamStyle, teamLogoStyle, hideTricode }: TeamProps) => {
  const breakpoints = useReactiveVar(breakpointsStateStore);
  const { isTv } = useGetDevice();
  const { colors } = useTheme();
  const styles = getStyles(breakpoints, isTv, colors);
  const { getCardImageSource } = useCardItemImage();
  const teamLogo =
    typeof team.logo === 'number'
      ? team.logo
      : getCardImageSource({
          fallbackName: CardDefaultBackground.Team,
          image: getImageWithStyle(team.logo),
        });

  return (
    <View style={[styles.team, teamStyle ? { ...teamStyle } : {}]}>
      <View style={[styles.logoContain, width ? { width: width } : {}, height ? { height: height } : {}]}>
        <Image style={[styles.logo, teamLogoStyle ? { ...teamLogoStyle } : {}]} source={teamLogo} />
      </View>
      {!hideTricode && <Text style={styles.tricode}>{team.tricode}</Text>}
    </View>
  );
};

export default Team;
