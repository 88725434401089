import React, { useCallback, useEffect, useRef, useState } from 'react';
import Reaptcha from 'reaptcha';
import { View } from 'react-native';
import Calendar from 'react-calendar';
import {
  CustomTextInput,
  formatDateDDMMYYYY,
  TextContent,
  Tooltip,
  validateCurrency,
  validateNonEmptyString,
  validateRUT,
  validateTicketNumber,
  validateDatePattern,
} from '@warnermmedia/gsp-core/sdk/ui';
import { getStyles } from './requestInvoiceForm.styles';
import { EstadioButton } from '../components';
import { languageStrings } from '@warnermmedia/gsp-core/brands/estadio/feature';
import { useReactiveVar } from '@apollo/client';
import { useTheme } from 'react-native-paper';
import { breakpointsStateStore } from '@warnermmedia/gsp-core/brands/estadio/data-access';

export interface FormData {
  folio: string;
  rut: string;
  date: string;
  amount: string;
  recaptcha_token: string;
}

interface RequestInvoiceForm {
  handleFormSubmit: (data: FormData) => void;
  recaptchaKey: string;
  loading: boolean;
  setErrorMsg: (error: string) => void;
}

const datePattern = /^([0-2][0-9]|(3)[0-1])(\/)(((0)[0-9])|((1)[0-2]))(\/)\d{4}$/;

export const RequestInvoiceForm = ({ handleFormSubmit, recaptchaKey, loading, setErrorMsg }: RequestInvoiceForm) => {
  const language = languageStrings.default;
  const breakpoints = useReactiveVar(breakpointsStateStore);
  const { colors } = useTheme();
  const styles = getStyles(breakpoints, colors);
  const calendarWrapperRef = useRef();
  const [showCalendar, setShowCalendar] = useState(false);
  const [data, setData] = useState<FormData>({
    folio: '',
    rut: '',
    date: '',
    amount: '',
    recaptcha_token: '',
  });
  const [error, setError] = useState({
    folio: false,
    rut: false,
    date: false,
    amount: false,
    recaptcha_token: false,
  });

  const [inputFocus, setInputFocus] = useState({
    folio: false,
    rut: false,
    date: false,
    amount: false,
  });

  const dataRef = useRef(data);
  const dateInputRef = useRef(false);

  useEffect(() => {
    document.addEventListener('click', handler);
    return () => {
      document.removeEventListener('click', handler);
    };
  }, []);

  const handler = (event: MouseEvent) => {
    /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
    /* @ts-ignore: */
    if (!calendarWrapperRef.current.contains(event.target)) {
      setShowCalendar(false);
      if (dateInputRef.current) {
        validateDateFormat(dataRef.current.date);
        dateInputRef.current = false;
      }
    }
  };

  const handleDatePick = (date: Date) => {
    const formattedDate = formatDateDDMMYYYY(date);
    handleChange('date', formattedDate);
    if (error.date) {
      handleErrorChange('date', false);
    }
    setShowCalendar(false);
  };

  const revertDate = (date: string) => {
    if (date.match(datePattern)) {
      const stripped = date.split('/');
      return new Date(`${stripped[1]}/${stripped[0]}/${stripped[2]}`);
    }
    return new Date();
  };

  const handleErrorChange = (field: string, value: boolean) => {
    setError((prevState) => ({
      ...prevState,
      [field]: value,
    }));
  };

  const handleChange = (field: string, value: string) => {
    setData((prevState) => ({
      ...prevState,
      [field]: value,
    }));
    if (field === 'date') {
      dataRef.current[field] = value;
    }
  };

  const handleSubmit = () => {
    setErrorMsg('');
    const errorObj = {
      folio: !validateTicketNumber(data.folio),
      rut: !validateRUT(data.rut),
      date: !validateDatePattern(data.date, datePattern),
      amount: !validateCurrency(data.amount),
      recaptcha_token: !validateNonEmptyString(data.recaptcha_token),
    };
    setError(errorObj);
    const isFormValid = !Object.values(errorObj).includes(true);
    if (error.recaptcha_token && !data.recaptcha_token) {
      setErrorMsg(language.invoiceErrorMsg);
      return;
    }
    if (!isFormValid) {
      setErrorMsg(language.invoiceSuccessMsg);
      return;
    }
    const formatedDate = data.date.replace(/\//g, '-');
    const payload = { ...data };
    payload.date = formatedDate;
    const processedRUT = data.rut.replace(/[-.]/g, '');
    payload.rut = `${processedRUT.substring(0, 8)}-${processedRUT[8]}`;
    handleFormSubmit(payload);
  };

  const memoizedCallback = useCallback(
    (token: string | null) => {
      handleChange('recaptcha_token', token as string);
      error.recaptcha_token && handleErrorChange('recaptcha_token', !validateNonEmptyString(token as string));
    },
    [error.recaptcha_token]
  );

  const memoizedOnExpiredCallback = useCallback(() => {
    handleChange('recaptcha_token', '');
    handleErrorChange('recaptcha_token', true);
  }, []);

  const validateDateFormat = (date: string) => {
    const valid = date.match(datePattern);
    handleErrorChange('date', !valid);
  };

  return (
    <View>
      <View style={styles.inputWrapper}>
        <TextContent style={styles.label}>{language.invoiceNumber}</TextContent>
        <CustomTextInput
          inputStyle={data.folio.length < 1 ? styles.input : styles.inputFilled}
          value={data.folio}
          onChangeText={(text) => {
            handleChange('folio', text);
            const hasError = !validateTicketNumber(text);
            handleErrorChange('folio', hasError);
          }}
          onBlur={() => {
            const hasError = !validateTicketNumber(data.folio);
            handleErrorChange('folio', hasError);
            setInputFocus((prevState) => ({ ...prevState, folio: false }));
          }}
          onFocus={() => {
            setInputFocus((prevState) => ({ ...prevState, folio: true }));
          }}
          error={error.folio}
          editable={!loading}
          focusable={!loading}
          shouldFocus={inputFocus.folio}
        />
        <Tooltip tooltipText={language.invoiceNumberToolTip} visible={error.folio} opacity={1} position="left" />
      </View>
      <View
        style={styles.inputWrapper}
        /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
        /* @ts-ignore: */
        ref={calendarWrapperRef}
      >
        <TextContent style={styles.label}>{language.invoiceDate}</TextContent>
        <input
          onClick={() => setShowCalendar(!loading)}
          placeholder={languageStrings.default.dateFormat}
          type="text"
          value={data.date}
          className="date-input"
          data-date-inline-picker="true"
          onChange={({ target }) => {
            validateDateFormat(target.value);
            handleChange('date', target.value);
          }}
          style={{
            height: '40px',
            width: '100%',
            borderRadius: '5px',
            border: `${error.date ? '2px solid red' : '0'}`,
            opacity: `${inputFocus.date || data.date ? 1 : 0.8}`,
            padding: '5px',
            boxSizing: 'border-box',
            ...(dateInputRef.current && !error.date ? { outline: `4px solid ${colors.fill.action.accent02}` } : {}),
          }}
          onFocus={() => {
            handleErrorChange('date', false);
            dateInputRef.current = true;
            setInputFocus((prevState) => ({ ...prevState, date: true }));
          }}
          contentEditable={!loading}
        />
        {showCalendar ? (
          <View style={styles.calendarWrapper}>
            <Calendar onChange={handleDatePick} value={revertDate(data.date)} maxDate={new Date()} />
          </View>
        ) : null}
        <Tooltip tooltipText={language.invoiceDateToolTip} visible={error.date} opacity={1} position="left" />
      </View>
      <View style={styles.inputWrapper}>
        <TextContent style={styles.label}>{language.invoiceAmount}</TextContent>
        <CustomTextInput
          inputStyle={data.amount.length < 1 ? styles.input : styles.inputFilled}
          value={data.amount}
          onChangeText={(text) => {
            handleChange('amount', text);
            const hasError = !validateCurrency(text);
            handleErrorChange('amount', hasError);
          }}
          onBlur={() => {
            const hasError = !validateCurrency(data.amount);
            handleErrorChange('amount', hasError);
            setInputFocus((prevState) => ({ ...prevState, amount: false }));
          }}
          onFocus={() => {
            setInputFocus((prevState) => ({ ...prevState, amount: true }));
          }}
          shouldFocus={inputFocus.amount}
          error={error.amount}
          editable={!loading}
          focusable={!loading}
        />
        <Tooltip tooltipText={language.invoiceAmountToolTip} visible={error.amount} opacity={1} position="left" />
      </View>
      <View style={styles.inputWrapper}>
        <TextContent style={styles.label}>RUT</TextContent>
        <CustomTextInput
          inputStyle={data.rut.length < 1 ? styles.input : styles.inputFilled}
          value={data.rut}
          onChangeText={(text) => {
            handleChange('rut', text);
            const hasError = !validateRUT(text);
            handleErrorChange('rut', hasError);
          }}
          onBlur={() => {
            const hasError = !validateRUT(data.rut);
            handleErrorChange('rut', hasError);
            setInputFocus((prevState) => ({ ...prevState, rut: false }));
          }}
          onFocus={() => {
            setInputFocus((prevState) => ({ ...prevState, rut: true }));
          }}
          shouldFocus={inputFocus.rut}
          error={error.rut}
          editable={!loading}
          focusable={!loading}
        />
        <Tooltip tooltipText={language.invoiceRUTToolTip} visible={error.rut} opacity={1} position="left" />
      </View>
      <View style={styles.inputWrapper}>
        <View style={styles.recaptchWrapper}>
          <Reaptcha
            hl={language.recaptchaLang}
            sitekey={recaptchaKey}
            onVerify={memoizedCallback}
            onExpire={memoizedOnExpiredCallback}
          />
        </View>
        <Tooltip
          tooltipText={language.invoiceRecaptchaToolTip}
          visible={error.recaptcha_token && !data.recaptcha_token}
          opacity={1}
          position="center"
        />
      </View>
      <EstadioButton
        labelStyle={styles.btnLabel}
        btnStyle={styles.btnWrapper}
        mode="contained"
        disabled={loading}
        onPress={handleSubmit}
        loading={loading}
        label={language.consult}
      />
    </View>
  );
};

export default RequestInvoiceForm;
