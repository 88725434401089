import React from 'react';
import { Text, View } from 'react-native';
import { useReactiveVar } from '@apollo/client';
import { getStyles } from './subscription.styles';
import { breakpointsStateStore, Product } from '@warnermmedia/gsp-core/brands/estadio/data-access';
import { useTheme } from 'react-native-paper';
import { EstadioButton } from '../../components';
import { languageStrings } from '@warnermmedia/gsp-core/brands/estadio/feature';
import { LineSeparator } from '@warnermmedia/gsp-core/brands/estadio/assets';
import { Maybe } from 'graphql/jsutils/Maybe';

interface ProductProps {
  product?: Product;
  handleLogin: () => void;
  handleSubscribe: () => void;
  loginLabel: Maybe<string>;
}

export const Subscription = ({ product, handleLogin, handleSubscribe, loginLabel }: ProductProps) => {
  const breakpoints = useReactiveVar(breakpointsStateStore);
  const { colors } = useTheme();
  const styles = getStyles(breakpoints, colors);
  const ctaText = product?.cta ? product?.cta?.replace(/\s/, ' ') : '';
  const currentWindowWidth = breakpoints.windowWidth;
  const lineSeparatorProps = {
    width: currentWindowWidth <= breakpoints.breakpointSizes.lg ? 53.5 : 145.5,
  };

  return (
    <View style={styles.pageContainer}>
      <View style={styles.headerViewStyle}>
        <Text style={styles.headerTextStyle}>{product?.headline}</Text>
        <Text style={styles.subHeaderTextStyle}>{product?.subHeadline}</Text>
      </View>
      <View>
        {loginLabel && (
          <EstadioButton
            onPress={handleLogin}
            label={loginLabel}
            btnStyle={styles.loginButtonStyle}
            labelStyle={styles.buttonLabelStyle}
          />
        )}
        {ctaText ? (
          <View>
            <View style={styles.needSubscribeWrapperStyle}>
              <LineSeparator {...lineSeparatorProps} />
              <Text style={styles.needSubscribeStyle}>{languageStrings.default.needToSubscribeText}</Text>
              <LineSeparator {...lineSeparatorProps} />
            </View>
            <EstadioButton
              onPress={handleSubscribe}
              label={ctaText}
              btnStyle={styles.subscribeButtonStyle}
              labelStyle={styles.buttonLabelStyle}
              btnColor="transparent"
            />
          </View>
        ) : null}
      </View>
    </View>
  );
};

export default Subscription;
