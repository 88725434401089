import React, { useCallback, useEffect, useState } from 'react';
import { Platform } from 'react-native';
import { useReactiveVar } from '@apollo/client';
import { Constants, getContentTitle, useIsMountedRef } from '@warnermmedia/gsp-core/brands/estadio/feature';
import {
  isTveAuth,
  isUserLoggedIn,
  loginAPIStateStore,
  tveProvider,
} from '@warnermmedia/gsp-core/brands/estadio/data-access';
import isEqual from 'lodash/isEqual';
import {
  AuthState,
  MParticleAppConfigObject,
  MParticleAppConfigParams,
  SubscriberType,
  SubscriptionStatus,
} from '@warnermmedia/gsp-core/sdk/data-access';
import { useLocation } from 'react-router';

export const MparticleEventDefaultPropertyValue = 'nvs';

export const useMparticleCustomEventObject = (options: MParticleAppConfigParams = {}): MParticleAppConfigObject => {
  const [mParticleEventData, setMParticleEventData] = useState<MParticleAppConfigObject>({
    app_name: `estadioar|${Platform.OS}`,
    brand: 'estadio argentina',
    market_region: 'latam',
    market: 'ar|argentina',
    subscriber_type: SubscriberType.TVE,
    version: `app|${Constants.APP_VERSION}`,
  } as MParticleAppConfigObject);
  const [mParticleEventOption, setMParticleEventOption] = useState<MParticleAppConfigParams>({});
  const isLoggedIn = useReactiveVar(isUserLoggedIn);
  const isTveAuthenticated = useReactiveVar(isTveAuth);
  const isMountedRef = useIsMountedRef();
  const location = useLocation();
  const storedProvider = useReactiveVar(tveProvider);

  const updateMParticleEventData = useCallback(() => {
    const {
      pageName,
      keywords,
      publicationDate,
      section,
      subsection,
      contentId,
      homeTeam,
      awayTeam,
      errorCode,
      authState,
      customerId,
      contentTitle,
      serviceProvider,
      cancelReason,
      redirectTiming,
      subscriptionType,
      devicePaired,
      playerTimer,
    } = mParticleEventOption;
    const { tveUserId } = loginAPIStateStore();
    setMParticleEventData({
      ...mParticleEventData,
      ...{
        subscription_status: SubscriptionStatus.Active,
        auth_state: authState ? authState : isLoggedIn ? AuthState.Authenticated : AuthState.Unauthenticated,
        service_provider: serviceProvider
          ? serviceProvider.description
          : storedProvider
          ? storedProvider.description
          : MparticleEventDefaultPropertyValue,
        // TODO: Change this to send the correct value in AR face 2
        favorite_team: MparticleEventDefaultPropertyValue,
        customer_id: customerId
          ? customerId
          : isTveAuthenticated && tveUserId
          ? tveUserId
          : MparticleEventDefaultPropertyValue,
        page_name: pageName ? pageName : location.pathname || MparticleEventDefaultPropertyValue,
        keywords: keywords?.join('|') || MparticleEventDefaultPropertyValue,
        publication_date: publicationDate || MparticleEventDefaultPropertyValue,
        section: section || MparticleEventDefaultPropertyValue,
        subsection: subsection || MparticleEventDefaultPropertyValue,
        content_id: contentId || MparticleEventDefaultPropertyValue,
        content_title: getContentTitle(contentTitle ?? '') || MparticleEventDefaultPropertyValue,
      },
      ...(homeTeam ? { home_team: homeTeam } : {}),
      ...(awayTeam ? { away_team: awayTeam } : {}),
      ...(errorCode ? { error_code: errorCode } : {}),
      ...(cancelReason ? { cancel_reason: cancelReason } : {}),
      ...(redirectTiming ? { redirect_timing: redirectTiming } : {}),
      ...(subscriptionType ? { subscription_type: subscriptionType } : {}),
      ...(devicePaired ? { device_paired: devicePaired } : {}),
      ...(playerTimer ? { player_timer: playerTimer } : {}),
    });
  }, [isLoggedIn, isTveAuthenticated, storedProvider, mParticleEventOption, mParticleEventData, location.pathname]);

  useEffect(() => {
    if (isMountedRef.current && !isEqual(options, mParticleEventOption)) {
      setMParticleEventOption(options);
    }
  }, [options, mParticleEventOption, isMountedRef]);

  useEffect(() => {
    if (isMountedRef.current && !!mParticleEventOption) {
      updateMParticleEventData();
    }
  }, [mParticleEventOption, isMountedRef, location.pathname]);

  return mParticleEventData;
};
