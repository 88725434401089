import { fromEvent } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { KeyCodes, SamsungMisc } from './device-keys';
import { Button } from '../model';
import { IInputMethod, IInputObservation } from './input-method';

export class KeyboardInput implements IInputMethod {
  private blurInputs(): void {
    const inputs = Array.from(document.getElementsByTagName('input'));
    inputs.forEach((element) => {
      element.blur();
    });
  }

  /**
   * codeToDirection returns a direction from keyCode
   */
  static readonly defaultCodeDirectionMap = new Map<number, Button>([
    [KeyCodes.LeftArrow, Button.Left],
    [KeyCodes.GamepadLeftThumbstickLeft, Button.Left],
    [KeyCodes.GamepadDPadLeft, Button.Left],
    [KeyCodes.NavigationLeft, Button.Left],

    [KeyCodes.RightArrow, Button.Right],
    [KeyCodes.GamepadLeftThumbstickRight, Button.Right],
    [KeyCodes.GamepadDPadRight, Button.Right],
    [KeyCodes.NavigationRight, Button.Right],

    [KeyCodes.UpArrow, Button.Up],
    [KeyCodes.GamepadLeftThumbstickUp, Button.Up],
    [KeyCodes.GamepadDPadUp, Button.Up],
    [KeyCodes.NavigationUp, Button.Up],

    [KeyCodes.DownArrow, Button.Down],
    [KeyCodes.GamepadLeftThumbstickDown, Button.Down],
    [KeyCodes.GamepadDPadDown, Button.Down],
    [KeyCodes.NavigationDown, Button.Down],
    [KeyCodes.Enter, Button.Submit],
    [KeyCodes.NavigationAccept, Button.Submit],
    [KeyCodes.GamepadA, Button.Submit],

    [KeyCodes.Escape, Button.Back],
    [KeyCodes.GamepadB, Button.Back],
    [KeyCodes.Numpad7, Button.X],
    [KeyCodes.GamepadX, Button.X],
    [KeyCodes.Numpad9, Button.Y],
    [KeyCodes.GamepadY, Button.Y],
    [KeyCodes.Numpad4, Button.TabLeft],
    [KeyCodes.GamepadLeftShoulder, Button.TabLeft],
    [KeyCodes.Numpad6, Button.TabRight],
    [KeyCodes.GamepadRightShoulder, Button.TabRight],
    [KeyCodes.Numpad8, Button.TabUp],
    [KeyCodes.GamepadLeftTrigger, Button.TabUp],
    [KeyCodes.Numpad2, Button.TabDown],
    [KeyCodes.GamepadRightTrigger, Button.TabDown],

    [KeyCodes.Divide, Button.View],
    [KeyCodes.GamepadView, Button.View],
    [KeyCodes.Multiply, Button.Menu],
    [KeyCodes.GamepadMenu, Button.Menu],

    [KeyCodes.FastForward, Button.FastForward],
    [KeyCodes.Rewind, Button.Rewind],
    [KeyCodes.PlayPause, Button.PlayPause],

    [KeyCodes.FireMediaRewind, Button.FireMediaRewind],
    [KeyCodes.FireMediaPlayPause, Button.FireMediaPlayPause],
    [KeyCodes.FireMediaFastForward, Button.FireMediaFastForward],

    [KeyCodes.LgPlay, Button.PlayPause],
    [KeyCodes.LgPause, Button.PlayPause],
    [KeyCodes.LgRWD, Button.Rewind],
    [KeyCodes.LgFWD, Button.FastForward],
  ]);

  readonly observeKeyDownEvents = fromEvent<KeyboardEvent>(window, 'keydown').pipe(
    map((event) => {
      const button = this.directionMap.get(event.keyCode);
      if (button === undefined) {
        return undefined as unknown;
      }

      return { button, event };
    }),
    filter((ev): ev is IInputObservation => ev !== undefined)
  );

  readonly isSupported = true;

  constructor(private readonly directionMap = KeyboardInput.defaultCodeDirectionMap) {
    this.directionMap = directionMap;
  }
}
