import React, { useCallback, useState } from 'react';
import { NativeSyntheticEvent, StyleProp, TextInputKeyPressEventData, TextStyle, View, ViewStyle } from 'react-native';
import { CustomTextInput, Tooltip, useGetDevice } from '@warnermmedia/gsp-core/sdk/ui';
import { breakpointsStateStore } from '@warnermmedia/gsp-core/brands/estadio/data-access';
import { getStyles } from './textInput.styles';
import { useReactiveVar } from '@apollo/client';
import { KeyCodes } from '@warnermedia/gsp-core/sdk/arcade-machine';
import { useTheme } from 'react-native-paper';
import { PasswordHide, PasswordShow } from '@warnermmedia/gsp-core/brands/estadio/assets';

interface TextInputProps {
  disabled?: boolean;
  disabledBtnColor?: string;
  btnColor?: string;
  onChangeText: (value: string) => void;
  value?: string;
  placeholder?: string;
  error: boolean;
  errorString: string;
  inputError?: string;
  style?: StyleProp<TextStyle>;
  secureTextEntry?: boolean;
  onBlur?: () => void;
  onFocus?: (e: unknown) => void;
  whiteBorder?: boolean;
  inputStyle?: ViewStyle;
  maxLength?: number;
  onKeyPress?: (e: NativeSyntheticEvent<TextInputKeyPressEventData>) => void;
  autoFocus?: boolean;
  shouldFocus?: boolean;
  editable?: boolean;
  focusable?: boolean;
}

export const EstadioTextInput = ({
  value,
  error,
  onChangeText,
  placeholder,
  style,
  errorString,
  secureTextEntry,
  onBlur,
  inputStyle,
  autoFocus,
  onKeyPress = () => null,
  shouldFocus = false,
  editable = true,
  focusable = true,
}: TextInputProps) => {
  const breakpoints = useReactiveVar(breakpointsStateStore);
  const { isTv, isPwa, isWeb } = useGetDevice();
  const { colors } = useTheme();
  const styles = getStyles(breakpoints, colors);
  const [focus, setFocus] = useState(shouldFocus);
  const [isSecureTextEntry, setIsSecureTextEntry] = useState(secureTextEntry);

  function handleFocus() {
    setFocus(true);
  }
  function handleBlur() {
    setFocus(false);
    onBlur && onBlur();
  }

  const onChange = (e: NativeSyntheticEvent<TextInputKeyPressEventData>) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (e.keyCode === KeyCodes.UpArrow || e.keyCode === KeyCodes.DownArrow) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      e.target.blur();
    } else {
      onKeyPress(e);
    }
  };

  const handlePasswordIconClick = useCallback(() => {
    setIsSecureTextEntry(!isSecureTextEntry);
  }, [isSecureTextEntry]);

  const getPasswordIcon = useCallback(() => {
    return isSecureTextEntry ? <PasswordHide /> : <PasswordShow />;
  }, [isSecureTextEntry]);

  return (
    <View>
      <CustomTextInput
        maxLength={100}
        onKeyPress={onChange}
        onFocus={handleFocus}
        onBlur={handleBlur}
        style={[
          style,
          styles.input,
          focusable && focus && !isTv && { borderColor: colors.fill.action.accent02, borderWidth: 2 },
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          /* @ts-ignore: properties for correct outlining. */
          focusable && focus && isWeb ? { outline: `2px solid ${colors.fill.action.accent02}`, opacity: 1 } : {},
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          /* @ts-ignore: properties for correct outlining. */
          error && isPwa ? { boxShadow: '0 0 2pt 1pt red' } : {},
        ]}
        placeholder={placeholder}
        onChangeText={onChangeText}
        error={error}
        errorString={errorString}
        containerStyle={[styles.container, inputStyle || {}]}
        secureTextEntry={isSecureTextEntry}
        value={value}
        autoFocus={autoFocus}
        shouldFocus={shouldFocus}
        editable={editable}
        focusable={focusable}
        {...(secureTextEntry ? { icon: getPasswordIcon(), handleIconClick: handlePasswordIconClick } : {})}
        iconPosition="right"
      />
      <Tooltip
        tooltipText={errorString}
        shouldFloat={false}
        opacity={1}
        visible={error}
        position="left"
        containerStyle={{ marginBottom: 10 }}
      />
    </View>
  );
};

export default EstadioTextInput;
