import { AppConfig } from '../types';

export const APP_CONFIG_DEFAULT: AppConfig = {
  name: '',
  device: '',
  identifier: '',
  webUrl: '',
  metadata: {
    description: '',
  },
  apis: {
    federatedAPI: '',
    daltonAPI: '',
    strappiAPI: '',
    mediumAPI: '',
    tokenAPI: '',
    boletaAPI: '',
    retentionAPI: '',
    vindiciaServer: '',
    vindiciaRestServer: '',
    pinAndPairAPI: '',
    geoAPI: '',
  },
  vindicia: {
    authid: '',
    hmac: '',
    pmt: '',
    terms: '',
  },
  mparticle: {
    key: '',
    value: '',
  },
  federated: {
    id: '',
  },
  authSettings: {
    clientID: '',
    OpenIDDiscoveryEndpoint: '',
    redirectURI: '',
  },
  playerSettings: {
    clientID: '',
  },
  imageSettings: {
    let: '',
  },
  locales: {
    default: '',
    availability: ['spa', 'por', 'eng'],
  },
  termsAndConditions: {
    locales: {
      spa: '',
      por: '',
      eng: '',
    },
  },
  privacyPolicy: {
    locales: {
      spa: '',
      por: '',
      eng: '',
    },
  },
  translation: {
    spa: '',
    por: '',
    eng: '',
  },
  video: {
    appId: '',
    convivaId: '',
    touchStoneApi: '',
    live: {
      env: '',
      compId: '',
      accessTokenType: '',
    },
    vod: {
      env: '',
      compId: '',
    },
  },
  tve: {
    enabled: false,
    authorizeUrl: '',
    tokenUrl: '',
    providersUrl: '',
    logoutUrl: '',
    clientId: '',
    scopes: [],
    redirectUrl: '',
    countries: [],
    videoTokenUrl: '',
    tenant: '',
    urnParam: '',
  },
  oneTrust: '',
  recaptchaKey: '',
  zendesk: '',
  appsFlyer: '',
  dalton: {
    tenant: '',
    apps: [],
  },
  geoConfig: {
    appId: '',
  },
  pinAndPair: {
    tenant: '',
  },
  packFutbolUrl: '',
  mvpdBlackList: {
    idp: [],
  },
  appEnabled: true,
};
