import merge from 'lodash/merge';
import { StyleProp, StyleSheet, TextStyle, ViewStyle } from 'react-native';
import { Breakpoints } from '@warnermmedia/gsp-core/sdk/ui';

interface Styles {
  pageContainer: ViewStyle;
  imageBackground: ViewStyle;
  productContainer: ViewStyle;
  productTextContent: ViewStyle;
  productWrapper: ViewStyle;
  productContentContainer: ViewStyle;
  productContent: ViewStyle;

  explanationContainer: ViewStyle;
  explanationElements: ViewStyle;
  explanationGroup: ViewStyle;
  explanationHeader: TextStyle;
  explanationText: TextStyle;
}

export const getStyles = (breakpoints: Breakpoints, colors: ReactNativePaper.ThemeColors) => {
  const base: StyleProp<Styles> = {
    pageContainer: {
      paddingBottom: 70,
      paddingHorizontal: 40,
    },
    imageBackground: {
      width: '100%',
    },
    productWrapper: {
      height: 595,
    },
    productContainer: {
      flexDirection: 'column',
      width: '100%',
      height: '100%',
      justifyContent: 'center',
    },
    productTextContent: {
      flexDirection: 'column',
    },
    productContentContainer: {},
    productContent: {
      width: '100%',
      backgroundColor: 'white',
      height: 300,
    },
    explanationContainer: {
      backgroundColor: colors.tenantBackground.dark.surfaceBase,
      paddingBottom: 51,
      paddingHorizontal: 40,
      paddingTop: 48,
    },
    explanationElements: {},
    explanationGroup: {},
    explanationHeader: {
      color: colors.keyboardFocus.light,
      fontFamily: 'Oswald-SemiBold',
      fontSize: 22,
      fontWeight: '600',
      paddingBottom: 24,
      textTransform: 'uppercase',
    },
    explanationText: {
      color: colors.keyboardFocus.light,
      fontFamily: 'Rubik-Regular',
      fontSize: 16,
      lineHeight: 20,
      paddingBottom: 20,
    },
  };

  const mediaQueries =
    breakpoints.currentBreakpoints.isTiny ||
    breakpoints.currentBreakpoints.isTnySm ||
    breakpoints.currentBreakpoints.isSmMed
      ? {
          explanationContainer: {
            paddingHorizontal: 25,
          },
          productWrapper: {
            alignItems: 'center',
            height: 595,
          },
          productContainer: {
            width: '100%',
            alignItems: 'center',
            flexDirection: 'column',
          },
          productTextContent: {
            flexDirection: 'column',
          },
        }
      : breakpoints.currentBreakpoints.isMedLg
      ? {
          explanationElements: {
            width: '100%',
            alignItems: 'flex-start',
            flexDirection: 'row',
            justifyContent: 'space-around',
          },
          explanationGroup: {
            maxWidth: '47%',
          },
          pageContainer: {
            justifyContent: 'center',
          },
          productWrapper: {
            alignItems: 'center',
            height: 725,
          },
          productContainer: {
            width: '85%',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'flex-start',
          },
          productTextContent: {
            flexDirection: 'column',
          },
        }
      : breakpoints.currentBreakpoints.isLgXl ||
        breakpoints.currentBreakpoints.isXlXxl ||
        breakpoints.currentBreakpoints.isXxl
      ? ({
          pageContainer: {
            justifyContent: 'center',
          },
          productWrapper: {
            alignItems: 'center',
            height: 663,
          },
          productContainer: {
            maxWidth: 943,
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'flex-start',
          },
          productTextContent: {
            flexDirection: 'column',
          },
          explanationContainer: {
            justifyContent: 'center',
            alignItems: 'center',
          },
          explanationElements: {
            maxWidth: 1025,
            alignItems: 'flex-start',
            flexDirection: 'row',
            justifyContent: 'space-around',
          },
          explanationGroup: {
            maxWidth: '47%',
          },
        } as StyleProp<Styles>)
      : {};

  const mergedStyles = merge(base, mediaQueries);

  return StyleSheet.create(mergedStyles);
};
