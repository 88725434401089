import * as React from 'react';
import { StyleProp, TextStyle, View, ViewStyle } from 'react-native';
import { EstadioButton, EstadioTextInput } from '@warnermmedia/gsp-core/brands/estadio/ui';
import { TextContent } from '@warnermmedia/gsp-core/sdk/ui';
import { getStyles } from './resetPasswordForm.styles';
import { languageStrings, useOnEnterKeyPress } from '@warnermmedia/gsp-core/brands/estadio/feature';
import { breakpointsStateStore } from '@warnermmedia/gsp-core/brands/estadio/data-access';

import { useReactiveVar } from '@apollo/client';
import { useTheme } from 'react-native-paper';
export interface ResetPasswordFormProps {
  isLoading?: boolean;
  onRequestSend(password: string): void;
  error?: string;
  topInstruction?: string;
  title?: string;
  formContainerStyle?: StyleProp<ViewStyle>;
  disabledLabelColor?: string;
  btnColor?: string;
  textInputColor?: string;
  passwordLabel: string;
  confirmPasswordLabel: string;
  passwordRule?: string;
  ctaBtnLabel: string;
  labelStyle?: StyleProp<TextStyle>;
  passwordRuleStyle?: StyleProp<TextStyle>;
  btnStyle?: StyleProp<ViewStyle>;
  textInputStyle?: StyleProp<TextStyle>;
  passwordMatchError?: string;
  validatePassword?: (password: string) => boolean;
  ruleMismatchError?: string;
}

export function EstadioResetPasswordForm(props: ResetPasswordFormProps) {
  const {
    isLoading,
    onRequestSend,
    passwordLabel,
    passwordRule,
    labelStyle,
    passwordRuleStyle,
    passwordMatchError,
    ruleMismatchError,
    disabledLabelColor,
    btnColor,
    formContainerStyle,
    confirmPasswordLabel,
    ctaBtnLabel,
    textInputStyle,
    validatePassword,
  } = props;

  const [passwordVisibility] = React.useState(true);
  const [confirmPasswordVisibility] = React.useState(true);
  const breakpoints = useReactiveVar(breakpointsStateStore);
  const { colors } = useTheme();
  const styles = getStyles(breakpoints, colors);
  const [fields, setFields] = React.useState({
    password: {
      value: '',
      error: '',
    },
    confirmPassword: {
      value: '',
      error: '',
    },
  });

  const {
    password: { value: password, error: passwordError },
    confirmPassword: { value: confirmPassword, error: confirmPasswordError },
  } = fields;

  const disabled = isLoading || !password || !confirmPassword;
  const misMatchError = ruleMismatchError || languageStrings.default.ruleMismatchError;
  const { onEnterKeyPress } = useOnEnterKeyPress();

  function onValueChange(field: string) {
    return function (value: string) {
      setFields({
        ...fields,
        [field]: {
          value,
          error: '',
        },
      });
    };
  }

  function onBlur() {
    const isPasswordValid = validatePassword && validatePassword(password);
    if (!isPasswordValid) {
      setFields((inputs) => ({
        ...inputs,
        password: {
          value: password,
          error: languageStrings.default.resetPasswordRule,
        },
      }));
    }
    if (password !== confirmPassword && isPasswordValid) {
      setFields((inputs) => ({
        ...inputs,
        confirmPassword: {
          value: confirmPassword,
          error: passwordMatchError || languageStrings.default.passwordMatchError,
        },
      }));
    }
  }

  function sendRequest() {
    if (validatePassword && validatePassword(password) && password === confirmPassword) {
      onRequestSend && onRequestSend(password);
    } else {
      onBlur();
    }
  }

  return (
    <View style={formContainerStyle}>
      <View style={styles.topContent}>
        <View style={styles.inputContainer}>
          <TextContent style={[styles.textLabel, labelStyle]}>{passwordLabel || 'Password'} </TextContent>
          <EstadioTextInput
            value={password}
            style={[password.length < 1 ? styles.input : styles.inputFilled, textInputStyle]}
            onBlur={onBlur}
            secureTextEntry={passwordVisibility}
            onChangeText={onValueChange('password')}
            error={!!passwordError}
            errorString={passwordError}
            onKeyPress={(e) => onEnterKeyPress(e, sendRequest)}
            editable={!isLoading}
            focusable={!isLoading}
          />
        </View>
        <View style={styles.inputContainer}>
          <TextContent style={[styles.textLabel, labelStyle]}>
            {confirmPasswordLabel || 'Confirm Password'}{' '}
          </TextContent>
          <EstadioTextInput
            value={confirmPassword}
            style={[confirmPassword.length < 1 ? styles.input : styles.inputFilled, textInputStyle]}
            onBlur={onBlur}
            secureTextEntry={confirmPasswordVisibility}
            onChangeText={onValueChange('confirmPassword')}
            error={!!confirmPasswordError}
            errorString={confirmPasswordError || misMatchError}
            onKeyPress={(e) => onEnterKeyPress(e, sendRequest)}
            editable={!isLoading}
            focusable={!isLoading}
          />
        </View>
        <TextContent style={[styles.rule, passwordRuleStyle]}>
          {passwordRule || languageStrings.default.resetPasswordRule}
        </TextContent>
      </View>

      <EstadioButton
        onPress={sendRequest}
        label={ctaBtnLabel || languageStrings.default.resetPasswordUpdateLabel}
        disabled={disabled}
        btnColor={btnColor}
        disabledLabelColor={disabledLabelColor}
        labelStyle={{
          fontFamily: 'Oswald-Regular',
        }}
      />
    </View>
  );
}

export default React.memo(EstadioResetPasswordForm);
