import React from 'react';
import { name, version } from '../version/autogenerated_version.json';
import languageStrings from '../I18n/index';
import { Facebook, TntSports, Twitter, Instagram, Youtube } from '@warnermmedia/gsp-core/brands/estadio/assets';
import Sizes from './Sizes';
import PrimitiveColors from './PrimitiveColors';
import { isWebPlatform } from './Platform';

const FOOTER_IMAGE_PROPS_1 = {
  color: PrimitiveColors.FooterImageColor,
  height: Sizes.THIRTY,
  width: Sizes.THIRTY,
};

const FOOTER_IMAGE_PROPS_2 = {
  height: Sizes.FIFTY,
  width: Sizes.FIFTY,
};

export default {
  // API ENDPOINTS
  API_BASE_URL: 'https://sports-federated-api-gw.qa.nonprod.wmsports.io/graphql',

  APP_NAME: name,
  APP_VERSION: version, //TODO: change to actual version of app.
  APP_FEATURESWITCH_CONFIG_URL: 'https://api.jsonbin.io/b/606b28c88be464182c591c8d',
  ////////////////////////////

  // FOOTER CONSTANTS
  FOOTER_LINK_1: 'https://estadioar.zendesk.com/hc/es/articles/15597287675540-T%C3%89RMINOS-Y-CONDICIONES',
  FOOTER_LINK_2: 'https://estadiocl.zendesk.com/knowledge/articles/4407740095380/es?brand_id=1155566',
  FOOTER_LINK_3: 'PrivacyPreferenceCenterModal',
  FOOTER_LINK_4: 'https://estadioar.zendesk.com/hc/es/categories/15758529767188-Preguntas-Frecuentes',
  FOOTER_LINK_5:
    'https://estadioar.zendesk.com/hc/es/articles/15759473753364--Qu%C3%A9-requisitos-b%C3%A1sicos-necesito-para-visualizar-Estadio-',
  FOOTER_LINK_6: '/consulta-boleta',
  FOOTER_LINK_7: 'https://tntsports.com.ar/',
  FOOTER_LINK_8: 'https://cl.estadio.com/',
  FOOTER_LINK_9: 'https://www.facebook.com/TNTSportsAR/',
  FOOTER_LINK_10: 'https://twitter.com/TNTSportsAR',
  FOOTER_LINK_11: 'https://tntsports.com.ar/',
  FOOTER_LINK_12: 'HelpModal',
  FOOTER_LINK_13: 'https://www.instagram.com/tntsportsar/',
  FOOTER_LINK_14: 'https://www.youtube.com/tntsportsar',
  FOOTER_LINK_15: 'https://www.warnermediaprivacy.com/policycenter/b2c/es-row/',

  FOOTER_IMAGE_1: <Facebook {...FOOTER_IMAGE_PROPS_1} />,
  FOOTER_IMAGE_2: <Twitter {...FOOTER_IMAGE_PROPS_1} />,
  FOOTER_IMAGE_3: <TntSports {...FOOTER_IMAGE_PROPS_2} />,
  FOOTER_IMAGE_4: <Instagram {...FOOTER_IMAGE_PROPS_1} />,
  FOOTER_IMAGE_5: <Youtube {...FOOTER_IMAGE_PROPS_1} />,

  FOOTER_COPYRIGHT: `${languageStrings.FOOTER_COPYRIGHT_1} ${new Date().getFullYear()} ${
    languageStrings.FOOTER_COPYRIGHT_2
  }`,
  FOOTER_VERSION: `${languageStrings.app_version} ${version}`,
  ///////////////////////////

  // FOR FAST STYLING USE THIS INSTEAD TO AVOID REPETITIONS AND USE REFERENCES INSTEAD
  STYLE_CENTER: 'center' as const,
  STYLE_FLEX_END: 'flex-end' as const,
  STYLE_FLEX_START: 'flex-start' as const,
  STYLE_STRETCH: 'stretch' as const,
  STYLE_INITIAL: 'initial' as const,
  STYLE_BASELINE: 'baseline' as const,
  STYLE_INHERIT: 'inherit' as const,
  STYLE_RELATIVE: 'relative' as const,
  STYLE_ABSOLUTE: 'absolute' as const,
  STATUS_BAR_STYLE_DARK: 'dark-content' as const,
  STATUS_BAR_STYLE_LIGHT: 'light-content' as const,
  ////////////////////////////

  // Persistence Decorator Switch
  persistDecorator: !isWebPlatform() ? '@persist' : '',

  HELP_LINK: 'https://estadioar.zendesk.com/hc/es',
};
