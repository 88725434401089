export default {
  app_name: 'EstadioTNTSports',
  app_version: 'Versión',
  SplashScreenText: 'ESTRALLA POTENCIAL',

  // FOOTER STRINGS
  FOOTER_LABEL_1: 'TÉRMINOS Y CONDICIONES',
  FOOTER_LABEL_2: 'POLÍTICA DE COOKIES',
  FOOTER_LABEL_3: 'CENTRO DE PREFERENCIAS',
  FOOTER_LABEL_4: 'FAQ',
  FOOTER_LABEL_5: 'REQS. MÍNIMOS',
  FOOTER_LABEL_6: 'CONSULTA BOLETA',
  FOOTER_LABEL_7: 'TNTSports.com.ar',
  FOOTER_LABEL_8: 'IR A ESTADIO CHILE',
  FOOTER_LABEL_9: 'AYUDA',
  FOOTER_LABEL_10: '[NUEVA] POLÍTICA DE PRIVACIDAD',
  FOOTER_COPYRIGHT_1: 'ESTADIO TNT SPORTS',
  FOOTER_COPYRIGHT_2: ', TODOS LOS DERECHOS RESERVADOS.',
  //////////////////////////////

  //Card State Labels
  todayLabel: 'Hoy',
  revealLabel: 'Revívelo',
  liveLabel: 'En Vivo',
  dayLabel: 'Fecha',

  //Error MessageDisplay
  contentLockError: 'Lo lamentamos, no tienes acceso a este contenido. Contáctanos para más información.',
  noAccessError: 'Lo lamentamos, no tienes acceso a este contenido. Contáctanos para más información.',
  cantPlayContentError: 'No puedes acceder al contenido en este momento. Por favor intenta nuevamente.',
  tokenExpiredError: 'Tu sesión ha expirado. Por favor ingresa nuevamente.',
  locationError: 'Contenido bloqueado debido a la ubicación',
  noAccessToContent: 'Lo sentimos, no tienes acceso a este contenido. Contáctenos para más información.',
  maxStream: 'Se ha excedido el número máximo de transmisiones permitidas',
  mediaNotAvailable: 'Media no disponible en este momento',

  //ResendEmailVerication
  emailVericationPageTitle: 'DEBES VALIDAR TU CUENTA',
  emailVericationPageTitleSuccess: 'Tu email se validó con éxito',
  emailVericationPageTitleError: 'Debes Validar tu Cuenta',
  emailVericationParagraph_1: 'Valida tu cuenta clickeando en el e-mail que enviamos a ',
  emailVerificationParagraph_2: 'Si no lo has recibido, clickea en el botón para enviarlo nuevamente.',
  emailVericationCtaButton: 'REENVIAR EMAIL',
  emailVericationMessage: '¿Ya has validado tu cuenta?',
  emailVericationLinkLabel: 'Ponte en contacto con nosotros.',
  emailVericationLogoutMessage: '¿Ingresaste un correo erróneo?',
  emailVericationLogoutLabel: 'Clickea aquí para empezar nuevamente.',
  emailVericationSuccessMessage: '¿No has recibido el email de validación?',
  emailVericationSuccessLabel: 'Ponte en contacto con nosotros.',
  emailVericationCheckButtonText: 'Chequear la validación',
  emailVericationApiSuccess:
    'Enviamos un email a la cuenta que utilizaste para registrarte.\n\nPor favor clickea en el email para validar tu cuenta.',
  emailVericationApiError: 'El e-mail no ha sido enviado, por favor intenta nuevamente.',
  emailVericationSuccessRouteMessage: 'Ya puedes ingresar a Estadio TNT Sports.',
  emailCheckvalidationMessageSuccess:
    'Hemos checkeado y tu email no está verificado aún.\nPor favor clickea en el email para validar tu cuenta.',
  emailCheckvalidationMessageError: 'Ocurrió un error comprobando la validación de tu cuenta.',

  //update app modal
  updateAppModalTitle: 'ACTUALIZACIÓN DISPONIBLE',
  updateAppModalDescription: 'Una nueva versión de la aplicación está disponible.',
  updateAppModalBtnText: 'ACTUALIZAR',
  // My Account
  editProfileSuccessMessage: 'Tu información personal se actualizó con éxito.',

  // Subscription
  buyNowLabel: 'COMPRAR AHORA',
  subscriptionFailureMessage: 'Código de cupón inválido.',
  subscriptionInvalidPromocodeMessage: 'Código de promoción no válido.',
  subscriptionUsedPromocodeMessage: 'Código de promoción ya utilizado para esta cuenta.',
  subscriptionSuccessMessage: 'El cupón ha sido aplicado correctamente.',
  subscriptionPreviewFailedMessage: '¡La vista previa del producto falló!',
  subscriptionPaymentFailedMessage: '¡Error en el pago de la suscripción!',
  subscriptioncancelledMessage:
    'Suscripción actual ya cancelada, vuelva a suscribirse para usar el código de promoción.',

  doNotRefreshLabel: 'Por favor, no actualices ésta página',
  doNotRefreshDescription: 'Espera unos momentos mientras se procesa tu pago.',

  loadingText: 'Cargando...',
  subscriptionSuccessHomeBtnText: 'IR AL Inicio',
  subscriptionSuccessHomeQuestionBtnText: '¿Qué es Estadio TNT Sports?',
  subscriptionSuccessRecommendationBtnText: 'Recomendaciones básicas',
  subscriptionSuccessText1: 'Bienvenido, ya tienes tu asiento asegurado',
  subscriptionSuccessText2: 'El cupón ha sido aplicado correctamente.',
  subscriptionSuccessText3: 'La transacción ha sido procesada correctamente.',
  subscriptionSuccessText4:
    'Puedes comenzar a disfrutar de todos los partidos del futbol chileno en vivo y VOD, junto a la mejor programación de Estadio TNT Sports. Además te invitamos a revisar algunos consejos.',
  backToAccountLabel: 'volver a mi cuenta',
  cancelSubscriptionLabel: 'cancelar mi suscripción',
  subscriptionCancelledLabel: 'Has cancelado tu suscripción.',
  cancelSubscriptionWarningDescription: 'Estás a punto de cancelar tu suscripción. ¿Estás seguro?',

  // Metatags
  myAccountTitle: 'Mi Cuenta - Estadio TNT Sports',
  appInfoTitle: 'Información de la aplicación - Estadio TNT Sports',
};
