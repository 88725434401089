import React, { useEffect, useState } from 'react';
import { ImageStyle, StyleProp, Text, TextStyle, View, ViewStyle } from 'react-native';
import { getStyles } from './help.styles';
import { useReactiveVar } from '@apollo/client';
import { breakpointsStateStore, isNavOpen } from '@warnermmedia/gsp-core/brands/estadio/data-access';
import QRCode from 'react-qr-code';
import { AutoFocus } from '@warnermedia/gsp-core/sdk/arcade-machine';
import { useGetDevice } from '@warnermmedia/gsp-core/sdk/ui';
import { Constants, useScaleSizing } from '@warnermmedia/gsp-core/brands/estadio/feature';
import { useTheme } from 'react-native-paper';

export interface helpScreenProps {
  containerStyle?: StyleProp<ViewStyle>;
  title?: string;
  paragraph?: string;
  subparagraph?: string;
  link?: string;
  imageStyle?: StyleProp<ImageStyle>;
  titleStyle?: StyleProp<TextStyle>;
  paragraphStyle?: StyleProp<TextStyle>;
  textContainerStyle?: StyleProp<ViewStyle>;
  device: string;
}

export function HelpScreen({
  title = '¿Quieres saber más?',
  paragraph = 'Escanea el código QR o ingresa a',
  subparagraph = 'para acceder a nuestros artículos de ayuda, términos y condiciones o ponerte en contacto con un agente.',
  containerStyle = {},
  textContainerStyle = {},
  link = Constants.HELP_LINK,
  device,
}: helpScreenProps) {
  const [qrWidth, setQrWidth] = useState<number>(405);
  const isExpanded = useReactiveVar(isNavOpen);
  const { isTv } = useGetDevice();
  const scaling = useScaleSizing();
  const breakpoints = useReactiveVar(breakpointsStateStore);
  const { colors } = useTheme();
  const styles = getStyles(breakpoints, isTv, scaling, colors);
  const customBorderStyle = {
    borderWidth: isExpanded ? 0 : 4,
  };

  useEffect(() => {
    setQrWidth(breakpoints.windowWidth * 0.15);
  }, [breakpoints]);

  if (device === 'web') {
    return null;
  }

  return (
    <AutoFocus>
      <View
        style={[styles.container, customBorderStyle, containerStyle]}
        nativeID="help_screen_item"
        accessible={false}
        focusable={false}
      >
        <View style={[styles.textContainer, textContainerStyle]}>
          <View style={styles.textWrapper}>
            <Text style={styles.titleStyle}>{title}</Text>
            <View>
              <Text style={styles.paragraphStyle}>{paragraph}</Text>
              <Text style={styles.linkStyle}>
                {link} <Text style={styles.paragraphStyle}>{subparagraph}</Text>
              </Text>
            </View>
          </View>
        </View>
        <View>
          <View style={styles.qrStyleContainer}>
            <QRCode value={link ?? ''} size={qrWidth} />
          </View>
        </View>
      </View>
    </AutoFocus>
  );
}

export default HelpScreen;
