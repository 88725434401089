import React from 'react';
import { StyleSheet, Text, View } from 'react-native';
import { getStyles } from './landing.styles';
import { useReactiveVar } from '@apollo/client';
import { breakpointsStateStore } from '@warnermmedia/gsp-core/brands/estadio/data-access';
import { AutoFocus } from '@warnermedia/gsp-core/sdk/arcade-machine';
import { EstadioButton } from '@warnermmedia/gsp-core/brands/estadio/ui';
import { languageStrings, useScaleSizing } from '@warnermmedia/gsp-core/brands/estadio/feature';
import { useTheme } from 'react-native-paper';

export interface LandingPinLoginScreenProps {
  onNext: () => void;
  onRegister: () => void;
}

export const LandingPinLoginScreen = (props: LandingPinLoginScreenProps) => {
  const breakpoints = useReactiveVar(breakpointsStateStore);
  const scaling = useScaleSizing();
  const { colors } = useTheme();
  const styles = getStyles(breakpoints, scaling, colors);

  return (
    <View style={styles.wrapper}>
      <View style={[styles.textContainer]}>
        <Text style={styles.titleStyle}>
          {languageStrings.default.pinPairLandingTitle}
          {'\n'}
          {languageStrings.default.pinPairLandingSubtitle}
        </Text>
        <AutoFocus>
          <EstadioButton
            onPress={props.onNext}
            label={languageStrings.default.enterText}
            btnStyle={StyleSheet.flatten([styles.buttonStyle, styles.loginBtn])}
            labelStyle={styles.buttonText}
          />
        </AutoFocus>
        <EstadioButton
          onPress={props.onRegister}
          label={languageStrings.default.registerTextTV}
          btnStyle={styles.buttonStyle}
          labelStyle={styles.buttonText}
        />
      </View>
      <View style={styles.empty} />
    </View>
  );
};

export default LandingPinLoginScreen;
