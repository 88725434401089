import React, { RefObject, useCallback, useContext, useEffect, useState } from 'react';
import {
  NativeSyntheticEvent,
  TextInput,
  TextInputKeyPressEventData,
  TextInputSelectionChangeEventData,
  TextStyle,
  View,
} from 'react-native';
import {
  CustomButton,
  MetaTags,
  TextContent,
  useGetDevice,
  validatePinCode,
  Variant,
} from '@warnermmedia/gsp-core/sdk/ui';
import { LayoutType, LayoutWrapper, TitleType } from '../layoutWrapper';
import { useReactiveVar } from '@apollo/client';
import {
  breakpointsStateStore,
  HistoryContext,
  isTveAuth,
  isUserLoggedIn,
  loginAPIStateStore,
  Page,
  PIN_CODE,
} from '@warnermmedia/gsp-core/brands/estadio/data-access';
import {
  getContentTitle,
  isJwtTokenExpired,
  languageStrings,
  logoutEstadioApplication,
  PagesUrl,
  QueryNames,
  ScreenEventType,
  Tve,
  useCMSPageMetaTags,
  useCMSQueryDataResults,
  useIsMountedRef,
  useLogout,
  useMparticleScreenEvent,
  useOnEnterKeyPress,
  usePinAndPairActions,
  useProfileData,
  useStatusMessage,
} from '@warnermmedia/gsp-core/brands/estadio/feature';
import { useTheme } from 'react-native-paper';
import { getStyles } from './codeValidation.styles';
import { KeyCodes } from '@warnermedia/gsp-core/sdk/arcade-machine';
import { saveData } from '@warnermmedia/gsp-core/sdk/data-access';
import { createRefs, ReferenceObject } from '@warnermmedia/gsp-core/sdk/util';
import isEqual from 'lodash/isEqual';

export const CodeValidation = () => {
  const historyContext = useContext(HistoryContext);
  const breakpoints = useReactiveVar(breakpointsStateStore);
  const history = historyContext?.ready ? historyContext?.useHistory() : null;
  const isLoggedIn = useReactiveVar(isUserLoggedIn);
  const { userData } = useProfileData();
  const language = languageStrings.default;
  const [code, setCode] = useState('');
  const [isValidatingCode, setIsValidatingCode] = useState(false);
  const [pinCode, setPinCode] = useState<string[]>(new Array(6).fill(''));
  const [pinCodeFocus, setPinCodeFocus] = useState<boolean[]>(new Array(6).fill(false));
  const textInputRefs = createRefs<TextInput>(6);
  const { setStatusMessage } = useStatusMessage();
  const [isValidCode, setIsValidCode] = useState(false);
  const isMountedRef = useIsMountedRef();
  const isTveAuthenticated = useReactiveVar(isTveAuth);
  const { tveUserId } = useReactiveVar(loginAPIStateStore);
  const { isMobileDevice } = useGetDevice();
  const { colors } = useTheme();
  const styles = getStyles(breakpoints, colors);
  const { logout } = useLogout();
  const { pageData: pageUriData } = useCMSQueryDataResults<Page>({
    queryName: QueryNames.GET_COMMON_PAGE_DATA,
    uri: PagesUrl.CodeValidation,
  });
  const { metaTags, title, type, cmsId } = useCMSPageMetaTags(pageUriData);
  useMparticleScreenEvent(type ?? ScreenEventType.CodeValidation, title ?? language.codeValidationTitle);
  const { claimCode, validateCode } = usePinAndPairActions({
    section: type,
    contentTitle: language.retentionTitle,
    contentId: cmsId,
  });
  const { onEnterKeyPress } = useOnEnterKeyPress();
  const useTve = Tve();

  useEffect(() => {
    if (isMountedRef.current && pinCode) {
      const isCodeComplete = pinCode.every((v) => !!v);
      const code = pinCode.join('');
      setCode(isCodeComplete && validatePinCode(code) ? code : '');
      setIsValidCode(isCodeComplete && validatePinCode(code));
    }
  }, [isMountedRef, pinCode]);

  const handleRedirection = useCallback(() => {
    setIsValidatingCode(false);
    saveData(PIN_CODE, code);
    const nextRoute = isLoggedIn ? '/logintv-success' : '/login';
    history?.push(nextRoute);
  }, [code, isLoggedIn, history]);

  const isTveTokenExpired = useCallback(async () => {
    try {
      const videoToken = await useTve.checkVideoTokenAccess(loginAPIStateStore().authToken ?? '');

      if (isJwtTokenExpired(videoToken.videoToken)) {
        logoutEstadioApplication();
        return true;
      }
      return false;
    } catch (e) {
      logoutEstadioApplication();
      return true;
    }
  }, [useTve]);

  const handleClaimCode = useCallback(async () => {
    const shouldClaimTveCode = (isTveAuthenticated && !(await isTveTokenExpired())) || !isTveAuthenticated;

    if (isLoggedIn && shouldClaimTveCode) {
      const userId = isTveAuthenticated ? tveUserId : userData.id;
      await claimCode(code, userId ?? '', (message: string) => {
        setStatusMessage({ message, type: Variant.Error });
      });
    }
  }, [claimCode, code, isLoggedIn, userData, isTveAuthenticated, tveUserId, setStatusMessage, isTveTokenExpired]);

  const handleCodeValidation = useCallback(async () => {
    setIsValidatingCode(true);
    await validateCode(
      code,
      async () => {
        await handleClaimCode();
        handleRedirection();
      },
      (message: string) => {
        setStatusMessage({ message, type: Variant.Error });
        setIsValidatingCode(false);
      }
    );
  }, [code, handleClaimCode, handleRedirection, validateCode, setStatusMessage]);

  const handleUseAnotherAccount = useCallback(() => {
    if (isLoggedIn) {
      logout();
    } else {
      history?.push('/login');
    }
  }, [isLoggedIn, history, logout]);

  const handleFocusValues = (index: number) => {
    const focusValues = new Array(6).fill(false);
    focusValues[index] = true;
    setPinCodeFocus(focusValues);
  };

  const setDigit = (value: string, index: number) => {
    const currentCode = [...pinCode];
    currentCode[index] = value;
    setPinCode(currentCode);
  };

  const setFocus = (value: string, ref: RefObject<TextInput> | null) => {
    if (value && ref && ref.current) {
      ref.current.focus();
    }
  };

  const onDirectionArrows = (e: NativeSyntheticEvent<TextInputKeyPressEventData>, ref: RefObject<TextInput> | null) => {
    e.preventDefault();
    ref && ref.current && ref.current.focus();
    ref && ref.current && ref.current.setNativeProps({ selection: { start: 1, end: 1 } });
  };

  const onBackspace = (currentIndex: number, ref: RefObject<TextInput> | null) => {
    if (!pinCode[currentIndex] && ref && ref.current) {
      ref.current.focus();
    } else {
      setDigit('', currentIndex);
    }
  };

  const onKeyPress = (
    e: NativeSyntheticEvent<TextInputKeyPressEventData>,
    currentIndex: number,
    current: RefObject<TextInput> | null,
    prev: RefObject<TextInput> | null,
    next: RefObject<TextInput> | null
  ) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (e.keyCode === KeyCodes.Backspace) {
      onBackspace(currentIndex, prev);
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
    } else if (e.keyCode === KeyCodes.LeftArrow || e.keyCode === KeyCodes.RightArrow) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const focusRef = e.keyCode === KeyCodes.LeftArrow ? prev : next ? next : current;
      onDirectionArrows(e, focusRef);
    } else {
      onEnterKeyPress(e, handleCodeValidation);
    }
  };

  const onSelectionChange = (
    e: NativeSyntheticEvent<TextInputSelectionChangeEventData>,
    ref: RefObject<TextInput> | null,
    index: number
  ) => {
    if (pinCode[index] && isEqual(e.nativeEvent.selection, { start: 0, end: 0 })) {
      ref && ref.current && ref.current.focus();
      ref && ref.current && ref.current.setNativeProps({ selection: { start: 1, end: 1 } });
    }
  };

  const onBlur = () => {
    const focusValues = new Array(6).fill(false);
    setPinCodeFocus(focusValues);
  };

  return (
    <LayoutWrapper
      pageTitle={language.codeValidationTitle}
      layoutType={LayoutType.Center}
      titleType={TitleType.Left}
      subTitle={language.codeValidationDescription}
      overrideTitleStyle={styles.title}
      overrideSubTitleStyle={styles.subtitle}
    >
      <MetaTags title={getContentTitle(title ?? language.codeValidationTitle)} data={metaTags} />
      <View style={styles.inputWrapper}>
        {textInputRefs.map((reference: ReferenceObject<TextInput>, index: number) => {
          const nextRef = index < 5 ? textInputRefs[index + 1].ref : null;
          const prevRef = index > 0 ? textInputRefs[index - 1].ref : null;

          return (
            <TextInput
              key={reference.idx}
              ref={reference.ref}
              style={[
                styles.input,
                pinCodeFocus[index] && !isValidatingCode
                  ? ({ outline: `2px solid ${colors.fill.action.accent02}` } as TextStyle)
                  : {},
              ]}
              value={pinCode[index]}
              maxLength={1}
              autoFocus={index === 0}
              onFocus={() => handleFocusValues(index)}
              onBlur={() => onBlur()}
              onChangeText={(text) => {
                setDigit(text, index);
                setFocus(text, nextRef);
              }}
              keyboardType={isMobileDevice ? 'number-pad' : 'numeric'}
              onKeyPress={(e) => onKeyPress(e, index, reference.ref, prevRef, nextRef)}
              onSelectionChange={(e) => onSelectionChange(e, prevRef, index)}
              editable={!isValidatingCode}
              focusable={!isValidatingCode}
            />
          );
        })}
      </View>
      <TextContent style={styles.subtitle}>
        {isLoggedIn
          ? language.codeValidationDescriptionLogged(userData.email)
          : language.codeValidationDescriptionUnLogged}
      </TextContent>
      <CustomButton
        mode="contained"
        btnStyle={[
          styles.btn,
          {
            backgroundColor:
              !isValidCode || isValidatingCode ? colors.fill.action.accent01Pressed : colors.fill.action.accent01,
          },
        ]}
        onPress={handleCodeValidation}
        label={!isValidatingCode ? language.codeValidationCta : language.codeValidationProcessingCta}
        disabled={!isValidCode || isValidatingCode}
      ></CustomButton>
      <CustomButton
        mode="text"
        btnStyle={styles.anotherAccount}
        labelStyle={styles.underlinedText}
        onPress={handleUseAnotherAccount}
        label={language.codeValidationOtherAccountBtn}
      />
    </LayoutWrapper>
  );
};

export default CodeValidation;
