import React from 'react';
import { StyleProp, Text, View, ViewStyle } from 'react-native';
import { useReactiveVar } from '@apollo/client';
import { IconButton, useTheme } from 'react-native-paper';
import { breakpointsStateStore, ModalType } from '@warnermmedia/gsp-core/brands/estadio/data-access';
import { getStyles } from './couponModal.styles';
import {
  CustomButton,
  CustomTextInput,
  MessageDisplay,
  TextContent,
  Tooltip,
  useGetDevice,
  Variant,
} from '@warnermmedia/gsp-core/sdk/ui';
import { callbackNavigateExternal } from '@warnermmedia/gsp-core/brands/estadio/feature';
import ModalWrapper from '../modalWrapper';

interface CouponProps {
  visible: boolean;
  modalBackgroundStyle?: StyleProp<ViewStyle>;
  modalStyle?: StyleProp<ViewStyle>;
  title?: string;
  description?: string;
  buttonLabel?: string;
  bottomText?: string;
  bottomLink?: string;
  bottomTextSpace?: number;
  inputLable?: string;
  toggleModal: () => void;
  formError: { [key: string]: boolean };
  handleSetFormError: (field: string, value: boolean) => void;
  setCouponApplied: (val: boolean) => void;
  applyCoupon: () => void;
  couponCode: string;
  setCouponCode: (code: string) => void;
  couponError: string;
  setCouponError: (val: string) => void;
  isLoading: boolean;
}

export const CouponModal = ({
  visible,
  title,
  description,
  modalStyle,
  toggleModal,
  formError,
  handleSetFormError,
  setCouponApplied,
  applyCoupon,
  setCouponCode,
  couponCode,
  couponError,
  setCouponError,
  isLoading,
  ...props
}: CouponProps) => {
  const breakpoints = useReactiveVar(breakpointsStateStore);
  const { isTv } = useGetDevice();
  const { colors } = useTheme();
  const styles = getStyles(breakpoints, isTv, colors);

  return (
    <ModalWrapper
      visible={visible}
      modalType={visible ? ModalType.CouponModal : null}
      onClose={() => toggleModal()}
      showCloseButton={true}
      modalLayoutContainerStyle={styles.fullScreenContainer}
      modalMainContainerStyle={styles.container}
      modalCloseButtonStyle={styles.closeBtn}
    >
      <>
        <Text style={styles.title}>{title}</Text>
        <View style={[styles.containerInner, modalStyle]}>
          <Text style={styles.description}>{description}</Text>
          {couponError ? (
            <MessageDisplay
              displayMessage={couponError}
              variant={Variant.Error}
              wrapperStyle={styles.messageDisplay}
              displayIcon={
                <IconButton
                  style={styles.displayIcon}
                  icon="alert-circle-outline"
                  color={colors.tenantBackground.light.surfaceBase}
                  size={22}
                />
              }
            />
          ) : null}
          <View style={[styles.inputContainer]}>
            <CustomTextInput
              placeholder=""
              inputLabel={<TextContent style={[styles.inputLabel]}>{props.inputLable}</TextContent>}
              inputStyle={couponCode.length < 1 ? styles.textInput : styles.textInputFilled}
              containerStyle={styles.couponInputContainer}
              value={couponCode}
              onChangeText={(text) => {
                setCouponCode(text);
                formError.couponCode && handleSetFormError('couponCode', false);
                couponError && setCouponError('');
                setCouponApplied(false);
              }}
              editable={!isLoading}
              focusable={!isLoading}
              error={formError.couponCode || formError.unappliedCoupon}
            />
            <Tooltip
              tooltipText={
                formError.unappliedCoupon
                  ? 'Tiene un cupón/código de promoción sin aplicar'
                  : 'Ingrese un código de cupón válido!'
              }
              position="left"
              opacity={1}
              visible={formError.couponCode || formError.unappliedCoupon}
            />
            <View style={styles.btnContainer}>
              <CustomButton
                mode="contained"
                btnStyle={styles.btn}
                onPress={() => applyCoupon && applyCoupon()}
                disabled={isLoading}
              >
                <TextContent style={styles.btnLabel}>{props.buttonLabel}</TextContent>
              </CustomButton>
            </View>
          </View>
          <View style={[styles.bottomContainer]}>
            <Text style={[styles.bottomText]}>{props?.bottomText}</Text>
            <View style={{ width: props?.bottomTextSpace }} />
            <Text
              onPress={() => callbackNavigateExternal('https://estadiocl.zendesk.com/hc/es')}
              style={[styles.bottomLink]}
              disabled={isLoading}
            >
              {props?.bottomLink}
            </Text>
          </View>
        </View>
      </>
    </ModalWrapper>
  );
};

export default CouponModal;
