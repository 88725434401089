import { StyleProp, StyleSheet, TextStyle, ViewStyle } from 'react-native';
import { Breakpoints } from '@warnermmedia/gsp-core/sdk/ui';
import { merge } from 'lodash';

interface Styles {
  fullScreenContainer: ViewStyle;
  container: ViewStyle;
  containerInner: ViewStyle;
  bottomContainer: ViewStyle;
  inputContainer: ViewStyle;
  title: TextStyle;
  description: TextStyle;
  btnLabel: TextStyle;
  btnContainer: ViewStyle;
  btn: ViewStyle;
  closeBtn: TextStyle;
  bottomText: TextStyle;
  bottomLink: TextStyle;
  textInput: TextStyle;
  textInputFilled: TextStyle;
  couponInputContainer: ViewStyle;
  inputLabel: TextStyle;
  messageDisplay: ViewStyle;
  displayIcon: ViewStyle;
}

export const getStyles = (breakpoints: Breakpoints, isTv: boolean, colors: ReactNativePaper.ThemeColors) => {
  const isMobileLandscape = breakpoints.breakpointHelpers.isLandScape;

  const base: StyleProp<Styles> = {
    fullScreenContainer: {
      backgroundColor: colors.tenantBackground.dark.modalBackground,
      position: 'absolute',
      width: '100%',
      height: '100%',
    },
    container: {
      flexDirection: 'column',
      paddingVertical: 48,
      backgroundColor: colors.tenantBackground.dark.surface03,
      alignItems: 'center',
      justifyContent: 'center',
      alignSelf: 'center',
    },
    containerInner: {
      flexDirection: 'column',
      alignItems: 'center',
      width: '100%',
    },
    bottomContainer: {
      flexDirection: 'row',
      marginTop: 24,
      width: '100%',
      flexWrap: 'wrap',
    },
    inputContainer: {
      width: '100%',
      alignItems: 'center',
    },
    title: {
      color: colors.keyboardFocus.light,
      fontFamily: 'Oswald-SemiBold',
      fontSize: 22,
      fontWeight: '600',
      textAlign: 'center',
      textTransform: 'uppercase',
    },
    description: {
      color: colors.keyboardFocus.light,
      fontFamily: 'Rubik-Regular',
      fontSize: 16,
      fontWeight: 'normal',
      lineHeight: 20,
      textAlign: 'center',
      marginVertical: 24,
    },
    btnContainer: {
      width: '100%',
      marginTop: 24,
    },
    btn: {
      backgroundColor: colors.fill.action.accent01,
      borderRadius: 4,
      height: 40,
      width: '100%',
      borderColor: 'transparent',
    },
    btnLabel: {
      color: colors.keyboardFocus.light,
      fontFamily: 'Oswald-Medium',
      fontSize: 20,
      fontWeight: '500',
      textTransform: 'uppercase',
      alignSelf: 'center',
    },
    closeBtn: {
      backgroundColor: 'transparent',
      borderColor: 'transparent',
      alignSelf: 'flex-end',
      fontSize: 20,
      fontWeight: '900',
      textTransform: 'uppercase',
      position: 'absolute',
      top: 15,
      right: 15,
    },
    bottomText: {
      color: colors.keyboardFocus.light,
      fontFamily: 'Rubik-Regular',
      fontSize: 16,
      fontWeight: 'normal',
      lineHeight: 21,
    },
    bottomLink: {
      color: colors.keyboardFocus.light,
      fontFamily: 'Oswald-Regular',
      fontSize: 14,
      fontWeight: 'normal',
      lineHeight: 21,
      textDecorationLine: 'underline',
      textTransform: 'uppercase',
    },
    textInput: {
      backgroundColor: colors.keyboardFocus.light,
      height: 40,
      borderRadius: 4,
      paddingHorizontal: 5,
      width: '100%',
      borderColor: 'transparent',
      opacity: 0.8,
    },
    textInputFilled: {
      backgroundColor: colors.keyboardFocus.light,
      height: 40,
      borderRadius: 4,
      paddingHorizontal: 5,
      width: '100%',
      borderColor: 'transparent',
      opacity: 1,
    },
    couponInputContainer: {
      width: '100%',
      height: 60,
      borderColor: 'transparent',
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
    inputLabel: {
      textAlign: 'left',
      color: colors.keyboardFocus.light,
      fontFamily: 'Rubik-Medium',
      fontSize: 16,
      fontWeight: '500',
      lineHeight: 20,
      marginBottom: 4,
    },
    messageDisplay: {
      minWidth: 200,
      width: '100%',
      marginBottom: 20,
    },
    displayIcon: {
      margin: 0,
      padding: 0,
      alignSelf: 'baseline',
    },
  };

  const mediaQueries = isTv
    ? ({
        container: {
          width: '43%',
          maxWidth: 622,
          paddingHorizontal: 64,
        },
      } as StyleProp<Styles>)
    : breakpoints.currentBreakpoints.isTiny ||
      breakpoints.currentBreakpoints.isTnySm ||
      breakpoints.currentBreakpoints.isSmMed
    ? {
        fullScreenContainer: {
          justifyContent: 'center',
        },
        container: {
          width: '86%',
          paddingHorizontal: 20,
          paddingVertical: isMobileLandscape ? 20 : 48,
        },
        bottomContainer: {
          justifyContent: 'center',
          marginTop: isMobileLandscape ? 10 : 24,
        },
        description: {
          marginVertical: isMobileLandscape ? 5 : 24,
        },
        btnContainer: {
          marginTop: isMobileLandscape ? 10 : 24,
        },
        messageDisplay: {
          marginBottom: isMobileLandscape ? 5 : 20,
        },
      }
    : breakpoints.currentBreakpoints.isMedLg
    ? ({
        container: {
          width: '74%',
          paddingHorizontal: 59,
          marginVertical: '12%',
        },
      } as StyleProp<Styles>)
    : breakpoints.currentBreakpoints.isLgXl ||
      breakpoints.currentBreakpoints.isXlXxl ||
      breakpoints.currentBreakpoints.isXxl
    ? ({
        container: {
          width: '43%',
          paddingHorizontal: 64,
          marginVertical: '12%',
        },
      } as StyleProp<Styles>)
    : {};

  const mergedStyles = merge(base, mediaQueries);

  return StyleSheet.create(mergedStyles);
};
