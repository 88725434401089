import React from 'react';
import { Redirect } from 'react-router';
import { useReactiveVar } from '@apollo/client';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { useFirstLoadingStatus } from '@warnermmedia/gsp-core/brands/estadio/feature';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import {
  isAppTransitioning,
  isUserEmailVerified,
  isUserLoggedIn,
  userHasSubscription,
} from '@warnermmedia/gsp-core/brands/estadio/data-access';
import SkeletonDisplay from '../skeletonDisplay/skeletonDisplay';

export interface RouteObj {
  component: React.ElementType;
  route: string;
  isProtected?: boolean;
}

interface AuthGuardProps {
  children: React.ReactElement;
  isProtected?: boolean;
  route: string;
  isAppEnabled: boolean;
}

export const AuthGuard: React.FC<AuthGuardProps> = ({ children, isProtected, route, isAppEnabled }) => {
  const isLoggedIn = useReactiveVar(isUserLoggedIn);
  const isEmailVerified = useReactiveVar(isUserEmailVerified);
  const hasSub = useReactiveVar(userHasSubscription);
  const isAppLoading = useFirstLoadingStatus();
  const isTransitioning = useReactiveVar(isAppTransitioning);
  const isAllowedTransitionHomeRoute = (route: string) => ['/login', '/logintv-success', '/'].includes(route);
  const isHomeSkipRoute = (route: string) => !['/logintv-success'].includes(route);
  const isAppDisabledRoute = (route: string) => ['/register'].includes(route);

  if (isAppLoading) {
    return <SkeletonDisplay route={route} />;
  }

  if (!isAppEnabled && !isAppDisabledRoute(route) && !isAppLoading) {
    return <Redirect to="/register" />;
  }

  if (
    isLoggedIn &&
    hasSub &&
    isEmailVerified &&
    isAllowedTransitionHomeRoute(route) &&
    isHomeSkipRoute(route) &&
    !isTransitioning
  ) {
    return <Redirect to="/home" />;
  }

  if (!isLoggedIn && isProtected && !isTransitioning) {
    return <Redirect to="/login" />;
  }

  return children;
};
