import merge from 'lodash/merge';
import { StyleProp, StyleSheet, ViewStyle, TextStyle } from 'react-native';

interface Styles {
  button: TextStyle;
  currentEnvStyle: ViewStyle;
}

export const getStyles = (colors: ReactNativePaper.ThemeColors) => {
  const base: StyleProp<Styles> = {
    button: {
      marginVertical: 10,
      width: 100,
    },
    currentEnvStyle: {
      borderWidth: 3,
      borderColor: colors.tenantBackground.light.surfaceBase,
    },
  };

  const mergedStyles = merge(base);

  return StyleSheet.create(mergedStyles);
};
