import React, { useCallback, useContext, useEffect, useState } from 'react';
import { View } from 'react-native';
import get from 'lodash/get';
import {
  languageStrings,
  PagesUrl,
  QueryNames,
  useCMSPageMetaTags,
  useCMSQueryDataResults,
  useGoBack,
  useIsMountedRef,
  useMparticleCustomEventObject,
  useMparticleScreenEvent,
} from '@warnermmedia/gsp-core/brands/estadio/feature';
import {
  breakpointsStateStore,
  HistoryContext,
  loginAPIStateStore,
  Page,
} from '@warnermmedia/gsp-core/brands/estadio/data-access';
import { EstadioButton, LayoutType, TitleType } from '@warnermmedia/gsp-core/brands/estadio/ui';
import { CustomButton, MetaTags, RadioButton, TextContent, useGetDevice } from '@warnermmedia/gsp-core/sdk/ui';
import { getStyles } from './cancelSubscription.styles';
import { useReactiveVar } from '@apollo/client';
import { LayoutWrapper } from '../../../layoutWrapper';
import Retention from '../retention/retention';
import { isEmpty } from 'lodash';
import { MParticleCustomEventTypes, mParticleEventProcessor } from '@warnermmedia/gsp-core/sdk/data-access';
import { useTheme } from 'react-native-paper';

export interface CancellationReason {
  key: number;
  label: string;
}

export const CancelSubscription = () => {
  const [cancelingReason, setCancelingReason] = useState<CancellationReason>({} as CancellationReason);
  const [reasonSelectedEventSent, setReasonSelectedEventSent] = useState(false);
  const { colors } = useTheme();
  const [retentionCouponApplied, setRetentionCouponApplied] = useState('');
  const [showRetention, setShowRetention] = useState(false);
  const breakpoints = useReactiveVar(breakpointsStateStore);
  const isMounted = useIsMountedRef();
  const historyContext = useContext(HistoryContext);
  const history = historyContext?.ready ? historyContext?.useHistory() : null;
  const styles = getStyles(breakpoints, colors);
  const { pageData: pageUriData, loading } = useCMSQueryDataResults<Page>({
    queryName: QueryNames.GET_COMMON_PAGE_DATA,
    uri: PagesUrl.CancelSubscription,
  });
  const { isWeb } = useGetDevice();
  const { type, title, cmsId } = useCMSPageMetaTags(pageUriData);
  useMparticleScreenEvent(type, title, cmsId);
  const mParticleEventData = useMparticleCustomEventObject({ section: type, contentTitle: title, contentId: cmsId });
  const { goBack } = useGoBack();

  const checkboxItems = get(pageUriData, 'components[0].content[0].form.element.options', {});
  const checkboxItemsArray = Object.keys(checkboxItems)
    .filter((key) => key !== '__persist')
    .map((key) => ({ key: Number(key), label: checkboxItems[key] }));

  const subscriptionId: string | undefined = get(history, 'location.state.subscriptionId', '');
  const subscriptionEndDate: string | undefined = get(history, 'location.state.subscriptionEndDate', '');
  const disableBtn = isEmpty(cancelingReason);
  const { preferences } = useReactiveVar(loginAPIStateStore);
  const language = languageStrings.default;

  useEffect(() => {
    if (!subscriptionId && isMounted.current) {
      history?.push('/profile');
    }
  }, [isMounted, history, subscriptionId]);

  useEffect(() => {
    const retentionPref = preferences?.find((item) => item.collection === 'retentionCouponApplied');
    if (retentionPref && isMounted.current) {
      setRetentionCouponApplied(retentionPref.selections[0]);
    }
  }, [preferences, isMounted]);

  const cancelSubscription = useCallback(() => {
    if (disableBtn) {
      return;
    }
    if (subscriptionId && !isEmpty(cancelingReason)) {
      setShowRetention(true);
    }
  }, [disableBtn, subscriptionId, cancelingReason]);

  const goToProfile = useCallback(() => {
    mParticleEventProcessor.pushMParticleEvent(
      [
        ...(!showRetention && isEmpty(cancelingReason)
          ? [MParticleCustomEventTypes.CancellationReasonDismissEvent]
          : []),
        MParticleCustomEventTypes.CancellationDeclineEvent,
      ],
      mParticleEventData
    );
    goBack();
  }, [mParticleEventData, cancelingReason, showRetention, goBack]);

  const renderCheckboxItems = () => {
    if (checkboxItemsArray && checkboxItemsArray.length) {
      return checkboxItemsArray.map((item: { key: number; label: string }) => (
        <View style={styles.radioWrapper} key={`canceling-reason-${item.key}`}>
          <RadioButton
            status={cancelingReason.key === item.key ? 'checked' : 'unchecked'}
            onPress={() => {
              setCancelingReason({ key: item.key, label: item.label });
            }}
            value={item.label}
            borderColor={cancelingReason.key === item.key ? colors.tenantBackground.light.surfaceBase : '#CCC'}
            checkedColor={colors.fill.action.accent01}
            uncheckedColor={colors.tenantBackground.light.surfaceBase}
          />
          <TextContent style={styles.label}>{item.label}</TextContent>
        </View>
      ));
    }
    return null;
  };

  if (showRetention && !isEmpty(cancelingReason) && subscriptionId && subscriptionEndDate) {
    if (!reasonSelectedEventSent) {
      mParticleEventProcessor.pushMParticleEvent(
        MParticleCustomEventTypes.CancellationReasonSelectedEvent,
        mParticleEventData
      );
      setReasonSelectedEventSent(true);
    }
    return (
      <Retention
        subscriptionId={subscriptionId}
        cancelingReason={cancelingReason}
        subscriptionEndDate={subscriptionEndDate}
        retentionCouponApplied={retentionCouponApplied}
      />
    );
  }

  return (
    <LayoutWrapper
      pageTitle={language.cancelSubscriptionTitle}
      layoutType={LayoutType.Full}
      titleType={TitleType.Left}
      subTitle={language.cancelSubscriptionSubTitle}
    >
      {isWeb && <MetaTags title={title} />}
      <View style={styles.optionsContainer}>{renderCheckboxItems()}</View>
      <View style={styles.buttonContainer}>
        <EstadioButton
          loading={loading}
          onPress={cancelSubscription}
          label={language.continueText}
          disabled={disableBtn}
          labelStyle={{
            fontFamily: 'Oswald-Regular',
            textTransform: 'uppercase',
          }}
          btnStyle={{
            maxWidth: 303,
          }}
        />
      </View>
      <View style={styles.buttonContainer}>
        <CustomButton
          mode="text"
          btnStyle={styles.returnBtn}
          labelStyle={styles.returnBtnText}
          onPress={goToProfile}
          label={language.backToAccountLabel}
        />
      </View>
    </LayoutWrapper>
  );
};

export default CancelSubscription;
