import React from 'react';
import { Linking, View } from 'react-native';
import { CustomButton } from '@warnermmedia/gsp-core/sdk/ui';
import { breakpointsStateStore } from '@warnermmedia/gsp-core/brands/estadio/data-access';
import { getStyles } from './downloadInvoice.styles';
import { useReactiveVar } from '@apollo/client';
import { useTheme } from 'react-native-paper';

interface DownloadInvoiceProps {
  handleReturn: () => void;
  link: string;
}

export const DownloadInvoice = ({ handleReturn, link }: DownloadInvoiceProps) => {
  const breakpoints = useReactiveVar(breakpointsStateStore);
  const { colors } = useTheme();
  const styles = getStyles(breakpoints, colors);
  const downloadFile = () => {
    Linking.openURL(link);
    handleReturn();
  };

  return (
    <View>
      <CustomButton
        labelStyle={styles.btnLabel}
        btnStyle={[styles.btnWrapper]}
        mode="contained"
        onPress={downloadFile}
        label="DESCARGAR"
      />
      <CustomButton
        labelStyle={styles.volverBtnLabel}
        btnStyle={[styles.volverBtn]}
        mode="text"
        onPress={handleReturn}
        label="Volver"
      />
    </View>
  );
};

export default DownloadInvoice;
