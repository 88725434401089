import { name, version } from './lib/version/autogenerated_version.json';

import getCurrentLocale from './lib/Services/getCurrentLocale';
import Constants from './lib/Utils/Constants';
import Colors from './lib/Utils/Colors';
import PrimitiveColors from './lib/Utils/PrimitiveColors';
import Sizes from './lib/Utils/Sizes';
import DEFAULT_FOOTER from './lib/Services/sample_footer_response';
import * as languageStrings from './lib/I18n';

export { name, version, getCurrentLocale, Constants, Colors, PrimitiveColors, Sizes, DEFAULT_FOOTER, languageStrings };

export * from './lib/Hooks/Footer';
export * from './lib/Hooks/dalton';
export * from './lib/Hooks/tve';
export * from './lib/Hooks/pinAndPair';
export * from './lib/Hooks/pinAndPair/usePinAndPairActions';
export * from './lib/Hooks/cmsPage';
export * from './lib/Hooks/useModal';
export * from './lib/Hooks/useCMSPageMetaTags/useCMSPageMetaTags';
export * from './lib/Services/CommonFunctions';
export * from './lib/Services/DeepLinking';
export * from './lib/Services/sample_footer_response';
export * from './lib/Types/RoutesParamList';
export * from './lib/Utils/Interfaces';
export * from './lib/Utils/Platform';
export * from './lib/Utils/PropTypesValidators';
export * from './lib/Utils/Constants';
export * from './lib/Utils/estadiosErrorHandle';
export * from './lib/Utils/videoPlayerError';
export * from './lib/Utils/estadioCarouselTest/mock-data';
export * from './lib/Hooks/useRouter/useRouter';
export * from './lib/Hooks/useStatusMessage/useStatusMessage';
export * from './lib/Hooks/useTickerData';
export * from './lib/Hooks/useRouter/useReactRouter';
export * from './lib/Hooks/useRouter/useReactRouterNative';
export * from './lib/Types/UseRouterTypes';
export * from './lib/Services/getCurrentLocale';
export * from './lib/Hooks/useVideo/useVideo';
export * from './lib/Hooks/useCardFilters/useCardFilters';
export * from './lib/Hooks/useProfileData';
export * from './lib/Hooks/useMobilePlayer/useMobilePlayer';
export * from './lib/Hooks/useGetBillingStatus';
export * from './lib/Hooks/useEmailStatus';
export * from './lib/Hooks/useEPGData';
export * from './lib/Hooks/useVideoTag/useVideoTag';
export * from './lib/Hooks/useVideoData/useVideoData';
export * from './lib/Hooks/useMparticleScreenEvent';
export * from './lib/Hooks/useMparticleCustomEventObject';
export * from './lib/Hooks/useZendesk/useZendesk';
export * from './lib/Hooks/useIsMountedRef';
export * from './lib/Hooks/useUpcomingEventCountdown';
export * from './lib/Hooks/useFocusType';
export * from './lib/Hooks/useFormatCarouselData/useFormatCarouselData';
export * from './lib/Hooks/useWebos';
export * from './lib/Hooks/useReducedHeroWidth';
export * from './lib/LayoutUpdate/LayoutUpdateProvider';
export * from './lib/Hooks/useMparticleEventProcessor';
export * from './lib/Hooks/useHeartBeat';
export * from './lib/Hooks/useInterval';
export * from './lib/Hooks/useEstadioDalton/useEstadioDalton';
export * from './lib/Hooks/useLogout';
export * from './lib/Hooks/useSetCardIndex';
export * from './lib/Hooks/useScrollToTop';
export * from './lib/Hooks/useGoBack';
export * from './lib/Hooks/useBreakpoints';
export * from './lib/Hooks/useTveRestoreSession';
export * from './lib/Hooks/useValidateEmailCode';
export * from './lib/Hooks/useFirstLoadingStatus';
export * from './lib/Hooks/useLiveTvActions/useLiveTvActions';
export * from './lib/Hooks/useRefreshToken';
export * from './lib/Hooks/useCardItemImage';
export * from './lib/Hooks/useHideSwimlaneArrow/useHideSwimlaneArrow';
export * from './lib/Hooks/useOnEnterKeyPress';
export * from './lib/Hooks/pinAndPair';
export * from './lib/Hooks/useScaleSizing';
