import React, { useCallback, useContext, useEffect, useState } from 'react';
import { ImageBackground, ScrollView, Text, View } from 'react-native';
import { getStyles } from './register.styles';
import { useReactiveVar } from '@apollo/client';
import {
  AppConfigContext,
  breakpointsStateStore,
  componentTypes,
  HistoryContext,
  Link,
  Page,
} from '@warnermmedia/gsp-core/brands/estadio/data-access';
import {
  callbackNavigateExternal,
  carouselFormat,
  getImageWithStyle,
  getLocalLink,
  languageStrings,
  PagesUrl,
  QueryNames,
  useCardItemImage,
  useCMSPageData,
  useCMSPageMetaTags,
  useCMSQueryDataResults,
  useIsMountedRef,
  useMparticleScreenEvent,
} from '@warnermmedia/gsp-core/brands/estadio/feature';
import { SubscriptionProducts } from '../../subscriptionProduct';
import { ImageAssets } from '@warnermmedia/gsp-core/brands/estadio/assets';
import { MetaTags, useGetDevice } from '@warnermmedia/gsp-core/sdk/ui';
import { useTheme } from 'react-native-paper';
import { SkeletonDisplay } from '../../skeletonDisplay';
import { get } from 'lodash';

export const Register = () => {
  const historyContext = useContext(HistoryContext);
  const history = historyContext?.ready ? historyContext?.useHistory() : null;
  const appConfig = useContext(AppConfigContext);
  const subscribePackLink = appConfig?.appConfig?.packFutbolUrl ?? 'https://www.packfutbol.com.ar/';
  const { pageData: pageUriData, loading } = useCMSQueryDataResults<Page>({
    queryName: QueryNames.GET_PRODUCTS,
    uri: PagesUrl.Register,
  });
  const [display, setDisplay] = useState<carouselFormat[] | null>(null);
  const { metaTags, title, type, cmsId } = useCMSPageMetaTags(pageUriData);
  const breakpoints = useReactiveVar(breakpointsStateStore);
  const { colors } = useTheme();
  const styles = getStyles(breakpoints, colors);

  const { formatData } = useCMSPageData();
  useMparticleScreenEvent(type, title, cmsId);
  const isMountedRef = useIsMountedRef();
  const { isWeb } = useGetDevice();
  const { getCardImageSource } = useCardItemImage();
  const touts = display && display.filter(({ type }) => type === componentTypes.TOUT);
  const landingHero = display && display.filter(({ type }) => type === componentTypes.SCREEN);
  const marketingImage = get(landingHero, '[0].image.url', null);
  const loginLink: Link | null = get(landingHero, '[0].link', null);
  const loginLabel = get(loginLink, 'text', languageStrings.default.enterText);
  const product = get(landingHero, '[0].content[0].product[0]', null);

  const backgroundImage = getCardImageSource({
    fallbackName: ImageAssets.stadiumBackground,
    image: getImageWithStyle(marketingImage),
  });

  useEffect(() => {
    if (pageUriData && pageUriData.components && isMountedRef.current) {
      setDisplay(formatData(pageUriData.components));
    }
  }, [pageUriData, isMountedRef]);

  const handleLogin = useCallback(() => {
    const link: string = getLocalLink(loginLink?.uri ?? '/login');
    history?.push(link);
  }, [history, loginLink]);

  const handleSubscribe = useCallback(() => {
    callbackNavigateExternal(subscribePackLink);
  }, [subscribePackLink]);

  return !loading ? (
    <>
      {isWeb && <MetaTags data={metaTags} title={title} />}
      <ScrollView>
        <View>
          <ImageBackground source={backgroundImage} resizeMode="cover" style={styles.imageBackground}>
            <View style={styles.productWrapper}>
              <View style={styles.productContainer}>
                <View style={styles.productTextContent}>
                  <SubscriptionProducts
                    handleLogin={handleLogin}
                    handleSubscribe={handleSubscribe}
                    product={product}
                    loginLabel={loginLabel}
                  />
                </View>
              </View>
            </View>
          </ImageBackground>
          <View style={styles.explanationContainer}>
            <View style={styles.explanationElements}>
              {touts &&
                touts.map(({ headline, description }) => (
                  <View key={headline} style={styles.explanationGroup}>
                    <Text style={styles.explanationHeader}>{headline}</Text>
                    <View>
                      <Text style={styles.explanationText}>{description}</Text>
                    </View>
                  </View>
                ))}
            </View>
          </View>
        </View>
      </ScrollView>
    </>
  ) : (
    <SkeletonDisplay route="/register" />
  );
};

export default Register;
