import React, { KeyboardEvent } from 'react';
import { ViewStyle, Pressable, PressableProps } from 'react-native';

export interface PressableWithOpacityProps extends PressableProps {
  overrideStyle?: ViewStyle | ViewStyle[];
  onKeyDown?: (e: KeyboardEvent) => void;
}

export const PressableWithOpacity = ({ overrideStyle, children, disabled, ...rest }: PressableWithOpacityProps) => {
  return (
    <Pressable
      style={({ pressed }) => [overrideStyle, { opacity: disabled ? 0.5 : pressed ? 0.5 : 1.0 }]}
      disabled={disabled}
      {...rest}
    >
      {children}
    </Pressable>
  );
};

export default PressableWithOpacity;
