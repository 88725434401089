import merge from 'lodash/merge';
import { StyleProp, StyleSheet, ViewStyle, TextStyle } from 'react-native';
import { Breakpoints } from '@warnermmedia/gsp-core/sdk/ui';

interface Styles {
  wrapper: ViewStyle;
  title: TextStyle;
  ctnBtn: ViewStyle;
  ctnBtnLabel: TextStyle;
  payPalWrapper: ViewStyle;
  paypalInfo: TextStyle;
  payPalBtnWrapper: ViewStyle;
  payPalInfoWrapper: ViewStyle;
  consentMessage: TextStyle;
  carouselPadding: ViewStyle;
  backArrow: ViewStyle;
  forwardArrow: ViewStyle;
}

export const getStyles = (breakpoints: Breakpoints, colors: ReactNativePaper.ThemeColors) => {
  const base: StyleProp<Styles> = {
    wrapper: {
      width: '100%',
      flexDirection: 'column',
    },
    title: {
      fontFamily: 'Oswald-SemiBold',
      fontSize: 33,
      fontWeight: '600',
      height: 49,
      textTransform: 'uppercase',
      alignSelf: 'flex-start',
    },
    ctnBtn: {
      width: '100%',
      backgroundColor: colors.fill.action.accent01,
      maxWidth: 367,
      borderColor: 'transparent',
      height: 40,
      marginTop: 24,
    },
    ctnBtnLabel: {
      fontFamily: 'Oswald-Medium',
      fontSize: 20,
      fontWeight: '500',
    },
    payPalWrapper: {
      flex: 1,
      flexDirection: 'column',
    },
    paypalInfo: {
      fontFamily: 'Rubik-Regular',
      fontSize: 14,
      fontWeight: 'normal',
      lineHeight: 20,
      textAlign: 'center',
      alignSelf: 'flex-start',
    },
    payPalBtnWrapper: {
      marginTop: 48,
    },
    payPalInfoWrapper: {
      paddingTop: 20,
    },
    consentMessage: {
      fontFamily: 'Rubik-Medium',
      fontSize: 12,
      lineHeight: 16,
      fontWeight: '500',
    },
    carouselPadding: {
      paddingLeft: 25,
      paddingRight: 25,
    },
    backArrow: {
      left: 0,
    },
    forwardArrow: {
      right: 0,
    },
  };

  const mediaQueries =
    breakpoints.currentBreakpoints.isTiny ||
    breakpoints.currentBreakpoints.isTnySm ||
    breakpoints.currentBreakpoints.isSmMed
      ? ({} as StyleProp<Styles>)
      : {};

  const mergedStyles = merge(base, mediaQueries);

  return StyleSheet.create(mergedStyles);
};
