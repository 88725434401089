import React from 'react';
import { PressableWithOpacity } from '../pressableWithOpacity';
import styles from './radioButton.styles';

export interface RadioBtnProps {
  value: string;
  uncheckedColor: string;
  checkedColor: string;
  borderColor?: string;
  status: 'checked' | 'unchecked';
  onPress: (value: string) => void;
  disabled?: boolean;
}

export const RadioButton = ({
  value,
  uncheckedColor,
  checkedColor,
  status,
  onPress,
  borderColor,
  disabled = false,
}: RadioBtnProps) => {
  return (
    <PressableWithOpacity
      overrideStyle={[
        styles.button,
        {
          borderColor,
          backgroundColor: status === 'checked' ? checkedColor : uncheckedColor,
        },
      ]}
      disabled={disabled}
      onPress={() => onPress(value)}
    />
  );
};

export default RadioButton;
