import React, { useCallback, useEffect, useState } from 'react';
import { View } from 'react-native';
import {
  CustomButton,
  CustomTextInput,
  MetaTags,
  TextContent,
  Tooltip,
  useGetDevice,
  validateNonEmptyString,
  Variant,
} from '@warnermmedia/gsp-core/sdk/ui';

import {
  MParticleCustomEventTypes,
  mParticleEventProcessor,
  Subscription,
} from '@warnermmedia/gsp-core/sdk/data-access';
import CouponRedemptionModal from '../../../modal/couponRedemptionModal';
import { LayoutType, LayoutWrapper, TitleType } from '../../../layoutWrapper';
import { useReactiveVar } from '@apollo/client';
import { breakpointsStateStore } from '@warnermmedia/gsp-core/brands/estadio/data-access';
import { AxiosError } from 'axios';
import {
  callbackNavigateExternal,
  Dalton,
  getContentTitle,
  languageStrings,
  ScreenEventType,
  useIsMountedRef,
  useMparticleCustomEventObject,
  useMparticleScreenEvent,
  useStatusMessage,
} from '@warnermmedia/gsp-core/brands/estadio/feature';
import { useTheme } from 'react-native-paper';
import get from 'lodash/get';
import { getStyles } from './redeemCoupon.styles';

export const RedeemCoupon = () => {
  const language = languageStrings.default;
  const dalton = Dalton();
  const [code, setCode] = useState('');
  const [hasError, setHasError] = useState(false);
  const [loading, setLoading] = useState(false);
  const { setStatusMessage } = useStatusMessage();
  const [subscription, setSubscription] = useState<Subscription>();
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [termsError, setTermsError] = useState(false);
  const [validationDisabled, setValidationDisabled] = useState(false);
  const [couponModalVisible, setCouponModalVisible] = useState(false);
  const isMountedRef = useIsMountedRef();
  const breakpoints = useReactiveVar(breakpointsStateStore);
  const { colors } = useTheme();
  const styles = getStyles(breakpoints, colors);
  const currentWindowWidth = breakpoints.windowWidth;
  const { isWeb, isMobileDevice } = useGetDevice();
  const isMobileSize = isMobileDevice || currentWindowWidth <= breakpoints.breakpointSizes.lg;
  const redeemCouponErrorMessage = language.redeemCouponErrorMessage;
  const mParticleEventData = useMparticleCustomEventObject({
    contentTitle: language.redeemCouponTitle,
    section: ScreenEventType.RedeemCoupon,
  });
  useMparticleScreenEvent(ScreenEventType.RedeemCoupon, language.redeemCouponTitle);

  useEffect(() => {
    (async (): Promise<void> => {
      const result = await dalton.getSubscriptions();
      if (isMountedRef.current && result.success) {
        // making this find to get an active subscription since there is a
        // possibility to have more than one subscription in this array
        const subscription = get(result.data, 'subscriptions', []).find(
          (subscription: Subscription) => subscription.active === true
        );
        setSubscription(subscription);
      }
    })();
  }, [isMountedRef]);

  const onChangeModalVisibility = useCallback(() => {
    setCouponModalVisible(false);
    setTermsAccepted(false);
    setTermsError(false);
  }, []);

  // keeping this implementation for post mvp specific error handling
  const handleRedeemCouponError = useCallback(
    /* eslint-disable @typescript-eslint/no-explicit-any */
    (error: AxiosError<any>) => {
      const errorCode = error?.response?.data.errors[0].error;
      let errorMessage = '';
      switch (errorCode) {
        case '400':
          if (subscription?.terminationDateMillis) {
            errorMessage = language.subscriptioncancelledMessage;
          } else {
            errorMessage = language.subscriptionUsedPromocodeMessage;
          }
          break;
        case 'promocode.invalid':
          errorMessage = language.subscriptionInvalidPromocodeMessage;
          break;
        default:
          errorMessage = redeemCouponErrorMessage;
          break;
      }
      errorMessage && setStatusMessage({ message: errorMessage, type: Variant.Error });
    },
    [
      subscription?.terminationDateMillis,
      setStatusMessage,
      language.subscriptioncancelledMessage,
      language.subscriptionUsedPromocodeMessage,
      language.subscriptionInvalidPromocodeMessage,
      redeemCouponErrorMessage,
    ]
  );

  const handleRedeemCoupon = useCallback(
    (coupon: string) => {
      dalton
        .redeemCoupon({
          coupon,
          subscriptionId: subscription?.id as string,
        })
        .then((response) => {
          setLoading(false);
          onChangeModalVisibility();
          setValidationDisabled(false);
          if (response.success) {
            setCode('');
            setStatusMessage({ message: language.subscriptionSuccessMessage, type: Variant.Success });
            mParticleEventProcessor.pushMParticleEvent(MParticleCustomEventTypes.RedeemCouponEvent, mParticleEventData);
          } else {
            // setting this to generic message according to brd
            setStatusMessage({ message: redeemCouponErrorMessage, type: Variant.Error });
          }
        });
    },
    [mParticleEventData, dalton, subscription?.id, setStatusMessage, onChangeModalVisibility]
  );

  const onValidate = () => {
    setValidationDisabled(true);

    if (!validateNonEmptyString(code)) {
      setHasError(true);
    } else {
      setCouponModalVisible(true);
      setValidationDisabled(false);
    }
  };

  const handleSubmit = useCallback(async () => {
    const invalidCode = !validateNonEmptyString(code);
    if (invalidCode || !termsAccepted) {
      setHasError(invalidCode);
      setTermsError(!termsAccepted);
      setValidationDisabled(false);
      return;
    }

    if (subscription) {
      setLoading(true);
      const mapCoupon = await dalton.mapCoupon(code);
      const coupon = get(mapCoupon?.data, 'mapped_code', code);
      handleRedeemCoupon(coupon);
    } else {
      setValidationDisabled(false);
      onChangeModalVisibility();
      setStatusMessage({ message: redeemCouponErrorMessage, type: Variant.Error });
    }
  }, [
    code,
    termsAccepted,
    subscription,
    redeemCouponErrorMessage,
    dalton,
    setStatusMessage,
    handleRedeemCoupon,
    onChangeModalVisibility,
  ]);

  const setAcceptTerms = (val: boolean) => {
    setTermsAccepted(val);
    if (val && termsError) {
      setTermsError(false);
    }
  };

  const handleSetTermsError = (_: string, value: boolean) => {
    setTermsError(value);
  };

  const handleValidateCoupon = () => {
    setHasError(!validateNonEmptyString(code));
  };

  return (
    <>
      <LayoutWrapper
        pageTitle={language.redeemCouponTitle}
        layoutType={LayoutType.Center}
        titleType={TitleType.Left}
        subTitle={language.redeemCouponSubTitle}
      >
        {isWeb && <MetaTags title={getContentTitle(language.redeemCouponTitle)} />}
        <View style={styles.inputWrapper}>
          <TextContent style={styles.label}>{language.code}</TextContent>
          <CustomTextInput
            inputStyle={code.length < 1 ? styles.input : styles.inputFilled}
            value={code}
            onChangeText={(text) => {
              setCode(text);
              if (text && hasError) {
                setHasError(false);
              }
            }}
            error={hasError}
            onBlur={handleValidateCoupon}
          />
          <Tooltip tooltipText={language.couponToolTip} visible={hasError} opacity={1} position="left" />
        </View>
        <CustomButton
          mode="contained"
          btnStyle={[
            styles.btn,
            {
              backgroundColor: validationDisabled ? colors.fill.action.accent01Pressed : colors.fill.action.accent01,
              borderWidth: 0,
            },
          ]}
          onPress={onValidate}
          label={language.valid}
          disabled={validationDisabled}
        ></CustomButton>
        <View style={isMobileSize ? styles.contactUsMobileSectionContainer : styles.contactUsWebSectionContainer}>
          <TextContent style={styles.contactUsMessage}>{language.contactUsMessage}</TextContent>
          <CustomButton
            mode="text"
            btnStyle={styles.contactUsBtn}
            labelStyle={styles.underlinedText}
            onPress={() => callbackNavigateExternal('https://estadiocl.zendesk.com/hc/es')}
            label={language.contactUsMessageButton}
          />
        </View>
      </LayoutWrapper>

      <CouponRedemptionModal
        isLoading={loading}
        visible={couponModalVisible}
        title={language.couponRedemptionTitle}
        description={language.couponRedemptionDescription}
        termsText={language.couponTermsText}
        tooltipText={language.couponRedemptionToolTip}
        buttonLabel={loading ? language.loadingText : language.confirm}
        handleSubmit={handleSubmit}
        onChangeVisible={onChangeModalVisibility}
        setAcceptTerms={setAcceptTerms}
        termsAccepted={termsAccepted}
        termsError={termsError}
        handleSetTermsError={handleSetTermsError}
      />
    </>
  );
};

export default RedeemCoupon;
