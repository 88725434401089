import { getDurationTime, getLocalDate, now } from '@warnermmedia/gsp-core/sdk/ui';
import { useEffect, useRef, useState } from 'react';
import { isStartingTimeLessThanInHours } from '../../Services/CommonFunctions';
import { useIsMountedRef } from '../useIsMountedRef';
import { ARGENTINA_TIMEZONE } from '@warnermmedia/gsp-core/brands/estadio/data-access';

export const useUpcomingEventCountdown = (isUpcomingEvent: boolean, scheduled_utc?: string, type?: string) => {
  const [countDown, setCountDown] = useState('');
  const isMountedRef = useIsMountedRef();
  const timerRef = useRef<ReturnType<typeof setTimeout>>();

  useEffect(() => {
    if (isMountedRef.current && scheduled_utc) {
      const date = getLocalDate(scheduled_utc, type && type === 'epg' ? ARGENTINA_TIMEZONE : null);

      if (isUpcomingEvent && isStartingTimeLessThanInHours(date)) {
        timerRef.current = setTimeout(() => {
          setCountDown(getDurationTime(now().diff(date)));
        }, 1000);
      }
    }

    return () => {
      if (timerRef.current) {
        clearInterval(timerRef.current);
      }
    };
  }, [countDown, isMountedRef, isUpcomingEvent, scheduled_utc, type]);

  return countDown;
};

export default useUpcomingEventCountdown;
