import React from 'react';
import { Image, TouchableOpacity } from 'react-native';
import { TveProvider } from '@warnermmedia/gsp-core/sdk/data-access';
import styles from './provider.styles';

export interface ProviderRenderProps {
  onPress: (provider: TveProvider) => void;
  provider: TveProvider;
  disabled?: boolean;
}

export function Provider({ onPress, provider, disabled }: ProviderRenderProps) {
  return (
    <TouchableOpacity
      disabled={disabled}
      key={provider.shortName}
      style={styles.provider}
      onPress={() => onPress(provider)}
    >
      <Image
        source={{
          uri: provider.logo,
        }}
        style={styles.providerLogoImage}
        resizeMode="contain"
      />
    </TouchableOpacity>
  );
}

export default Provider;
